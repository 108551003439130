import React, { CSSProperties } from 'react'
import { View } from 'react-native'
import { Button } from '..'
import Modal, { ModalBody, ModalFooter, ModalText } from '.'


export interface MessageProps {
    message:string;
    variant?: 'success' | 'error';
    onClose: () => void;
    onOK: () => void;
    show: boolean;
    OKButtonLabel?: string;
    messageStyle?: CSSProperties;
    minWidth?: string;
}

export default function Message(props: MessageProps) {

    return (
        <>

                <Modal
                    visible={props.show}
                    onClose={props.onClose}
                    variant={props.variant}
                    minWidth={props.minWidth}
                    >
                    <View accessibilityRole="combobox">
                    
                    <ModalBody>
                        <ModalText style={props.messageStyle}>{props.message}</ModalText>
                    </ModalBody>
                    <ModalFooter>
                         <Button label={props.OKButtonLabel?props.OKButtonLabel:"OK"} onPress={props.onOK} />
                    </ModalFooter>
                    </View>
                </Modal>
            
          
        </>
    )
}