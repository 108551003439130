import React from 'react'
import { Text } from '../../components'
import Button from '../../components/button'

export interface StepButtonProps {
    currentStep: number;
    nextDisabled: boolean;
    backDisabled: boolean;
    onClickNext: () => void;
    onClickPrevious: () => void;
}

export default function StepButtons(props: StepButtonProps) {
  return (
    <>
        <div style={{display: 'flex', alignItems: 'center', marginTop: 40 }}>
            <Button variant={props.currentStep === 1 ? "default" : "secondary"} disabled={props.backDisabled} onPress={props.onClickPrevious} label={props.currentStep === 1 ? 'Home' : 'Back'}></Button>
            <Button style={{marginLeft: 20}} variant={props.currentStep === 4 ? "default-orange" : "default"} disabled={props.nextDisabled} onPress={props.onClickNext} label={props.currentStep === 4 ? 'Submit' : 'Next'}></Button>
        </div>
    </>
  )
}