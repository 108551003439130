import React, { useEffect, useState } from 'react'
import { Question, Row, RadioButtonWrapper } from '../elements'
import QuestionContainer from '../questionContainer'
import { getErrorText } from '../constants'
import Text from '../../TextComponent'
import RadioButton from '../../radiobutton';
import RadioADA from '../../RadioADA'
import { View} from 'react-native'

interface IDidCompleteTask {
  errors: any;
  handleSetValue: (qId: string, answer: string) => void;
}

export default function DidCompleteTask(props: IDidCompleteTask) {
  const { errors, handleSetValue } = props
  const [ selected, setSelected ] = useState("")
  const [ isError, setIsError ] = useState(false)

  useEffect(() => {
    if(typeof errors['completedTask'] !== 'undefined') {
      setIsError(true)
    }
  }, [errors])

  const onCheck = (answer) => {
    handleSetValue("completedTask", answer)
    setSelected(answer)
    if(isError) {
      setIsError(false)
    }
  }
  return (
    <Question>
       <View style={{flexDirection:'row'}}>
          <Text size={16} style={{marginBottom: 8}} bold={true}>Were you able to complete your task?</Text>
          <Text size={16}> (Required)</Text>
      </View>
      <QuestionContainer
        aria-invalid={isError ? 'true' : 'false' }
        error={isError}
        errorMessage={getErrorText(errors['completedTask'])}
        id="error-completeTask"
      >
        <RadioADA aria-required="true">
          <Row>
            <RadioButtonWrapper>
              <RadioButton onCheck={() => onCheck("Yes")} selected={selected === "Yes"} label={"Yes"} />
            </RadioButtonWrapper>
            <RadioButtonWrapper>
              <RadioButton onCheck={() => onCheck("No")} selected={selected === "No"} label={"No"} />
            </RadioButtonWrapper>
          </Row>
        </RadioADA>
      </QuestionContainer>
    </Question>
  )
}