import axiosInstance from "../../../redux/axiosInstance";
import { Document} from '../../../redux/documents/types'
import  mime  from 'mime-types'
import { AxiosResponse } from "axios";
import { useOverlay } from "../../../context-api/overlay/overlayState";

 export interface DownloadResult{
    buffer?:ArrayBuffer
    errorMessage?:string 
    exception?:Error
  }
 
 
 export default  function useDocument(caseId:string){
  const documentName="Your_Negotiation_Plan.pdf"
  const { overlay } = useOverlay()
  
  const fetchDocuments =async  ()=>  {
    let result:Document[]=null;

    try {
      const axios = await axiosInstance()
      const response:AxiosResponse<Document[]> = await axios.get(`/document-lists?case-id=${caseId}`)
      result=response.data

      }
       
    catch (e) {}

    return result
    
  }

  const  fetchDocument =async  ()=>  {
    let result:DownloadResult=null;
    let documents=await fetchDocuments()
   
    if(documents && documents.length>0){
      try {
          const axios = await axiosInstance()
          const response = await axios.get(`/documents/${documents[0].id}?case-id=${caseId}`,{responseType: 'arraybuffer'})
          result={ buffer: response.data}
          } 
      catch (e) {
            let message="Error downloading";

            if(e.response && e.response.data)
              message=Buffer.from(e.response.data).toString('utf8')

            result={errorMessage:message,exception:e}
      
      }
   }

    return result
    
  }
    
  const createDocumentURL=async()=>{
       
      const result:DownloadResult = await fetchDocument();
      let url ="";
  
     
      if(result && !result.errorMessage){
      const mimeType = mime.lookup(documentName)
      const blob = new Blob([result.buffer], { type: mimeType })
      url=URL.createObjectURL(blob)
    }
   
    return url;
  }

  const print= async()=>{
    const url= await createDocumentURL();

    if (url){
      window.open(url);
      URL.revokeObjectURL(url);
    }
    else{
      overlay({
            show: true,
            type: 'message',
            message: 'Plan was not found.',
            messageType: 'error',
            buttonLabel: 'Close'
        })
      }
  }

  const download= async()=>{
   const url= await createDocumentURL();

    if(url){
      const link=document.createElement('a');
      link.href=url;
      link.download=documentName;
      link.click();
      URL.revokeObjectURL(url);
    }
    else{
      overlay({
            show: true,
            type: 'message',
            message: 'Plan was not found.',
            messageType: 'error',
            buttonLabel: 'Close'
        })
      }
  }
   
  return {print,download}
}