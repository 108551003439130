import React, { useLayoutEffect, useState } from 'react'
import { Dimensions } from 'react-native'

export default function useWindowResize() {
  const [size, setSize] = useState({ width: 0, height: 0 });
  useLayoutEffect(() => {
    function updateSize(event) {
      const { width, height } = event.window; 
      setSize({ width, height });
    }
    setSize({ width: window.innerWidth, height: window.innerHeight });

    Dimensions.addEventListener('change', updateSize);

    return () => Dimensions.removeEventListener('change', updateSize);
    
  }, []);

  return size; 
}