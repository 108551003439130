import React from "react";
import { View } from "react-native";
import styled from 'styled-components/native'
import { Button,Text } from "../../../components";

interface NegotiationErrorProps{
    onTryAgain:()=>void,
    message?:string
}

export default function NegotiationError(props:NegotiationErrorProps) {
    return(
        <View style={{ flexDirection:'column', alignItems:'center'}}>
            <FailureText>Oops! something went wrong</FailureText>
            {props.message && <MessageText>{props.message}</MessageText>}
            <Button onPress={props.onTryAgain}  label='Try Again' style={{marginTop:32}}></Button>
        </View>
    )
}

const FailureText = styled(Text)`
  font-family: SourceSansPro-Bold;
  font-size: 24px;
  color: #002C73;
  margin-bottom:12px;
`;
const MessageText = styled(Text)`
  font-family: SourceSansPro-Regular;
  font-size: 18px;
`;