import React, { useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { Question, Row } from '../elements'
import QuestionContainer from '../questionContainer'
import { getErrorText } from '../constants'
import Text from '../../TextComponent'
import Button from '../../button'

interface IFeedbackType {
  errors: any;
  handleSetValue: (qId: string, answer: string) => void;
}
export default function FeedbackType(props: IFeedbackType) {
  const { errors, handleSetValue } = props
  const [ selected, setSelected ] = useState("")
  const [ isError, setIsError ] = useState(false)

  useEffect(() => {
    if(typeof errors['feedbackType'] !== 'undefined') {
      setIsError(true)
    }
  }, [errors])

  const onSelect = (option) => {
    setSelected(option)
    handleSetValue("feedbackType", option)
    if(isError) {
      setIsError(false)
    }
  }
  return (
    <Question>
      <View style={{flexDirection:'row'}}>
        <Text style={{marginBottom: 7}} size={16} bold={true}>What type of feedback would you like to share?</Text>
        <Text size={16}> (Required)</Text>
      </View>
      <QuestionContainer
        aria-invalid={isError ? 'true' : 'false' }
        error={isError}
        errorMessage={getErrorText(errors['feedbackType'])}
        styleOnError={{paddingTop: 0}}
        id="error-feedbackType"
      >
        <Row aria-required="true">
          {
            ["Suggestion", "Dislike", "Compliment"].map((option) => {
              return (
                <View style={styles.buttonStyle}>
                  <Button 
                    key={`feedbacktype-${option}`}
                    label={option} 
                    onPress={() => onSelect(option)} 
                    variant={selected === option ? "default" : "secondary"} 
                  />
                </View>
              )
            })
          }
        </Row>
      </QuestionContainer>
    </Question>
  )
}

const styles = StyleSheet.create({
  buttonStyle: {
    marginRight: 8,
    marginTop: 8
  }
})