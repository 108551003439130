import { Task } from './../redux/cases/types';

export const getTaskName = (task: Task, providerName?: string, inProgress?: boolean) => {
    switch (task.taskName) {
        case 'Upload a Bill':
            return 'Upload your bill'
        case 'Member Story':
          return `${(inProgress && !task.taskCompleteInd)  ? 'Continue telling' : 'Tell'} us your experience with ${providerName}`
        case 'ESign':
            return 'Sign HIPAA Release & Consent to Negotiate forms'
        default:
            return task.taskName
    }
}

const getOpenTasks = (caseItem) => {
    let openTasks = caseItem.tasks.filter((task) => !task.taskCompleteInd)
    return openTasks.map((task) => {
        if(task.taskName === 'ESign') {
            if(!task.taskCompleteInd) {
                if(!task.readyForUser) {
                    return 'ESignNotReady'
                } else {
                    return 'ESignReady'
                }
            }
        }
        if(!task.taskCompleteInd && task.actionReqInd && task.readyForUser) {
            return task.taskName
        }
    })
}

export const getMessageType = (caseItem) => {
  let openTasks = getOpenTasks(caseItem)
    if(caseItem.caseStatus === 'Negotiation In Progress' && openTasks.length <= 0) {
      return 'InNegotiation'
    } else if(caseItem.caseStatus === 'Under Review' && openTasks.length <= 0) {
      return 'UnderReview'
    }else if(caseItem.caseStatus === "Negotiation Complete, you'll hear from us soon" && openTasks.length <= 0) {
      return 'NegotiationComplete'
    } else if(caseItem.caseStatus === 'Case Closed' && openTasks.length <= 0) {
      return 'CaseClosed'
    } else {
      
      if(openTasks.length <= 0) return 'TasksCompleted'
      if(openTasks.length === 1) {
        let task = openTasks[0]
        if(task === 'Member Story') {
          return 'MemberExperience'
        } else if(task === 'Upload a Bill') {
          return 'UploadBill'
        } else if(task === 'ESignNotReady') {
          return 'ESignNotReady'
        } else if(task === 'ESignReady') {
          return 'ESignReady'
        }
      }
      if(openTasks.includes('Upload a Bill') && openTasks.includes('Member Story') && openTasks.includes('ESignReady')) {
        return 'MemberExperienceAndUploadBillAndESignReady' 
      } else if(openTasks.includes('ESignReady') && openTasks.includes('Member Story')) {
        return 'MemberExperienceAndESignReady'
      } else if(openTasks.includes('ESignReady') && openTasks.includes('Upload a Bill')) {
        return 'UploadBillAndESignReady'
      } else if(openTasks.includes('Upload a Bill') && openTasks.includes('Member Story')) {
        return 'MemberExperienceAndUploadBill'
      } else if(openTasks.includes('Member Story') && !openTasks.includes('Upload a Bill') && openTasks.includes('ESignNotReady')) {
        return 'MemberExperience' 
      } else if(openTasks.includes('Upload a Bill') && !openTasks.includes('Member Story') && openTasks.includes('ESignNotReady')) {
        return 'UploadBill' 
      } 
    }
  }