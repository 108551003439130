import React, { useRef } from 'react'
import { View, TouchableOpacity, Text } from 'react-native';
import styled from 'styled-components/native';

const TouchableWrapper = styled(View) `
  display: flex;
  flex-direction: row;
  align-items: ${props => props.alignItems};
`;

const ButtonContainer = styled(View)`
  height: 18px;
  width: 18px;
  border-radius: 12px;
  border-width: 2px;
  border-color: #002C73;
  align-items: center;
  justify-content: center;
`;

const ButtonLabel = styled(Text)`
  color: #002C73;
  margin-left: 5px;
  font-size: 16px;
  font-family: SourceSansPro-Regular;
`;

export interface RadioButtonProps {
  label?: string|JSX.Element;
  selected: boolean;
  error?: boolean;
  testID?: string;
  onCheck: () => void;
  disabled?:boolean;
}

export default function RadioButton(props: RadioButtonProps) {
  const labelRef = useRef(null)

  return (
    <TouchableOpacity onPress={props.onCheck} testID={props.testID} accessibilityState={{checked:props.selected}}   accessibilityRole="radio" disabled={props.disabled?props.disabled:false}>
      <TouchableWrapper alignItems='flex-start'>
      <ButtonContainer style={{ 
        borderColor: props.error ? "#e00000" : "#002C73"
      }}>
        {
          props.selected ?
            <View style={{
              height: 10,
              width: 10,
              borderRadius: 6,
              backgroundColor: '#002C73',
            }}/>
            : null
        }
      </ButtonContainer>
      <ButtonLabel ref={labelRef}>{props.label}</ButtonLabel>
      </TouchableWrapper>
    </TouchableOpacity>
);
}