import React, {useEffect, useState } from 'react'
import Confirmation from './Confirmation';
import { FormState } from 'react-hook-form';

export interface UnsavedChangesWarningManualProps {
   
    formState:FormState<any>;
    onCancel:()=>void;
    onIgnoreChanges:()=>void;
    showIfAnyChanges:boolean
}
/**
 * Warn the user if there are any changes done to the form. 
 * @param props:UnsavedChangesWarningProps 
 */
const UnsavedChangesWarningManual=(props:UnsavedChangesWarningManualProps)=>{
    const[showLeavingConfirmation,setShowLeavingConfirmation]=useState(false)
   const {dirtyFields} = props.formState;

    useEffect(()=>{
        if (props.showIfAnyChanges){
            confirmLeavingIfAnyChanges()
        }
    },[props.showIfAnyChanges])

    const confirmLeavingIfAnyChanges=()=>{
    
    if(Object.keys(dirtyFields).length){
        setShowLeavingConfirmation(true)
    }
    else{
        handleLeavingConfirmationOk()
    }
  
  }
  
  ///////////handle dialog box confirmation
  const handleLeavingConfirmationOk=()=>{
    setShowLeavingConfirmation(false)
    props.onIgnoreChanges()
  }
  const handleLeavingConfirmationCancel=()=>{
    props.onCancel()
    setShowLeavingConfirmation(false)
    
  }
  return <Confirmation show={showLeavingConfirmation} message="You have made some changes. Are you sure to discard them and leave the page?" onCancel={handleLeavingConfirmationCancel} onOK={handleLeavingConfirmationOk}></Confirmation>
}

export default UnsavedChangesWarningManual