import React, {  useEffect, useRef } from "react";

interface RadioADAProps{
    children:JSX.Element[]|JSX.Element;
}


export default function RadioADA(props:RadioADAProps) {

const radiogroup=useRef<HTMLDivElement>()
const radios=useRef<NodeListOf<Element>>()
const selectedItemIndex=useRef(0)
const hasInputs=useRef(false)


  useEffect(()=>{ 
    if(radiogroup.current){
        hasInputs.current= radiogroup.current.querySelectorAll("input").length>0
        radios.current=radiogroup.current.querySelectorAll("[role=radio]")

        for(let i=0;i<radios.current.length;i++){
          const radio=radios.current[i] as HTMLElement;
          radio.onclick=()=>selectedItemIndex.current=i;

          if(i===0){// we want to actually focus on an already checked item. Because of late binding, the focus happens later.
            radio.onfocus=checkedItemFocus
          } //disable tab key on radios except for the first one
          else{
            radio.tabIndex=-1
          }
        }

          
        radiogroup.current.addEventListener('keydown', handleArrowKey);
    }
   
    return ()=>{
      if(radiogroup.current){
        radiogroup.current.removeEventListener('keydown', handleArrowKey);
      }
     
    }
  },[])

const checkedItemFocus=(ev:FocusEvent)=>{
  for(let i=0;i<radios.current.length;i++){
    const r =radios.current[i] as HTMLElement;
     if(r["ariaChecked"]==='true'){
       selectedItemIndex.current=i
       itemFocus(selectedItemIndex.current)
     }
  }
  (ev.currentTarget as HTMLElement).onfocus=undefined
}
const handleArrowKey=(e: KeyboardEvent)=>{
  
    const itemCount =radios.current.length
    switch(e.key){
      case ' ':
        //avoid screen scrolling
        e.preventDefault()
        itemFocus(selectedItemIndex.current,true)
        break;
      case 'ArrowLeft':
      case 'ArrowUp':
        //avoid screen scrolling
        e.preventDefault()
        selectedItemIndex.current=selectedItemIndex.current>0?selectedItemIndex.current-1:itemCount-1
        itemFocus(selectedItemIndex.current,false)
        break;
      case 'ArrowRight':
      case 'ArrowDown':
        //avoid screen scrolling
        e.preventDefault()
        selectedItemIndex.current=selectedItemIndex.current<itemCount-1? selectedItemIndex.current +1:0
        itemFocus(selectedItemIndex.current,false)
        break;
      case 'Tab':
        let radio = (radios.current[selectedItemIndex.current] as HTMLElement);
       
        if(radio["ariaChecked"]==='true'){ 
          radios.current.forEach(r => {
            (r as HTMLElement).tabIndex=-1
          });
        }
        else{
          if(e.shiftKey){
            selectedItemIndex.current=0;
          }
          else{
            selectedItemIndex.current=itemCount-1;
          }
          if(!hasInputs.current){
          //set the first or last radio as tabbable before tabbing out.
            itemFocus(selectedItemIndex.current);
          }
        }

         radio = (radios.current[selectedItemIndex.current] as HTMLElement);
         const disabled =radio["ariaDisabled"]
        
        if(disabled===null || disabled==='false'){
          radio.tabIndex=0;
        }
        break;
      default:
        break;
      }
    
  }
  const itemFocus=(index:number,select?:boolean)=>{
    if(radios.current.length>0){
      (radios.current[index] as HTMLElement).focus();
      if(select){
        (radios.current[index] as HTMLElement).click();
      }
    }
    
  }

return (
  <div ref={radiogroup}>
      {props.children}
  </div> 
 
)

}