import { Dispatch } from "redux";
import axiosInstance from "../axiosInstance";
import {
    FETCH_DOCUMENTS_SUCCESS,
    FETCH_DOCUMENTS_PENDING,
    UPLOAD_DOCUMENTS_PROGRESS,
    UPLOAD_DOCUMENTS_PROGRESS_RESET,
    DELETE_DOCUMENTS_SUCCESS,
    FETCH_DOCUMENTS_ERROR,
    UPLOAD_DOCUMENTS_ERROR,
    DELETE_DOCUMENTS_ERROR,
    DELETE_DOCUMENTS_STATUS_RESET,
     DocumentsDispatchTypes,
     DOCUMENTS_ERROR_RESET
} from "./types"
import { Case } from "../../redux/cases/types";

export const fetchDocuments =  (caseItem: Case)  => async (dispatch: Dispatch<DocumentsDispatchTypes>) => {
    
    dispatchFetchDocuments(caseItem,dispatch);
}

export interface DownloadResult{
  buffer?:ArrayBuffer
  errorMessage?:string 
  exception?:Error
}
export  const  downloadDocument =async  (documentId:string, caseId: string)=>  {
    
    const axios = await axiosInstance()
    let result:DownloadResult=null;

    try {
        const response = await axios.get(`/documents/${documentId}?case-id=${caseId}`,{responseType: 'arraybuffer'})
        result={ buffer: response.data}
        } catch (e) {
          let message="Error dowloading";

          if(e.response && e.response.data)
            message=Buffer.from(e.response.data).toString('utf8')

          result={errorMessage:message,exception:e}
    
   }

   return result

}
export const resetUploadDocumentsProgress=()=> async (dispatch: Dispatch<DocumentsDispatchTypes>) => {
   dispatch({
            type: UPLOAD_DOCUMENTS_PROGRESS_RESET
    })
}
export const uploadDocuments = (caseItem: Case, files:File[])  => async (dispatch: Dispatch<DocumentsDispatchTypes>) => {
    const { caseId, caseSetId } = caseItem
    const axios = await axiosInstance()
    let success=false;

    await Promise.all(files.map(async f=>{
        try {
           
              const  formData = new FormData(); 
            
               formData.append("document",f);
               await axios.post(`/documents?caseId=${caseId}${ caseSetId ? `&workPackageCaseSetId=${caseSetId}` : '' }`,formData, {
                   headers: {
                     'Content-Type': 'multipart/form-data'
                   },
                   onUploadProgress: progressEvent =>
                        dispatch({
                   type: UPLOAD_DOCUMENTS_PROGRESS,
                    payload: { fileName:f.name,progress: progressEvent.loaded,complete:false}
                   })
                   
               })
               
               success=true;
               dispatch({
                type: UPLOAD_DOCUMENTS_PROGRESS,
                 payload: { fileName:f.name,progress:f.size,complete: true}
                })
           
           } catch (e) {
             console.error(e)
             
             let error = e.toString()
             
             if(e.response && e.response.data) {
                 error =e.response.data
                 // attempt to normalize an error response into a single error string by extracting a
                 // standard "error" field. i said error like 5 times in this sentence lol.
                 if (typeof error === 'object'){
                   if (error.error) 
                      error = error.error
                    else if(error.title)
                      error=error.title
                 } else {
                   error = error.toString()
                 }
             }

             dispatch({
               type: UPLOAD_DOCUMENTS_ERROR,
               payload:  {caseId, error,exception:e}
             })
      }       
    }

    
    )
)

if (success)
{
  //update the documents list in application state
  dispatchFetchDocuments(caseItem, dispatch)

}

}
export const resetDeleteDocumentStatus=()=> async (dispatch: Dispatch<DocumentsDispatchTypes>) => {
 
  dispatch({
           type: DELETE_DOCUMENTS_STATUS_RESET
   })
}
export const deleteDocument = (documentId:string, caseItem: Case)  => async (dispatch: Dispatch<DocumentsDispatchTypes>) => {
  const { caseId } = caseItem
  const axios = await axiosInstance();
  let success=false;
  let message="";

  try {
        
    await axios.delete(`/documents/${documentId}?case-id=${caseId}`)
    success=true;
          
  } 
  catch(e) {
    
    message=e.response.data

    if(!message)
      message="This document failed to be deleted"
    dispatch({
      type: DELETE_DOCUMENTS_ERROR,
      payload: {caseId,documentId,success:false,message,exception:e} 
    })

  }
  if (success){
    dispatchFetchDocuments(caseItem, dispatch)
  }
  dispatch({
    type: DELETE_DOCUMENTS_SUCCESS,
    payload: {caseId,documentId,success:true,message,exception:null} 
  })
}

const dispatchFetchDocuments=async(caseItem: Case,dispatch: Dispatch<DocumentsDispatchTypes>)=>{
  const { caseId, caseSetId } = caseItem
  dispatch({
    type: FETCH_DOCUMENTS_PENDING
  })
  
  const axios = await axiosInstance()
 
    try {
        const response = await axios.get(
          `/document-lists?case-id=${caseId}${ caseSetId ? `&workPackageCaseSetId=${caseSetId}` : '' }`,
            {
              headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
              },
            }
          )
        dispatch({
            type: FETCH_DOCUMENTS_SUCCESS,
            payload: {caseId, documents:response.data}
          })
        } catch (e) {
          dispatch({
            type: FETCH_DOCUMENTS_ERROR,
            payload:{caseId,error:e.toString(),exception:e}
          })
   }
}
export const resetDocumentsError=()=> async (dispatch: Dispatch<DocumentsDispatchTypes>) => {
  dispatch({
           type: DOCUMENTS_ERROR_RESET
   })
}