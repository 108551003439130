import React from "react";
import { Case } from "../../redux/cases/types";
import AdvisorCard from "./advisorcard";
import { advisorEmail } from "../../utils/email";





export interface AdvisorPanelProps {
    cases: Case[];
}

export default function AdvisorPanel(props: AdvisorPanelProps) {

    const advisor = getAdvisor(props.cases)?.advisor

    return (
        <AdvisorCard advisor={advisor} email={advisorEmail} cases={props.cases}/>
    )
}

// If the only not-closed case is a self-coaching case show the limited advisor info.
// If more than 1 not-closed case:
    // Display from not closed cases the highest case number unless the highest case number is self-coach and there is also a not-closed traditional case.
// If only closed cases:
    // Then display the highest case number of traditional case.

const getAdvisor = (cases:Case[]) => {

    //no cases
    if(!cases || cases.length <= 0) {
        return null
    }
    
    let finalCases:Case[]=undefined
    const nonClosedExcludeSelfCoachCases:Case[] =cases.filter(c=>c.caseStatus!=='Case Closed' && c.naviguardProduct!=='Self Coach')
    const nonClosedSelfCoachCases:Case[] =cases.filter(c=>c.caseStatus!=='Case Closed' && c.naviguardProduct==='Self Coach')
    const closedExcludeSelfCoachCases:Case[] =cases.filter(c=>c.caseStatus==='Case Closed' && c.naviguardProduct!=='Self Coach')
    
     // Highest case number from non-closed cases and non-self-coaching
    if(nonClosedExcludeSelfCoachCases && nonClosedExcludeSelfCoachCases.length>0){
        finalCases=nonClosedExcludeSelfCoachCases
    }
    // non-closed self-coaching cases
    else if( nonClosedSelfCoachCases && nonClosedSelfCoachCases.length>0){
         finalCases=null
    }
    //closed cases exclude self-coaching
    else if(closedExcludeSelfCoachCases && closedExcludeSelfCoachCases.length>0){
        finalCases=closedExcludeSelfCoachCases
    }
    
    let mostRecent =null

    if(finalCases && finalCases.length>0){
        mostRecent = finalCases.reduce((a, b) => {
            return   Number(a.caseNumber) >  Number(b.caseNumber) ? a : b;
        })
    }
    return mostRecent
}