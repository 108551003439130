import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import React, { RefObject} from "react";
import Navigation from "../navigation";
import Header from '../components/header/index'
import OverlayProvider from '../context-api/overlay/overlayState'
import SessionTimeOut from "../components/modal/SessionTimeout";
import { View, StyleSheet, ActivityIndicator } from "react-native";

//used by Feeback button to place it on top of the screen for proper user interaction and user action
export const ScreenRefContext = React.createContext<RefObject<View>>(null)

export default function App(props) {
  const screenRef = React.createRef<View>();
  return (
    <>
      <ScreenRefContext.Provider value={screenRef}>
        <View ref={screenRef} style={{height:"100%"}}>
          <SafeAreaProvider>
            <SafeAreaView style={{flex: 1}}>
              <OverlayProvider>
                <Header {...props} />
                  <Navigation {...props} />
                <StatusBar />
                <SessionTimeOut />
              </OverlayProvider>
            </SafeAreaView>
          </SafeAreaProvider>
        </View>
      </ScreenRefContext.Provider>
      { props.unauthenticated && 
        <View style={[styles.container, styles.horizontal]}>
          <ActivityIndicator size="large" />
        </View>
      }
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#FFF',
    position: "absolute",
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 500000001
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
});
