import React from 'react'
import { StyleSheet, Image, View } from 'react-native'
import useDeviceWidth from '../../../utils/useDeviceWidth'



const nvgPatientsHeader1Height=369
const nvgBrandBarGradientHeight=15
const nvgGraphicGradient16gridHeight=419
const nvgGraphicGradient16gridWidth=775

export default function ImageBG() {
  const responsiveStyle = useDeviceWidth()

  return (
    <>
    <View style={[BackgroundStyle[responsiveStyle]]}>
    {(responsiveStyle==='largeScreen' || responsiveStyle==='tabletDevice' ||  responsiveStyle==='width375_811') &&
        <Image 
          source={require("../../../assets/images/Header-background@2x.png")} 
          style={{ width: '100%', height: `${nvgPatientsHeader1Height}px` }}
          resizeMode="stretch"
        />
    }
    {(responsiveStyle==='width295_359' || responsiveStyle==='wdth360_374') &&
        <View 
          style={{ width: '100%', height: `${nvgPatientsHeader1Height}px`,backgroundColor:'#FFF' }}
        />
    }
      
      <Image source={require("../../../assets/images/nvg-brand-bar-gradient.png")} 
        style={{ width: '100%', height: `${nvgBrandBarGradientHeight}px`}}
        resizeMode="stretch"
      />
      
     
    </View>
    <View style={[NVG_PATIENTS_HEADER_Style[responsiveStyle]]}>
      {responsiveStyle==='largeScreen' &&
        <Image 
          source={require("../../../assets/images/nvg_patients_header_1.png")} 
          style={{ width: '808px', height: `${nvgPatientsHeader1Height}px`,alignSelf:'flex-end' }}
        />
      }
      {responsiveStyle==='tabletDevice' &&
            <Image source={require("../../../assets/images/nvg-graphic-gradient-16grid.png")} 
              style={{ width: `${nvgGraphicGradient16gridWidth *2/3}px`, height: `${nvgGraphicGradient16gridHeight *2/3}px` }}
            />
        }
        {responsiveStyle==='width375_811' &&
            <Image source={require("../../../assets/images/nvg-graphic-gradient-16grid.png")} 
              style={{ width: `${nvgGraphicGradient16gridWidth *1/3}px`, height: `${nvgGraphicGradient16gridHeight *1/3}px`}}
              
            />
        }
         {(responsiveStyle==='width295_359' || responsiveStyle==='wdth360_374') &&
            <Image source={require("../../../assets/images/nvg-graphic-gradient-16grid.png")} 
              style={{ width: `${nvgGraphicGradient16gridWidth *1/4}px`, height: `${nvgGraphicGradient16gridHeight *1/4}px`}}
              
            />
        }
    </View>
    </>
  )
}
export const styles = StyleSheet.create({
  largeScreen: {
    top:"-70px",
    height: `${nvgPatientsHeader1Height + nvgBrandBarGradientHeight}px`
  },
  tabletDevice: {
    top:"-16px",
    height: `${nvgPatientsHeader1Height + nvgBrandBarGradientHeight}px`
  },
  width295_359: {
    top:"-16px",
    height: `${nvgPatientsHeader1Height + nvgBrandBarGradientHeight}px`,
  },
  width375_811: {
    top:"-16px",
    height: `${nvgPatientsHeader1Height + nvgBrandBarGradientHeight}px`
  },
  wdth360_374: {
    top:"-16px",
    height: `${nvgPatientsHeader1Height + nvgBrandBarGradientHeight}px`
   }
})
const BackgroundStyle = StyleSheet.create({
  largeScreen: {
    position: "absolute",
    width: "calc(100% + 2 * 84px)",
    height: styles.largeScreen.height,
    left: "-84px",
    zIndex: -1
  },
  tabletDevice: {
    position: "absolute",
    width: "calc(100% + 2 * 16px)",
    height: styles.tabletDevice.height,
    left: "-16px",
    zIndex: -1
  },
  width295_359: {
    position: "absolute",
    width: "calc(100% + 2 * 16px)",
    height: styles.width295_359.height,
    left: "-16px",
    zIndex: -1
  },
  width375_811: {
    position: "absolute",
    width: "calc(100% + 2 * 16px)",
    height: styles.width375_811.height,
    left: "-16px",
    zIndex: -1
  },
  wdth360_374: {
    position: "absolute",
    width: "calc(100% + 2 * 16px)",
    height: styles.wdth360_374.height,
    left: "-16px",
    zIndex: -1
  }
})
const NVG_PATIENTS_HEADER_Style = StyleSheet.create({
  largeScreen: {
    position: "absolute",
    right: "-84px",
    zIndex: -1
  },
  tabletDevice: {
    position: "absolute",
    height: styles.tabletDevice.height,
    right: "-16px",
    zIndex: -1
  },
  width295_359: {
    position: "absolute",
    height: styles.width295_359.height,
    right: "-16px",
    zIndex: -1
  },
  width375_811: {
    position: "absolute",
    height: styles.width375_811.height,
    right: "-16px",
    zIndex: -1
  },
  wdth360_374: {
    position: "absolute",
    height: styles.wdth360_374.height,
    right: "-16px",
    zIndex: -1
  }
})