import React, { ReactNode } from 'react'
import styled from 'styled-components/native';
import { View, Text } from 'react-native'
import Spinner from './spinner'
import Message from './message'

const CenteredView = styled(View)`
    flex: 1;
    justify-content: center;
    align-items: center;
    backgroundColor: ${props => props.whiteTransparent ? 'rgba(255, 255, 255, 0.9)' : 'rgba(51, 51, 51, 0.5)'};
    position: relative;
`;

const ModalText = styled(Text)`
  font-size: 24px;
  color: #002C73;
  font-family: SourceSansPro-Bold;
  text-align: center;
  margin-left: 16px;
  margin-right: 16px;
`;

type MessageOverlay = {
  show: boolean;
  message: string;
  type: 'message';
  messageType: 'error' | 'success';
  buttonLabel: string;
  buttonAction?: () => void;
  customMessage?: boolean;
  customMessageRender?: () => ReactNode;
}
type IOverlay = {
    show: boolean;
    message: string;
    type: 'spinner';
    autoHide?: boolean;
    hideTime?: number;
  } | MessageOverlay

export { 
    CenteredView,
    ModalText,
    Spinner,
    Message,
    IOverlay,
    MessageOverlay
}