import React, {  useEffect, useState } from 'react';
import { Link, Text } from '../../components/index'
import { useIsMobile } from '../../utils/useDeviceWidth'
import { Button } from '../../components'
import Layout, { InfoSection, LayoutDivider, MainSection, NavigationSection, ViewAdditionalInfo } from './Components/Layout';
import { TouchableOpacity, View } from 'react-native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import YourNumbers from './Components/YourNumbers';
import styled from 'styled-components/native'
import { Numbers as NegotiationNumbers } from '../../redux/negotiation/numbers/types';
import { Options as NegotiationOptions } from '../../redux/negotiation/options/types';
import { Story as NegotiationStory } from '../../redux/negotiation/story/types';
import {  useSelector } from 'react-redux'
import { ApplicationState } from '../../redux/rootReducer';
import FormatCurrency from '../../utils/FormatCurrency';
import useDocument from './Components/useDocument';
import Tooltip from 'react-native-walkthrough-tooltip';
import { storyServiceSentences } from './Components/types';
import useGoogleTagManager, { usePageLoadEffect } from '../../utils/useGoogleTagManager';

const toolTipStyle={
  padding: 12,
  borderRadius: 2,
  backgroundColor:'#002C73',
  fontFamily:'SourceSansPro',
  width: 116,
  height: 44
 
}
const toolTipTextStyle={
  fontSize: 14,
  color: '#ffffff'
}

export default function Plan({route, navigation }) {
  const caseId:string=route.params && route.params.caseId?route.params.caseId:""
  const numbers:NegotiationNumbers= useSelector((state: ApplicationState) =>state.negotiationNumbers && state.negotiationNumbers.data && state.negotiationNumbers.data.find(f=>f.caseId===caseId)?  
        state.negotiationNumbers.data.find(f=>f.caseId===caseId): {deductible:0, copay:0, coinsurance:0,nonCoveredAmount:0})
  const options:NegotiationOptions= useSelector((state: ApplicationState) =>state.negotiationOptions && state.negotiationOptions.data && state.negotiationOptions.data.find(f=>f.caseId===caseId)?  state.negotiationOptions.data.find(f=>f.caseId===caseId): {})
  const story:NegotiationStory= useSelector((state: ApplicationState) =>state.negotiationStory && state.negotiationStory.data && state.negotiationStory.data.find(f=>f.caseId===caseId)?  state.negotiationStory.data.find(f=>f.caseId===caseId): {})
  const isMobile = useIsMobile()
  const [nonCoveredAmountMultiplier,setNonCoveredAmountMultiplier] =useState(90)
  const costShared=numbers.deductible + numbers.coinsurance + numbers.copay
  const {print,download}  =useDocument(caseId)
  const [topPrintClicked,setTopPrintClicked] =useState(false)
  const [bottomPrintClicked,setBottomPrintClicked] =useState(false)
  const gtm=useGoogleTagManager()
  
  useEffect(()=>{
    if(!options.caseId){
      goToOptions()
    }
  },[caseId])

  useEffect(()=>{
    setNonCoveredAmountMultiplier(90)
  },[route.params])
 
  usePageLoadEffect()
  
    const handleSave=()=>{
      gtm.pushNegotiationTrack("YourNegotiationPlan","SaveAndReturnToDashboard")
      navigation.navigate("Home")
    }
    const handlePrint=async(isTopPrintClicked:boolean)=>{
      setTopPrintClicked(isTopPrintClicked)
      setBottomPrintClicked(!isTopPrintClicked)
      if(isMobile){
        await download()
      }
      else{
        await print()
      }
      setTopPrintClicked(false)
      setBottomPrintClicked(false)
      gtm.pushNegotiationTrack("YourNegotiationPlan","PrintYourNegotiationPlan")
    }
    const goToOptions=()=>{
      navigation.navigate("NegotiationOptions",route.params)
    }
    const goBack=()=>{
      gtm.pushNegotiationTrack("YourNegotiationPlan","Back")
      goToOptions()
    }
    const capitalizeFirstLetter=(value:string)=>{
      let result =""
        if(value){
          const words = value.split(" ")
          words.forEach(w => {
            result =`${result} ${w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()}`
          });
        }

        return result.trim()
    }
    return (
  <Layout 
  title="Your Negotiation Plan" 
  isMobile={isMobile} 
  StepTrackerActiveStepNumber={4}
  contentMarginTop={isMobile?0:''}>
    <ViewAdditionalInfo isMobile={isMobile}>
              <Text size={24} bold={true}>Before You Call</Text>
              <View style={{flexDirection:isMobile?'column':'row',justifyContent:'space-between'}}>
                  <View style={{width:isMobile?'100%':'20%'}}>
                    <Text size={20} bold={true} style={{marginTop:15}}>Are you in the right frame of mind?</Text>
                    <Text size={16}>Being calm, organized, and focused will make any conversations and next steps with providers and insurers go more smoothly</Text>
                  </View>
                  <View  style={{width:isMobile?'100%':'20%'}}>
                    <Text size={20} bold={true} style={{marginTop:15}}>Know what you want</Text>
                    <Text size={16}>Do you want a reduced bill? Better payment terms? Be able to articulate exactly what you are wanting from the person on the other end of the phone line.</Text>
                  </View>
                  <View  style={{width:isMobile?'100%':'20%'}}>
                    <Text size={20} bold={true} style={{marginTop:15}}>Know who you will be speaking with</Text>
                    <Text size={16}>Ask to speak to the Billing Office Manager — try to start with the decision maker or someone that can make things happen.</Text>
                  </View>
                  <View  style={{width:isMobile?'100%':'20%'}}>
                    <Text size={20} bold={true} style={{marginTop:15}}>Take notes</Text>
                    <Text size={16}>Whether you are negotiating for a lower payment or reduced terms, be sure to take notes during every conversation you have throughout the process to make sure you are clear on all details.</Text>
                  </View>
              </View>
          </ViewAdditionalInfo> 
    <LayoutDivider  isMobile={isMobile}  reverse={isMobile?true:false} marginTop={isMobile?"0px":"70px"} >
      <MainSection  isMobile={isMobile}>
        <View style={{flexDirection: isMobile?'column':'row',
        marginTop:isMobile?66:0,marginLeft:40}}>
          <Text size={24} bold={true}>Your Negotiation Plan</Text>
          {isMobile &&
            <DownloadPDFLink marginTop={14} onPress={()=>handlePrint(true)}  isTooltipVisible={topPrintClicked} onTooltipClose={()=>setTopPrintClicked(false)}/>
          
          }
          {!isMobile &&
           <Tooltip contentStyle={toolTipStyle} disableShadow={true} isVisible={topPrintClicked} content={<Text style={toolTipTextStyle}>Printing...</Text>} placement="right" backgroundColor='rgba(0,0,0,0)'  showChildInTooltip={false} onClose={()=>setTopPrintClicked(false)}>
            <Button onPress={()=>handlePrint(true)} variant="secondary" label="Print your Negotiation Plan"  style={{marginLeft:50}}/>
           </Tooltip>
          }
        </View>
       {story.patientName && <NegotiationStep 
        marginTop={42}
        marginLeft={-20}
        stepNumber={1}
        instruction="Start the call with your story"
        script={`“Hello, my name is ${capitalizeFirstLetter(story.patientName)}. I am calling about a recent bill I received. ${story.healthEvent}. On ${story.dateOfService} I visited your facility for ${story.service==='other'? story.otherServiceDescription.toLowerCase():storyServiceSentences[story.service]}. ${story.reason==='other'? story.otherReasonDescription:story.reasonDescriptions[story.reason]}. I would like to understand the charges and negotiate a fair price on the services performed.”`}
        />}
        <NegotiationStep
        marginLeft={-20}
        stepNumber={2}
        instruction="State what the provider will be paid by the insurance company as a fair rate"
        script={`“You will be paid ${FormatCurrency(numbers.insurancePaid)} by my insurance company. I understand that I owe ${FormatCurrency(costShared)} to cover my member cost share. Can you accept ${FormatCurrency(numbers.insurancePaid + costShared)} as the final amount on this bill?”`}
        />
        <NegotiationStep
        marginLeft={-20}
        stepNumber={3}
        instruction="If they decline, ask what would be the lowest amount they would accept"
        script="“What is the lowest amount you would be willing to accept as payment for this service?”"
        />
         <NegotiationStep
         isMobile={isMobile}
        marginLeft={-20}
        stepNumber={4}
        instruction="If that amount doesn’t work for you, make a counter offer"
        script={`“I’m not in a position to be able to pay that amount. Would you consider accepting ${FormatCurrency((numbers.nonCoveredAmount * nonCoveredAmountMultiplier/100) + costShared)}”`}
        >
          <View style={{marginTop:36,flexDirection:isMobile?'column':'row',justifyContent:'space-between',width:isMobile?200:''}}>
            <View style={{flexDirection:'column'}}>
              <Text for="perc-covered-amount" size={16} bold={true}  style={{alignSelf:'flex-end'}}>% of Non-Covered Amount:</Text>
              <Text size={16}>(Required)</Text>
              <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                <NumericInput aria-required="true" id="perc-covered-amount" defaultValue={nonCoveredAmountMultiplier} increment={5} minValue={0} maxValue={100} onValueChanged={(value)=>setNonCoveredAmountMultiplier(value)} />
              
                  <Text size={32} style={{marginLeft:20}}>{FormatCurrency(numbers.nonCoveredAmount * nonCoveredAmountMultiplier/100)}</Text>
                
              </View>
            </View>
          
              <Text size={32} style={{marginTop:20,display:isMobile?'none':'flex'}}>+</Text>
              <View style={{flexDirection:'column',marginTop:isMobile?15:0}}>
                <Text size={16} bold={true} style={{alignSelf:'flex-end'}}>Your Cost Share:</Text>
                <View style={{flexDirection:'row',alignSelf:'flex-end'}}>
                  <Text size={32} style={{marginRight:20,display:isMobile?'flex':'none'}}>+</Text>
                  <Text size={32}>{FormatCurrency(costShared)}</Text>
                </View>
              </View>
              <Text size={32} style={{marginTop:20,display:isMobile?'none':'flex'}}>=</Text>
              <View style={{flexDirection:'column',marginTop:isMobile?15:0}}>
                <Text size={16} bold={true} style={{alignSelf:'flex-end'}}>Total Payment:</Text>
                <View style={{flexDirection:'row',alignSelf:'flex-end'}}>
                <Text size={32} style={{marginRight:20,display:isMobile?'flex':'none'}}>=</Text>
                  <Text size={32}>{FormatCurrency((numbers.nonCoveredAmount * nonCoveredAmountMultiplier/100) + costShared)}</Text>
                </View>
              </View>
            
         </View>
          </NegotiationStep >
      </MainSection>
      <InfoSection 
      isMobile={isMobile}
      marginTop={isMobile?'0px':''}>
          
          <View>
            <YourNumbers isMobile={isMobile} caseId={caseId}/>
            <ViewAdditionalInfo isMobile={isMobile}  backgroundColor="#002C73" paddingTop='0px'>
              <Text size={16} style={{color:"#FFFFFF"}}>This is the amount that you will be asking the provider to discount.</Text>
            </ViewAdditionalInfo>
          </View>
      </InfoSection>
    </LayoutDivider>
    <LayoutDivider  isMobile={isMobile} marginTop="55px" >
      <MainSection  isMobile={isMobile}>
        <Text size={24} bold={true}>Negotiation Tactics</Text>
        {(options.plannedVisits==='No' || options.plannedVisits==="I don't know") && <NegotiationTactic
          instruction="Check with primary provider" 
          detail="Tell your provider that you were not aware that the services you received would be out of network. If you were not told up front, you may be able to lower your payment."
          />}
           <NegotiationTactic
          instruction="Compare insured and non-insured rates" 
          detail="Ask your provider what their out-of-network rate is. Your insurance benefits may have changed to cover less of this type of bill, so depending on the type of services you are receiving, it may be more cost-effective to pay the non-insured rate."
          />
          {options.pastVisits==='Yes' && <NegotiationTactic
          instruction="Ask the provider to bundle billing for multiple visits" 
          detail="If you plan to see this provider on a recurring basis, they may be able to offer a discount for recurring visits."
          />}
         
          { options.pastVisits==="Yes" && options.billIncreased==='Yes' && <NegotiationTactic
          instruction="Check for changes in your benefits" 
          detail={<>
                  <View><Text>Checking your benefits can help you avoid costly surprises, so it’s good to review what is and isn’t covered before you make an appointment. Plans may change from year to year.</Text></View> 
                  <View style={{marginTop:12}}><Text>Even if you’ve seen this provider in the past, it’s possible that your benefit coverage may have changed, which could affect how much you owe when seeing the provider.</Text></View> 
                  <View style={{marginTop:12}}><Text>Call your provider’s billing office and ask if their rate has changed.</Text></View>
              </>}
          />}
          {(options.futureVisits==='Yes' || options.futureVisits==="I don't know") && <NegotiationTactic
          instruction="If you plan on seeing this provider in the future, here are some tips for managing your costs." 
          detail={<>
              <View><Text>Talk to the provider’s office about payment options before your next appointment.</Text></View> 
              <View style={{marginTop:12}}><Text>Check about whether bundled pricing is available for your care, or if there is a discount for paying in cash. If you have a high-deductible health plan, bypassing your insurance claims process may actually save you money.</Text></View>
              <View style={{marginTop:12}}><Text>If you successfully negotiate a lower cost, get a summary of the agreement in writing or email.</Text></View>  
              <View style={{marginTop:12}}><Text>If you need additional services such as surgery, imaging or physical therapy, ask your doctor to refer you to an in-network facility to keep your costs down.</Text></View>  
              <View style={{marginTop:12}}><Text>Ask your provider if they can proactively provide a long-term rate to cover the potential recurring appointments.</Text></View>
            </>}
          />}
      </MainSection>
      <InfoSection isMobile={isMobile}>
        <ViewAdditionalInfo isMobile={isMobile}>
          <Text size={24} bold={true}>Want to avoid future out-of-network balance bills?</Text>
          <View style={{marginTop:15}}>
            <Text size={16}>
            1. Get familiar with your insurance plan, especially the difference in coverage for in- and out-of-network services.
            </Text>
            <Text size={16} style={{marginTop:15}}>
            2. Locate your nearest in-network emergency and urgent care facilities.
            </Text>
            <Text size={16} style={{marginTop:15}}>
            3. Ask questions when planning a procedure. What will be involved, who is providing services, and are they in-network?
            </Text>
            <View style={{marginTop:15,flexDirection:'row'}}>
              <Link onPress={()=>window.open("https://naviguard.com/avoid-surprise-bills/")}>Tips to avoid recurring balance bills</Link>
              <MaterialIcons name="launch"  style={{marginLeft:15,color:'#d04343'}}  />
            </View>
            <View style={{marginTop:15,flexDirection:'row'}}>
              <Link onPress={()=>window.open("https://connect.werally.com/plans/uhc")}>Search for in-network providers</Link>
              <MaterialIcons name="launch" style={{marginLeft:15,color:'#d04343'}} />
            </View>
          </View>
        </ViewAdditionalInfo>
      </InfoSection>

    </LayoutDivider>
    <NavigationSection flexDirection={isMobile?"column":"row"}>
      <Button onPress={goBack} variant="secondary"  label='BACK'/> 
      <Button onPress={handleSave}  label='Save & Return to Dashboard'  style={{marginLeft: isMobile?0:10,marginTop: isMobile?10:0}}/>
      {isMobile &&
        <DownloadPDFLink marginTop={26} onPress={()=>handlePrint(false)} isTooltipVisible={bottomPrintClicked} onTooltipClose={()=>setBottomPrintClicked(false)}/>
       
      }
      {!isMobile &&
       <Tooltip contentStyle={toolTipStyle} disableShadow={true} isVisible={bottomPrintClicked} content={<Text style={toolTipTextStyle}>Printing...</Text>} placement="right" backgroundColor='rgba(0,0,0,0)'  showChildInTooltip={false} onClose={()=>setBottomPrintClicked(false)}>
        <Button onPress={()=>handlePrint(false)}  label='Print your Negotiation Plan'  style={{marginLeft:10}}/>
       </Tooltip>
      }
    </NavigationSection>
  </Layout>
   )
  }
  
  interface DownloadPDFLinkProps{
    marginTop?:number,
    onPress:()=>void,
    isTooltipVisible:boolean,
    onTooltipClose:()=>void
  }

  function DownloadPDFLink(props:DownloadPDFLinkProps){

    return (
      <View style={{marginTop:props.marginTop?props.marginTop:0,flexDirection: 'row'}}>
         <Tooltip contentStyle={toolTipStyle} disableShadow={true} isVisible={props.isTooltipVisible} content={<Text style={toolTipTextStyle}>Downloading...</Text>} placement="right" backgroundColor='rgba(0,0,0,0)'  showChildInTooltip={false} onClose={props.onTooltipClose}>
          <TouchableOpacity onPress={props.onPress}>
            <View style={{flexDirection: 'row'}}>
              <MaterialIcons  name="file-download" size={16} color="#d04343" />
              <Text size={14} style={{color:'#d04343'}}>Download PDF</Text>
            </View>
          </TouchableOpacity>
        </Tooltip>
      </View>
    )
  }

  interface NegotiationTacticProps{
    instruction:string,
    detail:string|JSX.Element|JSX.Element[]
  }
  function NegotiationTactic(props:NegotiationTacticProps){
    return(
      <View style={{marginTop:14}}>
          <Text size={16} bold={true} >{props.instruction}</Text>
          <Text size={16} style={{marginTop:12}}>{props.detail}</Text>
        </View>
    )
  }

  interface NegotiationStepProps{
    marginTop?:number|string
    marginLeft?:number|string
    stepNumber:number,
    instruction: string,
    script:string,
    children?:JSX.Element|JSX.Element[]
    isMobile?:boolean
  }
  function NegotiationStep(props:NegotiationStepProps){

    const [instructionHeight,setInstructionHeight] = useState(0)

    const Step = styled.View`
    width:32px;
    height:32px;
    border-radius:50%;
    border: 3px solid #002C73;
`
  const Connector = styled.View`
    width:3px;
    height:${(connectorProps) => `${connectorProps.height}px`};
    background-color: #002C73;
`
    return(
      <View  
      style={{display:'flex', flexDirection:'row',alignItems:'flex-start',
              marginTop:props.marginTop?props.marginTop:0,
              marginLeft:props.marginLeft?props.marginLeft:0,
              marginBottom:8}}>
        <View style={{flexDirection:'column',alignItems:'center'}}>
          <Step>
              <Text size={16} style={{textAlign:'center',lineHeight:25}} >
                {props.stepNumber}
              </Text>
            </Step>
            <Connector isMobile={props.isMobile} height={instructionHeight}/>
        </View>
        <View 
        onLayout={(event) => {
          const {height} = event.nativeEvent.layout;
          setInstructionHeight(height)
        }}
        
        style={{flexDirection:'column',width:'90%',marginLeft:28,marginTop:5}}>
          <Text size={16} bold={true}>{props.instruction}</Text>
          <Text size={16} style={{marginTop:10}} >{props.script}</Text>
          {props.children}
        </View>
      </View>
      
    )
  }

  interface NumericInputProps{
    id?: string,
    defaultValue:number,
    increment:number,
    maxValue:number,
    minValue:number,
    onValueChanged:(value:number)=>void
  }
  function NumericInput(props:NumericInputProps){
    const [value,setValue]=useState(props.defaultValue)

    const handleIncrement=()=>{
      const newValue=value + props.increment

      if(newValue<=props.maxValue){
        setValue(newValue)
        props.onValueChanged(newValue)
      }
    }
    const handleDecrement=()=>{
      const newValue=value - props.increment

      if(newValue>=props.minValue){
        setValue(newValue)
        props.onValueChanged(newValue)
      }
    }
    const Container = styled.View`
    width: 90px;
    height: 44px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #999999;
    border-radius: 2px;
    padding:12px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
`
    return(
      <Container id={props.id}>
        <View>
          <Text size={16}>{value}%</Text>
        </View>
        <View>
          <TouchableOpacity onPress={handleIncrement}>
            <MaterialIcons accessible={true} accessibilityLabel={`Increase percentage arrow`} name="arrow-drop-up" size={24} color="#d04343" style={{lineHeight:5}}/>
          </TouchableOpacity>
          <TouchableOpacity onPress={handleDecrement}>
            <MaterialIcons accessible={true} accessibilityLabel={`Decrease percentage arrow`} name="arrow-drop-down" size={24} color="#d04343" style={{lineHeight:5,marginTop:9}} />
          </TouchableOpacity>
        </View>
      </Container>
    )
  }


