import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { Question, Row, ButtonOptionWrapper } from '../elements'
import QuestionContainer from '../questionContainer'
import { getErrorText } from '../constants'
import Text from '../../TextComponent'
import Button from '../../button'
import useDeviceWidth from '../../../utils/useDeviceWidth'

interface IRecommend {
  errors: any;
  handleSetValue: (qId: string, answer: string) => void;
}

export default function Recommend(props: IRecommend) {
  const { errors, handleSetValue } = props
  const [ selected, setSelected ] = useState("")
  const [ isError, setIsError ] = useState(false)
  const responsiveStyle = useDeviceWidth()
  
  useEffect(() => {
    if(typeof errors['willRecommend'] !== 'undefined') {
      setIsError(true)
    }
  }, [errors])

  const onSelect = (option) => {
    setSelected(option)
    handleSetValue("willRecommend", option)
    if(isError) {
      setIsError(false)
    }
  }

  
  return (
    <Question>
      <fieldset style={{padding: 0, margin: 0, borderStyle: "none"}}>
        <legend style={{marginBottom: 8}}>
          <Text size={16} style={{marginBottom: 8}} bold={true}>How likely are you to recommend our website?</Text>
          <Text size={16}> (Required)</Text>
        </legend>
        <QuestionContainer
          aria-invalid={isError ? 'true' : 'false' }
          error={isError}
          errorMessage={getErrorText(errors['willRecommend'])}
          id="error-willRecommend"
        >
          <Row aria-required="true">
          {
            Array.from(Array(11).keys()).map((item) => {
              let value = (item).toString()
              return (
                <ButtonOptionWrapper key={`option-${value}`}>
                  <Button
                    onPress={() => onSelect(value)} 
                    label={value}
                    variant={selected === value ? "default" : "primary-inverse"} 
                    style={[
                      styles.button,
                      {width:(responsiveStyle==='width295_359' || responsiveStyle==='wdth360_374')?25:32
                       }]}
                    textStyle={{fontFamily:"SourceSansPro-Bold"}}
                  />
                </ButtonOptionWrapper>
              )
            })
          }
          </Row>
        </QuestionContainer>
      </fieldset>
    </Question>
  )
}

const styles = StyleSheet.create({
  button: {
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 10,
    paddingRight: 10,
    height: 44,
    textAlign: 'center',
    borderRadius:7
  }
})