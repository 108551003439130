import React, { useState } from "react";
import { FontAwesome,MaterialIcons } from '@expo/vector-icons'; 
import Tooltip from 'react-native-walkthrough-tooltip';
import { Text } from '../../../components';
import { TouchableOpacity, View } from "react-native";

const toolTipStyle={
    borderRadius: 2,
    backgroundColor:'#002C73',
    fontFamily:'SourceSansPro',
    width:'100%',
    maxWidth:300
   
  }

export interface QuestionTooltipProps{
    content:string
    placement:'top' | 'bottom' | 'left' | 'right' | 'center'
    marginLeft?:string|number
    marginTop?:string|number
    lineHeight?:number
}

export default function QuestionTooltip(props:QuestionTooltipProps){
    const [isVisible,setVisible]= useState(false)
    return(
   
    <Tooltip contentStyle={toolTipStyle} disableShadow={true} isVisible={isVisible} content={<TooltipContent content={props.content} onClose={()=>setVisible(false)} />} placement={props.placement} backgroundColor='rgba(0,0,0,0)'  showChildInTooltip={false} onClose={()=>setVisible(false)}>
        <TouchableOpacity onPress={()=>{setVisible(true)}}> 
                <FontAwesome name="question-circle" size={16} color="#d04343" 
                    style={{marginLeft:props.marginLeft?props.marginLeft:0,
                            lineHeight:props.lineHeight?props.lineHeight:0,
                            marginTop:props.marginTop?props.marginTop:0}} />
        </TouchableOpacity>   
    </Tooltip>
    )

}
export interface TooltipContentProps{
    content:string,
    onClose:()=>void
}
function TooltipContent(props:TooltipContentProps){
    return(
        <View>
            <TouchableOpacity onPress={props.onClose} style={{alignSelf:"flex-end"}}>
                 <MaterialIcons name="close" size={14} color="#FFFFFF" />
            </TouchableOpacity>
           <Text size={14} style={{color: '#ffffff',padding:10,paddingTop:0}}>{props.content}</Text>
           
        </View>
    )
}

