
import { IAuthProvider } from './authProvider';

export interface HSIDAuthProviderProps {
    provider: IAuthProvider;
    children:any;
  }

export default function HSIDAuthProvider(props:HSIDAuthProviderProps){
    return (
      props.children(props.provider)
    )
}