const BrandLogo = require('../assets/images/Naviguard-Horizontal-Logo.png')
const Group3 = require('../assets/images/group-3/group-3.png');
const BrandMark = require('../assets/images/Naviguard-BrandMark.png')
const CheckBox = require('../assets/images/ic/ic-check/ic-check-box.png')
const CheckBoxBlank = require('../assets/images/ic/ic-check/ic-check-blank.png')
const BrandLogoColored = require('../assets/images/naviguard-logo.png')

export default {
  BrandLogo,
  Group3,
  BrandMark,
  CheckBox,
  CheckBoxBlank,
  BrandLogoColored
}