import React, { useState, useEffect  } from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import Text from '../../TextComponent'
import Link from '../../link'
import { useIsMobile } from '../../../utils/useDeviceWidth'
import { Case, Task } from '../../../redux/cases/types'
import Upload from '../../../screens/Documents/UploadOverlay'
import useGoogleTagManager, {
    GTMEvent,
} from '../../../utils/useGoogleTagManager'
import TaskLineItem from './task-line-item'
import axiosInstance from '../../../redux/axiosInstance'
import useAppInsights, { AIEvent } from '../../../utils/useAppInsights'
import { useOverlay } from '../../../context-api/overlay/overlayState'
import { isIosSafari } from '../../../utils/agent'
import { AxiosResponse } from 'axios'
import { useNavigation } from '@react-navigation/native'
import StatusTracker from './StatusTracker'
import SectionName from './SectionName'
import BodyCard from './BodyCard'
import { fetchDocuments, downloadDocument, deleteDocument, resetDeleteDocumentStatus, DownloadResult } from '../../../redux/documents/actions'
import {CaseError, Document, DocumentDeleteStatus} from '../../../redux/documents/types'
import { ApplicationState } from '../../../redux/rootReducer'
import { DocumentsState } from '../../../redux/documents/reducer'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/native'
import Tooltip from 'react-native-walkthrough-tooltip'
import mime  from 'mime-types'
import Ionicons from '@expo/vector-icons/Ionicons'
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import Confirmation from '../../../components/modal/Confirmation'
import Message from '../../../components/modal/Message'
import { fetchCases } from '../../../redux/cases/actions';
import { Button } from "../..";

export interface TaskAction {
    event?: GTMEvent
    route?: string
    params?: Record<string, string>
    handler?: (TaskItem) => void
}

export interface TaskItem {
    task: Task
    action: TaskAction
    caseId: string
}

export interface BodyProps {
    case: Case
    onTaskAction: (route: string, params?: Record<string, string>) => void
}

export default function Body(props: BodyProps) {
    const appInsights = useAppInsights()
    const { overlay } = useOverlay()
    const navigation = useNavigation()
    const dispatchEsign = useDispatch();

    const [linkTooltipVisibleLookup, setLinkTooltipVisibleLookup] = useState(
        new Map()
    )

    const actions = {
        'Member Story': () => {
            return {
                event: GTMEvent.StoryClick,
                route: 'Wizard',
                params: {
                    caseId: props.case.caseId,
                    mode: 'edit',
                },
            }
        },
        'Upload a Bill': () => {
            return {
                event: GTMEvent.UploadClick,
                handler: () => {
                    appInsights.trackDocumentEvent(
                        AIEvent.UploadClick,
                        props.case
                    )
                    setUploadVisible(true)
                },
            }
        },
        ESign: () => {
            return {
                event: GTMEvent.InitiateRelease,
                handler: (task: TaskItem) => {
                    overlay({
                        show: true,
                        type: 'spinner',
                        message: `Securely navigating to your forms..`,
                        autoHide: false,
                    })
                    let errorMessage = 'Please try again later.'
                    const responseNoEsignMessage =
                        'No ESign Available for signing'
                    const friendlyNoEsignMessage =
                        'This document is no longer available for signing. It has most likely been signed within the past few minutes.'

                    // ios safari really doesn't like opening windows in async functions for some reason.
                    // work around this by opening a blank window, fetching the url, and replacing it in
                    // the newly opened window reference.
                    // because, you know, it's apple and you gotta "think different" >:(
                    //
                    // see:
                    // https://stackoverflow.com/a/39387533/5190023
                    if (isIosSafari()) {
                        const wnd = window.open('/loading_esign.html', '', '')

                        getDocuSignUrl(task.caseId)
                            .then((data: string) => {
                                const status = Object.values(data)[1]
                                const url = Object.values(data)[2]
                                if (url) {
                                    overlay({
                                        show: false,
                                        type: 'spinner',
                                        message: '',
                                    })
    
                                    // set the url for the new window and bring it into focus
                                    wnd.location.href = url
                                    wnd.focus()
                                }
                                else if(status){
                                    overlay({
                                        show: true,
                                        type: 'message',
                                        message: 'Your documents were successfully signed.  You can refresh your page to update your task list.',
                                        messageType: 'success',
                                        buttonLabel: 'Close',
                                    })
                                } else {
                                    overlay({
                                        show: true,
                                        type: 'message',
                                        message: 'An Error Occured Fetching ESign. Please Contact Your Advisor.',
                                        messageType: 'error',
                                        buttonLabel: 'Close',
                                    })
                                }
                            })
                            .catch((e) => {
                                if (
                                    e.response.data === responseNoEsignMessage
                                ) {
                                    errorMessage = friendlyNoEsignMessage
                                }
                                // close the previously opened window
                                wnd.close()

                                overlay({
                                    show: true,
                                    type: 'message',
                                    message: errorMessage,
                                    messageType: 'error',
                                    buttonLabel: 'Close',
                                })
                            })
                    } else {
                        getDocuSignUrl(task.caseId)
                            .then((data: string) => {
                                const status = Object.values(data)[1]
                                const url = Object.values(data)[2]
                                if (url) {
                                    overlay({
                                        show: false,
                                        type: 'spinner',
                                        message: '',
                                    })

                                    window.open(url, '_blank')
                                }
                                else if(status){
                                    overlay({
                                        show: true,
                                        type: 'message',
                                        message: 'Your documents were successfully signed.  You can refresh your page to update your task list.',
                                        messageType: 'success',
                                        buttonLabel: 'Close',
                                    })
                                } else {
                                    overlay({
                                        show: true,
                                        type: 'message',
                                        message: 'An Error Occured Fetching ESign. Please Contact Your Advisor.',
                                        messageType: 'error',
                                        buttonLabel: 'Close',
                                    })
                                }
                            })
                            .catch((e) => {
                                if (
                                    e.response.data === responseNoEsignMessage
                                ) {
                                    errorMessage = friendlyNoEsignMessage
                                }
                                overlay({
                                    show: true,
                                    type: 'message',
                                    message: errorMessage,
                                    messageType: 'error',
                                    buttonLabel: 'Close',
                                })
                            })
                    }
                    dispatch(fetchCases())
                },
            }
        },
    }

    const gtm = useGoogleTagManager()
    const isMobile = useIsMobile()
    // sort tasks by their display number in ascending order

    const taskOrder = {
        'Member Story': 1,
        'Upload a Bill': 2,
        ESign: 3,
        undefined: 4,
    }
    const tasks = props.case.tasks
        .map((task) => {
            let action = undefined

            if (actions.hasOwnProperty(task.taskName)) {
                action = actions[task.taskName]()
            }

            return {
                task,
                action,
                caseId: props.case.caseId,
            }
        })
        .sort((a, b) =>
            taskOrder[a.task.taskName] < taskOrder[b.task.taskName] ? -1 : 1
        )
    // .sort((a, b) => (a.task.displayOrder < b.task.displayOrder ? -1 : 1))

    const getDocuSignUrl = async (caseId: string) => {
        const axios = await axiosInstance()
        const redirectUrl = new URL(window.location.href)

        redirectUrl.searchParams.append('caseId', caseId)

        return axios
            .post('/document-links', {
                caseNumber: props.case.caseNumber,
                workPackageCaseSetId: props.case.caseSetId,
                returnUrl: redirectUrl, // TODO: Expo/RN Friendly
            })
            .then((response: AxiosResponse) => response.data)
    }

    const handleEmailAdvisor = () => {
        navigation.navigate('Contact', {
            caseId: props.case.caseId,
            contactAdvisorLocation: 'Case',
        })
    }

    const handleTaskAction = async (task: TaskItem) => {
        // push an event for clicking on the task
        if (task.action.event) {
            gtm.pushEvent(task.action.event, {
                caseNumber: props.case.caseNumber,
                patientID: props.case.patientId,
                userIsPatient: props.case.patientInd ? 'Yes' : 'No',
            })
        }

        // if a handler function is to be invoked, call it now
        // otherwise invoke the props task action handler if a route change is required
        if (task.action.handler) {
            task.action.handler(task)
        } else if (task.action.route) {
            props.onTaskAction(task.action.route, task.action.params)
        }
    }

    const [deleteTooltipVisibleLookup,setDeleteTooltipVisibleLookup] =useState(new Map())
    const [downloadTooltipVisibleLookup,setDownloadTooltipVisibleLookup] =useState(new Map())
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [deleteDocumentId, setDeleteDocumentId] = useState("")
    const [messageVisible,setMessageVisible] =useState(false)
    const [messageText,setMessageText] =useState("")

    const setDeleteTooltipVisible = (documentId: string, visible: boolean) => {
        const map = new Map(deleteTooltipVisibleLookup)
        map.set(documentId, visible)
        setDeleteTooltipVisibleLookup(map)
    }
    const setDownloadTooltipVisible = (
        documentId: string,
        visible: boolean
    ) => {
        const map = new Map(downloadTooltipVisibleLookup)
        map.set(documentId, visible)
        setDownloadTooltipVisibleLookup(map)
    }
    const setLinkTooltipVisible = (documentId: string, visible: boolean) => {
        const map = new Map(linkTooltipVisibleLookup)
        map.set(documentId, visible)
        setLinkTooltipVisibleLookup(map)
    }

    const createDocumentURL = async (
        documentId: string,
        documentName: string,
        forLink: boolean
    ) => {
        if (forLink) {
            setLinkTooltipVisible(documentId, true)
        } else {
            setDownloadTooltipVisible(documentId, true)
        }

        const result: DownloadResult = await downloadDocument(
            documentId,
            props.case.caseId
        )
        let url = ''

        if (result.errorMessage) {
            appInsights.trackDocumentException(
                forLink ? AIEvent.DocumentView : AIEvent.DocumentDownload,
                result.exception,
                result.errorMessage,
                props.case,
                { id: documentId, fileName: documentName }
            )

            setMessageText(result.errorMessage)
            setMessageVisible(true)
        } else {
            const mimeType = mime.lookup(documentName)
            const blob = new Blob([result.buffer], { type: mimeType })
            url = URL.createObjectURL(blob)
        }

        if (forLink) {
            setLinkTooltipVisible(documentId, false)
        } else {
            setDownloadTooltipVisible(documentId, false)
        }

        return url
    }

    const handleOnDocumentLinkPress = async (
        documentId: string,
        documentName: string
    ) => {
        gtm.pushDocument(
            GTMEvent.DocumentView,
            props.case.caseNumber,
            props.case.patientId,
            props.case.patientInd
        )
        appInsights.trackDocumentEvent(AIEvent.DocumentView, props.case, {
            id: documentId,
            fileName: documentName,
        })
        const url = await createDocumentURL(documentId, documentName, true)

        if (url) {
            window.open(url)
            URL.revokeObjectURL(url)
        }
    }
    const handleOnFileDownloadPress = async (
        documentId: string,
        documentName: string
    ) => {
        gtm.pushDocument(
            GTMEvent.DocumentDownload,
            props.case.caseNumber,
            props.case.patientId,
            props.case.patientInd
        )
        appInsights.trackDocumentEvent(AIEvent.DocumentDownload, props.case, {
            id: documentId,
            fileName: documentName,
        })
        const url = await createDocumentURL(documentId, documentName, false)

        if (url) {
            const link = document.createElement('a')
            link.href = url
            link.download = documentName
            link.click()
            URL.revokeObjectURL(url)
        }
    }
    const handleOnDeleteIconPress = (documentId: string) => {
        setDeleteDocumentId(documentId)
        setShowDeleteConfirmation(true)
    }
    const handleDeleteConfirmationCancel = () => {
        setDeleteDocumentId('')
        setShowDeleteConfirmation(false)
    }

    const handleDeleteConfirmationOk = () => {
        gtm.pushDocument(
            GTMEvent.DocumentDelete,
            props.case.caseNumber,
            props.case.patientId,
            props.case.patientInd
        )
        appInsights.trackDocumentEvent(AIEvent.DocumentDelete, props.case, {
            id: deleteDocumentId,
        })
        dispatch(deleteDocument(deleteDocumentId, props.case))
        setShowDeleteConfirmation(false)
        setDeleteTooltipVisible(deleteDocumentId, true)
    }

    const [uploadVisible, setUploadVisible] = useState(false)
    const handleUploadClose = () => {
        setUploadVisible(false)
    }

    const handleUploadOpen = () => {
        gtm.pushDocument(
            GTMEvent.UploadClick,
            props.case.caseNumber,
            props.case.patientId,
            props.case.patientInd
        )
        appInsights.trackDocumentEvent(AIEvent.UploadClick, props.case)
        setUploadVisible(true)
    }

    const fetchDocFailedText =
        'Oh no! We’re having trouble displaying your documents.'

    const GoogleTagHandler = () => {
        const charLimit = (str) => str.substring(0, 100)

        gtm.pushEvent(GTMEvent.DocumentFetchFailure, {
            event: 'fetchDoc - Documents/CaseItem',
            errorType: 'Failed to fetch documents',
            errorMessage: charLimit(fetchDocFailedText),
        })
    }

    const oneMB = 1048576
    const oneKB = 1024

    const formatFileSize = (fileSize: number) => {
        let formattedSize = ''

        if (fileSize) {
            if (fileSize >= oneMB) {
                formattedSize = `(${Math.round(fileSize / oneMB)}MB)`
            } else if (fileSize >= oneKB) {
                formattedSize = `(${Math.round(fileSize / oneKB)}KB)`
            } else {
                formattedSize = `(${fileSize / oneKB}B)`
            }
        }
        return formattedSize
    }

    useEffect(()=>{
        dispatch(fetchDocuments(props.case))
        }
    ,[])
     
    const dispatch = useDispatch()
    const documentsState:DocumentsState= useSelector((state:ApplicationState)=>state.documents)
    let documents:Document[] = [];
    
    if(documentsState.data) 
    {
          const cases=documentsState.data.filter(f=>f && f.caseId===props.case.caseId);
    
          if(cases.length>0)
            documents= cases[0].documents;
          else
           documents=[]
    }

    const deleteSelector: DocumentDeleteStatus = documentsState.deleteStatus

    useEffect(() => {
        if (deleteSelector !== undefined) {
            setDeleteTooltipVisible(deleteDocumentId, false)
            if (
                !deleteSelector.success &&
                props.case.caseId == deleteSelector.caseId
            ) {
                appInsights.trackDocumentException(
                    AIEvent.DocumentDelete,
                    deleteSelector.exception,
                    deleteSelector.message,
                    props.case,
                    { id: deleteDocumentId }
                )
                setMessageText(deleteSelector.message)
                setMessageVisible(true)
            }
            dispatch(resetDeleteDocumentStatus())
        }
    }, [deleteSelector])

    let documentFetchError: CaseError = undefined
    if (documentsState.fetchErrors) {
        const errors = documentsState.fetchErrors.filter(
            (f) => f.caseId === props.case.caseId
        )

        if (errors.length > 0) {
            documentFetchError = errors[0]
        }
    }

    useEffect(() => {
        if (documentFetchError) {
            GoogleTagHandler()
            appInsights.trackDocumentException(
                AIEvent.DocumentList,
                documentFetchError.exception,
                documentFetchError.error,
                props.case,
                {}
            )
        }
    }, [documentFetchError])

    return (
        <BodyCard case={props.case} isMobile={isMobile}>
            <SectionName case={props.case} isMobile={isMobile}>
                <View
                    style={
                        isMobile ? mobile.tasksSection : desktop.tasksSection
                    }>
                    <Text accessibilityRole="heading" ariaLevel="3" bold={true} style={styles.columnTitle}>
                        Tasks to Complete
                    </Text>
                    <View>
                        <ul
                            style={{
                                margin: 0,
                                padding: 0,
                                listStyle: 'none',
                            }}>
                            {tasks.map((taskItem: TaskItem, index) => {
                                let linkOnPress
                                if (taskItem) {
                                    linkOnPress = () =>
                                        handleTaskAction(taskItem)
                                }

                                return (
                                    <li key={`task-${index}`}>
                                        <TaskLineItem
                                            key={taskItem.task.taskId}
                                            task={taskItem.task}
                                            taskAction={taskItem.action}
                                            linkOnPress={linkOnPress}
                                            case={props.case}
                                        />
                                    </li>
                                )
                            })}
                        </ul>
                    </View>
                </View>
                {documents.length > 0 && (
                    <View
                        style={{
                            paddingRight: '75px'
                        }}
                    >
                        <View 
                            style={{
                                borderBottomColor: 'rgba(42, 46, 114, 0.3)',
                                borderBottomWidth: '1px'
                            }}
                        >
                            <Text bold={true} style={styles.columnTitle}>
                                Documents
                            </Text>
                            {props.case.caseStatus !== 'Case Closed' && (
                                <View style={styles.additionalDocumentUpload}>
                                  <Text bold={false}>
                                      Upload additional documents for your case
                                  </Text>
                                  <Button onPress={() => setUploadVisible(true)}  
                                      label={
                                      <>
                                          Upload
                                          <MaterialIcons name="file-upload" style={{marginLeft:6, fontSize:15,verticalAlign:'middle'}} />
                                      </>
                                      }
                                  ></Button>
                              </View>
                            )}
                      
                        </View>
                        <View
                            style={{
                                marginBottom: '15px'
                            }}
                        >
                            {documents.map((doc, i) => {
                                return (
                                    <DocumentRow key={doc.id}>
                                        <View style={{ maxWidth: '80%' }}>
                                            <Tooltip
                                                contentStyle={toolTipStyle}
                                                disableShadow={true}
                                                isVisible={linkTooltipVisibleLookup.get(
                                                    doc.id
                                                )}
                                                content={
                                                    <Text style={toolTipTextStyle}>
                                                        Downloading...
                                                    </Text>
                                                }
                                                placement="right"
                                                backgroundColor="rgba(0,0,0,0)"
                                                showChildInTooltip={false}
                                                onClose={() =>
                                                    setLinkTooltipVisible(doc.id, false)
                                                }>
                                                <Link
                                                    accessibilityLabel="Document opens in a new window"
                                                    onPress={async () =>
                                                        await handleOnDocumentLinkPress(
                                                            doc.id,
                                                            doc.fileName
                                                        )
                                                    }>
                                                    {`${doc.fileName} ${formatFileSize(
                                                        doc.fileSize
                                                    )}`}
                                                    <MaterialIcons name="open-in-new" size={12} />
                                                </Link>
                                            </Tooltip>
                                        </View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Tooltip
                                                contentStyle={[
                                                    toolTipStyle,
                                                    { width: 90 },
                                                ]}
                                                disableShadow={true}
                                                isVisible={deleteTooltipVisibleLookup.get(
                                                    doc.id
                                                )}
                                                content={
                                                    <Text style={toolTipTextStyle}>
                                                        Deleting...
                                                    </Text>
                                                }
                                                placement="left"
                                                backgroundColor="rgba(0,0,0,0)"
                                                showChildInTooltip={false}
                                                onClose={() =>
                                                    setDeleteTooltipVisible(
                                                        doc.id,
                                                        false
                                                    )
                                                }>
                                                {doc.canDelete && (
                                                    <TouchableOpacity
                                                        accessible={false}
                                                        onPress={() =>
                                                            handleOnDeleteIconPress(
                                                                doc.id
                                                            )
                                                        }>
                                                        <Ionicons
                                                            onKeyUp={(ev) => {
                                                                if(ev.key==='Enter') {
                                                                    handleOnDeleteIconPress(
                                                                        doc.id
                                                                    )
                                                                }
                                                            }}
                                                            accessible={true}
                                                            accessibilityRole="button"
                                                            accessibilityLabel="Delete file"
                                                            name="md-trash"
                                                            size={16}
                                                            color="#0074C8"
                                                            style={{ marginRight: 8 }}
                                                        />
                                                    </TouchableOpacity>
                                                )}
                                            </Tooltip>
                                            <Tooltip
                                                contentStyle={toolTipStyle}
                                                disableShadow={true}
                                                isVisible={downloadTooltipVisibleLookup.get(
                                                    doc.id
                                                )}
                                                content={
                                                    <Text style={toolTipTextStyle}>
                                                        Downloading...
                                                    </Text>
                                                }
                                                placement="left"
                                                backgroundColor="rgba(0,0,0,0)"
                                                showChildInTooltip={false}
                                                onClose={() =>
                                                    setDownloadTooltipVisible(
                                                        doc.id,
                                                        false
                                                    )
                                                }>
                                                <TouchableOpacity
                                                    accessible={false}
                                                    onPress={() =>
                                                        handleOnFileDownloadPress(
                                                            doc.id,
                                                            doc.fileName
                                                        )
                                                    }>
                                                    <MaterialIcons
                                                        onKeyUp={(ev) => {
                                                            if(ev.key==='Enter') {
                                                                handleOnFileDownloadPress(
                                                                    doc.id,
                                                                    doc.fileName
                                                                )
                                                            }
                                                        }}
                                                        accessible={true}
                                                        accessibilityRole="button"
                                                        accessibilityLabel="Download File"
                                                        name="file-download"
                                                        size={16}
                                                        color="#0074C8"
                                                    />
                                                </TouchableOpacity>
                                            </Tooltip>
                                        </View>
                                    </DocumentRow>
                                )
                            })}
                        </View>
                    </View>
                )}
                <View
                    style={isMobile ? mobile.infoSection : desktop.infoSection}>
                    <Text accessibilityRole="heading" ariaLevel="3" bold={true} style={[styles.columnTitle]}>
                        Questions?
                    </Text>
                    <Link style={{ fontSize: 16 }} onPress={handleEmailAdvisor}>
                        Contact your advisor
                    </Link>
                </View>
                <Upload
                    onClose={() => setUploadVisible(false)}
                    visible={uploadVisible}
                    caseItem={props.case}
                />
            </SectionName>
            <StatusTracker progress={props.case.progress} />
            <Confirmation show={showDeleteConfirmation} message="Are you sure you want to delete this file?" onCancel={handleDeleteConfirmationCancel} onOK={handleDeleteConfirmationOk}></Confirmation> 
            <Message message={messageText} show={messageVisible} onClose={()=> setMessageVisible(false)} onOK={()=> setMessageVisible(false)} variant="error"></Message>
        </BodyCard>
    )
}

const styles = StyleSheet.create({
    subTitle: {
        color: '#002C73',
        fontWeight: '700',
    },
    columnTitle: {
        fontWeight: '700',
        color: '#002C73',
        marginBottom: 10,
        fontSize: 16,
    },
    additionalDocumentUpload: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        marginBottom: 10,
    },
    link: {
        color: '#D04443',
        fontSize: 14,
        fontWeight: '700',
        textDecorationLine: 'underline',
    },
})

const mobile = StyleSheet.create({
    tasksSection: {
        marginBottom: 40,
        flexBasis: '100%',
    },
    infoSection: {
        flexBasis: '100%',
        marginBottom: 40,
    },
})

const desktop = StyleSheet.create({
    nameSection: {
        marginBottom: 20,
    },
    tasksSection: {
        marginBottom: 20,
    },
    infoSection: {
        marginBottom: 20,
    },
})

const DocumentRow = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 8px;
    border-top-color: rgba(42, 46, 114, 0.3);
    border-top-width: ${(props) => (props.key === 0 ? '1px' : '0px')};
    border-bottom-color: rgba(42, 46, 114, 0.3);
    border-bottom-width: 1px;
`

const toolTipStyle = {
    padding: 12,
    borderRadius: 2,
    backgroundColor: '#002C73',
    fontFamily: 'SourceSansPro',
    width: 116,
    height: 44,
}

const toolTipTextStyle = {
    fontSize: 14,
    color: '#ffffff',
}
