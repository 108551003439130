import React, { useReducer, useContext } from 'react';
import { Modal as ReactModal } from "react-native";
import OverlayContext from './overlayContext';
import OverlayReducer from './overlayReducer';
import { CenteredView, Message, Spinner, IOverlay } from './overlayComponents'

export const useOverlay = () => {
  const { dispatch } = useContext(OverlayContext);

  const overlay = (newState: IOverlay) => {
    dispatch({
      type: 'UPDATE_OVERLAY',
      payload: newState,
    });  

    if(newState.type === 'spinner' && newState.autoHide) {
      let hideTime = newState.hideTime || 3000
      setTimeout(() => {
        dispatch({
          type: 'UPDATE_OVERLAY',
          payload: { show: false, message: '' },
        });  
      }, hideTime)
    }
  }
  
  return { overlay }
};

const initialState: IOverlay = {
  show: false,
  message: '',
  type: 'spinner',
  autoHide: true,
  hideTime: 3000
};
const OverlayProvider = (props: any) => {
  const [state, dispatch] = useReducer(OverlayReducer, initialState);
  return (
    <OverlayContext.Provider value={{state, dispatch}}>
      {props.children}
      <ReactModal transparent={true} visible={state.show}>
        <CenteredView whiteTransparent={state.type === 'spinner'}>
          { state.type === 'spinner' && <Spinner message={state.message} />}
          { state.type === 'message' && <Message dispatch={dispatch} data={state} /> }
        </CenteredView>
      </ReactModal>
    </OverlayContext.Provider>
  );
}

export default OverlayProvider