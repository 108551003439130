import { Dispatch } from "redux";
import axiosInstance from "../axiosInstance";
import { Contacts, ContactsDispatchTypes,  FETCH_CONTACTS_PENDING } from "./types";

export const fetchContacts =  ()  => async (dispatch: Dispatch<ContactsDispatchTypes>):Promise<Contacts>  => {
    
  dispatch({
    type: FETCH_CONTACTS_PENDING,
    payload:true
  })
  
  const axios = await axiosInstance()
 
    try {
      const response = await axios.get('/contacts')
     
       return response.data
      
        } 
          finally{
            dispatch({
              type: FETCH_CONTACTS_PENDING,
              payload:false
            })
          }
}





export const updateContacts = async (contacts:Contacts):Promise<any> => {
  const axios = await axiosInstance()
  const response = await axios.put('/contacts',contacts)
  return  response.data
  
}