import React, { useState, useRef, useEffect } from 'react'
import { createPortal } from "react-dom"
import { View, TouchableOpacity, StyleSheet, ScrollView } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons';
import { Hoverable } from 'react-native-web-hover'
import { Question, SelectWrapper } from '../elements'
import QuestionContainer from '../questionContainer'
import { getErrorText, selectOptions } from '../constants'
import Text from '../../TextComponent'

interface IVisitPurpose {
  errors: any;
  handleSetValue: (qId: string, answer: string) => void;
  modalRef: any;
  isMobile: boolean;
  isTablet: boolean;
}

export default function VisitPurpose(props: IVisitPurpose) {
  const { errors, handleSetValue, modalRef, isMobile } = props
  const anchorRef = useRef(null)
  const [ selected, setSelected ] = useState(null)
  const [open, setIsOpen] = useState(false)
  const [anchorLayout, setAnchorLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });
  const [ isError, setIsError ] = useState(false)
  const selectItemIndexRef=useRef(-1);
  const dropdownRef=useRef<HTMLDivElement>()
  const comboboxRef =useRef<TouchableOpacity>()
  const selectRef= useRef<HTMLDivElement>()

  useEffect(() => {
    if(typeof errors['visitPurpose'] !== 'undefined') {
      setIsError(true)
    }
  }, [errors])

  const onSelect = (option) => {
    setSelected(option)
    handleSetValue("visitPurpose", option.value)
    setIsOpen(false)
    comboboxRef.current.focus()
    if(isError) {
      setIsError(false)
    }
  }
  const listItemFocus=(index:number)=>{
    
    if(dropdownRef.current){
      const focusables= dropdownRef.current.querySelectorAll("[data-focusable=true]")
      if(focusables.length>0){
        (focusables[index] as HTMLElement).focus()
      }
    }
  }

  const handleListItemArrowKey=(e: KeyboardEvent)=>{
    switch(e.key){
      case 'ArrowUp':
        //avoid screen scrolling
        e.preventDefault()
        selectItemIndexRef.current=selectItemIndexRef.current>0? selectItemIndexRef.current-1:0
        listItemFocus(selectItemIndexRef.current)
        break;
      case 'ArrowDown':
        //avoid screen scrolling
        e.preventDefault()
        const itemCount =selectOptions.length
        selectItemIndexRef.current=Math.max(0,selectItemIndexRef.current<itemCount-1? selectItemIndexRef.current +1:itemCount-1)
        listItemFocus(selectItemIndexRef.current)
        break;
      case 'Tab':
        //avoid screen scrolling
        e.preventDefault()
       setIsOpen(false)
        comboboxRef.current.focus()
        break;
      default:
        break;
    }
  }
  const handleSelectArrowKey=(e: KeyboardEvent)=>{
   
    switch(e.key){
      case 'ArrowUp':
         //avoid screen scrolling
        e.preventDefault()
        selectItemIndexRef.current=selectItemIndexRef.current>0? selectItemIndexRef.current-1:0
        onSelect(selectOptions[selectItemIndexRef.current])
        
        break;
      case 'ArrowDown':
         //avoid screen scrolling
        e.preventDefault()
        const itemCount =selectOptions.length
        selectItemIndexRef.current=Math.max(0,selectItemIndexRef.current<itemCount-1? selectItemIndexRef.current +1:itemCount-1)
        onSelect(selectOptions[selectItemIndexRef.current])
        
        break;
      default:
        break;
    }
  }

useEffect(() => {
  selectRef.current.addEventListener('keydown', handleSelectArrowKey);
  return () => {
    selectRef.current.removeEventListener('keydown', handleSelectArrowKey);
  };
}, [])

useEffect(() => {
  if(open && dropdownRef.current) { 
    dropdownRef.current.addEventListener('keydown', handleListItemArrowKey);
    if(selectItemIndexRef.current<0){
       selectItemIndexRef.current=0
    }
    listItemFocus(selectItemIndexRef.current)
  }
return () => {
  if(dropdownRef.current){
    dropdownRef.current.removeEventListener('keydown', handleListItemArrowKey);
  }
};
}, [open])
  return (
    <Question>
      <View style={{flexDirection:'row'}}>
        <label id="visitPurpose" style={{ fontSize: '16px', fontFamily: 'SourceSansPro-Bold', color: '#002C73', marginBottom: 6}}>What is the purpose of your visit?</label>
        <Text size={16}> (Required)</Text>
      </View>
      <QuestionContainer
        error={isError}
        aria-invalid={isError ? 'true' : 'false' }
        errorMessage={getErrorText(errors['visitPurpose'])}
        noBorderOnError={true}
        id="error-visitPurpose"
      >
        <SelectWrapper
          aria-invalid={isError ? 'true' : 'false' }
          aria-labelledby="visitPurpose"
          aria-required="true"
          ref={anchorRef}
          error={isError}
          onLayout={(event) => setAnchorLayout(event.nativeEvent.layout)}
        >
          <div ref={selectRef}>
            <TouchableOpacity onPress={() =>setIsOpen(!open)} style={styles.select} ref={comboboxRef}>
              {!selected && <Text>Select one</Text>}
              {selected && <Text>{selected.text}</Text>}
              <MaterialIcons name="keyboard-arrow-down" size={24} color="black" />
            </TouchableOpacity>
          </div>
          {
            open && 
              <Menu  
                anchorRef={anchorRef}
                anchorLayout={anchorLayout}
                parentRef={modalRef}
                style={[styles.menu, 
                  { 
                    width: anchorLayout?.width,
                    left: isMobile ? 17 : 32
                  }]}
              >
                <ScrollView>
                <div ref={dropdownRef} >
                {
                  selectOptions.map((option) => {
                    let isSelected = selected?.value === option.value
                    return (
                      <Hoverable key={option.value}>
                        {({ hovered }) => (
                          <TouchableOpacity
                            onPress={() => {onSelect(option);}}
                            style={[
                              styles.option, 
                              hovered || isSelected ? styles.hoveredOption : {},
                            ]}
                          >
                            <Text>{option.text}</Text>
                          </TouchableOpacity>
                        )}
                      </Hoverable>
                      
                    )
                  })
                }
                 </div>
                </ScrollView>
              </Menu>
          }
        </SelectWrapper>
      </QuestionContainer>
    </Question>
  )
}

const Menu = ({ children, parentRef, anchorRef, anchorLayout, style }) => {
  if(!anchorLayout || !anchorRef.current) 
    return null
  
  let position = anchorRef.current.getBoundingClientRect();
  let coord = { top: position.y + 49}
  let parentTop = parentRef.current.getBoundingClientRect().y;
  coord.top = coord.top - parentTop;

  return createPortal(<View  accessibilityRole="combobox" style={[style, coord]}>{children}</View>, parentRef.current)
}

const styles = StyleSheet.create({
  select: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  menu: {
    position: "absolute",
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderColor: "#999999",
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    borderTopWidth: 1,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2
  },
  option: {
    height: 44,
    paddingLeft: 11,
    flexDirection: 'row',
    alignItems: 'center'
  },
  selectedOption: {
    backgroundColor: ""
  },
  hoveredOption: {
    backgroundColor: "#f6f6f6"
  }
})