import { useLayoutEffect, useState } from 'react'
import "@expo/match-media";
import { useMediaQuery } from "react-responsive";
import { Dimensions } from 'react-native'

export default function useDeviceType() {
  const [deviceWidth, setDeviceWidth] = useState(0);
  useLayoutEffect(() => {
    function updateSize(event) {
      const { width } = event.window;
      setDeviceWidth(width);
    }
    Dimensions.addEventListener('change', updateSize);
    setDeviceWidth(window.innerWidth);
    
    return () => Dimensions.removeEventListener('change', updateSize);
  }, []);

  const isTablet = useMediaQuery({
    query: "(min-device-width:768px) and (max-device-width:1024px)"
  });

  const isPhone = useMediaQuery({
    query: "(max-device-width:767px)"
  });

  const deviceType = () => {
    let _isPhone = deviceWidth <= 1024
    let _isTablet = deviceWidth >= 768 && deviceWidth <= 1024

    if(isTablet || _isTablet) {
      return 'tablet'
    } else if(isPhone || _isPhone) {
      return 'phone'
    } else {
      return 'desktop'
    }
  }

  return deviceType()
}