import React, { useEffect } from 'react';
import { useQueryNavigator } from '../../context-api/query';

export default function Landing({ navigation }) {
  const navigator = useQueryNavigator(navigation)
  
  useEffect(() => {
    navigator.navigate('Home')
  }, [])

  return <></>
}
