import React from 'react'
import { View } from 'react-native';
import NumberFormat from 'react-number-format';
import Skeleton from '../Skeleton';

export interface PhoneFormatProps {
  editMode:boolean;
  value:string|number;
  onBlur?:()=>void;
  onValueChange?:(...event:any[])=>void;
  errorState:boolean;
  dataLoaded:boolean;
  width?:string;
  editModeHeight?:string;
  readOnlyModeHeight?:string;
  maskValue?:string;
  id?:string;
}

export default  function PhoneFormat(props:PhoneFormatProps){
const {maskValue, editMode,value,onBlur,onValueChange,errorState,dataLoaded,width,editModeHeight,readOnlyModeHeight, id} = props;
const mask =maskValue?maskValue:"_"
let inputWidth=width
let editableInputHeight=editModeHeight
let readOnlyInputHeight=readOnlyModeHeight


if(!inputWidth)
  inputWidth="200px"

if(!editableInputHeight)
  editableInputHeight="30px"

if(!readOnlyInputHeight)
  readOnlyInputHeight="20px"

const style ={
   fontFamily: 'SourceSansPro-Regular',
   color: '#002C73',
   fontSize:16,
   width:inputWidth
 }
return(
  <>
   { !dataLoaded &&
    <View  style= {{height: readOnlyInputHeight, width: inputWidth}}>
     <Skeleton />
    </View>
   }
   { dataLoaded &&
    <NumberFormat
      aria-invalid={errorState ? "true" : "false"}
      autoComplete="tel"
      id={id}
      data-focusable={editMode ? "true" : "false"}
      displayType={editMode?"input":"text"}  
      style={{...style,width:inputWidth,height:editMode?editableInputHeight:readOnlyInputHeight,borderColor:errorState?'#e00000':'' }} 
      format={!value && !editMode?"":"(###) ###-####"} 
      allowEmptyFormatting 
      mask={mask} 
      value={value}
      onBlur={onBlur}
      onValueChange={onValueChange}  
    />
   }
   </>
)
}