import React, { ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-native'
import Text from '../TextComponent'
import { Button } from '..'
import { ApplicationState } from '../../redux/rootReducer'
import useGoogleTagManager, { GTMEvent } from '../../utils/useGoogleTagManager';

export interface CasesErrorBoundaryProps {
    children: ReactNode;
    onRetry: () => void;
}

export default function CasesErrorBoundary(props: CasesErrorBoundaryProps) {
    const casesError = useSelector((state: ApplicationState) => state.cases.error)
    const errorText = 'Oh no! We’re having trouble displaying your case information. Please retry or refresh the page.'
    const gtm = useGoogleTagManager()

    const GoogleTagHandler = () => {
        const charLimit = str => str.substring(0, 100);

        if(casesError) {
            gtm.pushEvent(GTMEvent.CasesErrorBoundary, {
                'event': 'CasesErrorBoundary',
                'errorType': 'Trouble displaying document',
                'errorMessage' : charLimit(errorText)
            })
        }
    }

    useEffect(() => {
        GoogleTagHandler()
    }, [casesError])

    return (
        <>
            {
                casesError ?
                    <View testID="case-error-boundary-root">
                        <Text style={{ marginBottom: 46 }} bold={true} size="m">{errorText}</Text>
                        <Button label="RETRY" onPress={props.onRetry} />
                    </View>
                : props.children
            }
           
        </>
    )
}