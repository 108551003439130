import {
  FETCH_CASES_PENDING,
  FETCH_CASES_SUCCESS,
  FETCH_CASES_ERROR,
  Case,
  CasesDispatchTypes,
  UPDATE_CASE_TASK,
} from "./types"

export interface CasesState {
  pending: boolean;
  data: Case[];
  error?: string;
}

const defaultState: CasesState = {
  pending: true,
  data: [],
  error: undefined
}

export const CasesReducer = (state: CasesState = defaultState, action: CasesDispatchTypes) => {
  switch (action.type) {
    case FETCH_CASES_PENDING:
      return {
        ...state, 
        pending: action.payload,
        error: undefined,
      }
    case FETCH_CASES_SUCCESS:
      return { 
        ...state, 
        data: action.payload,
        pending: false,
        error: undefined,
      }
    case FETCH_CASES_ERROR:
      return { 
        ...state, 
        error: action.payload,
        pending: false
      }
      case UPDATE_CASE_TASK:
        return {
          ...state,
          data: state.data.map(data => {
            if (data.caseId === action.payload.caseId) {
              return {
                ...data,
                tasks: data.tasks.map(task => {
                  if (task.taskId === action.payload.taskId) {
                    return {
                      ...task,
                      taskCompleteInd: action.payload.completed
                    }
                  } else {
                    return task
                  }
                })
              }
            } else {
              return data
            }
          })
        }
    default:
      return state;
  }
}