import React, { useRef, useEffect } from 'react';
import { Animated,  View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

export default function Skeleton(props) {
  const animatedController = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(animatedController, {
          toValue: 0,
        }),
        Animated.timing(animatedController, {
          toValue: 1,
        }),
      ])
    ).start();
  }, [])

  const transform = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['-100%', '100%'],
  });

  return (
    <View style={[{backgroundColor: "#eeeeee", height: '100%', width: '100%', position: 'relative', overflow: 'hidden'}, props.style]}>
      <Animated.View style={[
        props.style, 
        { position: "absolute", height: '100%', width: '100%', transform: [{ translateX: transform }] }
      ]}>
        <LinearGradient
          colors={['#eeeeee', '#dddddd']}
          style={[
            {
              flex: 1,
              width: '100%',
              height: '100%'
            }]
          }
          start={{
            x: -1,
            y: 0.5
          }}
          end={{
            x: 2,
            y: 0.5
          }}
          locations={[0.3, 0.5]}
        />
      </Animated.View>
    </View>
  )
}