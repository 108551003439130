import { Story,UpdateStory,UPDATE_STORY } from "./types"
   
  
export interface NegotiationStoryState {
data: Story[];
}
  
const defaultState: NegotiationStoryState = {
data: undefined

}
  
export const NegotiationStoryReducer = (state: NegotiationStoryState = defaultState, action:UpdateStory) => {

    if (action.type=== UPDATE_STORY){
        return{
        ...state,
        data:state.data===undefined? [action.payload]:
            [...state.data.filter(f=>f.caseId!==action.payload.caseId),action.payload]
        }
    } 
    else{ 
        return state;
    }
}