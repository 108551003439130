export default function FormatCurrency(value:number){
    let maximumFractionDigits=2
    //check if it has decimal values
    if((value % 1)===0)
        maximumFractionDigits=0

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits:maximumFractionDigits
    });

    return formatter.format(value)
  }