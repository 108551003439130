export const UPDATE_NUMBERS = 'UPDATE_NUMBERS'

export interface Numbers {
    caseId: string;
    billed: number;
    insurancePaid: number;
    deductible:number;
    copay:number;
    coinsurance:number;
    allowedAmount:number;
    nonCoveredAmount:number;
    providerAmount:number;
}

export interface UpdateNumbers {
    type: typeof UPDATE_NUMBERS;
    payload:  Numbers;
}


