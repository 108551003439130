import React from "react";
import CardComponent from "../card";
import CardTitle from '../card/cardtitle'
import Text from '../TextComponent'
import {View } from "react-native";
import { Case } from "../../redux/cases/types";
import useGoogleTagManager from "../../utils/useGoogleTagManager";
import { Button } from "..";
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

export interface ProviderCardProps {
  cases:Case[];
}
export default function ProviderCard(props:ProviderCardProps) {
  const gtm = useGoogleTagManager()
  
  const handleProviderLinkPress=()=>{
      const providerUrl='https://www.uhc.com/find-a-doctor?cid=NaviguardMPB'
      gtm.pushFindProvider(props.cases,'INN Lookup panel click',providerUrl)
      window.open(providerUrl, '_blank')
  }
  return (
      <CardComponent style={{padding: 0}}>
        <CardTitle accessibilityRole="heading" ariaLevel="2">FIND A PROVIDER</CardTitle>
        <View style={{padding: 32,backgroundColor:"#FFFFFF",borderRadius:14,top:-14}}>
            <Text style={{fontSize: 16}}>Finding an in-network provider may lower costs in the future.</Text>
            <View style={{marginTop:16,alignItems:"center"}}>
                <Button variant="primary-cta" onPress={handleProviderLinkPress} accessibilityLabel="Find a provider opens in a new window"
                label={
                  <>
                    <Text>FIND A PROVIDER</Text>
                    <MaterialIcons name="launch" style={{marginLeft:6, fontSize:15,verticalAlign:'middle'}} />
                  </>
                }
                ></Button>
            </View>
        </View>
      </CardComponent>
     
    )
  }