import { Options,UpdateOptions,UPDATE_OPTIONS } from "./types"
   
  
export interface NegotiationOptionsState {
data: Options[];
}
  
const defaultState: NegotiationOptionsState = {
data: undefined

}
  
export const NegotiationOptionsReducer = (state: NegotiationOptionsState = defaultState, action:UpdateOptions) => {

    if (action.type=== UPDATE_OPTIONS){
        return{
        ...state,
        data:state.data===undefined? [action.payload]:
            [...state.data.filter(f=>f.caseId!==action.payload.caseId),action.payload]
        }
    }
    else{
        return state;
    }
}