import axiosInstance from "../axiosInstance";
import {
  COMPLETE_INVITATION
} from "./types"

export const completeInvitation = async (inviteId: string) => {
  const axios = await axiosInstance()

  return {
    type: COMPLETE_INVITATION,
    payload: axios.post(`/invitation-verifications`, {
      inviteId,
      firstName: "testname",// required as input but not validated
      dateOfBirth: '2020-01-01T00:00:00.000Z', // This isn't used for validation, but required for the API.  We are no longer getting the birthdate from the account object so it was agreed upon to hardcode it to this value.
      zipCode: "11111" // required as input but not validated
    })
  }
}
