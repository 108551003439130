import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../redux/rootReducer';
import Layout from '../../components/layout'
import { SkeletonCaseCard } from '../../components/casecard'
import { Text } from '../../components'
import { Case } from '../../redux/cases/types';
import CaseBanner from '../../components/casebanner'
import AdvisorPanel from '../../components/advisorpanel';
import { fetchCases } from '../../redux/cases/actions';
import { usePageLoadEffect } from '../../utils/useGoogleTagManager';
import CaseSelect from '../../components/caseselect';

export default function YourExperience({ navigation }) {
  const dispatch = useDispatch()
  const casesPending = useSelector((state: ApplicationState) => state.cases.pending)
  const cases = useSelector((state: ApplicationState) => state.cases.data)
  const [filteredCases,setFilteredCases]=useState(cases)

  
  const handleClick = (selected: Case, mode: 'edit'|'view') => {
    if(mode === 'view') {
      navigation.navigate('WizardViewer', {
        caseId: selected.caseId,
        mode
      })
    } else {
      navigation.navigate('Wizard', {
        caseId: selected.caseId,
        mode
      })
    }
  }

  useEffect(() => {
    if (cases.length === 0) {
      dispatch(fetchCases())
    }
  }, [])

  useEffect(() => {
    setExcludeSelfCoachingCases(cases)
  }, [cases])

  usePageLoadEffect()

  const setExcludeSelfCoachingCases=(selectCases:Case[])=>{
    if(selectCases && selectCases.length>0){
      setFilteredCases(selectCases.filter(c=>c.naviguardProduct!=='Self Coach'))
    }
  }
  const advisor = cases && cases.length > 0 ? cases[0].advisor : null

  return (
    <Layout introHeading="Your Experience" testID='member-stories-root'
    informationSelect={
      <CaseSelect  cases={cases} casesPending={casesPending} onCasesFiltered={setExcludeSelfCoachingCases} />
    }
    >
      <>
        {
          casesPending && (
            [0, 1, 2].map((item) => <SkeletonCaseCard key={`skeleton-${item}`} />)
          )
        }
        {
          !casesPending && filteredCases.length > 0 && 
          (
            filteredCases.map((caseCard: Case) => (
              <CaseBanner key={caseCard.caseId} case={caseCard} onClick={handleClick.bind(null, caseCard)} />
            ))
          )
        }
        {
          !casesPending && filteredCases.length === 0 && (
            <View style={{marginTop: 30}}>
              <Text size="m" style={{transform: 'unset'}}>There are no experience currently available for your review.</Text>
            </View>
          )
        }
      </>
      <>{ advisor && filteredCases.length> 0  && <AdvisorPanel cases={filteredCases} /> }</> 
    </Layout>
  )
}