import { StackScreenProps } from '@react-navigation/stack';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import AppContainer from '../components/appcontainer'
import Text from '../components/TextComponent'
import Button from '../components/button'
import Grid, { GridItem } from '../components/grid/grid'
import { RootStackParamList } from '../types';
import { useQueryNavigator } from '../context-api/query';

export default function NotFoundScreen({
  navigation,
}: StackScreenProps<RootStackParamList, 'NotFound'>) {
  const navigator = useQueryNavigator(navigation)

  const handleBack = () => {
    navigator.navigate('Home')
  }
  
  return (
    <AppContainer>
      <View style={{width: '100%'}}>
        <Grid direction="column">
          <GridItem tablet={'50%'} phone={'100%'} desktop={'50%'} style={{marginBottom: 16}}>
            <Text size="xl">The page you’re looking for can’t be found.</Text>
          </GridItem>
          <GridItem tablet={'50%'} phone={'100%'} desktop={'50%'} style={{marginBottom: 48}}>
            <Text size="xs" style={{ fontFamily: "SourceSansPro-Regular" }}>
              The page you're looking for may have been moved or deleted. If you typed the URL, double check spelling and punctuation for accuracy.
            </Text>
          </GridItem>
          <GridItem tablet={'50%'} phone={'100%'} desktop={'50%'}>
            <Button label="Back to Home" onPress={handleBack} />
          </GridItem>
        </Grid>
      </View>
    </AppContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});
