import React from 'react'
import { Hoverable } from 'react-native-web-hover'
import { StyleSheet, TouchableOpacity, View } from "react-native"
import { Text } from '../index'

interface NavItemProps {
  accessibilityLabel: string;
  navPath: string;
  activeRoute: string;
  navText: string | JSX.Element;
  handleNavigate: (string) => void;
  testID?: string;
  style?: Object;
}

export default function NavItem(props: NavItemProps) {
  const { accessibilityLabel, navPath, activeRoute, navText, handleNavigate } = props

  return (
    <Hoverable >
      {({ hovered }) => (
        <TouchableOpacity
          testID={props.testID}
          accessible={true}
          accessibilityRole="menuitem"
          accessibilityLabel={accessibilityLabel}
          activeOpacity={0.8}
          onPress={() => handleNavigate(navPath)}
          style={styles.navItem}
        >
          <Text 
            bold={true} 
            style={[
              styles.navItemText, 
              {
                borderBottom: hovered || activeRoute === navPath ? '2px solid #E64B38': "2px solid #ffffff",
              }
            ]}
          >{navText}</Text>
        </TouchableOpacity>
      )}
    </Hoverable>
  )
}

const styles = StyleSheet.create({
  navItem: {
    marginTop: '5px',
    marginRight: 30,
    flexDirection: 'row',
    alignItems: "center",
    cursor: "pointer",
    color: '#002C73',
  },
  navItemText: {
    fontFamily: 'JekoBold',
    fontSize: '14px',
    paddingBottom: '3px'
  }
})