import React, { useState } from 'react';
import Message from '../../components/modal/Message';
import { useNavigation } from '@react-navigation/native';
import { Overlay } from 'react-native-elements';
import { ScrollView, View } from 'react-native';
import Upload from './Upload'
import useWindowResize from '../../utils/useWindowResize';
import { useQueryNavigator } from '../../context-api/query';

interface Props {
  visible: boolean;
  onClose: () => void;
  attachFiles: (files: File[]) => void
}
export default function UploadOverlay(props:Props) {
  const navigator = useQueryNavigator(useNavigation())
  const [showCompletedMessage, setShowCompletedMessage] = useState(false);
  const dimensions = useWindowResize()

  const handleCompletedMessageOK = () => {
    handleCompletedMessageClose()
    navigator.navigate("Home")
  }

  const handleDocumentsUploaded = () => {
    setShowCompletedMessage(true)
  }
  const handleCompletedMessageClose = () => {
    setShowCompletedMessage(false)
  }

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const handleError=(message:string) => {
    setErrorMessage(message)
    setShowErrorMessage(true)
  }

  const handleErrorClose = () => {
    setErrorMessage("")
    setShowErrorMessage(false)
  }
  
  return (
    <View>
      <Overlay 
        isVisible={props.visible} 
        overlayStyle={{ 
          padding: 20, 
          borderRadius: 14, 
          margin: 16, 
          maxHeight: dimensions.height -32 
        }}
      >
        <ScrollView>
          <Upload 
            onClose={props.onClose} 
            onDocumentsUploaded={handleDocumentsUploaded} 
            onError={handleError}
            attachFiles={(files) => props.attachFiles(files)}
          ></Upload>
        </ScrollView> 
      </Overlay> 
    <Message minWidth='46em' show={showErrorMessage} variant="error" onClose={handleErrorClose} onOK={handleErrorClose} message={errorMessage}></Message>
    <Message minWidth='46em'
              show={showCompletedMessage}
              variant="success"
              onClose={handleCompletedMessageClose}
              onOK={handleCompletedMessageOK}
              OKButtonLabel="BACK TO HOMEPAGE"
              message="Thank you for uploading your bill. Your Advisor will review your case details and contact you with next steps in 2-3 business days."
              messageStyle={{fontSize: '18px', width: '400px', margin: 'auto'}} ></Message>
  </View>
  );
}

