import React from 'react'
import styled from 'styled-components/native';
import Skeleton from '../../Skeleton'

const Wrapper = styled.View`
  width: auto;
  height: 28px;
  border-radius: 14px;
  align-self: flex-end;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  border: 1px solid #002C73;
  box-sizing: border-box;
`
const Text = styled.Text`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #002C73;
  text-align: center;
  font-family: JekoRegular;
  font-weight: bold;
  font-size: 12px
`

export default function StatusPill(props) {
  const bgcolors = {
    caseOpened: "#D0DFB4",
    warning: "#FFCEC1",
    closed: "#D0D3D4",
    underReview: "#C6DEF1",
    negotiationInProgress: "#FBEAC5",
    negotiationComplete: "#FBEAC5",
    waitingOnAppealResults: "#FBEAC5"
  }

  return (
    <Wrapper style={ !props.skeleton ? {backgroundColor: bgcolors[props.level]} : {}}>
    {
      !props.skeleton ?
        <Text>{props.text}</Text>
      : <Skeleton />
    }
    </Wrapper>
  )
}