import { Dispatch } from 'redux'
import axiosInstance from '../axiosInstance'
import {
    FETCH_CASES_PENDING,
    FETCH_CASES_SUCCESS,
    FETCH_CASES_ERROR,
    UPDATE_CASE_TASK,
    CasesDispatchTypes,
    Case,
} from './types'
import { getCaseSigning } from '../../utils/signingStorage'

export const fetchCases = () => async (dispatch: Dispatch<CasesDispatchTypes>) => {
  dispatch({ 
    type: FETCH_CASES_PENDING,
    payload: true
  })

  const axios = await axiosInstance()

  try {
    const response = await axios.get('/cases', {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    })
    const caseOrder = { 
      'Case Opened': 1, 
      'Under Review': 2, 
      'Negotiation In Progress': 3, 
      "Negotiation Complete, you'll hear from us soon": 4,
      'Waiting on Appeal Results': 5,
      'Case Closed': 6
    }

    // any status value that is not known to us, or missing status codes altogether
    const unknownOrder = Object.keys(caseOrder).length
   
    let sortedCases = response.data.cases.sort((a: Case, b: Case) => {
      const orderA = caseOrder[a.caseStatus] || unknownOrder
      const orderB = caseOrder[b.caseStatus] || unknownOrder
      
      return orderA - orderB
    })

    // TODO(docusigncache): remove this cache check if updated directly to service portal
    for (let c of response.data.cases) {
        const found = await getCaseSigning(c.caseId)
        if (found) {
            const i: number = c.tasks.findIndex(t => t.taskName.toLowerCase() === 'esign')
            c.tasks[i].taskCompleteInd = true; 
        }
    }

    dispatch({
      type: FETCH_CASES_SUCCESS,
      payload: sortedCases
    })
    
  } catch (e) {
    // 404 means the user has no cases to view, and therefore this is not a critical error
    if (e.response && e.response.status === 404) {
      dispatch({
        type: FETCH_CASES_SUCCESS,
        payload: []
      })
    } else {
      console.error(e)
      dispatch({
        type: FETCH_CASES_ERROR,
        payload: e.toString(),
      })
    }
}
}

export const updateTask = (
    caseId: string,
    taskId: string,
    completed: boolean
) => (dispatch: Dispatch<CasesDispatchTypes>) => {
    dispatch({
        type: UPDATE_CASE_TASK,
        payload: {
            caseId,
            taskId,
            completed,
        },
    })
}
