import React, { ReactNode, ReactPortal, useEffect, useState } from 'react';
import { 
  ImageBackground,
  StyleSheet,
  View, 
  ScrollView,
  Dimensions,
  TouchableOpacity
} from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { useNavigation, useRoute } from '@react-navigation/native';
import "@expo/match-media";
import Footer from './footer'
import Text from '../TextComponent'
import useDeviceWidth, { useResize } from '../../utils/useDeviceWidth'
import styled from 'styled-components/native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import useGoogleTagManager, { GMTNavigationLinkPlacement } from '../../utils/useGoogleTagManager';
import { useQueryNavigator } from '../../context-api/query';
import Feedback from '../feedback';
import  { createPortal } from 'react-dom';
import { ScreenRefContext } from '../../screens';
import ProviderBanner from '../header/ProviderBanner';
const bgImage = require('../../assets/images/blue-gradation-bkgd-dots-desktop1440.png');

var height = Dimensions.get('window').height;

const BreadcrumbContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0 0 20px;
`;

const BreadcrumbWrapper = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 9px;
`;

const BreadcrumbText = styled(Text)<{clickable: boolean}>`
  color: #002C73;
  text-decoration: ${props => props.clickable ? 'underline' : 'none'};
  text-decoration-color: #002C73;
  margin-right: 9px;
`;

export interface Breadcrumb {
  label: string;
  route?: string;
}

export interface AppContainerProps {
  breadcrumbs?: Breadcrumb[];
  children?: ReactNode;
  navigation?: any;
  testID?: string;
  mainSectionWrapper?: any;
}

export default function AppContainer(props: AppContainerProps) {
  const navigator = useQueryNavigator(props.navigation)
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const currentRoute = useRoute();
  const responsiveStyle = useDeviceWidth()
  const gtm = useGoogleTagManager()
  const [windowWidth,isMobile,isTablet] = useResize()
  const [isSmallscreen,setIsSmallscreen] =useState(isMobile && ! isTablet)
  const [ screenInView, setInView ] = useState(true)

  useEffect(()=>{
    navigation.addListener('blur', () => {
      const {index, routes} = navigation.dangerouslyGetState();
      const toRoute = routes[index];
      if(currentRoute.name !== toRoute.name) {
        setInView(false)
      }
    })
  },[navigation])
  useEffect(()=>{
    navigation.addListener('focus', () => {
        setInView(true)
    })
  },[navigation])

  const handleBreadcrumb = (breadcrumb: Breadcrumb) => {
    gtm.pushNavigation(GMTNavigationLinkPlacement.Breadcrumb,breadcrumb.label)
    navigator.navigate(breadcrumb.route)
  }

   const [feedbackPortal, setfeedbackPortal]=useState<ReactPortal>()
   const screenRef = React.useContext(ScreenRefContext)
   const [contentWidth,setContentWidth]=useState(0)

  useEffect(()=>{
    const scrollbarWidth=(windowWidth as number)-contentWidth
    const small=isMobile && ! isTablet
    setIsSmallscreen(small)

    const paddingStyle=small?{paddingRight: 16}:{paddingRight:84-scrollbarWidth}
    const positionStyle={...paddingStyle,right:scrollbarWidth}
     
    if(screenRef.current){
      setfeedbackPortal(
        createPortal(
          <View style={{...positionStyle, position:'absolute', bottom:0}}>
            <Feedback />
          </View> ,screenRef.current as any
        ))
    }
  },[windowWidth,contentWidth,isMobile ,isTablet])
  //needed to allow focus state be on the front of the stack, not behind.
  if(!isFocused && !screenInView) return null

  return (
    <>
    <ScrollView testID={props.testID}
    onContentSizeChange={(width) => {setContentWidth(width)}}
    >
      <ImageBackground 
        source={bgImage} 
        resizeMode="cover"
        style={styles.gradientBg}
      >
        <main id="main-content" style={{height: 'auto', minHeight: '100vh',marginBottom:37}}>
        {isMobile &&
          <ProviderBanner/>
      }
          <View style={{height: 'auto', minHeight: '100%'}}>
            {
              props.breadcrumbs && (
                <BreadcrumbContainer style={{
                  paddingLeft: breadcrumbPadding[responsiveStyle]
                }}>
                  {props.breadcrumbs.map((bc, i) => (
                    <BreadcrumbWrapper key={i}>
                      {bc.route && (
                      <TouchableOpacity testID={`breadcrumb-${i}`} onPress={handleBreadcrumb.bind(null, bc)}>
                        <BreadcrumbText clickable={true}>{bc.label}</BreadcrumbText>
                      </TouchableOpacity>
                      )}
                      {!bc.route && <BreadcrumbText clickable={false}>{bc.label}</BreadcrumbText>}

                      {i < props.breadcrumbs.length - 1 && <MaterialIcons name="play-arrow" size={13} color="#002C73" />}
                    </BreadcrumbWrapper>
                  ))}
                </BreadcrumbContainer>
              )
            }
            <View style={[styles[responsiveStyle], props.mainSectionWrapper]}>
              {props.children}
            </View>
          </View>
        </main>
        <footer id="footer" style={{marginBottom:0}}>
          <Footer />
        </footer>
        
      </ImageBackground>
    </ScrollView>
     {/* {feedbackPortal} */}
    </>
  )
}

// derived from styles with indentation for breadcrumbs
const breadcrumbPadding = {
  largeScreen: 65,
  tabletDevice: 10,
  width295_359: 10,
  width375_811: 10,
  wdth360_374: 10,
}

export const styles = StyleSheet.create({
  gradientBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    minHeight: height
  },
  largeScreen: {
    flex: 1, 
    paddingLeft: 84, 
    paddingRight: 84, 
    paddingTop: 70
  },
  tabletDevice: {
    flexDirection: 'column',
    padding: 16
  },
  width295_359: {
    flexDirection: 'column',
    padding: 16
  },
  width375_811: {
    flexDirection: 'column',
    padding: 16
  },
  wdth360_374: {
    flexDirection: 'column',
    padding: 16
  }
})