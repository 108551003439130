import { useOverlay } from '../context-api/overlay/overlayState'
import axiosInstance from '../redux/axiosInstance'
import { Case } from '../redux/cases/types'
import useGoogleTagManager, { GTMEvent } from '../utils/useGoogleTagManager';
import { isIosSafari } from '../utils/agent'
import { AxiosResponse } from 'axios'
import { useDispatch } from 'react-redux'
import { fetchCases } from '../redux/cases/actions';

export default function useHandleEsign() {
  const { overlay } = useOverlay()
  const dispatch = useDispatch()

  const eSign = async (caseItem: Case) => {
    const axios = await axiosInstance()

    const gtm = useGoogleTagManager()
    
    const getDocuSignUrl = async (caseId: string) => {
        const axios = await axiosInstance()
        const redirectUrl = new URL(window.location.href)

        redirectUrl.searchParams.append('caseId', caseId)

        return axios
            .post('/document-links', {
                caseNumber: caseItem.caseNumber,
                workPackageCaseSetId: caseItem.caseSetId,
                returnUrl: redirectUrl, // TODO: Expo/RN Friendly
            })
            .then((response: AxiosResponse) => response.data)
    }
    
        overlay({
            show: true,
            type: 'spinner',
            message: `Securely navigating to your forms..`,
            autoHide: false,
        })
        let errorMessage = 'Please try again later.'
        const responseNoEsignMessage =
            'No ESign Available for signing'
        const friendlyNoEsignMessage =
            'This document is no longer available for signing. It has most likely been signed within the past few minutes.'

        // ios safari really doesn't like opening windows in async functions for some reason.
        // work around this by opening a blank window, fetching the url, and replacing it in
        // the newly opened window reference.
        // because, you know, it's apple and you gotta "think different" >:(
        //
        // see:
        // https://stackoverflow.com/a/39387533/5190023
        if (isIosSafari()) {
            const wnd = window.open('/loading_esign.html', '', '')

            getDocuSignUrl(caseItem.caseId)
                .then((data: string) => {
                    const status = Object.values(data)[1]
                    const url = Object.values(data)[2]
                    if (url) {
                        overlay({
                            show: false,
                            type: 'spinner',
                            message: '',
                        })

                        // set the url for the new window and bring it into focus
                        wnd.location.href = url
                        wnd.focus()
                    }
                    else if(status){
                        overlay({
                            show: true,
                            type: 'message',
                            message: 'Your documents were successfully signed.  You can refresh your page to update your task list.',
                            messageType: 'success',
                            buttonLabel: 'Close',
                        })
                    } else {
                        overlay({
                            show: true,
                            type: 'message',
                            message: 'An Error Occured Fetching ESign. Please Contact Your Advisor.',
                            messageType: 'error',
                            buttonLabel: 'Close',
                        })
                    }
                })
                .catch((e) => {
                    if (
                        e.response.data === responseNoEsignMessage
                    ) {
                        errorMessage = friendlyNoEsignMessage
                    }
                    // close the previously opened window
                    wnd.close()

                    overlay({
                        show: true,
                        type: 'message',
                        message: errorMessage,
                        messageType: 'error',
                        buttonLabel: 'Close',
                    })
                })
        } else {
            getDocuSignUrl(caseItem.caseId)
                .then((data: string) => {
                    const status = Object.values(data)[1]
                    const url = Object.values(data)[2]
                    if (url) {
                        overlay({
                            show: false,
                            type: 'spinner',
                            message: '',
                        })

                        window.open(url, '_blank')
                    }
                    else if(status){
                        overlay({
                            show: true,
                            type: 'message',
                            message: 'Your documents were successfully signed.  You can refresh your page to update your task list.',
                            messageType: 'success',
                            buttonLabel: 'Close',
                        })
                    } else {
                        overlay({
                            show: true,
                            type: 'message',
                            message: 'An Error Occured Fetching ESign. Please Contact Your Advisor.',
                            messageType: 'error',
                            buttonLabel: 'Close',
                        })
                    }
                })
                .catch((e) => {
                    if (
                        e.response.data === responseNoEsignMessage
                    ) {
                        errorMessage = friendlyNoEsignMessage
                    }
                    overlay({
                        show: true,
                        type: 'message',
                        message: errorMessage,
                        messageType: 'error',
                        buttonLabel: 'Close',
                    })
                })
        }
        dispatch(fetchCases())

        
    // overlay({
    //     show: true,
    //     type: 'spinner',
    //     message: `Securely navigating to your forms..`,
    //     autoHide: false
    // })
    // try {
    //     const redirectUrl = new URL(window.location.href);
        
    //     redirectUrl.searchParams.append('caseId', caseItem.caseId)
        
    //     const response = await axios.post('/document-links', {
    //         caseNumber: caseItem.caseNumber,
    //         workPackageCaseSetId: caseItem.caseSetId,
    //         returnUrl: redirectUrl, // TODO: Expo/RN Friendly
    //     })

    //     overlay({
    //         show: false,
    //         type: 'spinner',
    //         message: ''
    //     })
    //     const url = Object.values(response.data)[2].toString()
    //     window.open(url, '_blank')
    //     // await WebBrowser.openBrowserAsync(response.data)
    // } catch (e) {
    //     // 404 means the user has no cases to view, and therefore this is not a critical error
    //     if (!e.response || (e.response && e.response.status !== 404)) {
    //         const charLimit = (str) => str.substring(0, 100);
    //         const fetchDocFailed = 'This document is no longer available for signing. It has most likely been signed within the past few minutes.';   
          
    //         let errorMessage ='Please try again later.'
            
    //         if(e.response.data==='No ESign Available for signing'){
    //             errorMessage=fetchDocFailed

    //             gtm.pushEvent(GTMEvent.DocumentSignFailure, {
    //                 'event': 'useHandleEsign',
    //                 'errorType': 'Failed to sign documents',
    //                 'errorMessage' : charLimit(fetchDocFailed)
    //             })
    //         }
    //         overlay({
    //             show: true,
    //             type: 'message',
    //             message: errorMessage,
    //             messageType: 'error',
    //             buttonLabel: 'Close'
    //         })
    //     }
    // }
  } 

  return eSign
}