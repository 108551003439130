import React from "react";
import { View } from "react-native";
import Skeleton from "../../../components/Skeleton";

export default function NegotiationSkeleton() {
    
    return (
    <View  style={{ width: '100%',flexDirection:'column'}}>
       { [1,2,3,4,5].map((counter)=> 
        <View  key={counter} style={{ width: '100%' ,height:20,marginBottom:20}}>
          <Skeleton />
        </View>
        )} 
    </View>
    )
  }