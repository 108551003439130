import React, {forwardRef }  from 'react';
import Card from 'react-native-elements/src/card/Card'
import { View } from 'react-native';
import useDeviceWidth, { useIsMobile } from '../../../utils/useDeviceWidth'
import StatusPill from './status-pill'
import Text from '../../TextComponent' 
import Skeleton from '../../Skeleton'
import { Case } from '../../../redux/cases/types';
import { styles } from './styles'

export interface TitleProps {
  case: Case;
  expanded: boolean;
}

const Title = forwardRef((props: TitleProps, ref) => {
  const responsiveStyle = useDeviceWidth()

  // translate the case status into a status level and human-friendly description
  const caseText = props.case.caseStatus
  let caseLevel = ''
  switch (props.case.caseStatus) {
    case 'Case Opened':
      caseLevel = 'caseOpened';
      break;
    case 'Under Review':
      caseLevel = 'underReview';
      break;
    case 'Negotiation In Progress':
      caseLevel = 'negotiationInProgress'
      break;
    case "Negotiation Complete, you'll hear from us soon":
      caseLevel = 'negotiationComplete'
      break;
    case 'Waiting on Appeal Results':
      caseLevel = 'waitingOnAppealResults'
      break;
    case 'Case Closed':
      caseLevel = 'closed';
      break;
  }

  return (
    <Card.Title style={[styles.containerCommon, styles[responsiveStyle].container]}>
      {
        responsiveStyle === 'largeScreen' ?
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{ marginRight: 24 }}>
              <Text accessibilityRole="heading" ariaLevel="2" bold={true} style={{fontSize: 24}}>{`Case # ${props.case.caseNumber}`}</Text>
            </View>
            <View>
              <Text style={{fontSize: 16, textTransform: 'capitalize'}}>{props.case.billingProviderName?.toLowerCase()}</Text>
            </View>
          </View>
        :
          <>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <Text accessibilityRole="heading" ariaLevel="2" bold={true} style={{fontSize: 24}}>{`Case # ${props.case.caseNumber}`}</Text>
                {props.expanded && 
                  <View style={{alignSelf:'center'}}>
                    <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 2H0V0H14V2Z" fill="#0074C8"/>
                    </svg>
                  </View>
                  
              }
              {!props.expanded && 
                <View style={{alignSelf:'center'}}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#0074C8"/>
                  </svg>
               </View>
              }
            </View>
            <View style={styles[responsiveStyle].provider}>
              <Text style={{fontSize: 16, textTransform: 'capitalize'}}>{props.case.billingProviderName?.toLowerCase()}</Text>
            </View>
          </>
      }
      <View style={styles[responsiveStyle].statusPill}>
        <StatusPill level={caseLevel} text={caseText} />
        {
          responsiveStyle === 'largeScreen' ?
          <>
            {props.expanded && 
              <View style={{alignSelf:'center'}}>
              <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 2H0V0H14V2Z" fill="#0074C8"/>
              </svg>
            </View>
            }
            {!props.expanded && 
              <View style={{alignSelf:'center'}}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#0074C8"/>
              </svg>
           </View>
            }
            </>
          : null
        }
      </View>
    </Card.Title>
  )
})

export const SkeletonTitle = () => {
  const responsiveStyle = useDeviceWidth()
  const isMobile = useIsMobile()

  let containerStyle = isMobile ? {...styles[responsiveStyle].container, alignItems: 'flex-start'} : styles[responsiveStyle].container
  return (
    <Card.Title style={[styles.containerCommon, containerStyle]}>
      <View style={isMobile ? { width: '60%', flexBasis: 28, position: "relative", height: 20 } : { height: 20, width: '20%' }}>
        <Skeleton />
      </View>
      <View style={isMobile ? [styles[responsiveStyle].provider, { height: 20 }] : { flex: 1, height: 20, marginLeft: 20 }}>
        <Skeleton />
      </View>
      <View style={isMobile ? { width: '30%', flexDirection: "row", flexBasis: 24, marginTop: 18, height: 30} : { flex: 0.3, height: 30, marginLeft: 70 }}>
        <Skeleton style={{borderRadius: 14}} />
      </View>
    </Card.Title>
  )
}

export default Title