import React, { useState } from 'react';
import {useDispatch} from 'react-redux'
import Message from '../../components/modal/Message';
import { useNavigation } from '@react-navigation/native';
import { Overlay } from 'react-native-elements';
import { ScrollView, View } from 'react-native';
import Upload from './Upload'
import { fetchCases } from '../../redux/cases/actions';
import { Case } from '../../redux/cases/types';
import useWindowResize from '../../utils/useWindowResize';
import { useQueryNavigator } from '../../context-api/query';
import { DisplayBanner } from '../../redux/providerBanner/actions';



interface Props {
  caseItem:Case;
  visible:boolean;
  onClose:()=>void;
}
export default function UploadOverlay(props:Props) {
  const navigator = useQueryNavigator(useNavigation())
  const [showCompletedMessage, setShowCompletedMessage] = useState(false);
  const dimensions = useWindowResize()
  const dispatch=useDispatch();

  const handleCompletedMessageOK = () => {
    handleCompletedMessageClose()
    navigator.navigate("Home")
  }

  const handleDocumentsUploaded = () => {
    setShowCompletedMessage(true)
    if(props.caseItem.caseType==='MNRP'){
      dispatch(DisplayBanner(true))
    }
  }
  const handleCompletedMessageClose = () => {
    //update the cases to see task complete
    dispatch(fetchCases())
    setShowCompletedMessage(false)
  }

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const handleError=(message:string) => {
    setErrorMessage(message)
    setShowErrorMessage(true)
  }

  const handleErrorClose = () => {
    setErrorMessage("")
    setShowErrorMessage(false)
  }
  
  return (
    <View>
      <Overlay 
        isVisible={props.visible} 
        overlayStyle={{ 
          padding: 20, 
          borderRadius: 14, 
          margin: 16, 
          maxHeight: dimensions.height -32 
        }}
      >
        <ScrollView>
          <Upload 
            caseItem={props.caseItem} 
            onClose={props.onClose} 
            onDocumentsUploaded={handleDocumentsUploaded} 
            onError={handleError}
          ></Upload>
        </ScrollView> 
      </Overlay> 
    <Message minWidth='46em' show={showErrorMessage} variant="error" onClose={handleErrorClose} onOK={handleErrorClose} message={errorMessage}></Message>
    <Message minWidth='46em'
              show={showCompletedMessage}
              variant="success"
              onClose={handleCompletedMessageClose}
              onOK={handleCompletedMessageOK}
              OKButtonLabel="BACK TO HOMEPAGE"
              message="Thank you for uploading your bill. Your Advisor will review your case details and contact you with next steps in 2-3 business days."
              messageStyle={{fontSize: '18px', width: '400px', margin: 'auto'}} ></Message>
  </View>
  );
}

