import React from 'react'
import { View, StyleSheet } from 'react-native'
import styled, { css } from 'styled-components/native';
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { MessageOverlay } from '.'
import useDeviceWidth from '../../../utils/useDeviceWidth'
import Button from '../../../components/button'

interface IMessage {
  data: MessageOverlay;
  dispatch: any;
}
export default function Message(props: IMessage) {
  const { data, dispatch } = props
  const device = useDeviceWidth()

  let width = '-webkit-fill-available', isMobile = true
  if(device === 'largeScreen' || device === 'tabletDevice') {
    width = '600px'
    isMobile=false
  } 
  
  const hideOverlay=()=>{
    dispatch({
      type: 'UPDATE_OVERLAY',
      payload: { show: false, message: '', customMessage:false },
    }); 
  }
  
  const onPress = () => {
    hideOverlay()  
    if(data.buttonAction) {
      data.buttonAction()
    }
  }

  let iconName = 'check-circle', iconColor = '#53a653'
  let messageText = 'Success!', messageColor = '#53a653'
  if(data.messageType === 'error') {
    iconName = 'alert-circle'
    iconColor = 'red'
    messageColor = "#ef4c4c"
    messageText = "An error occured."
  }
  return (
    <MessageContainer width={width} isMobile={isMobile}>
      <View style={styles.closeBtn}>
        <Button onPress={hideOverlay} icon={true}  iconName="md-close"
         iconStyle={{color:'#0074C8'}}
         hoveredStyle={{color:'#9F2140'}} />
      </View>
      {
        !data.customMessage &&
        <>
          <MaterialCommunityIcons style={{marginBottom: 16}} name={iconName} size={30} color={iconColor} /> 
          <MessageText noMargin={true} size={24} color={messageColor} bold={true}>{messageText}</MessageText>
          <MessageText size={18}>{data.message}</MessageText>
        </>
      }
      { data.customMessage ? data.customMessageRender() : null }
      <Button onPress={onPress} label={data.buttonLabel} />
    </MessageContainer>
  )
}

const styles = StyleSheet.create({
  closeBtn: {
    position: 'absolute',
    top: 20,
    right: 20
  }
})

const MessageContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 3%;
  padding-right: 3%;
  background-color: #FFF;
  width: ${props => props.width};
  height: auto;
  min-height: 372px;
  ${props => props.isMobile ? css`margin: 16px;` : ''}
`

const MessageText = styled.Text`
  margin-top: ${props => !props.noMargin ? '24' : '0'}px;
  margin-bottom: ${props => !props.noMargin ? '32' : '0'}px;
  color: ${props => props.color ? props.color : '#002C73'};
  font-family: ${props => props.bold ? 'SourceSansPro-Bold': 'SourceSansPro-Regular'};
  font-size: ${props => props.size ? props.size : '#002C73'};
  text-align: center;
`