import React from 'react'
import {
    StyleSheet,
    View
} from 'react-native'
import {
    Case
} from '../../../redux/cases/types'

export interface BodyProps {
    case: Case
    isMobile:boolean
    children:JSX.Element | JSX.Element[]
}

export default function BodyCard(props: BodyProps) {
  
    return (
        <View style={props.isMobile ? mobile.cardBody : desktop.cardBody}>
            {props.children}
     </View>
    )
}


const mobile = StyleSheet.create({
    cardBody: {
        flex: 1,
        width: '100%',
        flexDirection: 'column',
    }
})

const desktop = StyleSheet.create({
    cardBody: {
        display:'flex',
        flexDirection: 'row',
    }
})
 
