import React from 'react';
import { Modal as ReactModal, Text,  View } from "react-native";
import styled from 'styled-components/native';

const CenteredView = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  backgroundColor: rgba(255, 255, 255, 0.5);
`;


export const Status = styled(View)`
width: 60px;
height: 60px;
border-radius: 50%;
border-width: 10px;
border-color: #dce3f2;
transform: rotate(-45deg);
margin-bottom: 24px;
border-right-color:${(props:Props) => props.status>24?'#2b2d76':''}; 
border-bottom-color:${(props:Props) => props.status>49?'#2b2d76':''}; 
border-left-color:${(props:Props) => props.status>74?'#2b2d76':''}; 
border-top-color:${(props:Props) => props.status>99?'#2b2d76':''}; 
  }
`;


export const ModalText = styled(Text)`
  font-size: 24px;
  color: #002C73;
  font-family: SourceSansPro-Bold;
`;

export interface Props {
    visible: boolean;
    message:string;
    status:number
}

export default function WaitStatus(props: Props) {

    return (
        <ReactModal
            transparent={true}
            visible={props.visible}>
            <CenteredView>
                <Status status={props.status}/>
                    <ModalText>{props.message}</ModalText>
            </CenteredView>
        </ReactModal>
    )
}
