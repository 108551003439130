import  { useLayoutEffect, useState } from 'react'
import "@expo/match-media";
import { useMediaQuery } from "react-responsive";
import { Dimensions } from 'react-native'

export function useResize() {
  const [size, setSize] = useState({ width: 0, height: 0 });
  useLayoutEffect(() => {
    function updateSize(event) {
      const { width, height } = event.window; 
      setSize({ width, height });
    }
    Dimensions.addEventListener('change', updateSize);
    setSize({ width: window.innerWidth, height: window.innerHeight});
    
    return () => Dimensions.removeEventListener('change', updateSize);
  }, []);

  const sizeWidth = size.width;
  const sizeHeight = size.height
  const isMobile = sizeWidth <= 1024
  const isTablet = sizeWidth >= 768 && sizeWidth <= 1024
  return [ sizeWidth, isMobile, isTablet, sizeHeight ]
}

export function useIsMobile() {
  const [mobileWidth, setMobileWidth] = useState(0);
  useLayoutEffect(() => {
    function updateSize(event) {
      const { width } = event.window; 
      setMobileWidth(width);
    }
    Dimensions.addEventListener('change', updateSize);
    setMobileWidth(window.innerWidth);
    
    return () => Dimensions.removeEventListener('change', updateSize);
  }, []);

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width:1024px)",
  });

  return isTabletOrMobileDevice || mobileWidth <= 1024
}

export default function useDeviceWidth() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize(event) {
      const { width, height } = event.window; 
      setSize([width, height]);
    }
    Dimensions.addEventListener('change', updateSize);
    setSize([window.innerWidth, window.innerHeight]);

    return () => Dimensions.removeEventListener('change', updateSize);
  }, []);

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(min-device-width:768px) and (max-device-width:1024px)",
  });
  const isDeviceWidth375_811 = useMediaQuery({
      query: "(min-device-width:375px) and (max-device-width:767px)",
  });
  const isDeviceWidth360_374 = useMediaQuery({
      query: "(min-device-width:360px) and (max-device-width:374px)",
  });
  const isDeviceWidth295_359 = useMediaQuery({
      query: "(min-device-width:295px) and (max-device-width:359px)",
  });
  

  const getStyle = () => {
    const [width] = size

    if(width>1024){
      return 'largeScreen'
    } else if(isTabletOrMobileDevice || (width >= 768 && width <= 1024)) {
      return 'tabletDevice'
    } else if(isDeviceWidth295_359 || (width >= 295 && width <= 359)) {
      return 'width295_359'
    } else if(isDeviceWidth375_811 || (width >= 375 && width <= 767)) {
      return 'width375_811'
    } else if(isDeviceWidth360_374 || (width >= 360 && width <= 374)) {
      return 'wdth360_374'
    } else {
      return 'width295_359'
    }
  }

  return getStyle();
}