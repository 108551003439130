import React from 'react'
import styled from 'styled-components/native'
import { Text, Link } from '../../components'

export const SelfCoachingStart=({caseNumber})=>{
  return(
    <MessageText>
      Complete your negotiation plan.
      <CaseNumber> {`(Case #${caseNumber})`}</CaseNumber>
  </MessageText>
  )
}
export const MemberExperienceAndUploadBill = ({onTaskAction, handleUpload, caseNumber, providerName, hasUserStoryAnswers}) => {
  return (
    <MessageText>
      {hasUserStoryAnswers ? 'Continue telling us about' : 'Tell us'} <Link onPress={onTaskAction}>your experience</Link> { providerName ? `with ${providerName}` : '' }, 
      and <Link onPress={handleUpload}>upload a bill</Link> so your advisor may start negotiation on your case. 
      <CaseNumber> {`(Case #${caseNumber})`}</CaseNumber>
    </MessageText>
  )
}
export const MemberExperienceAndESignReady = ({onTaskAction,handleESign, caseNumber}) => {
  return (
    <MessageText>
      Tell us <Link onPress={onTaskAction}>your experience</Link>,  
     and sign the <Link onPress={handleESign}>{`HIPAA Release & Consent to Negotiate forms`}</Link> so your advisor may start negotiation on your case. 
      <CaseNumber> {`(Case #${caseNumber})`}</CaseNumber>
    </MessageText>
  )
}
export const UploadBillAndESignReady = ({handleUpload,handleESign, caseNumber}) => {
  return (
    <MessageText>
      <Link onPress={handleUpload}>Upload a bill</Link>,  
     and sign the <Link onPress={handleESign}>{`HIPAA Release & Consent to Negotiate forms`}</Link> so your advisor may start negotiation on your case. 
      <CaseNumber> {`(Case #${caseNumber})`}</CaseNumber>
    </MessageText>
  )
}
export const MemberExperienceAndUploadBillAndESignReady = ({onTaskAction, handleUpload,handleESign, caseNumber}) => {
  return (
    <MessageText>
      Tell us <Link onPress={onTaskAction}>your experience</Link>, <Link onPress={handleUpload}>upload a bill</Link>, 
     and sign the <Link onPress={handleESign}>{`HIPAA Release & Consent to Negotiate forms`}</Link> so your advisor may start negotiation on your case. 
      <CaseNumber> {`(Case #${caseNumber})`}</CaseNumber>
    </MessageText>
  )
}
export const MemberExperience = ({onTaskAction, caseNumber}) => {
  return (
    <MessageText>
      Tell us <Link onPress={onTaskAction}>your experience</Link> so your advisor may start negotiation on your case. 
      <CaseNumber> {`(Case #${caseNumber})`}</CaseNumber>
    </MessageText>
  )
}

export const UploadBill = ({handleUpload, caseNumber}) => {
  return (
    <MessageText>
      <Link onPress={handleUpload}>Upload a bill</Link> from your provider so your Naviguard advisor may start negotiation on your case.  
      <CaseNumber> {`(Case #${caseNumber})`}</CaseNumber>
    </MessageText>
  )
}

export const ESignNotReady = ({caseNumber}) => {
  return (
    <MessageText>
      You have no tasks to complete, your advisor is reviewing your case details and will reach out with next steps in a few business days.
      <CaseNumber> {`(Case #${caseNumber})`}</CaseNumber>
    </MessageText>
  )
}

export const ESignReady = ({handleESign, caseNumber}) => {
  return (
    <MessageText>
      Please sign the <Link onPress={handleESign}>{`HIPAA Release & Consent to Negotiate forms`}</Link> so your advisor may start negotiation on your case. 
      <CaseNumber> {`(Case #${caseNumber})`}</CaseNumber>
    </MessageText>
  )
}

export const InNegotiation = ({caseNumber}) => {
  return (
    <MessageText>
      You have no tasks to complete. Your case is in negotiation and we will reach out as soon as a resolution is ready. 
      <CaseNumber> {`(Case #${caseNumber})`}</CaseNumber>
    </MessageText>
  )
}

export const UnderReview = ({caseNumber}) => {
  return (
    <MessageText>
      You have no tasks to complete. Your case is under review and we will reach out as soon as a resolution is ready. 
      <CaseNumber> {`(Case #${caseNumber})`}</CaseNumber>
    </MessageText>
  )
}
export const NegotiationComplete = ({caseNumber}) => {
  return (
    <MessageText>
      Your case has been resolved. Your advisor will email you with your case resolution details.
      <CaseNumber> {`(Case #${caseNumber})`}</CaseNumber>
    </MessageText>
  )
}
export const CaseClosed = ({caseNumber}) => {
  return (
    <MessageText>
      Your case has been resolved. Your advisor will email you with your case resolution details.
      <CaseNumber> {`(Case #${caseNumber})`}</CaseNumber>
    </MessageText>
  )
}
export const TasksCompleted = ({caseNumber}) => {
  return (
    <MessageText>
      You have no tasks to complete. Your advisor is reviewing your case details.
      <CaseNumber> {`(Case #${caseNumber})`}</CaseNumber>
    </MessageText>
  )
}

export const MessageText = styled(Text)`
  font-size: 18px;
`
export const CaseNumber = styled(Text)`
  font-size: 18px;
  font-style: italic;
  font-family: SourceSansPro-Bold;
`