import React from 'react';
import styled from 'styled-components/native';
import { Button } from '../../components';
import Text from '../../components/TextComponent'
import { MemberStory} from '../../redux/memberstory/types';
import { ButtonWrapper, FormGroup, FormText, Intructions, LabelWrapper, QuestionLabel } from './layout';

const HintText = styled(Text)`
  margin-top: 25px;
  font-size: 16px;
  font-style: italic;
  font-family: SourceSansPro-Regular;
`;

export interface QuestionFormProps {
  memberStory: MemberStory;
  onReturn: () => void;
}

export default function FormViewer(props: QuestionFormProps) {
  const responses = [...props.memberStory.responses]
  responses.sort((r1,r2)=>{ return r1.displayOrder<r2.displayOrder?0:1})

  //apply  formatting
  const formatAnswer = (answerText:string,answerType:string)=>{
    let formattedAnswerText=answerText;
    if(answerType && answerText){
      if (answerType.toLowerCase() === "currency"){
        let maximumFractionDigits=2
        //check if it has decimal values
        let answerFloat =parseFloat(answerText)
        if (isNaN(answerFloat)) { 
          answerFloat= 0; 
        }
        if((answerFloat % 1)===0)
            maximumFractionDigits=0

        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits:maximumFractionDigits
        });
        formattedAnswerText=formatter.format(answerFloat)
      }
    }
    return formattedAnswerText;
  }

  return (
    <>
      <Intructions>The information below reflects the information you provided about your experience with this case.</Intructions>

      
      {responses.map((response, index) =>  {
        if(response.answerText){
          return (
            <FormGroup key={response.questionId} marginTop={index === 0 ? 24 : 40}>
              <QuestionLabel>{response.questionText}</QuestionLabel>
              <FormText>{formatAnswer(response.answerText,response.answerFormat)}</FormText>
            </FormGroup>
          )
        }
      }
      )}
     

      <ButtonWrapper style={{marginTop: 40, marginBottom: 15}}>
        <Button
          label="Back to Dashboard"
          onPress={props.onReturn} />
      </ButtonWrapper>

      <HintText>If you have any updates about your experience, please contact your advisor.</HintText>
    </>
  )
}
