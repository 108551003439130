import { StoryReason, StoryService } from "../../../redux/negotiation/story/types"

export interface Questionnaire {
    case__c:string,
    patient_Name__c: string,
    bill_Amount_Increased_Since_Last_Visit__c: string,
    cost_Share_Amount__c: number,
    date_of_Service__c: string,
    insurance_Allowed_Amount__c: number,
    non_Covered_Amount__c: number,
    patient_Asked_To_Pay_Amount__c: number,
    percent_of_Non_Covered_Amount__c: number,
    plan_Paid_Amount__c: number,
    plan_to_See_Provider_More_Than_One_Time__c: string,
    provider_Billed_Amount__c: number,
    realize_out_of_network__c: string,
    seen_by_Out_Of_Network__c: string,
    seen_by_Out_Of_Network_Other__c: string,
    seen_Provider_More_Than_One_Time__c: string,
    short_Description__c: string,
    type_of_Service__c: string,
    type_of_Service_Other__c: string
}

export const storyServiceNames:StoryService={
    mentalHealth:"Mental Health Services",
    altMedicine:"Complementary and Alternative Medicine",
    lab:"Lab and Pathology",
    officeVisit:"Office Visit",
    radiology:"Radiology",
    procedure:"Procedure",
    other:"Other"
}
export const storyServiceSentences:StoryService={
    mentalHealth:"mental health services",
    altMedicine:"complementary and alternative medicine",
    lab:"lab and pathology",
    officeVisit:"an office visit",
    radiology:"radiology",
    procedure:"a procedure",
    other:""
}

export const storyServiceDescriptions:StoryService={
    mentalHealth:"(Treatment for illnesses related to emotions, behavior, and substance abuse)",
    altMedicine:"(Natural ways of treating disease, like Chiropractic or Acupuncture)",
    lab:"(Testing of bodily fluids or tissues)",
    officeVisit:"(A meeting with a health care professional to diagnose or prevent a health condition)",
    radiology:"(X-ray department)",
    procedure:"(The way a health problem is treated, such as an operation)",
    other:""
}

export const storyReasonDescriptions:StoryReason={
    scheduled:"I didn't schedule directly; this was scheduled as part of my surgery/procedure",
    primaryDoctor:"I was at my primary doctor's office and assumed all tests/biopsys/scans were also in-network",
    inNetwork:"I was at an in-network hospital or surgery center and assumed the doctors were also in-network",
    emergency:"It was an emergency and I didn't have a choice",
    pastCoverage:"I have been seen here before and it was covered",
    noAltReason:"There were no other alternatives",
    referral:"I was referred by a friend/co-worker/family member",
    choice:"I specifically chose this provider",
    other:"Other"
} 


