export const questions = {
  "feedbackType": {
    questionId: 'feedbackType', rules: { required: true }
  },
  "comments": {
    questionId: 'comments', 
    rules: {
      required: true,
      maxLength: {
        value: 100,
        message: "You have reached your maximum limit of characters allowed"
      }
    }
  },
  "visitPurpose": {
    questionId: 'visitPurpose', rules: { required: true }
  },
  "completedTask": {
    questionId: 'completedTask', rules: { required: true }
  },
  "willRecommend": {
    questionId: 'willRecommend', rules: { required: true }
  }
}

export const selectOptions = [
  { value: 'upload', text: "Upload a bill" },
  { value: 'signForm', text: "Sign an online form" },
  { value: 'connectWithAdvisor', text: "Connect with my advisor" },
  { value: 'experienceDetails', text: "Provide details about my out-of-network experience" },
  { value: 'other', text: "Other" },
]

export const getErrorText = (error: any) => {
  if(!error) return ""
  if (error.type === 'required') {
    return 'This question is required'
  }

  return error.message
}

export const isSafari = () => {
  if(!window) return false 
  if(window && !window.navigator) return false 

  return window.navigator.userAgent.indexOf('Safari') != -1 && window.navigator.userAgent.indexOf('Chrome') <= -1
}
