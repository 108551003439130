import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import { Hoverable } from 'react-native-web-hover'
import { 
    DefaultButton, 
    DefaultText, 
    ButtonSecondary, 
    SecondaryText, 
    SecondaryInverse,
    InverseSecondaryText,
    PrimaryButtonDisabled,
    PrimaryButtonDisabledText,
    LightButton,
    PrimaryCTAButton,
    PrimaryCTAText,
    RedesignLogout,
    ButtonPrimaryInverse,
    PrimaryInverseText
} from './buttonElements'

type IButton = {
  onPress: () => void;
  label: string|JSX.Element|JSX.Element[];
  disabled?: boolean;
  icon?: false | null;
  accessible?: boolean;
  accessibilityLabel?: string;
  variant?: 'primary-cta'|'secondary' | 'secondary-inverse' |'primary-inverse'| 'primary-disabled' | 'light-bg' | 'redesign-logout' | 'default' | 'default-orange';
  style?: any;
  textStyle?:any;
}
type IButtonIcon = {
  onPress: () => void;
  disabled?: boolean;
  icon: true;
  iconName: string;
  iconColor?: string;
  size?: number;
  hoveredStyle?: any;
  iconStyle?: any;
  accessible?: boolean;
  accessibilityLabel?: string;
  variant?: '';
  style: any;
}
export default function ButtonComponent(props: IButton | IButtonIcon) {
  const [style,setStyle]=useState({padding:0})
  
  const hasFocus=(focus:boolean)=>{
    if (focus && !props.icon){ 
      setStyle({padding:2})
    }
    else{ 
      setStyle({padding:0})
    }
  }
  const renderButton = (buttonProps: IButton) => {
    switch (buttonProps.variant) {
      case "primary-cta":
        return (
          <Hoverable >
            {({ hovered }) => (
              <PrimaryCTAButton style={buttonProps.style} hovered={hovered} accessibilityLabel={props.accessibilityLabel}>
                <PrimaryCTAText style={buttonProps.textStyle}>{buttonProps.label}</PrimaryCTAText>
              </PrimaryCTAButton>
            )}
          </Hoverable>
        )
      case "secondary":
        return (
          <Hoverable >
            {({ hovered }) => (
              <ButtonSecondary hovered={hovered} style={buttonProps.style}>
                <SecondaryText style={buttonProps.textStyle}>{buttonProps.label}</SecondaryText>
              </ButtonSecondary>
          )}
          </Hoverable>
        )
      case "secondary-inverse":
        return (
          <Hoverable >
            {({ hovered }) => (
              <SecondaryInverse  hovered={hovered} style={[buttonProps.style, { color: hovered ?'#f4a587' : ''}]}>
                <InverseSecondaryText hovered={hovered} style={buttonProps.textStyle}>{buttonProps.label}</InverseSecondaryText>
              </SecondaryInverse>
           )}
          </Hoverable>
        )
      case "primary-inverse":
        return(
          <Hoverable >
            {({ hovered }) => (
            <ButtonPrimaryInverse style={buttonProps.style} hovered={hovered}>
              <PrimaryInverseText style={buttonProps.textStyle}>{buttonProps.label}</PrimaryInverseText>
            </ButtonPrimaryInverse>
             )}
          </Hoverable>
        )
      case "primary-disabled":
        return(
          <PrimaryButtonDisabled style={buttonProps.style}>
            <PrimaryButtonDisabledText style={buttonProps.textStyle}>{buttonProps.label}</PrimaryButtonDisabledText>
          </PrimaryButtonDisabled>
        )
      case "light-bg":
        return (
          <Hoverable >
            {({ hovered }) => (
              <LightButton style={[buttonProps.style, { opacity: hovered ? 0.7 : 1 }]}>
                <SecondaryText style={buttonProps.textStyle}>{buttonProps.label}</SecondaryText>
              </LightButton>
            )}
          </Hoverable>
        )
      case "redesign-logout":
        return (
          <Hoverable >
            {({ hovered }) => (
              <RedesignLogout style={[buttonProps.style, { opacity: hovered ? 0.7 : 1 }]}>
                {/* div tag added to remove console error complaining about text within a View tag */}
                <div>
                  {buttonProps.label}
                </div>
              </RedesignLogout>
            )}
          </Hoverable>
        )
      case "default-orange":
        return (
          <Hoverable >
            {({ hovered }) => (
              <DefaultButton style={[buttonProps.style, {backgroundColor: "#DE321B"}]} hovered={hovered}>
                <DefaultText hovered={hovered} style={buttonProps.textStyle}>{buttonProps.label}</DefaultText>
              </DefaultButton>
            )}
          </Hoverable>
        )
      case 'default':
      default:
        return (
          <Hoverable >
            {({ hovered }) => (
              <DefaultButton style={buttonProps.style} hovered={hovered}>
                <DefaultText hovered={hovered} style={buttonProps.textStyle}>{buttonProps.label}</DefaultText>
              </DefaultButton>
            )}
          </Hoverable>
        )
    }
  }

  const renderIconButton = (buttonProps: IButtonIcon) => {
    return (
      <Hoverable >
        {({ hovered }) => (
          <Ionicons 
            name={buttonProps.iconName} 
            size={buttonProps.size || 24} 
            color={buttonProps.iconColor || "#0074C8"} 
            style={hovered ? (buttonProps.hoveredStyle?buttonProps.hoveredStyle:buttonProps.iconStyle): [buttonProps.iconStyle]}
          />
        )}
      </Hoverable>
    )
  }
  const renderIconOrButton=()=>{
    if(props.icon) {
      return renderIconButton(props)
    } else {
      return renderButton(props as IButton)
    }
  }

  if(props.disabled) {
    let buttonProps = props as IButton
    return(
      <PrimaryButtonDisabled style={buttonProps.style}>
        <PrimaryButtonDisabledText>{buttonProps.label}</PrimaryButtonDisabledText>
      </PrimaryButtonDisabled>
    )
  }

  if(props.variant && props.variant.includes("disabled")){
    return(renderButton(props))
  }
  else{
    return (
      <TouchableOpacity 
        accessibilityLabel={props.accessibilityLabel}
        onFocus={()=>hasFocus(true)}
        onBlur={()=>hasFocus(false)}
          onPress={props.onPress} 
          style={[{width: "fit-content"}, style, props.style]}
        >
        {renderIconOrButton()}
      </TouchableOpacity>
    )
  }
}