import React, { useState,useEffect} from 'react';
import { View } from 'react-native'
import {FileRejection, useDropzone} from 'react-dropzone';
import styled , { css } from 'styled-components';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { Button } from '../../components'
import {useDispatch,useSelector} from 'react-redux'
import {uploadDocuments,resetUploadDocumentsProgress, resetDocumentsError, fetchDocuments} from '../../redux/documents/actions'
import { ApplicationState } from '../../redux/rootReducer';
import {CaseError, DocumentProgress} from '../../redux/documents/types'
import UploadError from './UploadError'
import useGoogleTagManager, { GTMEvent } from '../../utils/useGoogleTagManager';
import { Case } from '../../redux/cases/types';
import { Hoverable } from 'react-native-web-hover'
import useAppInsights, { AIEvent } from '../../utils/useAppInsights'
import { DocumentsState } from '../../redux/documents/reducer';
import { useResize } from '../../utils/useDeviceWidth';
import Text from '../../components/TextComponent'

const Wrapper = styled.div.attrs({
  testID: 'upload-files-modal'
})`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 25px;
`

const Container = styled.div`
  flex: 1;
  display: flex;
  gap: 18px;
  min-height: 130px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #999999;
  border-style: dashed;
  color: #002C73;
  outline: none;
  transition: border .24s ease-in-out;
  background-color:${props => props.hover? '#ebeeff':'#fafafa'} ;
  cursor:${props => props.hover? 'pointer':''} ;
`;

const Uploads = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: ${props => props.value}%;
`

const ProgressBarWrapper = styled.div`
  height: 10px;
  display: flex;
  overflow: hidden;
  line-height: 0;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: 34px;
  flex-basis: 100%;
`
const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  width: ${props => props.value}%;
  background-color:${props => props.value<100? '#002C73':'#52A653'} ;
 
`

const Progress = (props) => {
  return <ProgressBarWrapper><ProgressBar {...props} /></ProgressBarWrapper>
}

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
`

const RegularText = styled.span`
  color: #002C73;
  font-size: 14px;
  font-family: SourceSansPro-Regular;
  ${props => props.italic && css`
    font-style: italic;
  `}
  ${props => props.black && css`
  color: black;
  `}
`

interface DropzoneProps {
  caseItem:Case;
  onDocumentsUploaded: ()=>void;
  onError: (message:string)=>void;
  onClose:()=>void;
}
export default function Upload(props:DropzoneProps) {
  const maxUploadAllowed=10;
  const appInsights = useAppInsights();
  const [ uploadReadyFiles, setUploadReadyFiles ] = useState<File[]>([])
  const [ rejectFiles, setRejectedFiles ] = useState([])
  const dispatch=useDispatch();
  const gmt = useGoogleTagManager();
  const oneMB=1048576
  const oneKB =1024
  const maxMB=7
  const acceptedFileTypes='.jpg, .jpeg, .png, .gif, .tiff, .pdf, .doc, .docx'

  ///////get the total  uploaded documents
const documentsState:DocumentsState= useSelector((state:ApplicationState)=>state.documents)
let uploadedFilesCount=0;

if(documentsState.data) 
{
      const cases=documentsState.data.filter(f=>f && f.caseId===props.caseItem.caseId);

      if(cases.length>0 && cases[0].documents){
        uploadedFilesCount= cases[0].documents.length;
      }
      
}
useEffect(()=>{
  if(!documentsState.data)
      dispatch(fetchDocuments(props.caseItem))
  }
,[])
// maximum amount of files that can be uploaded in one request
const maxFiles =Math.max(maxUploadAllowed-uploadedFilesCount,0)
//get the progress of the documents uploading

  const progressSelector=(state: ApplicationState) =>{
    if(state.documents.uploadProgress)
      return state.documents.uploadProgress
     
    return[]
  }
  const documentUploadProgress:DocumentProgress[] = useSelector(progressSelector);
  
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ multiple: true, 
                   onDrop: files => onDrop(files),
                   accept: acceptedFileTypes,
                   maxSize:oneMB * maxMB,
                   onDropRejected: files=>onDropRejected(files)
            });

const primaryButtonDisabledVariant="primary-disabled"
type btnVariant = 'secondary' | 'secondary-inverse' | 'primary-disabled' | 'light-bg' | 'default'
const [uploadButtonVariant,setUploadButtonVariant] =useState<btnVariant>(primaryButtonDisabledVariant)           
const onDrop = (files:File[]) => {
    setRejectedFiles([])
    let newFiles= files.filter(f=> !uploadReadyFiles.some((uploadReadyFile:File)=>uploadReadyFile.name===f.name))

    // prevent uploading more than the maximum files amount
    if (uploadReadyFiles.length + newFiles.length > maxFiles) {
      const delta = maxFiles - uploadReadyFiles.length

      // reject files that are above our limit
      const rejected = newFiles.slice(delta).map(f => ({
        ...f,
        file: { 
          name: f.name
        },
        errors: [{
          code: 'too-many-files'
        }]
      }))

      newFiles = newFiles.slice(0, delta)
      setRejectedFiles(rejected)
    }

    setUploadReadyFiles( [...uploadReadyFiles,...newFiles])
}

const onDropRejected = (files:FileRejection[]) => {
          setRejectedFiles(files);
}
            
  const onRemoveFile = (fileItem) => {
    let list = uploadReadyFiles.filter(( f => f.name !== fileItem.name))
    setUploadReadyFiles(list)
  }
  
  const [isUploadButtonPressed,setIsUploadButtonPressed] =useState(false)
  const handleOnUploadButtonPress=()=>
  {
      setIsUploadButtonPressed(true)
      dispatch(uploadDocuments(props.caseItem, uploadReadyFiles));
      
  }

 
   const getProgressBytes=(fileName:string)=>{
    let uploadProgressBytes=0;
    if (documentUploadProgress.length>0)
    {
      const progress= documentUploadProgress.filter(f=>f.fileName===fileName);
      if(progress.length>0)
      {
        uploadProgressBytes= progress[0].progress 
        
      }
      
    }
    return uploadProgressBytes;
   }
   
  const formatSize=(byteSize:number)=>{
    let formattedSize ={size:byteSize,unit:'B'};
    
    if(byteSize>=oneMB){
      formattedSize= {size:Math.round(byteSize/oneMB),unit:'MB'};
    }
    else if(byteSize>=oneKB){
      formattedSize ={size:Math.round(byteSize/oneKB),unit:'KB'};
    }
    

    return formattedSize
  }
  const getFormattedSizeString=(byteSize:number)=>
   {
    const formattedSize=formatSize(byteSize);
    return `${formattedSize.size}${formattedSize.unit}`
   }
  const displayProgressText=(file:File)=>{
    
    const progressBytes= getProgressBytes(file.name)

    return  `${formatSize(progressBytes).size} of ${getFormattedSizeString(file.size)}`
    
  }
  
  
   const handleClose=()=>
   {
    dispatch(resetUploadDocumentsProgress());
    dispatch(resetDocumentsError());
    setUploadReadyFiles([])
    setRejectedFiles([])
    setIsUploadButtonPressed(false)
     props.onClose();
   }

   const errorSelector=(state: ApplicationState) =>{
    if(state.documents.error){
      const errors=state.documents.error.filter(e=>e.caseId===props.caseItem.caseId)
      if(errors.length>0)
        return errors[0]
    }
    return null
  }
  const documentUploadError:CaseError= useSelector(errorSelector);

   //on upload errors
   useEffect(()=>{
    const hasError=documentUploadError===null?false:true;
   
    if (hasError)
    {
   
      handleClose()
      uploadReadyFiles.forEach(f => {
        appInsights.trackDocumentException(AIEvent.Upload,documentUploadError.exception,documentUploadError.error,props.caseItem,{fileName:f.name,fileSize:f.size})
     
      });
      props.onError(documentUploadError.error)
       
    }

  },[documentUploadError])

  const calculateProgressBar=(file:File)=>
  {
     let uploadProgress=getProgressBytes(file.name)/file.size * 100;
    return uploadProgress;
  }

   //when upload is done
  useEffect(()=>{
    const progressCount=documentUploadProgress.length;
    
    if (progressCount>0 && documentUploadProgress.filter(f=>f.complete).length==progressCount)
    {
   
      gmt.pushDocument(GTMEvent.Upload,props.caseItem.caseNumber,props.caseItem.patientId,props.caseItem.patientInd)

      uploadReadyFiles.forEach(f => {
        appInsights.trackDocumentEvent(AIEvent.Upload,props.caseItem,{fileName:f.name, fileSize:f.size})
      });
     
      handleClose()
      props.onDocumentsUploaded()
       
    }

  },[documentUploadProgress])

  //enable/disable upload button
   useEffect(()=>{
    const progressCount=documentUploadProgress.length;
    const notReadyForUpload = uploadReadyFiles.length===0;
    const readyForUpload= uploadReadyFiles.length>0 && progressCount===0;
    const  uploading = progressCount>0 && documentUploadProgress.filter(f=>!f.complete).length>0
    const uploaded=  progressCount>0 && documentUploadProgress.filter(f=>f.complete).length===progressCount
    
    if(notReadyForUpload || uploading || uploaded)
      setUploadButtonVariant(primaryButtonDisabledVariant)
    else if(readyForUpload)
     setUploadButtonVariant("")
  
   
  },[uploadReadyFiles,documentUploadProgress])

  const [,isMobile] = useResize()

  const getExplanationTextStyle = (isMobileScreen: any) => {
    if(isMobileScreen) {
      return {
        width: "80vw"
      }
    } else {
      return {
        width: "46em"
      }
    }
  }
  
  return (
    <div data-role="modal">
    <Wrapper style={getExplanationTextStyle(isMobile as boolean)}>
      <Header style={{ display: 'flex', alignItems: 'center'}}>
          <Text accessibilityRole="heading" ariaLevel="2" style={{ color: '#002C73', fontSize: 24, fontFamily: 'JekoBold'}}>Upload Files</Text>
        <Button onPress={handleClose} iconName="ios-close" size={32} icon={true} accessibilityLabel="Close modal" />
      </Header>
      <Text ariaDescribedby="max-file-size" accessibilityRole="heading" ariaLevel="3" style={{ fontFamily: 'JekoBold', color: '#002C73', fontSize: '16px', marginBottom: 6 }}>
        Supporting Documents
      </Text>
      <RegularText id="max-file-size" style={{ fontFamily: 'JekoRegular', color: '#002C73', fontSize: '16px', marginBottom: 6 }}>
        Maximum file size is 7MB
      </RegularText>
      <Hoverable>
          {({ hovered }) => (
          <div role="dropzone"  data-focusable="true" data-dropzone="true" 
          onKeyUp={(ev)=> {if(ev.key==='Tab')ev.currentTarget.classList.add('focused')}}
          onKeyDown={(ev)=> {if(ev.key==='Tab')ev.currentTarget.classList.remove('focused')}}
          onClick={(ev)=>ev.currentTarget.classList.remove('focused')}
          >
          <Container hover={hovered} {...getRootProps({isDragActive, isDragAccept, isDragReject})} aria-describedby="upload-error">
            <input id="upload-input" {...getInputProps()} />
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <MaterialIcons name="cloud-upload" size={50} color="#002C73" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
              <div style={{fontSize: '18px', fontFamily: "JekoRegular"}}>Drop files to upload or</div>
              <div style={{fontSize: '18px', fontFamily: "JekoRegular"}}>
                <span style={{fontFamily: "JekoBold", color: "#0074C8", textDecoration: 'underline'}}>click to browse</span>
              </div>
            
            </div>
          </Container>
          </div>
          )}
      </Hoverable>
      <RegularText style={{ fontFamily: 'JekoRegular', color: '#002C73', fontSize: '12px', marginBottom: 2 }}>
        Accepted File Types: { acceptedFileTypes }
      </RegularText>
      <UploadError id="upload-error" rejectedFiles={rejectFiles} allowedFileTypes={acceptedFileTypes} maxFileSize={`${maxMB}MB`}  maxUploadAllowed={maxUploadAllowed} remainingUploadAllowed={maxFiles}/> 
      { uploadReadyFiles.length>0  &&
      <Uploads>
        {
          uploadReadyFiles.map((fileItem:File,index) => {
            return (
              <div key={index} style={{display: 'flex', flexDirection: 'column',}}>
                <div style={{display: 'flex', flexDirection: 'row',alignItems: 'center',gap: 5}}>
                <Button onPress={() => onRemoveFile(fileItem)} iconName="ios-close-circle" size={24} icon={true} iconColor='#0074C8' accessibilityLabel="Remove file" />
                <RegularText style={{color: '#2a2e72', fontFamily: 'JekoBold', marginLeft: 5 }}>{`${fileItem.name} (${getFormattedSizeString(fileItem.size)})`}</RegularText>
   
                </div>
                {isUploadButtonPressed &&
                <div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                  <Progress value={calculateProgressBar(fileItem)} />
                </div>
                <RegularText style={{color: '#2a2e72', fontFamily: 'JekoBold' }}>{displayProgressText(fileItem)}</RegularText>
                </div>
                }
              </div>
            )
          })
        }
      </Uploads>
     }
      <Footer>
        <View style={{marginRight: 12}}>
          <Button variant="secondary" onPress={handleClose} label="Cancel" />
        </View>
        <Button variant={uploadButtonVariant} onPress={handleOnUploadButtonPress} label="Upload" />
      </Footer>
    </Wrapper>
    </div>
  );
}

