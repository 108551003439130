import React from 'react'
import { 
    StyleSheet, 
    View, 
} from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { useIsMobile } from '../../utils/useDeviceWidth';

export interface ProgressBarProps {
    percentComplete: number;
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#DCEBF8',
        borderRadius: 10,
        width: '100%',
        maxWidth: '600px',
        height: '20px',
    }
})

export default function ProgressBar(props: ProgressBarProps) {
    const isMobile = useIsMobile()

    const fillStyle = {
        backgroundColor: '#002C73',
        width: `${props.percentComplete * 100}%`,
        borderRadius: 10,
        height: '20px'
    }

    const iconStyle = {
        position: 'absolute',
        backgroundColor: props.percentComplete === 1 ? '#61A60D' : '#DCEBF8',
        color: props.percentComplete === 1 ? 'white' : '#002C73',
        padding: 3,
        borderRadius: 15,
        right: 0,
        top: -5
    }

    return (
        <View style={[styles.container, { width: isMobile ? '100%' : '600px' }]}>
            <View style={fillStyle}></View>
            <MaterialIcons style={iconStyle} name="done" size={24} />
        </View>
    )
}
