import React from "react";
import { Linking, View } from "react-native";
import { TouchableOpacity } from "react-native";
import { CaseAdvisor } from "../../redux/cases/types";
import CardComponent from "../card";
import Text from '../TextComponent'

export interface PhoneCardProps {
    advisor: CaseAdvisor;
  }
export default function PhoneCard(props: PhoneCardProps) {
 
    const advisorPhone = props.advisor && props.advisor.phone? props.advisor.phone:""
    const advisorExt =  props.advisor && props.advisor.extension? props.advisor.extension:""

    const handlePhonePress=()=>{
       const phone=keepNumbersOnly(advisorPhone)
       const extension=keepNumbersOnly(advisorExt)
        Linking.openURL(`tel:${phone},${extension}`)
    }
    const keepNumbersOnly=(phone)=>{
      let result="";
      if(phone) result= phone.replace(/\D/g, "");
  
      return result
    }

    return ( <CardComponent style={{padding: 0}}>
            <View style={{flex: 1, alignItems: "center", padding: 16,paddingBottom:32}}>
            <Text bold={true} style={{fontSize: 14, marginBottom:24}}>CALL YOUR ADVISOR</Text>
            <TouchableOpacity onPress={handlePhonePress} style={{flex: 1, alignItems: "center"}} accessible={false}>
                <Text bold={true} style={{fontSize: 20}}>(866) 622-8015</Text>
            </TouchableOpacity>
            </View>
        </CardComponent>
       
    )
}