import React from 'react';
import Card from 'react-native-elements/src/card/Card'
import  { useIsMobile } from '../../utils/useDeviceWidth'
import {
  View,
  TouchableOpacity,
} from 'react-native';
import Text from '../TextComponent'
import Link from '../link'
import { styles } from '../casecard/components/styles'
import { Case } from '../../redux/cases/types';
import styled from 'styled-components/native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { margin, padding } from '../../utils/styles';

const cardStyle = {
  borderRadius: 14,
  borderWidth: 0,
  shadowColor: "rgba(0, 44, 115, 0.1)",
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 1,
  shadowRadius: 12.22,

  elevation: 3,

  ...margin(0, 0, 16, 0),
  ...padding(23, 23, 0, 23),
}

const LinkWrapper = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #D04443;
  font-size: 15px;
`;

export interface CaseBannerProps {
  case: Case;
  onClick: (mode: 'edit'|'view') => void;
}

export default function CaseBanner(props: CaseBannerProps) {
  const isMobile = useIsMobile()

  // determine if this case requires a member story to be completed
  const memberStoryCompleted = props.case.tasks.find(t => t.taskName === 'Member Story')?.taskCompleteInd

  return (
    <Card containerStyle={cardStyle}>
      <Card.Title style={[styles.containerCommon, { flexDirection: 'row', alignItems: !isMobile ? 'center' : 'flex-start' }]}>
        {
          !isMobile ?
            <View style={{width: '70%', flexDirection: 'row', alignItems: 'center'}} testID={`member-story-${props.case.caseNumber}`}>
              <View style={{ marginRight: 24 }}>
                <Text bold={true} style={{fontSize: 24}}>{`Case # ${props.case.caseNumber}`}</Text>
              </View>
              <View>
                <Text style={{fontSize: 16, textTransform: 'capitalize'}}>
                  {props.case.billingProviderName?.toLowerCase()}
                </Text>
              </View>
            </View>
          :
          <View style={{width: '80%', flexDirection: 'column', alignItems: 'center'}} testID={`member-story-${props.case.caseNumber}`}>
            <View style={{ width: '100%', flexBasis: 28, position: "relative" }}>
              <Text bold={true} size="m">{`Case # ${props.case.caseNumber}`}</Text>
            </View>
            <View style={{ width: '100%', flexBasis: 24, marginTop: 4 }}>
              <Text style={{ fontSize: 16, textTransform: 'capitalize' }}>
                {props.case.billingProviderName?.toLowerCase()}
              </Text>
            </View>
          </View>
        }
        <View style={{ marginLeft: "auto", flexDirection: "row" }}>
          {memberStoryCompleted && 
            <Link style={{fontSize: 15}} 
                  testID={`member-story-view-link-${props.case.caseNumber}`}
                  onPress={props.onClick.bind(null, 'view')}>View</Link>
          }
          {!memberStoryCompleted &&
            <LinkWrapper>
              <TouchableOpacity accessible={false} onPress={props.onClick.bind(null, 'edit')}>
                <MaterialIcons 
                  accessible={false}
                  accessibilityRole="button"
                  accessibilityLabel="Edit Case" 
                  name="edit" 
                  size={16} 
                  color="#D04443" 
                />
              </TouchableOpacity>
              <Link accessible={true}
                  accessibilityLabel="Edit Case" style={{fontSize: 15, marginLeft: 4}} 
                    testID={`member-story-edit-link-${props.case.caseNumber}`}
                    onPress={props.onClick.bind(null, 'edit')}>
                Edit
              </Link>
            </LinkWrapper>
          }
        </View>
      </Card.Title>
    </Card>
  )
}
