import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet, 
  Image,
  View,
  TouchableOpacity,
} from 'react-native';
import Header from 'react-native-elements/src/header/Header'
import Ionicons from '@expo/vector-icons/Ionicons';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { getFocusedRouteNameFromRoute, useRoute } from '@react-navigation/native';
import { Hoverable } from 'react-native-web-hover'
import { Select, Text, Link } from '../index'
import { useIsMobile } from '../../utils/useDeviceWidth'
import NavItem from './navItem'
import useGoogleTagManager, { GMTNavigationLinkPlacement } from '../../utils/useGoogleTagManager';
import WaitStatus from "../modal/WaitStatus";
import { useQueryNavigator } from "../../context-api/query";
import UpdateBanner from "../updatebanner";
import ProviderBanner from "./ProviderBanner";
import { authProvider } from '../../authProvider';
import { Badge } from 'react-native-elements'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../../redux/rootReducer'
import { fetchMessageCount } from '../../redux/messages/actions'

export default function WebHeader(props) {
  const dispatch = useDispatch()
  const navigator = useQueryNavigator(props.navigation)
  const [showDropDown, setShowDropDown] = useState(false);
  const [activeMenu, setSelectedMenu] = useState("");
  const isMobile = useIsMobile()
  const route = useRoute()
  const gtm = useGoogleTagManager();
  const [showWait,setShowWait]= useState(false)
  const [waitStatus,setWaitStatus]= useState(0)
  const routeName = getFocusedRouteNameFromRoute(route) ?? 'Home'
  const accountMenuRef= useRef<TouchableOpacity>()
  const [isBannerVisible, setBannerVisible] = useState(true)
  const messageCount: number = useSelector((state: ApplicationState) => state.messages.messageCount)
  const mainSkiplinkRef= useRef<View>()
  const footerSkiplinkRef= useRef<View>()
  
  useEffect(() => {
    setSelectedMenu(routeName)
    dispatch(fetchMessageCount())
  }, [routeName])
  
  const menuItem = [
    { label: 'Your Experience', value: 'YourExperience' },
    { label: 'Account Settings', value: 'AccountSettings' },
    { label: 'Sign Out', value: 'logout' },
  ];
  
  //dictionary path:Text
   const navigationTextLookupByPath= {
    "Home": "Home",
    "Documents": "Documents",
    "MessageCenter": "Message Center",
    "FAQ": "FAQ"
  };
  const handleNavigate = (path) => {
    gtm.pushNavigation(GMTNavigationLinkPlacement.Header,navigationTextLookupByPath[path])
    if(path === "FAQ") {
      const faqUrl='https://www.naviguard.com/about/member-case-resolution-faq?CTA=ResolutionSupport&utm_source=NVG&utm_medium=web&utm_campaign=memberportal&utm_term=negotiationresolution&utm_content=faq'
      window.open(faqUrl, '_blank')
    }
    else {
      navigator.navigate(path)
    }    
  }

  useEffect(() => {
    const store = localStorage.getItem('NaviguardBookmarkBanner')

    if(store === 'isHidden') setBannerVisible(false);
  }, [])

  const handleBookmarkClose = () => {
    localStorage.setItem('NaviguardBookmarkBanner', 'isHidden')

    setBannerVisible(false);
  }

  const onSelect = (selected) => {
    gtm.pushNavigation(GMTNavigationLinkPlacement.Header,selected.label)
    if(selected.value === 'logout') {
      // setShowWait(true)
      authProvider.logout();
    } else if(selected.value === 'YourExperience') {
      navigator.navigate("YourExperience")
    } else if(selected.value === 'AccountSettings') {
      navigator.navigate("AccountSettings")
    }
  }
  //wait status. need to delay sign out to for gtm to push data and ga to send info
  useEffect(() => {
    if(showWait)
    {
      if(waitStatus<100)
      {
      const timer = setTimeout(() => {setWaitStatus(waitStatus+25)}, 2500);
      return () => {
       clearTimeout(timer);
      };
    }
    else{
      props.logout()
    }
  }
  }, [showWait,waitStatus]);
  return (
    <>
    <div data-testid='web-header-root'>
      <View style={{ position: 'absolute', width: 1, height: 1, padding: 0, margin: -1, overflow: 'hidden', zIndex: 1 }} ref={mainSkiplinkRef}>
        <Link 
          accessible={true} 
          accessibilityLabel="Skip to main content"
          onPress={() => document.location.href = '#main-content'}
          onFocus={(focus) => {
            const size = focus ? 'auto' : '1px'
            mainSkiplinkRef.current.style.width = size
            mainSkiplinkRef.current.style.height = size
          }}
        >Skip to main content</Link>
        <a style={{ width: 1, height: 1, padding: 0, margin: -1, overflow: 'hidden', clip: 'rect(0, 0, 0, 0)', border: 0 }} href="#footer">Skip to footer</a>
      </View>

      <View style={{ position: 'absolute', width: 1, height: 1, padding: 0, margin: -1, overflow: 'hidden', zIndex: 1 }} ref={footerSkiplinkRef}>
        <Link 
          accessible={true} 
          accessibilityLabel="Skip to footer"
          onPress={() => document.location.href = '#footer'}
          onFocus={(focus) => {
            const size = focus ? 'auto' : '1px'
            footerSkiplinkRef.current.style.width = size
            footerSkiplinkRef.current.style.height = size
          }}
        >Skip to footer</Link>
      </View>

      <UpdateBanner/>
      <Header
        accessible={false}
        containerStyle={{ 
          backgroundColor: '#ffffff',
          height: isMobile ? 64 : 88,
          paddingLeft: 1
        }}
        placement="left"
        leftComponent={null}
        centerContainerStyle={{minWidth: '50vw'}}
        rightContainerStyle={{ minWidth: '50vw' }}
        centerComponent={
          <TouchableOpacity
            accessibilityRole="menuitem"
            accessible={true}
            accessibilityLabel="Naviguard Home"
            activeOpacity={0.8}
            onPress={() => handleNavigate("Home")}
            style={[styles.navItem, { marginLeft: isMobile ? 1 : 60 }]}
          >
            <View style={
                !isMobile ? {height: 70, width: 200}
                : {height: 28, width: 156}
              }>
              <Image 
                style={styles.logo}
                accessibilityLabel="Naviguard Home"
                resizeMode="contain" 
                source={require('../../assets/images/logos/naviguard_color_desktop.svg')} 
              /> 
            </View>
          </TouchableOpacity>
        }
        rightComponent={
          isMobile ?
             <TouchableOpacity
              accessible={true}
              accessibilityRole="menu"
              accessibilityLabel="Menu Options"
              onPress={() => props.navigation.toggleDrawer()}
              style={{ marginRight: 16 }}
            >
              <Ionicons name="md-menu" size={25} color="#002C73" />
            </TouchableOpacity>
          :
            <View style={styles.navWrapper}>
              <NavItem
                accessibilityLabel={navigationTextLookupByPath["Home"]}
                navPath="Home"
                activeRoute={routeName}
                navText={navigationTextLookupByPath["Home"]}
                handleNavigate={handleNavigate}
              />
              <NavItem
                style={{marginRight: '20px'}}
                testID={`nav-documents`}
                accessibilityLabel={navigationTextLookupByPath["Documents"]}
                navPath="Documents"
                activeRoute={routeName}
                navText={navigationTextLookupByPath["Documents"]}
                handleNavigate={handleNavigate}
              />
             <NavItem
                style={{marginRight: '20px'}}
                testID={`nav-message-center`}
                accessibilityLabel={navigationTextLookupByPath["MessageCenter"]}
                navPath="MessageCenter"
                activeRoute={routeName}
                navText={<View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ fontFamily: 'JekoBold', fontSize: '14px' }}>
                      { navigationTextLookupByPath["MessageCenter"] } 
                    </Text>
                    {
                      !!(messageCount && messageCount > 0) &&
                      <Badge containerStyle={{ marginLeft: 3 }} status="error" value={messageCount} />                
                    }
                  </View>}
                handleNavigate={handleNavigate}
              />
              <NavItem
                style={{marginRight: '20px'}}
                testID={`nav-faq`}
                accessibilityLabel={navigationTextLookupByPath["FAQ"]}
                navPath="FAQ"
                activeRoute={routeName}
                navText={navigationTextLookupByPath["FAQ"]}
                handleNavigate={handleNavigate}
              />
              <TouchableOpacity
                testID='account-menu-root'
                accessible={true}
                accessibilityRole="menuitem"
                accessibilityLabel="Account"
                activeOpacity={0.8}
                onPress={() => setShowDropDown(!showDropDown)}
                style={[styles.navItem, {
                  paddingTop: '8px',
                  paddingRight: '22px',
                  paddingBottom: '8px',
                  paddingLeft: '22px',
                  backgroundColor: '#E9F2F9',
                  borderRadius: '20px'
                }]}
                ref={accountMenuRef}
              >
                <Select
                  testID='account-dropdown'
                  label={'Menu'}
                  mode={'outlined'}
                  value={activeMenu}
                  setValue={setSelectedMenu}
                  list={menuItem}
                  visible={showDropDown}
                  onSelect={onSelect}
                  showDropDown={() => setShowDropDown(true)}
                  onDismiss={() => {setShowDropDown(false); accountMenuRef.current.focus();}}
                  selectedItemBackground="#ffffff"
                  staticLabel={
                    <Hoverable>
                      {({ hovered }) => (
                        <Text 
                          style={[
                            styles.dropdownText, 
                            { 
                              marginLeft: 0,
                              marginRight: '5px',
                              color: '#002C73'
                            }
                          ]}
                        >Account</Text>
                      )}
                    </Hoverable>
                  }
                  rightIcon={<MaterialIcons name="expand-more" size={20} color="#002C73" />}
                />
              </TouchableOpacity>
            </View>
        }
      />
      {/* <Wait show={showWait} onClose={()=>setShowWait(false)} message="Logging out..." ></Wait> */}
      <WaitStatus status={waitStatus} visible={showWait} message="Logging Out..."></WaitStatus>
    </div>
    {isBannerVisible && (
      <View style={styles.bookmarkBarWrapper}>
        <View style={[
          styles.bookmarkBar,
          {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            justifyContent: 'space-between',
            paddingLeft: isMobile ? '15px' : '75px',
            paddingRight: isMobile ? '15px' : '75px',
          }
        ]}>
          <View style={{ 
            width: '80%',
            display: 'flex',
            flexDirection: 'row'
          }}>
            <View style={{ marginRight: '5px' }}>
              <MaterialIcons name="info" size={18} color="#002C73" />
            </View>
            <Text bold={true} style={{ display: 'flex', alignContent: 'center' }}>We recommend that you bookmark this homepage so you can easily return to manage your case.</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexGrow: 0
            }}
          >
            <TouchableOpacity onPress={handleBookmarkClose}>
              <MaterialIcons name="close" size={18} color="#0074C8" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )}
    {!isMobile && <ProviderBanner/>}
    </>
  )
}

const styles = StyleSheet.create({
  containerStyle: {
    flex: 1,
    marginHorizontal: 20,
    justifyContent: 'center',
  },
  container: {
    backgroundColor: '#002C73',
    height: 88
  },
  logo: {
    width: "100%",
    height: "100%"
  },
  navWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 20
  },
  navItem: {
    marginRight: 45,
    flexDirection: 'row',
    alignItems: "center",
    cursor: "pointer"
  },
  navItemText: {
    color: "#FFF",
    fontSize: 14
  },
  dropdown: {
    flexDirection: "row"
  },
  dropdownText: {
    fontSize: 14,
    fontFamily: "JekoBold",
    marginLeft: 14,
    marginRight: 14
  },
  bookmarkBarWrapper: {
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundColor: '#E9F2F9',
    width: '100%'
  },
  bookmarkBar: {
  }
});
