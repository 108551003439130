export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS'
export const FETCH_DOCUMENTS_PENDING = 'FETCH_DOCUMENTS_PENDING'
export const FETCH_DOCUMENTS_ERROR = 'FETCH_DOCUMENTS_ERROR'
export const UPLOAD_DOCUMENTS_SUCCESS = 'UPLOAD_DOCUMENTS_SUCCESS'
export const UPLOAD_DOCUMENTS_PROGRESS = 'UPLOAD_DOCUMENTS_PROGRESS'
export const UPLOAD_DOCUMENTS_PROGRESS_RESET = 'UPLOAD_DOCUMENTS_PROGRESS_RESET'
export const UPLOAD_DOCUMENTS_ERROR = 'UPLOAD_DOCUMENTS_ERROR'
export const DELETE_DOCUMENTS_SUCCESS = 'DELETE_DOCUMENTS_SUCCESS'
export const DELETE_DOCUMENTS_ERROR = 'DELETE_DOCUMENTS_ERROR'
export const DELETE_DOCUMENTS_STATUS_RESET = 'DELETE_DOCUMENTS_STATUS_RESET'
export const DOCUMENTS_ERROR_RESET = 'DOCUMENTS_ERROR_RESET'

export interface Document {
    id:string
    fileName: string;
    fileType: string;
    fileSize:number;
    uploadDate: Date;
    uploadBy: string;
    canDelete: boolean;
}
export interface CaseDocuments{
    caseId:string
    documents:Document[]
}
export interface CaseError{
    caseId:string
    error:string
    exception:Error
}
export interface CaseMessage{
    caseId:string
    message:string
}
export interface DocumentProgress{
    fileName:string
    progress:number
    complete:boolean
}
export interface DocumentDeleteStatus{
    caseId:string
    documentId:string
    success:boolean
    message:string
    exception:Error
}

export interface FetchDocumentsSuccess {
    type: typeof FETCH_DOCUMENTS_SUCCESS;
    payload:  CaseDocuments;
}
export interface FetchDocumentsPending {
    type: typeof FETCH_DOCUMENTS_PENDING;
}
export interface FetchDocumentsError {
    type: typeof FETCH_DOCUMENTS_ERROR;
    payload: CaseError;
}
export interface UploadDocumentsSuccess{
    type: typeof UPLOAD_DOCUMENTS_SUCCESS;
    payload: CaseMessage;
}
export interface UploadDocumentsProgress{
    type: typeof UPLOAD_DOCUMENTS_PROGRESS;
    payload: DocumentProgress;
}
export interface UploadDocumentsProgressReset{
    type: typeof UPLOAD_DOCUMENTS_PROGRESS_RESET;
}
export interface UploadDocumentsError{
    type: typeof UPLOAD_DOCUMENTS_ERROR;
    payload: CaseError;
}
export interface DeleteDocumentsSuccess{
    type: typeof DELETE_DOCUMENTS_SUCCESS;
    payload: DocumentDeleteStatus;
}

export interface DeleteDocumentsError{
    type: typeof DELETE_DOCUMENTS_ERROR;
    payload: DocumentDeleteStatus;
}
export interface DeleteDocumentsStatusReset{
    type: typeof DELETE_DOCUMENTS_STATUS_RESET;
}
export interface DocumentsErrorReset{
    type: typeof DOCUMENTS_ERROR_RESET;
}
export type DocumentsDispatchTypes =
 | FetchDocumentsSuccess
 | FetchDocumentsPending
 | FetchDocumentsError
 | UploadDocumentsSuccess
 | UploadDocumentsProgress
 | UploadDocumentsProgressReset
 | UploadDocumentsError
 | DeleteDocumentsSuccess
 | DeleteDocumentsError
 | DeleteDocumentsStatusReset
 | DocumentsErrorReset
