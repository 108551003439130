import { Dispatch } from "redux";
import axiosInstance from "../axiosInstance";
import { FETCHED_MESSAGE_COUNT } from "./types";

export const fetchMessageCount =  ()  => async (dispatch: Dispatch):Promise<number>  => {
  const axios = await axiosInstance()
  const response = await axios.get('/messages/unreadCount', {
    headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    }
  })
  dispatch({
    type: FETCHED_MESSAGE_COUNT,
    payload: response.data
  })
  return response.data
}
