import { FETCHED_MESSAGE_COUNT, MessagesState, FetchCount } from "./types";

const defaultState = {
  messageCount: null,
}

export const MessagesReducer = (state: MessagesState = defaultState, action: FetchCount) => {
  if (action.type===FETCHED_MESSAGE_COUNT) {
    return(
      {
        ...state,
        messageCount: action.payload
      }
    )
  }
  else{
    return state;
  }
}
