import styled from 'styled-components/native';
import webStyled  from 'styled-components';

export const DefaultButton = styled.View`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
min-height: 34px;
border-radius: 20px;
border-width:2px;
border-style:solid;
letter-spacing: 2px;
border-color: ${props=>props.hovered? '#E64B38': 'transparent'};
background: ${props=>props.hovered? '#FFFFFF': '#002C73'};
`

export const DefaultText = styled.Text`
  font-size: 16px;
  font-family: JekoRegular;
  font-weight: bold;
  white-space: nowrap;
  padding: 8px 30px;
  color:  ${props=>props.hovered? '#002C73': '#FFFFFF'}; 
  text-transform: uppercase;
`
//the primary CTA button uses a linear gradient on hover which requires hacking some css using a div tag.
export const PrimaryCTAButton = webStyled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
min-height:34px;
background: #FFFFFF;
border-radius: 20px;
border-color:transparent;
padding: 2px;
background-image:linear-gradient(#FFFFFF, #FFFFFF), ${props=>props.hovered? 'linear-gradient(90deg, #8CC6E7 0%,#071D49 50%, #E64B38 100%)': 'linear-gradient(#E64B38,#E64B38)'}; 
background-origin: border-box;
background-clip: content-box, border-box;
text-transform: uppercase;
`

export const PrimaryCTAText =  styled(DefaultText)`
  color: #002C73; 
`
export const LightButton = styled.View`
  background-color: #ebeeff;
  color: #FFF;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: fit-content;
  border-radius: 4px;
`


export const ButtonSecondary = styled(DefaultButton)`
border-color: ${props=>props.hovered? '#0074C8': 'transparent'};
background: #E9F2F9;
`
export const SecondaryText = styled(DefaultText)`
color: #002C73 
`
export const ButtonPrimaryInverse = styled(DefaultButton)`
border-color: ${props=>props.hovered? '#E64B38': '#002C73'};
background: #FFF;
`
export const PrimaryInverseText = styled(DefaultText)`
color: #002C73 
`
export const SecondaryInverse = styled.View`
  border-color: ${props=>props.hovered? '#f4a587': '#FFF'};
  border-width: 2px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: fit-content;
  border-radius: 4px;
`
export const InverseSecondaryText = styled.Text`
  color: ${props=>props.hovered? '#f4a587': '#FFF'};
  font-size: 16px;
  font-family: SourceSansPro-Bold;
`
export const PrimaryButtonDisabled = styled.View`
padding-left: 30px;
padding-right: 30px;
padding-top: 8px;
padding-bottom: 8px;
width: fit-content;
shadowColor: rgb(0, 0, 0),
shadowOffset: { width: 0, height: 1 },
shadowOpacity: 0.15,
shadowRadius: 2,
border-radius: 20px;
background-color: rgba(0, 0, 0, 0.1);
`
export const PrimaryButtonDisabledText = styled.Text`
font-family: JekoRegular;
font-size: 16px;
letter-spacing: 2px;
font-weight: bold;
color: rgba(51, 51, 51, 0.6);
`

export const RedesignLogout = styled.View`
  background-color: #E9F2F9;
  color: #002C73;
  font-family: 'JekoBold';
  font-size: 14px;
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: fit-content;
  border-radius: 20px;
`