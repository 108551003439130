import React, { ReactNode } from 'react'
import { View } from 'react-native';
import Skeleton from '../Skeleton';
import styled from 'styled-components/native'

export interface PickerFormatProps {
  nativeID?: string;
  selectedValue:string;
  onValueChange?:(...event:any[])=>void;
  errorState:boolean;
  dataLoaded:boolean;
  children:ReactNode;//PickerItemProps[]
  height?:string;
  width?:string;
  fontSize?:string;
  ariaLabel?:string;
  ariaDescribedby?: string;
  required?:boolean;
}

export default  function PickerFormat(props:PickerFormatProps){
const {selectedValue,onValueChange,errorState,dataLoaded,children,
  height,width,fontSize, required, ariaLabel, ariaDescribedby} = props;

const inputHeight=height?height:"35px"
const inputWidth=width?width:"250px"
const inputFontSize=fontSize?fontSize:"14px"

return(
  <>
    { !dataLoaded &&
      <View style= {{height: inputHeight, width: inputWidth}}>
        <Skeleton />
      </View>
    }
    { dataLoaded &&
      <ErrorPicker 
        id={props.nativeID}
        aria-labelledby={ariaLabel} 
        aria-describedby={ariaDescribedby}
        aria-invalid={errorState ? 'true' : 'false'} 
        aria-required={required ? 'true' : 'false'} 
        height={inputHeight} 
        width={inputWidth} 
        fontSize={inputFontSize}
        onValueChange={(value) => { onValueChange(value)}}
        selectedValue={selectedValue}
        hasErrors={errorState}
      >
        {children}
      </ErrorPicker>
    }
   </>
)
}

const ErrorPicker = styled.Picker`
    height:${(props) => props.height};
    width: ${(props) => props.width};
    padding: 0 21px;
    background-color: #E9F2F9;
    font-family: 'JekoBold';
    color: #002C73;
    font-size: ${(props) => props.fontSize};
    border-color: ${(props) => (props.hasErrors ? '#E00000' : '')};
    border-width: ${(props) => (props.hasErrors ? '2px' : '')};
    border-radius: 7px;
`

