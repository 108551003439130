import axiosInstance from "../axiosInstance";
import {
  FETCH_MEMBER_STORY,
  UPDATE_MEMBER_STORY,
  FETCH_MEMBER_STORIES,
  FETCH_MEMBER_STORY_TEMPLATE,
  MemberStory,
} from "./types"
import { Case } from '../../redux/cases/types';

export const fetchMemberStory = async (caseId: string, version: number) => {
  const axios = await axiosInstance()

  return {
    type: FETCH_MEMBER_STORY,
    payload: axios.get(`/member-stories?case-id=${caseId}&version=${version}`)
  }
}

export const updateMemberStory = async (story: MemberStory, caseItem: Case) => {
  const { caseSetId } = caseItem
  const axios = await axiosInstance()

  return {
    type: UPDATE_MEMBER_STORY,
    payload: axios.post('/member-stories', {
      ...story,
      workPackageCaseSetId: caseSetId
    })
  }
}
export const fetchMemberStoryTemplate = async (caseItem: Case) => {
  const { caseId, caseSetId } = caseItem
  const axios = await axiosInstance()

  return {
    type: FETCH_MEMBER_STORY_TEMPLATE,
    payload: axios.get(`/member-stories?caseId=${caseId}${ caseSetId ? `&workPackageCaseSetId=${caseSetId}` : '' }`)
  }
}
export const fetchMemberStories = async (caseItem: Case) => {
  const { caseId, caseSetId } = caseItem
  const axios = await axiosInstance()

  return {
    type: FETCH_MEMBER_STORIES,
    payload: axios.get(`/member-stories?caseId=${caseId}${ caseSetId ? `&workPackageCaseSetId=${caseSetId}` : '' }`)
  }
}
