import { AxiosResponse } from "axios"

export const COMPLETE_INVITATION = 'COMPLETE_INVITATION'

export interface CompleteInvitation {
    type: typeof COMPLETE_INVITATION;
    payload: Promise<AxiosResponse<void>>;
}

export type MemberStoryDispatchTypes =
 | CompleteInvitation
