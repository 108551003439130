import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import Layout from '../../components/layout'
import CaseCard, { SkeletonCaseCard } from '../../components/casecard'
import { Text, Link } from '../../components'
import CardComponent from '../../components/card'
import CardTitle from '../../components/card/cardtitle'
import { fetchCases } from '../../redux/cases/actions'
import { fetchContacts } from '../../redux/accountSettings/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../redux/rootReducer'
import { Case } from '../../redux/cases/types'
import useAuthentication from '../../utils/useAuthentication'
import CasesErrorBoundary from '../../components/boundaries/CasesErrorBoundary';
import AdvisorPanel from '../../components/advisorpanel';
import useGoogleTagManager, { GTMEvent, usePageLoadEffect } from '../../utils/useGoogleTagManager';
import { useQueryNavigator } from '../../context-api/query';
import { useOverlay } from '../../context-api/overlay/overlayState'
import { setCaseSigning } from "../../utils/signingStorage";
import MessageItem from './MessageItem'
import CaseSelect from '../../components/caseselect'
import styled from 'styled-components/native';
import * as Linking from 'expo-linking';
import { useWindowDimensions } from 'react-native';


const GreetingHeading = styled.Text`
  color:#002C73;
  font-family: Canela-Regular-Web;
  font-weight: 400;
  font-size: 55px;
`

export default function Home({ route, navigation }) {
  const { overlay } = useOverlay()
  const gtm = useGoogleTagManager()
  const navigator = useQueryNavigator(navigation)
  const dispatch = useDispatch()
  const casesPending = useSelector((state: ApplicationState) => state.cases.pending)
  const cases:Case[] = useSelector((state: ApplicationState) => state.cases.data)
  const casesError = useSelector((state: ApplicationState) => state.cases.error)
  const [contacts, setContacts] = useState(null)
  const [signedCaseId, setSignedCaseId] = useState(undefined)
  const [filteredCases,setFilteredCases]=useState(cases)
  const [unExpiredCases,setUnExpiredCases]=useState(cases)
  const expiredInDays=45
  const { width } = useWindowDimensions()

  useEffect(() => {
    // Return the function to unsubscribe from the event so it gets removed on unmount
    const unsubscribe= navigation.addListener('focus', async () => {
      if (
        route.params &&
        route.params.event &&
        route.params.event == 'signing_complete'
      ) {
        overlay({
          show: true,
          type: 'message',
          message:
            'Thank you for your signature.  Our team will start the negotiation process and your advisor will contact you with next steps.',
          messageType: 'success',
          buttonLabel: 'Close',
        })

        // store the id of the case for which the consent was signed
        setSignedCaseId(route.params.caseId)

        try {
          // cache docusign update until backend sync occurs from docusign to service portal (~15 minutes)
          // TODO(docusigncache): update this in future to save in servce portal in case docusign to service portal comms fail
          await setCaseSigning(route.params.caseId);
        } catch (e) {
          // saving error
          console.error(e);
        }
        navigation.setParams({ event: undefined, caseId: undefined })
        if(unsubscribe){
          unsubscribe()
        }

      }
    })
    return unsubscribe
  }, [navigation])

  useEffect(() => {
    if (cases.length === 0) {
      doFetchCases()
      doFetchContacts()
    }
  }, [])

   

    useEffect(() => {
      // if the user came back from signing the consent for a case, push a gtm event specifically
      // for it
      if (signedCaseId && !casesPending) {
        // find the case by its id number
        const targetCase: Case = cases.find((c: Case) => c.caseId === signedCaseId)
        if (!targetCase) {
          console.warn(`Could not find case to report consent complete GTM event: ${signedCaseId}`)
        }
        
        // if found, push information about the case. at minimum, we can still push the completeRelease
        // gtm event regardless.
        gtm.pushEvent(GTMEvent.CompleteRelease, targetCase ? {
          caseNumber: targetCase.caseNumber,
          patientID: targetCase.patientId,
          userIsPatient: targetCase.patientInd ? 'Yes' : 'No'
        } : {})

        // we don't need this case id anymore
        setSignedCaseId(undefined)
      }
    }, [cases, casesPending, signedCaseId])

    const addDays=(date:Date, days:number)=> {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }
    useEffect(()=>{
        if(cases && cases.length>0){
          setUnExpiredCases(cases)
          const closedCases= cases.filter(c=>c.caseStatus==="Case Closed")
          if(closedCases && closedCases.length>0){
            const completedClosedCases= closedCases.filter(c=>c.progress && c.progress.steps && c.progress.steps.length>0?
              c.progress.steps[c.progress.steps.length-1].name==="Case Closed" &&
              c.progress.steps[c.progress.steps.length-1].status==="Complete" :false)
              if(completedClosedCases && completedClosedCases.length>0){
                const today = new Date()
                const expiredClosedCases=completedClosedCases.filter(c=> 
                  c.progress.steps[c.progress.steps.length-1].date &&
                  addDays(new Date(c.progress.steps[c.progress.steps.length-1].date),expiredInDays) < today)

                  if(expiredClosedCases && expiredClosedCases.length>0){
                   
                    setUnExpiredCases(cases.filter(c=>expiredClosedCases.filter(expC=>expC.caseId===c.caseId).length===0))
                  }
              }
          }
        }
    },[cases])

    usePageLoadEffect()

    const handleContactLink = () => {
      window.open('https://www.naviguard.com/contact-us', '_blank', 'noopener')
    }
  
    const handlePhonePress=(advisorPhone)=>{
      Linking.openURL(`tel:${advisorPhone}`)
   }

    const doFetchCases = () => {
      dispatch(fetchCases())
    }

    const doFetchContacts = () => {
      dispatch(fetchContacts()).then((c) => {
        setContacts(c)
      })
    }

    const handleTaskAction = (
        actionRoute: string,
        params?: Record<string, string>
    ) => {
        navigator.navigate(actionRoute, params)
    }

    const handleRetryCases = () => {
        doFetchCases()
    }
    
  
    return (
        <Layout
      testID="home-root"
      singleColumn={filteredCases.length === 0}
      imageBG={width > 1400} 
      introHeading={<GreetingHeading style={{ textTransform: 'capitalize' }}>Hello { contacts?.firstName.toLowerCase() },</GreetingHeading>}
      introBody="Thank you for taking the time to check your case status. We understand that navigating the complexities of the health care system, particularly when it comes to out-of-network billing, can be overwhelming."
      informationSelect={
        <CaseSelect cases={cases} casesPending={casesPending} disabled={filteredCases.length === 0} onCasesFiltered={(cases) => setFilteredCases(cases)}/>
      }
    >
      <CasesErrorBoundary onRetry={handleRetryCases}>
        {
          !casesPending && filteredCases.length > 0 && unExpiredCases && unExpiredCases.length > 0 &&
            <CardComponent style={{padding: 0}}>
              <CardTitle accessibilityRole="heading" ariaLevel="2">MESSAGES</CardTitle>
              <View style={{ padding: 28, flexDirection: 'column',backgroundColor:'#FFFFFF',borderRadius:14,top:-14}}>
              {
                unExpiredCases.map((caseItem: Case) => {
                  return (
                    <MessageItem 
                      key={`message-${caseItem.caseId}`} 
                      caseItem={caseItem} 
                      onTaskAction={handleTaskAction}
                    />
                  );
                })
              }
              </View>
            </CardComponent>
        }
        {
          casesPending && (
            [0, 1, 2].map((item) => <SkeletonCaseCard key={`skeleton-${item}`} />)
          )
        }
        {
          !casesPending && filteredCases.length > 0 && 
          <View style={{marginTop:unExpiredCases && unExpiredCases.length>0?0:-31}}>
          {
            filteredCases.map((caseCard: Case) => {
              return (
                <View key={caseCard.caseId} style={{marginTop:15}}>
                  <CaseCard case={caseCard} onTaskAction={handleTaskAction} defaultOpen={caseCard.caseStatus == 'Case Opened'} />
                </View>
              );
            })
          }
          </View>
        }
        {
          !casesPending && filteredCases.length === 0 && (
            <View style={{marginTop: 30}}>
              <Text style={{transform: 'unset', marginBottom: 12, fontSize: 18}}>
                <Text bold={true} style={{transform: 'unset'}}>We want to assure you that we are here to help.</Text> However, at the moment, we have not yet set up an account for this case. But don't worry, it will be accessible to you once you receive a Welcome to Naviguard! Email. Inside that email, you will find clear instructions on the next steps to take.
              </Text>

              <Text style={{transform: 'unset', marginBottom: 12, fontSize: 18}}>
                If you require any further assistance, please don't hesitate to reach out to a Naviguard Advisor. You can contact them using our <Link onPress={() => handleContactLink()}>Contact Us</Link> form or <Link onPress={() => handlePhonePress('8666228015')}>(866) 622-8015</Link>. 
              </Text>

              <Text style={{transform: 'unset', marginBottom: 12, fontSize: 18}}>
                We appreciate your patience and understanding as we work to provide you with the information you need.
              </Text>
            </View>
          )
        }
      </CasesErrorBoundary>
      <>
        {
          !casesPending && !casesError && filteredCases.length > 0 && (<AdvisorPanel cases={filteredCases}/>)
        }
      </> 
    </Layout>
  )
}


