export const styles = {
  containerCommon: {
    display: "flex",
    fontWeight: "normal",
    textAlign: 'left',
    color: '#002C73',
    cursor: "pointer"
  },
  largeScreen: {
    container: {
      alignItems: "center",
      flexDirection: "row",
    },
    title: { width: '25%' },
    provider: { width: '25%' },
    statusPill: { marginLeft: "auto", flexDirection: "row" },
    arrow: {}
  },
  tabletDevice: {
    container: {
      alignItems: "center",
      flexDirection: "column",
    },
    title: { width: '100%', flexBasis: 28, position: "relative" },
    provider: { width: '100%', flexBasis: 24, marginTop: 4 },
    statusPill: { width: '100%', flexDirection: "row", flexBasis: 24, marginTop: 18 },
    arrow: {}
  },
  width295_359: {
    container: {
      alignItems: "center",
      flexDirection: "column",
    },
    title: { width: '100%', flexBasis: 28, position: "relative" },
    provider: { width: '100%', flexBasis: 24, marginTop: 4 },
    statusPill: { width: '100%', flexDirection: "row", flexBasis: 24, marginTop: 18 },
    arrow: {
      position: "absolute",
      top: -12,
      right: 0
    }
  },
  width375_811: {
    container: {
      alignItems: "center",
      flexDirection: "column",
    },
    title: { width: '100%', flexBasis: 28, position: "relative" },
    provider: { width: '100%', flexBasis: 24, marginTop: 4 },
    statusPill: { 
      width: '100%',
      flexDirection: "row", 
      flexBasis: 24, 
      marginTop: 18 
    },
    arrow: {
      position: "absolute",
      top: 10,
      right: 10
    }
  },
  wdth360_374: {
    container: {
      alignItems: "center",
      flexDirection: "column",
    },
    title: { width: '100%', flexBasis: 28, position: "relative" },
    provider: { width: '100%', flexBasis: 24, marginTop: 4 },
    statusPill: { width: '100%', flexDirection: "row", flexBasis: 24, marginTop: 18 },
    arrow: {
      position: "absolute",
      top: 10,
      right: 10
    }
  },
  casePill: {
    width: 101,
    height: 28,
    borderRadius: 14,
    alignSelf: "flex-end",
    marginRight: 15,
    display: "flex",
    justifyContent: "center"
  },
  caseOpened: {
    backgroundColor: "#e0f6f1"
  },
  warning: {
    backgroundColor: "#faefdb",
  },
  closed: {
    backgroundColor: "rgba(51, 51, 51, 0.1)",
  }
}