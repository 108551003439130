import { Numbers,UpdateNumbers,UPDATE_NUMBERS } from "./types"
   
  
export interface NegotiationNumbersState {
data: Numbers[];
}
  
const defaultState: NegotiationNumbersState = {
data: undefined

}
  
export const NegotiationNumbersReducer = (state: NegotiationNumbersState = defaultState, action:UpdateNumbers) => {

    if (action.type=== UPDATE_NUMBERS){
        return{
            ...state,
            data:state.data===undefined? [action.payload]:
                [...state.data.filter(f=>f.caseId!==action.payload.caseId),action.payload]
        }
    }
    else{
        return state;
    }
}
