import React from 'react'
import { Controller,Control } from "react-hook-form";
import NumberFormat from 'react-number-format';
import { ErrorText} from './Layout';


export interface CurrencyFormatProps {
  editable?:boolean
  control:Control<Record<string,any>>
  name:string
  defaultValue?:string|number
  required?:boolean
  error?:boolean
  errorMessage?:string
  isMobile:boolean
}

export default  function CurrencyFormat(props:CurrencyFormatProps){
   
    const style={
            padding:10,
           fontFamily: 'SourceSansPro-Regular',
           color: '#002C73',
           borderColor: props.error?'#E00000':'rgb(153, 153, 153)',
           backgroundColor:props.editable?'':'rgba(128, 128, 128,.1)',
           fontSize:16,
           borderRadius:2,
           borderWidth:1,
           borderStyle:'solid',
           width:props.isMobile?'100%':'50%'
        }
     
return(

    <>
    <Controller control={props.control}
        name={props.name}
        rules={{required:props.required}}
        defaultValue={props.defaultValue}
        render={( { onChange, onBlur, value }) => (
            <NumberFormat
            aria-required={props.required ? 'true' : 'false'}
            style={style}
            isNumericString={true}
            data-focusable={props.editable ? "true" : "false"}
            readOnly={props.editable ? false : true}
            thousandSeparator={true} 
            prefix={'$'}
            value={value}
            onBlur={onBlur}
            onValueChange={values => onChange(values.value)}
            decimalScale={2}
            error={props.error?props.error:undefined}
            />
        )}
    />
       {props.error && <ErrorText>{props.errorMessage}</ErrorText>}
    </>
    

   
)
}

