import {
    FETCH_CONTACTS_PENDING,
    ContactsDispatchTypes
  } from "./types"
   
  
  export interface ContactsState {
    fetchPending:boolean;
  }
  
  const defaultState: ContactsState = {
    fetchPending:false
  }
  
  export const ContactsReducer = (state: ContactsState = defaultState, action: ContactsDispatchTypes) => {
  
    if (action.type===FETCH_CONTACTS_PENDING) {
      return(
        {
          ...state,
          fetchPending:action.payload,
        }
      )
    }
    else{
        return state;
    }
  }