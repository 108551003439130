import React from "react";
import { View } from "react-native";
import Skeleton from "../../components/Skeleton";
import { useIsMobile } from "../../utils/useDeviceWidth";

export default function SkeletonQuestion() {
    const isMobile = useIsMobile()

    return (
        <View style={{ marginTop: 30 }}>
            <View style={isMobile ? { width: '60%', flexBasis: 28, position: "relative", height: 20 } : { height: 20, width: '40%' }}>
                <Skeleton />
            </View>
        </View>
    )
}
