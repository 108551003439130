import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { TextInput, View } from "react-native";
import Text from '../../components/TextComponent'
import styled from 'styled-components/native';
import { Case } from '../../redux/cases/types';
import { QuestionResponseTemplate } from '../../redux/memberstory/types';
import { updateMemberStory } from '../../redux/memberstory/actions';
import RadioButton from '../../components/radiobutton';
import { Button } from '../../components';
import { useForm } from 'react-hook-form';
import { ButtonWrapper, FormGroup, FormText, Intructions, LabelWrapper, QuestionLabel } from './layout';
import Modal, { ModalBody, ModalFooter, ModalText } from '../../components/modal';
import UnsavedChangesWarning from '../../components/modal/UnsavedChangesWarning';
import NumberFormat, {  NumberFormatValues } from 'react-number-format';
import RadioADA from '../../components/RadioADA';
import { MaterialIcons } from '@expo/vector-icons';


const RadioGroup = styled(View)`
  padding: ${props => props.error ? "8px" : "0"};
  padding-bottom: 0;
  border-width: ${props => props.error ? "2px" : "0"};
  border-color: ${props => props.error ? "#e00000" : "#999999"};
`
const RadioButtonWrapper = styled(View)`

`;

const HintText = styled(Text)`
`;

const ErrorText = styled(Text)`
  margin-top: 4px;
  color: #e00000;
  font-family: SourceSansPro-Regular;
  font-size: 16px;
`

const NumericTextInput = styled(TextInput)`
  width: 100px;
  height: 40px;
  border-width: ${props => props.error ? "2px" : "1px"};
  border-color: ${props => props.error ? "#e00000" : "#002C73"};
  padding: 12px;
  color: #002C73;
`;

const FreeTextInput = styled(TextInput)`
  font-family: SourceSansPro-Regular;
  font-size: 16px;
  border-width: ${props => props.error ? "2px" : "1px"};
  border-color: ${props => props.error ? "#e00000" : props.disabled ? "#94989B" : "#002C73"};
  padding: 12px;
  color: #002C73;
  background-color: ${props => props.disabled ? '#EAEBEC' : '#E9F2F9'};
`;

const NumberInputStyle = {
  fontFamily: 'SourceSansPro-Regular',
  color: '#002C73',
  fontSize: 16,
  width: 200,
  height: 40,
  padding: 12,
  background: '#F5F9FB',
  borderRadius: 7,
}

const TextAreaInputStyle = {
  fontFamily: 'SourceSansPro-Regular',
  color: '#002C73',
  fontSize:16,
  borderRadius: 7
}

const CurrencyWrapper = styled(View)`
  padding: ${props => props.error ? "8px" : "0"};
  padding-bottom: 0;
  border-width: ${props => props.error ? "2px" : "0"};
  border-color: ${props => props.error ? "#e00000" : props.disabled ? "#94989B" : "#002C73"};
`

const CurrencyWrapperInner = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 42px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  padding-left: 8px;
  padding-right: 5px;
`

export interface QuestionFormProps {
  case: Case;
  questions: QuestionResponseTemplate[];
  version: number;
  onUpdateComplete: () => void;
}

 interface AnswerListChoice {
  answerId: number;
  answerText: string;
}

export default function FormEditor(props: QuestionFormProps) {
  const { register, setValue, handleSubmit, formState ,reset,trigger} = useForm()
  const [form, setForm] = useState({})
  const [clearForm, setClearForm] = useState(false)
  const [responsePending, setResponsePending] = useState(false)
  const { errors, isSubmitting } = formState
  const [modal, setModal] = useState({
    visible: null,
    variant: null,
    message: null,
    onClose: null
  })

  useEffect(() => {
    props.questions.forEach(question =>{
      register(question.questionId.toString(), {required:question.required})
      registerDependency(question, false)
    })
  }, [register])

  useEffect(()=>{
    if(clearForm){
      reset();
      setClearForm(false)
    }

  }, [clearForm])

  const handleModalCloseSuccess = () => {
    closeModal()
    props.onUpdateComplete()
  }

  const handleFormSubmit = async (data: any) => {
    // guard against submitting the form while a request to do so is already in flight
    if (responsePending) {
      return
    }

    // format the form into a structure ready for submission to the backend
    const story = {
      caseId: props.case.caseId,
      questionSetVersion: props.version,
      responses: Object.keys(data).map(key => +key).map(key => {
        const question = props.questions.find(q => q.questionId === key)

        return {
          questionId: key,
          answerId: question.answerType.toLowerCase() === 'picklist' ? +data[key] : undefined,
          answerText: question.answerType.toLowerCase() !== 'picklist' ? data[key]?.toString() : undefined,
        }
      }).filter(question =>(question.answerText !== undefined && question.answerText?question.answerText.trim() !== '':false) || question.answerId !== undefined)
    }
    setResponsePending(true)
  
    const request = await updateMemberStory(story, props.case)
    request.payload.then(() => {
      setResponsePending(false)
      reset();
      setModal({
        visible: true,
        variant: 'success-v2',
        message: 'Your member story has been successfully submitted.',
        onClose: handleModalCloseSuccess
      })

    }).catch((e: AxiosError) => {
      console.error(e)

      setResponsePending(false)
      setModal({
        visible: true,
        variant: 'error-v2',
        message: 'An unknown error has occured. Please try again in a few minutes.',
        onClose: closeModal
      })
    })
  }

  const handleSetValue = (questionId: number, answer: any,answerKey:number) => {
    setValue(questionId.toString(), answer,{shouldDirty:true})
    setForm({
      ...form,
      [questionId]:answerKey,
      [`${questionId}-${answerKey}`]:answer
    })
  }
  const handleSetDisabled = (question:QuestionResponseTemplate, enabledAnswer: any,disabled?:boolean) => {
    const questionId=question.questionId.toString()
    let answer = enabledAnswer;

    if(disabled){
      answer=''
    }
    //if a question is required but has a dependency, make it required only if it is enabled
    registerDependency(question,disabled)
    
    //ui update only when there is a change.
    const key=`${question.questionId}-disabled`
    if(form[key]!==disabled)
    {
      setForm({
      ...form,
      [key]:disabled
      })

      setValue(questionId, answer,{shouldDirty:true})
    }
   
  }
  const registerDependency=(question:QuestionResponseTemplate,disabled?:boolean)=>{
    const questionId=question.questionId.toString()
    if(question.dependsOn && question.required){
      if(disabled===undefined){
        const key=`${question.questionId}-disabled`
         disabled= form[key] as boolean
      }
      register(questionId,{required:!disabled})
      if(formState.isSubmitted)
        trigger(questionId)
    }
  
 }
  const disableDependentQuestion=(question:QuestionResponseTemplate)=>{
    let disabled = false;

    if(question.dependsOn) {
      //calculate values when disabled/enabled
      let answers=question.answers?question.answers as []:undefined;
      let enabledAnswer='';

      if(answers)
        answers.forEach((value:AnswerListChoice) => {
          if(form[question.questionId] === value.answerId){
           enabledAnswer= form[`${question.questionId}-${value.answerId}`]
          }
        });
      
      const dependency = question.dependsOn

      disabled = !dependency.answerIds.includes(form[dependency.questionId])
      handleSetDisabled(question,enabledAnswer,disabled)
    }
   return disabled
  }
  const closeModal = () => {
    setModal({
      visible: false,
      variant: undefined,
      message: undefined,
      onClose: undefined
    })
  }

  const renderQuestionLabels = (question: QuestionResponseTemplate, disabled: Boolean) => {
    if (question.required) {
      return <QuestionLabel disabled={disabled}>{question.questionText} <Text style={{ fontWeight: 400 }}>(Required)</Text></QuestionLabel>
    } else {
      return <LabelWrapper>
        <QuestionLabel disabled={disabled}>{question.questionText}</QuestionLabel>
        <FormText disabled={disabled}>(Optional)</FormText>
      </LabelWrapper>
    }
  }

  const getErrorText = (error: any) => {
    if (error.type === 'required') {
      return 'This question is required'
    }

    return error.message
  }


  return (
    <>
      <Intructions style={{ fontSize: 32 }}>
        <Text bold={true} >
          We will be asking you to answer a few questions regarding your experience with <Text bold={true} style={{ textTransform: 'capitalize' }}>{props.case.billingProviderName.toLowerCase()}</Text>.
        </Text>
        The answers you provide will help us in our attempt to lower your costs.
      </Intructions>
      
      {props.questions?.map((question, index) => {

        let marginDependsOn = question?.dependsOn?.questionId === question?.questionId - 1 ? 15 : 40;

        if (question.answerType.toLowerCase() === 'picklist') {
          const disabled =disableDependentQuestion(question)
          return <fieldset style={{padding: 0, margin: 0, borderStyle: "none"}} aria-required={question.required ? 'true' : 'false'}>
            <legend>
              <Text accessibilityLabel={question.questionText} for={`q-${question.questionId}`} style={{marginBottom: errors[question.questionId] ? 0 : 8}}>{renderQuestionLabels(question, disabled)}</Text>
            </legend>
            <FormGroup aria-describedby="memberStoryForm" key={question.questionId} marginTop={marginDependsOn}>
            {errors[question.questionId] && <ErrorText style={{display: 'flex', alignItems: 'center'}}><MaterialIcons name="error" size={16} color="#e00000"/><span id={`q-${question.questionId}-error`} role="alert">{getErrorText(errors[question.questionId])}</span></ErrorText>}
              <RadioGroup
                nativeID={`q-${question.questionId}`}
                aria-describedby={errors[question.questionId] ? `q-${question.questionId}-error` : ''}
                error={typeof errors[question.questionId] !== 'undefined'}
                aria-invalid={errors[question.questionId] ? 'true' : 'false'}
                disabled={disabled}>
              <RadioADA>
              {question.answers && (question.answers as AnswerListChoice[]).map((value:AnswerListChoice, idx) => (
                <RadioButtonWrapper>
                  <View style={{marginBottom: idx === question.answers.length - 1 ? 0 : 12}}>
                    <RadioButton onCheck={() =>
                      handleSetValue(question.questionId, value.answerId,value.answerId)
                    }
                      selected={form[question.questionId] === value.answerId}
                      label={value.answerText}
                      testID={`member-story-radio-${value.answerId}-${props.case.caseNumber}`} />
                  </View>
                </RadioButtonWrapper>
              ))}
              </RadioADA>
              </RadioGroup>
            </FormGroup>
          </fieldset>
        } else if (question.answerType.toLowerCase() === 'string') {
          const disabled =disableDependentQuestion(question)
          return <FormGroup key={question.questionId} marginTop={marginDependsOn} disabled={disabled}>
            <Text accessibilityLabel={question.questionText} for={`q-${question.questionId}`}  style={{marginBottom: errors[question.questionId] ? 0 : 8}}>{renderQuestionLabels(question, disabled)}</Text>
            {errors[question.questionId] && <ErrorText style={{display: 'flex', alignItems: 'center'}}><MaterialIcons name="error" size={16} color="#e00000"/><span id="questionId" role="alert">{getErrorText(errors[question.questionId])}</span></ErrorText>}
            <FreeTextInput
              nativeID={`q-${question.questionId}`}
              style={TextAreaInputStyle}
              aria-invalid={errors[question.questionId] ? 'true' : 'false'}
              aria-required={question.required ? 'true' : 'false'}
              error={typeof errors[question.questionId] !== 'undefined'}
              multiline={true}
              numberOfLines={8}
              onChangeText={(text: string) =>
                setValue(question.questionId.toString(), text)
              } 
              testID={`member-story-input-${question.questionId}-${props.case.caseNumber}`}
              disabled={disabled}
              aria-describedby={`q-${question.questionId}-max-chars`}
            />
              <HintText id={`q-${question.questionId}-max-chars`}>1000 maximum characters</HintText>
          </FormGroup>
        } else if (question.answerType.toLowerCase() === 'int') {
          const disabled =disableDependentQuestion(question)
          return <FormGroup key={question.questionId} marginTop={marginDependsOn} >
            <Text accessibilityLabel={question.questionText} for={`q-${question.questionId}`}  style={{marginBottom: errors[question.questionId] ? 0 : 12}}>{renderQuestionLabels(question, disabled)}</Text>
            {errors[question.questionId] && <ErrorText style={{display: 'flex', alignItems: 'center'}}><MaterialIcons name="error" size={16} color="#e00000"/><span id="questionId" role="alert">{getErrorText(errors[question.questionId])}</span></ErrorText>}
            <NumericTextInput
              nativeID={`q-${question.questionId}`}
              style={NumberInputStyle}
              aria-invalid={errors[question.questionId] ? 'true' : 'false'}
              aria-required={question.required ? 'true' : 'false'}
              error={typeof errors[question.questionId] !== 'undefined'}
              keyboardType = 'number-pad'
              onChangeText={(text: string) =>
                // require numeric input only
                handleSetValue(question.questionId, text.replace(/[^0-9]/g, ''),question.questionId)
              } 
              value={form[`${question.questionId}-${question.questionId}`]} 
              testID={`member-story-input-${question.questionId}-${props.case.caseNumber}`}
              disabled={disabled} />
          </FormGroup>
        } else if (question.answerType.toLowerCase() === 'currency') {
          const disabled =disableDependentQuestion(question)
          const CurrencyInputStyle = {
            fontFamily: 'SourceSansPro-Regular',
            color: '#002C73',
            fontSize:16,
            width:140,
            height:16,
            padding:12,
            background:'#F5F9FB',
            borderTopRightRadius: 7,
            borderBottomRightRadius: 7,
            borderTop: disabled ? '1px solid #94989B' : '1px solid rgb(0, 44, 115)',
            borderBottom: disabled ? '1px solid #94989B' : '1px solid rgb(0, 44, 115)',
            borderRight: disabled ? '1px solid #94989B' : '1px solid rgb(0, 44, 115)'
          }
          return <FormGroup key={question.questionId} marginTop={marginDependsOn} disabled={disabled}>
            <Text accessibilityLabel={question.questionText} for={`q-${question.questionId}`}  style={{marginBottom: errors[question.questionId] ? 0 : 8}}>{renderQuestionLabels(question, disabled)}</Text>
            {errors[question.questionId] && <ErrorText style={{display: 'flex', alignItems: 'center'}}><MaterialIcons name="error" size={16} color="#e00000"/><span id="questionId" role="alert">{getErrorText(errors[question.questionId])}</span></ErrorText>}
            <CurrencyWrapper 
              aria-describedby={`q-${question.questionId}-dollar`}
              nativeID={`q-${question.questionId}`}
              disabled={disabled}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
            <CurrencyWrapperInner 
              disabled={disabled}
              style={{
                borderTop: errors[question.questionId] ? "1px solid #e00000" : disabled ? "1px solid #94989B" : "1px solid #002C73",
                borderBottom: errors[question.questionId] ? "1px solid #e00000" : disabled ? "1px solid #94989B" : "1px solid #002C73",
                borderLeft: errors[question.questionId] ? "1px solid #e00000" : disabled ? "1px solid #94989B" : "1px solid #002C73",
              }}
            >
                <MaterialIcons
                  id={`q-${question.questionId}-dollar`}
                  name="attach-money"
                  role="img"
                  accessibilityLabel="In dollars"
                  size={20} 
                  color={disabled ? '#7A858C' : "rgb(0, 44, 115)"}
                />
            </CurrencyWrapperInner>
              <NumberFormat 
                disabled={disabled}
                style={CurrencyInputStyle} 
                displayType="input" 
                value={form[`${question.questionId}-${question.questionId}`]} 
                thousandSeparator={true} 
                isNumericString={true} 
                decimalScale={2}
                onValueChange={(Texts:NumberFormatValues) =>handleSetValue(question.questionId,Texts.value,question.questionId)}
              />
            </CurrencyWrapper>
          </FormGroup>
        }
      })}

      <ButtonWrapper>
        <Button
          disabled={isSubmitting || responsePending}
          label="Submit"
          onPress={handleSubmit(handleFormSubmit)} 
          testID={`member-story-submit-${props.case.caseNumber}`} />
        {Object.keys(errors).length > 0 && 
          <ErrorText testID={`member-story-submit-error-${props.case.caseNumber}`}><span id="memberStoryForm" role="alert">Please correct the questions highlighted above and try again.</span></ErrorText>
        }
      </ButtonWrapper>

      <Modal {...modal}>
          {modal.variant === 'success-v2' ? (
            <>
              <ModalBody style={{ paddingLeft: 10, paddingRight: 10 }}>
                <View style={{ textAlign: 'left' }}>
                  <ModalText>
                    <Text style={{ fontSize: '32px' }}><Text bold={true}>Thank you for your time and effort.</Text> We understand that revisiting this experience may not have been easy, but we appreciate the work it takes to tell us your story.</Text>
                  </ModalText>
                  <View style={{ marginTop: '15px'}}>
                    <Text style={{ fontSize: '16px' }}>
                      You should expect to hear from your Naviguard advisor regarding what to expect next.
                    </Text>
                  </View>
                </View>
              </ModalBody>
              <ModalFooter style={{ justifyContent: 'baseline', marginLeft: '10px', marginRight: '10px' }}>
                  <Button label={modal.variant === 'success-v2' ? 'RETURN TO HOMEPAGE' : 'Close'} 
                        onPress={modal.onClose}
                        testID={`member-story-modal-button-${props.case.caseNumber}`} />
              </ModalFooter>
            </>
          ) : null}
      
          {modal.variant === 'error-v2' ? (
            <>
              <ModalBody>
                <ModalText>{modal.message}</ModalText>
              </ModalBody>
              <ModalFooter>
                <Button 
                  label={'Close'}  
                  onPress={modal.onClose}
                  testID={`member-story-modal-button-${props.case.caseNumber}`}
                />
              </ModalFooter>
            </>
          ): null}

          {modal.variant === 'error' || modal.variant === 'success' ? (
            <>
              <ModalBody>
                <ModalText>{modal.message}</ModalText>
              </ModalBody>
              <ModalFooter>
                <Button 
                  label={'Close'}  
                  onPress={modal.onClose}
                  testID={`member-story-modal-button-${props.case.caseNumber}`}
                />
              </ModalFooter>
            </>
          ) : null}
      </Modal>
      <UnsavedChangesWarning formState={formState}  onIgnoreChanges={()=>{setForm({});setClearForm(true)}}/> 
    </>
  )
}


