import React, { useEffect, useState } from 'react';
import { Image, View } from 'react-native';
import Layout from '../../components/layout'
import { Button, Link, Text } from '../../components'
import styled from 'styled-components/native';
import * as Linking from 'expo-linking';
import { completeInvitation } from '../../redux/invitations/actions';
import useDeviceWidth from '../../utils/useDeviceWidth';
import { advisorEmail, openAdvisorEmail } from '../../utils/email';
import { useQueryNavigator } from '../../context-api/query';
import useGoogleTagManager, {
  GTMEvent,
} from '../../utils/useGoogleTagManager'

const Wrapper = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const NotFoundWrapper = styled(View)<{sidePadding: string}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: ${props => props.sidePadding};
  padding-right: ${props => props.sidePadding};
`;
const TextWrapper = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #002C73;
`;

const LoadingText = styled(Text)`
  margin-top: 10px;
  font-size: 18px;
`;

const HeaderText = styled(Text)`
  margin-top: 25px;
  color: #002C73;
  font-size: 52px;
  font-weight: bold;
`;

const ErrorText = styled(Text)<{width: string,textAlign:string}>`
  color: #002C73;
  font-size: 32px;
  font-family: SourceSansPro-Bold;
  margin-bottom: 15px;
  width: ${props => props.width};
  text-align: ${props => props.textAlign};
`;


const HintText = styled(Text)<{fontStyle: string,marginTop:string}>`
  margin-top: ${props => props.marginTop};
  font-size: 16px;
  font-style: ${props => props.fontStyle};
  color: #002C73;
`;
interface ErrorSetting{
  visible: boolean;
  button: string;
  onAction: ()=>void;
  retry?:boolean;
  notFound?:ErrorNotFound;
}
interface ErrorNotFound{
  message:string;
  detail:string;
  bold?: string;
  invite?: boolean;
}
export default function Verification({ navigation }) {
  const navigator = useQueryNavigator(navigation)
  const responsiveStyle = useDeviceWidth()
  const [error, setError] = useState<ErrorSetting>({
    visible: false,
    button: undefined,
    onAction: undefined
  })

  const gtm = useGoogleTagManager()

  useEffect(() => {
    const parseUrl = async () => {
      try {
        const url = await Linking.parseInitialURLAsync()

        const inviteId = 'inviteId' in url.queryParams ? url.queryParams.inviteId : ''
        if (!inviteId) {
          setError({
            visible: true,
            button: 'Go to Dashboard',
            onAction: handleBack
          })
        } else {
          await doCompleteInvitation(inviteId)
        }
      } catch (e) {
        console.error(e)

        setError({
          visible: true,
          button: 'Go to Dashboard',
          onAction: handleBack
        })
      }
    }

    parseUrl()
  }, [])

  const handleBack = () => {
    navigator.navigate('Home')
  }

  const handleEmail = () => {
    openAdvisorEmail()
  }

  const handlePhonePress=()=>{
     Linking.openURL(`tel:8666228015`)
 }

  const doCompleteInvitation = async (inviteId: string) => {
    const payload = await completeInvitation(inviteId)
    payload.payload.then(() => {
      navigator.navigate('Home')
    }).catch((e) => {
      if (e.response && e.response.status === 404){
        let setting:ErrorSetting={
          visible: true,
          button: undefined,
          onAction:undefined
        }
        switch (e.response.data) {
          case "The invitation has already been accepted":
            setting.notFound={
              message:"Oops! This Case Invite Has Already Been Accepted.",
              bold: "Do you share an email account? If so, please check with them to see if an account was already created for this case.",
              invite: true,
              detail:"If you were expecting to see your case details, please contact a Naviguard advisor for assistance at "
           }
            break;
          case "The invitation has expired":
            setting.notFound={
              message:"Oh no! It seems that your invitation has expired. Please confirm that you used the most recent invitation email. If you have, reply to the invitation to request assistance.",
              detail:"Please check your inbox and make sure that you have clicked the link in the most recent email from us. Links from older emails will expire. If you continue to have problems, please contact us for help at ",
           }
            break;
          default:
            setting.notFound={
              message:"Our apologies. We are experiencing a system issue and are unable to display your information. Please try again. If you continue to get this error, please wait a few minutes and try again.",
              detail:"Please contact us for help at ",
           }
            break;
        }

        const charLimit = str => str.substring(0, 100);

        gtm.pushEvent(GTMEvent.InvitationExpireError, {
          'event': 'doCompleteInvitation',
          'errorType': 'Invite Error',
          'errorMessage' : charLimit(setting.notFound.message)
        })

        setError(setting);
      }
      else{
        setError({
          visible: true,
          button: 'RETRY',
          retry:true,
          onAction: handleRetry.bind(null, inviteId)
        })
      }
    })
  }

  const handleRetry = async (inviteId: string) => {
    setError({
      ...error,
      visible: false,
    })

    await doCompleteInvitation(inviteId)
  }

  const styles = responsiveStyle === 'largeScreen' ? {
    errorText: {
      width: '40%'
    },
    NotFoundWrapper:{
      sidePadding:'20%'
    }
  } : {
    errorText: {
      width: '100%'
    },
    NotFoundWrapper:{
      sidePadding:'0%'
    }
  }

  const GoogleTagHandler = () => {
    if(error.visible && !error.notFound) {
        const charLimit = str => str.substring(0, 100);
        const successFailed = `We were unable to display your case in your account. Please try again in a few minutes. If you continue to have problems, reply to the invitation email you received to request assistance.`;

        gtm.pushEvent(GTMEvent.EsignError, {
          'event': 'saveData - Negotiation/Options',
          'errorType': 'Save Data Failed',
          'errorMessage' : charLimit(successFailed)
      })
    }
  }

  useEffect(() => {
    GoogleTagHandler();
  }, [error])

  return (
    <Layout
    mainSectionStyle={{
      flexBasis: '100%',
      width: '100%'
    }}>
        <>
        {
          !error.visible &&
          <Wrapper>
            <Image
              style={{
                width: '118px',
                height: '115px'
              }}
              source={require('../../assets/images/clock.png')} />
            <HeaderText>One moment</HeaderText>
            <TextWrapper>
              <LoadingText>Your case details are loading...</LoadingText>
            </TextWrapper>
          </Wrapper>
        }
        {
          error.visible && !error.notFound &&
          <Wrapper>
             <ErrorText width={styles.errorText.width} textAlign="center">We were unable to verify your case. {error.retry?"Would you like to try again?":""}</ErrorText>
             <Button label={error.button} onPress={error.onAction} />
            <HintText marginTop="50px" fontStyle="italic">If you are having trouble verifying your case, please contact us at&nbsp;
            <Link onPress={handleEmail}>{advisorEmail}</Link>
            .</HintText>
          </Wrapper>
        }
        {error.visible && error.notFound &&
          <NotFoundWrapper sidePadding={styles.NotFoundWrapper.sidePadding}>
            <>
              <ErrorText>{error.notFound.message}</ErrorText>
              {error.notFound.invite ? (
                <>
                  <HintText style={{fontWeight:'bold'}}>{error.notFound.bold} </HintText>
                  <HintText>If you were expecting to see your case details, please contact Naviguard<HintText>&#174;</HintText> advisor for assistance at <Link onPress={handleEmail}>{advisorEmail}</Link> or <Link onPress={handlePhonePress}>(866) 622-8015</Link></HintText>
                </> 
              ): (
                <HintText marginTop="20px">
                    { error.notFound.detail } <Link onPress={handleEmail}>{advisorEmail}</Link>.
                </HintText>
              )}
            </>
          </NotFoundWrapper>
        }
      </>
    </Layout>
  )
}
