import React, { useEffect } from 'react';
import { View , TextInput} from 'react-native';
import { Text } from '../../../components/index'
import useScreenFormat from '../../../components/form/useScreenFormat'
import AppContainer from '../../../components/appcontainer';
import StepTracker from './StepTracker';
import styled from 'styled-components/native'
import { useNavigation, useRoute } from '@react-navigation/native';

export interface LayoutProps{
    title:string
    isMobile:boolean,
    StepTrackerActiveStepNumber?:number
    children:JSX.Element|JSX.Element[]
    contentMarginTop?:string|number
}


export default function Layout(props:LayoutProps) {
  const styles= useScreenFormat();
  const navigation = useNavigation();
  const route =useRoute();
  
  useEffect(()=>{
    if(!route.params || !route.params["caseId"])
     navigation.navigate("Home")
   })
   
  return (
  <AppContainer >
  <Text size="52px" bold={true}>{props.title}</Text>
    <View style={[styles.caseCard,{marginTop:32}]}>
      <StepTracker isMobile={props.isMobile} activeStepNumber={props.StepTrackerActiveStepNumber}></StepTracker>
      <View style={{marginTop:props.contentMarginTop || props.contentMarginTop===0?props.contentMarginTop:40}}>
       {props.children}
      </View>
    </View>
  </AppContainer>
 )
}

export const ViewAdditionalInfo = styled.View`
  padding:28px;
  background-color:${(props) => props.backgroundColor?props.backgroundColor:'#ebeeff80'};
  width:${(props) => props.isMobile?'calc(100% + 64px)':'100%'};
  margin-left:${(props) => props.isMobile?'-32px':'0'};
  border-color: ${(props) => props.borderColor?props.borderColor:''};
  border-width: ${(props) => props.borderWidth?props.borderWidth:'0'};
  padding-top:${(props) => props.paddingTop?props.paddingTop:'28px'};
`
export const LayoutDivider = styled(View)`
    display:flex;
    flex-direction:${props => props.isMobile? props.reverse? 'column-reverse':'column' : props.reverse? 'row-reverse' :'row'};
    margin-top:${props => props.marginTop ? props.marginTop : '0'};
`
export const MainSection = styled(View)`
    width:${props => props.isMobile ? '100%' : '70%'};
    padding-right:${props => props.isMobile ? '0px' : '64px'};
`
export const InfoSection = styled(View)`
    width:${(props) => props.isMobile?'100%':'30%'}
    margin-top:${(props) => props.isMobile?props.marginTop?
      props.marginTop:'40px'
      :props.marginTop?props.marginTop:'0px'}
`
export const NavigationSection = styled(View)`
  margin-top:57px
  flex-direction:${props => props.flexDirection ?  props.flexDirection : 'row'};
`
export const InputText = styled(TextInput)`
  font-family: 'SourceSansPro-Regular';
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #999999;
  ${props => props.error ?`border: 2px solid #e00000` : ''}
  border-radius: 2px;
  height: ${props => props.multiline?'200px' : '44px'};
  width: 100%;
  max-width:${props => props.maxWidth? props.maxWidth : '100%'};
  min-width:${props => props.minWidth? props.minWidth : '100%'};
  width:${props => props.width? props.width : '100%'};
  padding: 10px;
  font-size: 16px;
  ${props => props.editable===false?`background-color: rgba(128, 128, 128,.1)`:''}

`
export const TextSection = styled(View)`
  flex-wrap: wrap;
  flex-direction:${props => props.isMobile ? 'column' : 'row'};
`
export const TextRowGroup = styled(View)`
  margin-top:${props => props.marginTop ? props.marginTop  : '0'};
  width:${props => props.isMobile ? '100%' : props.width? props.width: '100%'};
  margin-left:${props=>props.marginLeft? props.marginLeft:'0'};
`

export  const InfoDivider = styled.View`
  height: 1px
  width:${(props) => props.isMobile?'calc(100% + 64px)':'100%'}
  margin-left:${(props) => props.isMobile?'-32px':'0'}
  background-color:#EBEEFF
`
export const ErrorText = styled.Text`
    padding-left:${(props) => (props.paddingLeft? props.paddingLeft : '0px')};
    margin-top: 6px;
    color: #e00000;
    font-family: SourceSansPro-Regular;
    font-size: 12px;
    font-style: italic;
`
export const ErrorView = styled.View`
padding: ${(props) => (props.hasErrors ? '5px' : '0px')};
border-color: ${(props) => (props.hasErrors ? '#E00000' : '')};
border-width: ${(props) => (props.hasErrors ? '2px' : '0px')};
margin-top: 16px
`

export const FormGroup = styled(View)<{marginTop: number}>`
  display: flex;
  margin-top: ${props => props.marginTop}px;
  ${props => props.disabled ?'pointer-events:none; opacity: 0.5;' : ''}
`;