import { useNavigation,useRoute } from "@react-navigation/native";
import React from "react";
import { View } from "react-native";
import StepItem, { StepStatus } from "./StepItem";
import {useSelector } from 'react-redux'
import { ApplicationState } from "../../../redux/rootReducer";

export interface StepTrackerProps{
    isMobile:boolean,
    activeStepNumber?:number
}


export default function StepTracker(props:StepTrackerProps){
    const navigation = useNavigation();
    const route =useRoute();
    const caseId:string=route.params && route.params["caseId"]?route.params["caseId"]:""
    const storyCompleted:boolean= useSelector((state: ApplicationState) =>state.negotiationStory && state.negotiationStory.data && state.negotiationStory.data.find(f=>f.caseId===caseId)?  true:false)
    const numbersCompleted:boolean= useSelector((state: ApplicationState) =>state.negotiationNumbers && state.negotiationNumbers.data && state.negotiationNumbers.data.find(f=>f.caseId===caseId)?  true:false)
    const optionsCompleted:boolean= useSelector((state: ApplicationState) =>state.negotiationOptions && state.negotiationOptions.data && state.negotiationOptions.data.find(f=>f.caseId===caseId)?  true:false)
    
    const calculateStepStatus=(stepNumber:number)=>{
        
        let stepStatus=StepStatus.NotStarted

        switch(stepNumber){
            case 1:
                if(storyCompleted){
                    stepStatus =StepStatus.Completed
                }
                break;
            case 2:
                if(numbersCompleted){
                    stepStatus =StepStatus.Completed
                }
                break
            case 3:
                if(optionsCompleted){
                    stepStatus =StepStatus.Completed
                }
                break
            case 4:
                if(storyCompleted && numbersCompleted && optionsCompleted){
                    stepStatus =StepStatus.Completed
                }
                break
            default:
                stepStatus =StepStatus.NotStarted
        }
        if (!props.activeStepNumber|| (stepNumber >=props.activeStepNumber && props.activeStepNumber!=4)){
            stepStatus=StepStatus.NotStarted
            if(stepNumber ===props.activeStepNumber){
                stepStatus=StepStatus.InProgress
            }
        }
        
        return stepStatus
    }
    
    const handStepItemPress=(stepNumber:number)=>{
        let routeName=undefined

        switch(stepNumber){
            case 1:
                routeName="NegotiationStory"
                break;
            case 2:
                routeName="NegotiationNumbers"
                break;
            case 3:
                routeName="NegotiationOptions"
                break;
            case 4:
                routeName="NegotiationPlan"
                break;
            default:
                routeName=undefined
        }
        if(routeName){
            navigation.navigate(routeName,route.params)
        }
    }

    return(
        <>
            <View style={{display:'flex', flexDirection:'row',alignSelf:'center'}}>
                <StepItem status={calculateStepStatus(1)} stepNumber={1} isFinalStep={false} title="Know Your Story" isMobile={props.isMobile}  onPress={()=>handStepItemPress(1)}></StepItem>
                <StepItem status={calculateStepStatus(2)} stepNumber={2} isFinalStep={false}  title="Know Your Numbers"  isMobile={props.isMobile} onPress={()=>handStepItemPress(2)}></StepItem>
                <StepItem status={calculateStepStatus(3)} stepNumber={3} isFinalStep={false}  title="Know Your Options"  isMobile={props.isMobile} onPress={()=>handStepItemPress(3)}></StepItem>
                <StepItem status={calculateStepStatus(4)} stepNumber={4} isFinalStep={true}  title="Your Negotiation Plan"  isMobile={props.isMobile} onPress={()=>handStepItemPress(4)}></StepItem>
                
            </View>
            <View style={{height: 1,marginTop:props.isMobile?18:48,width:'calc(100% + 64px)',marginLeft:-32, backgroundColor:'rgba(42, 46, 114, 0.2)'}}/>
        </>
    )
}

