import { Platform } from "react-native"

/**
 * For web platforms, returns the user agent string of the current browser.
 * 
 * @return The user agent string, or undefined if not on web.
 */
export const getUserAgent = () => {
    const web = Platform.OS === 'web'
    if (!web) {
        return undefined
    }

    return window.navigator.userAgent.toLowerCase()
}

/**
 * Detects if the current user agent is an iOS Safari web browser.
 * 
 * @return true if so, false if not.
 */
export const isIosSafari = () => {
    // abuse the maxTouchPoints property to test for safari on ios 13 and above
    // this detection stuff is really annoying
    // gotcha: apple silicon devices still identify as MacIntel, but that may change in the future
    //cx: this function is missing the dectection of Safari browser (as opposed to say chrome.) I am not sure if that was intended.
    return isIos()
}

export const isIos = () => {
    return /ipad|iphone|ipod/.test(navigator.platform.toLowerCase())
        || (navigator.platform.toLowerCase() === 'macintel' && navigator.maxTouchPoints > 1)
}
