import React from 'react'
import styled from 'styled-components/native';
import useDeviceWidth from '../utils/useDeviceWidth'

const Text = styled.Text`
  color: #002C73;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
`
const Heading = styled.Text`
  color: #002C73;
  font-family: SourceSansPro-Bold;
  font-size: 14px;
`

const getXLResponsiveFont = (screen: string) => {
  return screen === 'largeScreen' ? 52 : 32
}
const getXSResponsiveFont = (screen) => {
  return screen === 'largeScreen' ? 18 : 16
}

const getMResponsiveFont = (screen) => {
  return screen === 'largeScreen' ? 24 : 20
}

interface TextProps {
  for?: string;
  size?: string | number;
  style?: any;
  nativeProps?: any;
  children: any;
  bold?: boolean;
  id?:string;
  testID?: string;
  accessibilityRole?: string;
  accessibilityLabel?: string;
  ariaLevel?: string;
  ariaDescribedby?: string;
}

export default function TextComponent(props: TextProps) {
  const deviceWidth = useDeviceWidth()
  const { size, id } = props
  let responsiveStyle = props.style && props.style[deviceWidth] ? props.style[deviceWidth] : props.style
  let content = props.children

  switch (props.size) {
    case "xl":
      return <Heading nativeID={id} id={id} aria-describedby={props.ariaDescribedby} aria-labelledby={props.for} testID={props.testID} {...props.nativeProps} style={[{ fontSize: getXLResponsiveFont(deviceWidth) }, props.style, {transform: 'unset'}]} accessibilityRole={props.accessibilityRole} accessibilityLabel={props.accessibilityLabel} aria-level={props.ariaLevel}>{content}</Heading>
    case "l":
      return <Heading nativeID={id} id={id} aria-describedby={props.ariaDescribedby} aria-labelledby={props.for} testID={props.testID} {...props.nativeProps} style={[{ fontSize: 32 }, responsiveStyle, {transform: 'unset'}]} accessibilityRole={props.accessibilityRole} accessibilityLabel={props.accessibilityLabel} aria-level={props.ariaLevel}>{content}</Heading>
    case "m":
      return <Heading nativeID={id} id={id} aria-describedby={props.ariaDescribedby} aria-labelledby={props.for} testID={props.testID} {...props.nativeProps} style={[{ fontSize: 24 }, getMResponsiveFont(deviceWidth), props.style, {transform: 'unset'}]} accessibilityRole={props.accessibilityRole} accessibilityLabel={props.accessibilityLabel} aria-level={props.ariaLevel}>{content}</Heading>
    case "s":
      return <Heading nativeID={id} id={id} aria-describedby={props.ariaDescribedby} aria-labelledby={props.for} testID={props.testID} {...props.nativeProps} style={[{ fontSize: 20 }, responsiveStyle, {transform: 'unset'}]} accessibilityRole={props.accessibilityRole} accessibilityLabel={props.accessibilityLabel} aria-level={props.ariaLevel}>{content}</Heading>
    case "xs":
      return <Heading nativeID={id} id={id} aria-describedby={props.ariaDescribedby} aria-labelledby={props.for} testID={props.testID} {...props.nativeProps} style={[{ fontSize: getXSResponsiveFont(deviceWidth) }, props.style, {transform: 'unset'}]} accessibilityRole={props.accessibilityRole} accessibilityLabel={props.accessibilityLabel} aria-level={props.ariaLevel}>{content}</Heading>
    case "xxs":
      return <Heading nativeID={id} id={id} aria-describedby={props.ariaDescribedby} aria-labelledby={props.for} testID={props.testID} {...props.nativeProps} style={[{ fontSize: 16 }, responsiveStyle, {transform: 'unset'}]} accessibilityRole={props.accessibilityRole} accessibilityLabel={props.accessibilityLabel} aria-level={props.ariaLevel}>{content}</Heading>
    case "title":
      return <Heading nativeID={id} id={id} aria-describedby={props.ariaDescribedby} aria-labelledby={props.for} testID={props.testID} {...props.nativeProps} style={[{ fontSize: 14 }, responsiveStyle, {transform: 'unset'}]} accessibilityRole={props.accessibilityRole} accessibilityLabel={props.accessibilityLabel} aria-level={props.ariaLevel}>{content}</Heading>
    default:
      if(props.bold) {
        return <Heading nativeID={id} id={id} aria-describedby={props.ariaDescribedby} aria-labelledby={props.for} label-for={props.for} htmlFor={props.for} testID={props.testID} {...props.nativeProps} style={[{ fontSize: size }, responsiveStyle, {transform: 'unset'}]} accessibilityRole={props.accessibilityRole} accessibilityLabel={props.accessibilityLabel} aria-level={props.ariaLevel}>{content}</Heading>
      } else {
        return <Text nativeID={id} id={id} aria-describedby={props.ariaDescribedby} aria-labelledby={props.for} label-for={props.for} htmlFor={props.for} testID={props.testID} {...props.nativeProps} style={[{ fontSize: size }, responsiveStyle, {transform: 'unset'}]} accessibilityRole={props.accessibilityRole} accessibilityLabel={props.accessibilityLabel} aria-level={props.ariaLevel}>{content}</Text>
      }
  }
  
}