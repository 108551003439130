import React, {  useCallback, useEffect, useState} from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../redux/rootReducer';
import Layout from '../../components/layout'
import { Button } from '../../components'
import useDeviceWidth from '../../utils/useDeviceWidth'
import { AxiosError, AxiosResponse } from 'axios';
import { fetchMemberStory, fetchMemberStoryTemplate } from '../../redux/memberstory/actions';
import { MemberStory, MemberStoryTemplate } from '../../redux/memberstory/types';
import FormEditor from './FormEditor';
import FormViewer from './FormViewer';
import Modal, { ModalBody, ModalFooter, ModalText } from '../../components/modal';
import SkeletonQuestion from './SkeletonQuestion';
import AdvisorPanel from '../../components/advisorpanel';
import { useFocusEffect } from '@react-navigation/native';
import useGoogleTagManager, { GTMEvent } from '../../utils/useGoogleTagManager';
import { updateTask, fetchCases } from '../../redux/cases/actions';
import { Task } from '../../redux/cases/types';
import { useQueryNavigator } from '../../context-api/query';
import Skeleton from '../../components/Skeleton';
import { DisplayBanner } from '../../redux/providerBanner/actions';

export default function YourExperienceDetails({ navigation, route }) {
  const navigator = useQueryNavigator(navigation)
  const gtm = useGoogleTagManager()
  const dispatch = useDispatch()
  const responsiveStyle = useDeviceWidth()
  const [caseId, setCaseId] = useState(undefined)
  const [memberStory, setMemberStory] = useState<MemberStory>(undefined)
  const [memberStoryTemplate, setMemberStoryTemplate] = useState<MemberStoryTemplate>(undefined)
  const [mode, setMode] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [dataLoading, setDataLoading]=useState(false)

  const paramCaseId=route.params?.caseId
  const paramMode=route.params?.mode

  let selectedCase = useSelector((state: ApplicationState) => state.cases.data.find(c => c.caseId === caseId));
 
  useEffect(() => {
    if(!selectedCase) {
      dispatch(fetchCases());
    }
  }, [])


  useEffect(() => {
    setError(undefined)
    setCaseId(paramCaseId)
    setMode(paramMode)

    if (paramMode === 'view' && (!memberStory || memberStory.caseId !== paramCaseId)) {
      const fetchMemberStoryData = async () => {

        setDataLoading(true)
        const request = await fetchMemberStory(paramCaseId, 1)

        request.payload
          .then((response: AxiosResponse<MemberStory>) => setMemberStory(response.data))
          .catch((e: AxiosError) => {
            console.error(e)
            
            setMemberStory(undefined)

            if (e.response && e.response.headers['content-type']?.indexOf('text/plain') > -1) {
              setError(e.response.data)
            } else {
              const charLimit = str => str.substring(0, 100);
              const errorText = 'We were unable to display the results of your member story for this case. Please try again in a few minutes.'  
            
              gtm.pushEvent(GTMEvent.MemberStoryError, {
                  'event': 'fetchMemberStoryData',
                  'errorType': 'Unable to display member story',
                  'errorMessage' : charLimit(errorText)
              })

              setError(errorText)
            }
          })
          .finally(()=>setDataLoading(false))
      }

      fetchMemberStoryData()
  } else if (paramMode === 'edit' && (!memberStoryTemplate || memberStoryTemplate.caseId !== paramCaseId)) {
    const fetchMemberStoryTemplateData = async () => {

      setDataLoading(true)
      const request = await fetchMemberStoryTemplate(selectedCase)

      request.payload
        .then((response: AxiosResponse<MemberStoryTemplate>) => setMemberStoryTemplate(response.data))
        .catch((e: AxiosError) => {
          console.error(e)
          
          setMemberStoryTemplate(undefined)

          if (e.response && e.response.headers['content-type']?.indexOf('text/plain') > -1) {
            setError(e.response.data)
          } else {
            const charLimit = str => str.substring(0, 100);
            const errorText = 'We were unable to display the results of your member story for this case. Please try again in a few minutes.'  
          
            gtm.pushEvent(GTMEvent.MemberStoryError, {
                'event': 'fetchMemberStoryTemplateData',
                'errorType': 'Unable to display member story',
                'errorMessage' : charLimit(errorText)
            })

            setError(errorText)
          }
        })
        .finally(()=>setDataLoading(false))
    }

    fetchMemberStoryTemplateData()
  }
  }, [paramCaseId, paramMode])

  useFocusEffect(
    useCallback(() => {
      if (selectedCase) {
        gtm.pushPageLoadWithCases([selectedCase])
      }
    }, [selectedCase])
  )

  let styles: any = {}
  if (responsiveStyle === 'largeScreen' || responsiveStyle === 'tabletDevice') {
    let ccflex = responsiveStyle === 'largeScreen' ? '70%' : '60%'
    styles = {
      caseCard: {
        backgroundColor: "#FFF",
        flexBasis: ccflex,
        borderRadius: 3,
        borderWidth: 0,
        shadowColor: "rgba(244, 165, 135, 0.2)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 12.22,
        padding: 32
      }
    }
  } else {
    styles = {
      caseCard: {
        backgroundColor: "#FFF",
        flexBasis: "100%",
        borderRadius: 3,
        borderWidth: 0,
        shadowColor: "rgba(244, 165, 135, 0.2)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 12.22,
        padding: 32
      }
    }
  }

  const handleCloseErrorModal = () => {
    setError(undefined)
    navigator.navigate('YourExperience')
  }

  const handleStoryUpdated = () => {
    gtm.pushEvent(GTMEvent.SubmitStory, {
      caseNumber: selectedCase.caseNumber,
      patientID: selectedCase.patientId,
      userIsPatient: selectedCase.patientInd ? 'Yes' : 'No'
    })

    // mark the member story task as completed
    dispatch(updateTask(
      selectedCase.caseId, 
      selectedCase.tasks.find((t: Task) => t.taskName === 'Member Story').taskId, 
      true))
      if(selectedCase.caseType==='MNRP'){
          dispatch(DisplayBanner(true))
      }
    navigator.navigate('Home')
  }

  const handleBack = () => {
    navigator.navigate('YourExperience')
  }

  useEffect(()=>{
   if(!route.params)
    navigation.navigate("YourExperience")
  }
  )
  return (
    <Layout
      testID={`member-story-${mode}-${selectedCase?.caseNumber}`}
      introHeading={
      <View style={{flexDirection:'row',alignItems:'center'}}>
        <span>Case #</span>{selectedCase?<span>{selectedCase.caseNumber}</span>:
        <View style={{height:40,width:250}}>
        <Skeleton/>
      </View>
      }
      </View>
    }
      mainSectionWrapper={{ paddingTop: 44 }}
      introSectionWrapper={{paddingBottom: 0}}
      contentWrapper={{  marginTop:  ['largeScreen', 'tabletDevice'].includes(responsiveStyle) ? 44 : 19}}
      mainSectionStyle={styles.caseCard}
      navigation={navigation}
      breadcrumbs={[{
        label: 'Home',
        route: 'Home'
      }, {
        label: 'Your Experience',
        route: 'YourExperience'
      }, {
        label: `${selectedCase?`Case #${selectedCase.caseNumber}`:''}`,
      }]}
    >
      {selectedCase && (
        <View>
          {
            mode === 'edit' && (!memberStoryTemplate || dataLoading) && [...Array(10).keys() as any].map((i: number) => <SkeletonQuestion key={`skeleton-question-${i}`}/>)
          }
          {
            mode === 'edit'  && memberStoryTemplate && !dataLoading &&
            <FormEditor case={selectedCase}
              questions={memberStoryTemplate.template}
              version={memberStoryTemplate.questionSetVersion}
              onUpdateComplete={handleStoryUpdated} />
          }
          {
            mode === 'view' && (!memberStory || dataLoading) && [...Array(10).keys() as any].map((i: number) => <SkeletonQuestion key={`skeleton-question-${i}`}/>)
          }
          {
            mode === 'view' && memberStory && !dataLoading &&
            <FormViewer 
              memberStory={memberStory}
              onReturn={handleBack} />
          }
        </View>
        
      )
      }
      {selectedCase && <><AdvisorPanel cases={[selectedCase]} />
        <Modal
          visible={error !== undefined}
          variant='error'
          onClose={handleCloseErrorModal}>
          <ModalBody>
            <ModalText>{error}</ModalText>
          </ModalBody>
          <ModalFooter>
            <Button onPress={handleCloseErrorModal} label='Close' />
          </ModalFooter>
        </Modal></>
      }
    </Layout>
    
  )
}
