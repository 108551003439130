import React from 'react';
import { Text } from '../../../components/index'
import { View ,StyleSheet} from 'react-native';
import styled from 'styled-components/native'
import QuestionTooltip from './QuestionTooltip';
import { Numbers as NegotiationNumbers  } from '../../../redux/negotiation/numbers/types';
import { ApplicationState } from '../../../redux/rootReducer';
import {useSelector } from 'react-redux'
import FormatCurrency from '../../../utils/FormatCurrency';

const styles = StyleSheet.create({
  mainPadding:{
      paddingRight:30
  }
})

interface YourNumbersProps{
  caseId:string  
  isMobile:boolean
  defaultNumbers?:NegotiationNumbers

}

export default function YourNumbers(props:YourNumbersProps){
  const numbers:NegotiationNumbers= useSelector((state: ApplicationState) =>
                    state.negotiationNumbers && state.negotiationNumbers.data && state.negotiationNumbers.data.find(f=>f.caseId===props.caseId)?  state.negotiationNumbers.data.find(f=>f.caseId===props.caseId):
                    props.defaultNumbers?props.defaultNumbers:{})
    return(
        <>
        <CostSharedSection isMobile={props.isMobile}>
        <Text bold={true} size={24}>Your Numbers</Text>
        <CostSharedDivider/>
        <CostSharedLineItem>
          <Text bold={true} size={16} style={{lineHeight:40}}>Billed:</Text>
          <View style={{flexDirection:"row"}}>
            <Text size={32}>{FormatCurrency(numbers.billed)}</Text>
            <View accessible={true} accessibilityLabel={'Billed Tooltip'}>
              <QuestionTooltip  placement="left" content={"The amount your doctor (your provider) is charging for their service. This is what the provider expects to be paid by you and your insurance company. You might also see \"amount claimed\"."} marginLeft={15} lineHeight={40}/>
            </View>
           </View>
         
        </CostSharedLineItem>
        <CostSharedDivider/>
        <CostSharedLineItem>
          <Text bold={true} size={16} style={{lineHeight:40}}>Insurance Paid:</Text>
          <View style={{flexDirection:"row"}}>
            <Text size={32} >-{FormatCurrency(numbers.insurancePaid)}</Text>
            <View accessible={true} accessibilityLabel={'Insurance Paid Tooltip'}>
              <QuestionTooltip placement="left" content="The amount your insurance company agreed to pay a provider for a specific service. For out-of-network situations, if the allowed amount is less than the charged amount, the patient may need to pay the balance." marginLeft={15} lineHeight={40}/>
            </View>
          </View>
        </CostSharedLineItem>
        <CostSharedDivider/>
        <CostSharedLineItem>
          <View style={{width:120}}>
            <Text  size={16} style={{fontStyle:'italic',textAlign:'right'}}>Deductible</Text>
          </View>
          <Text size={16} bold={true} style={styles.mainPadding}>{FormatCurrency(numbers.deductible)}</Text>
        </CostSharedLineItem>
        <CostSharedLineItem marginTop="20px">
          <View style={{width:120}}>
            <Text  size={16} style={{fontStyle:'italic',textAlign:'right'}}>Co-Pay</Text>
          </View>
          <Text size={16} bold={true} style={styles.mainPadding} >{FormatCurrency(numbers.copay)}</Text>
        </CostSharedLineItem>
        <CostSharedLineItem  marginTop="20px">
          <View style={{width:120}}>
            <Text  size={16} style={{fontStyle:'italic',textAlign:'right'}}>Co-Insurance</Text>
          </View>
          <Text size={16} bold={true} style={styles.mainPadding}>{FormatCurrency(numbers.coinsurance)}</Text>
        </CostSharedLineItem>
        <View style={{height: 1,width:'100%', backgroundColor:'#002C73',marginTop:20}}/>
        <CostSharedLineItem marginTop="20px">
          <View style={{width:120}}>
            <Text bold={true} size={16} style={{lineHeight:40}}>Your Cost Share:</Text>
           </View>
           <View style={{flexDirection:"row"}}>
            <Text size={32}>-{FormatCurrency(numbers.deductible + numbers.copay + numbers.coinsurance )}</Text>
            <View accessible={true} accessibilityLabel={'Your Cost Share Tooltip'}>
              <QuestionTooltip  placement="left" content="Cost share is the amount of your deductible plus the amount of your co-pay/co-insurance." marginLeft={15} lineHeight={40}/>
            </View>
          </View>
        </CostSharedLineItem>
      </CostSharedSection>
      <NonCoveredSection isMobile={props.isMobile}>
        <CostSharedLineItem style={{alignItems: 'center'}}>
          <Text bold={true} size={24} style={{color:'#FFFFFF'}}>Non-Covered Amount:</Text>
          <Text  bold={true}  size={32} style={{paddingRight:30,color:'#FFFFFF'}} >{FormatCurrency(numbers.nonCoveredAmount)}</Text>
        </CostSharedLineItem>
      </NonCoveredSection>
    </>
    )
}

const CostSharedSection = styled.View`
border-width:2px
border-color:#EBEEFF80
padding:28px
width:${(props) => props.isMobile?'calc(100% + 64px)':'100%'}
margin-left:${(props) => props.isMobile?'-32px':'0'}
`
const CostSharedDivider = styled.View`
height: 2px
width:calc(100% + 56px)
margin-left:-28px
background-color:#EBEEFF80
margin-top:20px
margin-bottom:20px
`
const CostSharedLineItem = styled.View`
flex-direction:row
justify-content:space-between
margin-top:${(props) => props.marginTop?props.marginTop:'0'}
`
const NonCoveredSection = styled.View`
background-color:#002C73
padding:28px
width:${(props) => props.isMobile?'calc(100% + 64px)':'100%'}
margin-left:${(props) => props.isMobile?'-32px':'0'}
`