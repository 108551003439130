import React from "react";
import { View, StyleSheet,Image,TouchableOpacity } from "react-native";
import Text from '../../../components/TextComponent' 

export enum StepStatus {
    NotStarted = 0,
    InProgress=1,
    Completed=2

}
export interface StepItemProps{
    status:  StepStatus,
    title: string,
    stepNumber: number,
    isMobile:boolean,
    isFinalStep:boolean,
    onPress:()=>void
}

export default function StepItem(props:StepItemProps){
    let styStatus=undefined
    
    switch (props.status) {
        case StepStatus.NotStarted:
            styStatus =styleStatus.notStarted
            break;
        case StepStatus.InProgress:
            styStatus =styleStatus.inProgress
            break;
        case StepStatus.Completed:
            styStatus =styleStatus.complete
            break;
        default:
            styStatus =undefined
            break;
    }
    return(
    <View style={{display:'flex', flexDirection:'column',alignItems:'center'}}>
        <View style={{display:'flex', flexDirection:'row'}}>
            <View style={[styleConnector.common, props.isMobile? styleConnector.mobile:styleConnector.desktop, props.stepNumber>1? styleConnector.visible :styleConnector.hidden]}/>
               <TouchableOpacity disabled={props.status===StepStatus.NotStarted} onPress={props.onPress} >
                    <View style={{alignItems:'center',width: props.isMobile?50:72}}>
                        {props.status===StepStatus.Completed && !props.isFinalStep &&
                            <Image 
                                accessible={true}
                                accessibilityLabel={`Step ${props.stepNumber} - ${props.title} Completed`}
                                style={styStatus} 
                                source={require('../../../assets/images/check-icon-step.svg')} 
                            />
                        }
                        {props.status===StepStatus.Completed && props.isFinalStep &&
                            <Image 
                                accessible={true}
                                accessibilityLabel={`Step ${props.stepNumber} - ${props.title} Final Step`}
                                style={styStatus} 
                                source={require('../../../assets/images/check-icon-step-final.svg')} 
                            />
                        }
                        {props.status!==StepStatus.Completed &&
                                <View 
                                    accessible={true}
                                    accessibilityLabel={`Step ${props.stepNumber} - ${props.title}`}
                                    style={[styleStatus.common,styStatus,{paddingTop:2}]}
                                >
                                    <Text size={16}>{props.stepNumber}</Text>
                                </View>
                        }
                    </View>
                </TouchableOpacity>
            <View style={[styleConnector.common, props.isMobile? styleConnector.mobile:styleConnector.desktop, props.isFinalStep? styleConnector.hidden:styleConnector.visible ]}/>
        </View>
        <Text bold={true} size={14} style={{textAlign:'center',width: props.isMobile?80:150 }}>{props.title}</Text>
    </View>
    )
}

const styleStatus = StyleSheet.create({
    common:{
        width: 32,
        height: 32,
        borderRadius: 16,
        borderStyle:'solid',
        borderWidth:3,
        alignItems:'center'
      
    },
    notStarted: {
        borderColor:'#ebeeff'
    },
    inProgress: {
        borderColor:'#f4a587'
    },
    complete: { 
        width:32,
        height: 32
    }
        
})
const styleConnector = StyleSheet.create({
    common:{
        alignSelf:'center',
        minHeight: 3,
        width:30
    },
    mobile:{
        width:15
    },
    desktop:{
        width:50
    },
    visible:{
        backgroundColor:'#ebeeff'
    },
    hidden:{
        backgroundColor:'transparent'
    }
})




