import React from "react";
import { View, TouchableOpacity } from 'react-native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import Text from '../TextComponent'
import { Hoverable } from 'react-native-web-hover';
import styled from 'styled-components/native'

export interface AdvisorNoneContentProps {
  onEmailPress:()=>void;
}

  
export default function AdvisorNoneContent(props: AdvisorNoneContentProps) {
  const iconStyle={color:"#0074C8"}
    return (
        <View style={{flex: 1, alignItems: "center", padding: 15,paddingBottom:40,backgroundColor:"#FFFFFF",borderRadius:14,top:-14}}>
          <Text accessibilityRole="heading" ariaLevel="3" bold={true} style={{fontSize: 20,marginTop:20}}>Have a Question?</Text>
          <Text style={{fontSize: 20}}>Email Us</Text>
          <TouchableOpacity onPress={props.onEmailPress}  style={{marginTop:36}}>
                    <MaterialIcons
                        style={iconStyle}
                        accessibilityRole="button"
                        accessible={true}
                        accessibilityLabel="Email advisor"
                        name="email" 
                        size={24} />
            </TouchableOpacity>
      </View>

    )
}
