import React, { useState } from 'react'
import styled from 'styled-components/native'
import { Case } from '../../redux/cases/types'
import { getMessageType } from '../../utils/tasks';
import useAppInsights, { AIEvent } from '../../utils/useAppInsights'
import useGoogleTagManager, {
  GTMEvent,
} from '../../utils/useGoogleTagManager'
import useHandleEsign from '../../utils/useHandleEsign'
import Upload from '../Documents/UploadOverlay'
import {
  MemberExperienceAndUploadBill,
  MemberExperience,
  UploadBill,
  ESignNotReady,
  ESignReady,
  InNegotiation,
  UnderReview,
  NegotiationComplete,
  CaseClosed,
  MemberExperienceAndUploadBillAndESignReady,
  MemberExperienceAndESignReady,
  UploadBillAndESignReady,
  TasksCompleted,
  SelfCoachingStart
} from './MessageTemplates'

interface Iprops {
  caseItem: Case
  onTaskAction: (route: string, params?: Record<string, string>) => void
}

export default function MessageItem(props: Iprops) {
  const { caseItem } = props 
  const appInsights = useAppInsights();
  const gtm = useGoogleTagManager()
  const esign = useHandleEsign()
  const [uploadVisible, setUploadVisible] = useState(false)
  
  const handleGTMEvent = (gtmEvent) => {
    gtm.pushEvent(gtmEvent, {
      caseNumber: caseItem.caseNumber,
      patientID: caseItem.patientId,
      userIsPatient: caseItem.patientInd ? 'Yes' : 'No',
    })
  }
  const handleMemberExperience = () => {
    let params = { caseId: caseItem.caseId, mode: 'edit' }
    handleGTMEvent(GTMEvent.StoryClick)
    props.onTaskAction('Wizard', params)
  }

  const handleUpload = () => {
    handleGTMEvent(GTMEvent.UploadClick)
    appInsights.trackDocumentEvent(AIEvent.UploadClick, caseItem);
    setUploadVisible(true)
  }

  const handleESign = async () => {
    handleGTMEvent(GTMEvent.InitiateRelease)
    esign(caseItem)
  }

  const renderMessage = () => {
    if(caseItem.naviguardProduct==='Self Coach'){
      if(caseItem.caseStatus==='Case Opened' && caseItem.tasks && caseItem.tasks.find(t=>t.taskName==='Questionnaire') && !caseItem.tasks.find(t=>t.taskName==='Questionnaire').taskCompleteInd){
        return <SelfCoachingStart caseNumber={caseItem.caseNumber} />
      }
      else{
        return null
      }
    }
    let messageType = getMessageType(caseItem)
    switch(messageType) {
      case 'InNegotiation':
        return <InNegotiation caseNumber={caseItem.caseNumber} />
      case 'UnderReview':
        return <UnderReview caseNumber={caseItem.caseNumber} />
        case 'NegotiationComplete':
        return <NegotiationComplete caseNumber={caseItem.caseNumber} />
      case 'CaseClosed':
        return <CaseClosed caseNumber={caseItem.caseNumber} />
      case 'MemberExperienceAndUploadBill':
          return (
            <MemberExperienceAndUploadBill 
              onTaskAction={handleMemberExperience}
              handleUpload={handleUpload}
              caseNumber={caseItem.caseNumber}
              providerName={caseItem.billingProviderName}
              hasUserStoryAnswers={caseItem.hasUserStoryAnswers}
            />
          )
      case 'MemberExperience':
        return (
          <MemberExperience   
            onTaskAction={handleMemberExperience}
            caseNumber={caseItem.caseNumber} 
          />
        )
      case 'UploadBill':
        return (
          <UploadBill 
            handleUpload={handleUpload} 
            caseNumber={caseItem.caseNumber} 
          />
        )
      case 'ESignNotReady':
        return <ESignNotReady caseNumber={caseItem.caseNumber} />
      case 'ESignReady':
        return (
          <ESignReady 
            handleESign={handleESign} 
            caseNumber={caseItem.caseNumber} 
          />
        )
      case 'MemberExperienceAndUploadBillAndESignReady':
        return (
          <MemberExperienceAndUploadBillAndESignReady
           onTaskAction={handleMemberExperience}
            handleUpload={handleUpload} 
            handleESign={handleESign} 
            caseNumber={caseItem.caseNumber} 
          />
        )
        case 'MemberExperienceAndESignReady':
        return (
          <MemberExperienceAndESignReady
           onTaskAction={handleMemberExperience}
            handleESign={handleESign} 
            caseNumber={caseItem.caseNumber} 
          />
        )
        case 'UploadBillAndESignReady':
          return (
            <UploadBillAndESignReady
            handleUpload={handleUpload}
              handleESign={handleESign} 
              caseNumber={caseItem.caseNumber} 
            />
          )
        case 'TasksCompleted':
          return(
            <TasksCompleted
              caseNumber={caseItem.caseNumber} 
            />
          )
      default:
        return null
    }
  }

  return (
    <MessageWrapper>
      {renderMessage()}
      <Upload
        onClose={() => setUploadVisible(false)}
        visible={uploadVisible}
        caseItem={caseItem}
      />
    </MessageWrapper>
  )
}

const MessageWrapper = styled.View`
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
`