import React from 'react'
import Card from 'react-native-elements/src/card/Card'

interface ITitleProps{
    children:string|JSX.Element | JSX.Element[],
    smallHeader?: boolean
    accessibilityRole?: string;
    ariaLevel?: string;
}

export default function CardTitle(props:ITitleProps){
    const cardTitleStyle = {
        borderTopLeftRadius: 14,
        borderTopRightRadius: 14,
        textAlign: 'left', 
        color: '#FFF', 
        backgroundColor: '#002C73', 
        fontSize: 14 ,
        paddingLeft: 32,
        paddingTop: props.smallHeader ? 5 : 10,
        marginBottom: 0,
        fontFamily: "SourceSansPro-Bold",
        letterSpacing: 0.77,
        height: 52
      }
    return (
        <Card.Title accessibilityRole={props.accessibilityRole} aria-level={props.ariaLevel} style={cardTitleStyle}>{props.children}</Card.Title>
    )
}