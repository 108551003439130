
import React, { useEffect, useRef } from 'react';
import { Controller,Control, Validate } from "react-hook-form";
import { ErrorText, InputText} from './Layout';
export interface FormInputTextProps{
    id?: string;
    control:Control<Record<string,any>>
    name:string
    defaultValue?:string
    multiline?:boolean
    placeholder?:string
    maxWidth?:string
    minWidth?:string
    width?:string
    editable?:boolean
    required?:boolean
    error?:boolean
    errorMessage?:string
    validate?:Validate | Record<string, Validate>;
}

export default function FormInputText(props:FormInputTextProps){
    const inputRef= useRef<HTMLElement>();
    useEffect(()=>{
       if(props.editable!==undefined && !props.editable){
            inputRef.current.setAttribute("disabled","")
       }
       else{
        inputRef.current.removeAttribute("disabled")
       }
    },[props.editable])
    
    return (
        <>
        <Controller 
            id={props.id}
            control={props.control} 
            name={props.name}
            rules={{required:props.required,validate:props.validate}}
            defaultValue={props.defaultValue}
            render={( { onChange, onBlur, value }) => (
                <InputText
                aria-required={props.required ? 'true' : 'false'}
                ref={inputRef}
                style={{color:'#002C73'}}
                onBlur={onBlur}
                onChangeText={text => onChange(text)}
                value={value}
                multiline={props.multiline}
                placeholder={props.placeholder}
                placeholderTextColor="rgba(42, 46, 114, 0.7)"
                minWidth={props.minWidth}
                maxWidth={props.maxWidth}
                width={props.width}
                editable={props.editable}
                error={props.error}
                />
            )}
        />
           {props.error && <ErrorText>{props.errorMessage}</ErrorText>}
        </>
    )
}