import React, { useCallback, useEffect, useState } from 'react'
import {  View } from 'react-native'
import Layout from '../../components/layout'
import styled from 'styled-components/native'
import { useForm, Controller } from 'react-hook-form'
import { Text, Button as CustomButton} from '../../components'
import PickerFormat from '../../components/form/PickerFormat'
import { Picker } from '@react-native-picker/picker'
import {ErrorText} from '../../components/form/ErrorFormat'
import TextFormat from '../../components/form/TextFormat'
import { TextInput } from "react-native";
import useScreenFormat from '../../components/form/useScreenFormat'
import useGoogleTagManager, { GTMEvent, usePageLoadEffect } from '../../utils/useGoogleTagManager'
import { fetchContacts } from '../../redux/accountSettings/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Contacts} from "../../redux/accountSettings/types";
import { ApplicationState } from '../../redux/rootReducer'
import { fetchCases } from '../../redux/cases/actions'
import { useFocusEffect } from '@react-navigation/native'
import { Case } from '../../redux/cases/types'
import axiosInstance from '../../redux/axiosInstance'
import UnsavedChangesWarning from '../../components/modal/UnsavedChangesWarning'
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

interface ISendEmail{
    fromAddress:string;
    fromName: string;
    toAddress: string;
    toName: string;
    replyToAddress: string,
    replyToName: string,
    subject:string,
    plainTextContent: string
}
interface IComposeEmail{
    caseId:string;
    subject: string;
    message: string;
}
enum EmailState {
    Composing = 1,
    Sending=2,
    Sent_Success = 3,
    Sent_Failed = 4,
    Error=5
  }
export default function Contact({ route, navigation }) {
    const dispatch = useDispatch();
    const styles= useScreenFormat();
    const gtm = useGoogleTagManager()
    const [emailState,setEmailState]=useState(EmailState.Composing)

    const maxCharacters = 250;
    const [remainingChar, setRemainingChar] = useState(250);

    let caseId=undefined
    let contactAdvisorLocation =undefined
    if(route.params){
        caseId= route.params.caseId;
        contactAdvisorLocation=route.params.contactAdvisorLocation;
    }

    let defaultComposedEmail:IComposeEmail={
        caseId:caseId,
         subject:'Question for my Naviguard Advisor',
         message:''
    }
    const { control, handleSubmit, errors, reset,formState} = useForm({
        defaultValues:  defaultComposedEmail
    })

    //GTM page load tracking
    usePageLoadEffect()
    
    //Commnication Preferences: user email, first name, last name
    const [senderEmail,setSenderEmail]=useState("")
    const [senderFirstName,setSenderFirstName]=useState("")
    const [senderLastName,setSenderLastEmail]=useState("")

    useFocusEffect(useCallback(() =>{if(emailState!==EmailState.Error) loadContacts()},[emailState]))
    const loadContacts=()=>{
        ((dispatch(fetchContacts()) as unknown) as Promise<Contacts>)
        .then((c:Contacts) => { 
            setSenderEmail(c.email);
            setSenderFirstName(c.firstName);
            setSenderLastEmail(c.lastName);
        })
        .catch(()=>setEmailState(EmailState.Error))}

    //Cases
    const casesPending:boolean = useSelector((state: ApplicationState) => state.cases.pending)
    const casesHasError:boolean = useSelector((state: ApplicationState) => state.cases.error?true:false)
    let cases:Case[] = useSelector((state: ApplicationState) => state.cases.data)
  
    useEffect(() => {
      if(cases.length <= 0) {
        dispatch(fetchCases());
      }
    }, [])

    useEffect(() => {
        setRemainingChar(250)
    }, [])

    useEffect(() => {
        if(casesHasError){
            setEmailState(EmailState.Error)
        }
      }, [casesHasError,emailState])

    const [dataLoaded, setDataLoaded] = useState(false)
    useEffect(()=>{
        setDataLoaded(!casesPending)
    },[casesPending])

    useEffect(()=>{
        if(cases.length===1){
            caseId=cases[0].caseId
            defaultComposedEmail.caseId=caseId
            reset(defaultComposedEmail)
        }
    },[cases])

    useEffect(()=>{
        if(cases && cases.length>0){
            defaultComposedEmail.caseId=caseId
            reset(defaultComposedEmail)
        }
    },[cases,caseId])
 
   
    const  onSubmit=async(composedEmail: IComposeEmail)=>{
        const selectedCase=cases.find(c=>c.caseId===composedEmail.caseId)
        if(selectedCase){
            gtm.pushEvent(GTMEvent.EmailAdvisor, {
                contactAdvisorLocation:contactAdvisorLocation,
                caseNumber: selectedCase.caseNumber,
                patientID: selectedCase.patientId,
                userIsPatient: selectedCase.patientInd ? 'Yes' : 'No',
            })
           const postingEmail:ISendEmail= {
                fromAddress:process.env.EXPO_SERVICE_EMAIL,
                fromName:'Contact Naviguard',
                toAddress:process.env.EXPO_ADVISOR_EMAIL,
                toName:'Naviguard Advisor',
                replyToAddress:senderEmail,
                replyToName:`${senderFirstName} ${senderLastName}`,
                subject:composedEmail.subject,
                plainTextContent:`${composedEmail.message}\n\nDo not modify anything below this line:\n${selectedCase.emailTag}`}
            
            const axios = await axiosInstance();
            try {
                setEmailState(EmailState.Sending)
                await axios.post('/email',postingEmail)
                setEmailState(EmailState.Sent_Success)
                reset()
            }
            catch (e) {
                setEmailState(EmailState.Sent_Failed)
            } 
                
        }
        else
            setEmailState(EmailState.Sent_Failed)
        
    }

    useFocusEffect(useCallback(() =>  setEmailState(EmailState.Composing),[]))
    
    return (
        <Layout
            introHeading="Contact Your Advisor" 
            mainSectionStyle={styles.caseCard}>
            <View>
                {
                    emailState===EmailState.Sent_Success && 
                    <View>
                        <View
                            style={{
                                display: 'flex',
                                width: 'max-content',
                                flexDirection: 'row',
                                alignItems: 'center',
                                borderLeft: '8px solid #61A60D',
                                backgroundColor: '#EFF5E5',
                                paddingTop: '8px',
                                paddingRight: '16px',
                                paddingBottom: '8px',
                                paddingLeft: '8px',
                                marginBottom: '20px'
                            }}
                        >
                            <MaterialIcons 
                                name="check-circle" 
                                size={22} 
                                color="#61A60D" 
                            />
                            <Text
                                style={{
                                    fontFamily: 'JekoBold',
                                    fontSize: '16px',
                                    color: '#002C73',
                                    marginLeft: '5px'
                                }}
                            >
                                Your message was received!
                            </Text>
                        </View>
                        <MessageText>Your advisor will reach out soon.</MessageText>
                        <View style={{marginTop:32}}>
                            <CustomButton 
                                label="VIEW DASHBOARD" 
                                variant="default" 
                                onPress={()=>navigation.navigate("Home")}
                            />
                        </View>
                    </View>
                }
                {
                    (emailState===EmailState.Sent_Failed || emailState===EmailState.Error) && 
                    <View>
                        <View
                            style={{
                                display: 'flex',
                                width: 'max-content',
                                flexDirection: 'row',
                                alignItems: 'center',
                                borderLeft: '8px solid #9F2140',
                                backgroundColor: '#F7E7E8',
                                paddingTop: '8px',
                                paddingRight: '16px',
                                paddingBottom: '8px',
                                paddingLeft: '8px',
                                marginBottom: '20px'
                            }}
                        >
                            <MaterialIcons 
                                name="error" 
                                size={22} 
                                color="#9F2140" 
                            />
                            <Text
                                style={{
                                    fontFamily: 'JekoBold',
                                    fontSize: '16px',
                                    color: '#002C73',
                                    marginLeft: '5px'
                                }}
                            >
                                Oops! something went wrong
                            </Text>
                        </View>
                       { emailState===EmailState.Sent_Failed && <MessageText>Your message was <Text style={{fontWeight:'bold'}}>not</Text> sent.</MessageText>}
                        <CustomButton 
                            style={{marginTop:32}} 
                            label="Try Again" 
                            variant="default" 
                            onPress={()=>setEmailState(EmailState.Composing)}
                        />
                    </View>
                }
                {
                  (emailState===EmailState.Composing|| emailState===EmailState.Sending) &&
                 <>
                <View
                    accessible={false}
                    accessibilityLabel="case number"
                >
                    <Text for="caseNumber" style={{ color: 'rgb(42, 46, 114)', marginBottom: 6}}>
                        <Text style={{fontSize: '16px', fontFamily: 'JekoBold'}}>Case Number</Text>
                        <Text style={{fontFamily: 'JekoRegular'}}> (Required)</Text>
                    </Text>
                    <Controller control={control} name="caseId" defaultValue="" rules={{ required: true }} 
                        render={({ onChange, value }) => (
                            <PickerFormat
                                nativeID="caseNumber"
                                aria-invalid={errors.caseId ? 'true' : 'false' }
                                ariaDescribedby={errors.caseId ? '' : 'error-caseId'}
                                ariaLabel="caseNumber"
                                onValueChange={(pickerValue) => { onChange(pickerValue) }} 
                                height="44px"
                                fontSize="16px" 
                                required={true}
                                dataLoaded={dataLoaded} 
                                selectedValue={value}  
                                errorState={errors.caseId?true:false }
                            >
                                <Picker.Item 
                                    label="Select case" 
                                    value="" 
                                />
                                { [...cases].sort((c1,c2)=>Number(c2.caseNumber)-Number(c1.caseNumber)).map((caseItem:Case) => {
                                return (
                                            <Picker.Item 
                                                key={caseItem.caseId} 
                                                label={caseItem.caseNumber} 
                                                value={caseItem.caseId} 
                                            />
                                        )
                                })}
                            </PickerFormat> )} />
                    {errors.caseId && <ErrorText><span id="error-caseId" role="alert">A case number is required</span></ErrorText>}
                </View>
                <View style={{marginTop:32}}>
                    <View
                        accessible={false}
                        accessibilityLabel="Subject"
                    >
                        <Text for="subject" style={{ color: 'rgb(42, 46, 114)', marginBottom: 6}}>
                            <Text style={{fontSize: '16px', fontFamily: 'JekoBold'}}>Subject</Text>
                            <Text style={{fontFamily: 'JekoRegular'}}> (Required)</Text>
                        </Text>
                        <Controller 
                            control={control}  
                            name="subject" 
                            defaultValue="" 
                            rules={{ required: true }}
                            render={({ onChange, onBlur, value }) => (
                                <TextFormat
                                    nativeID="subject"
                                    aria-invalid={errors.subject ? 'true' : 'false' }
                                    ariaDescribedby="error-subject"
                                    editMode={true} 
                                    onBlur={onBlur} 
                                    onChangeText={(text) => onChange(text)} 
                                    width="100%"
                                    editModeHeight="44px"
                                    required={true}
                                    value={value}
                                    errorState={errors.subject?true:false}
                                    dataLoaded={true}  
                                />
                            )}
                        />
                    </View>
                    {errors.subject && <ErrorText><span id="error-subject" role="alert">A subject line is required</span></ErrorText> }
                </View>
                <View style={{marginTop:32}}>
                    <Text for="message" style={{ color: 'rgb(42, 46, 114)', marginBottom: 6}}>
                        <Text style={{fontFamily: 'JekoBold', fontSize: '16px'}}>Message</Text>
                        <Text style={{fontFamily: 'JekoRegular'}}> (Required)</Text>
                    </Text>
                    <Controller 
                        control={control} 
                        name="message" 
                        defaultValue="" 
                        rules={{ required: true }}
                        render={({ onChange,onBlur, value }) => (
                            <FreeTextInput
                                nativeID="message"
                                aria-invalid={errors.message ? 'true' : 'false' }
                                aria-labelledby="message"
                                aria-describedby="error-message"
                                aria-required="true" 
                                onBlur={onBlur}
                                maxLength={250}
                                value={value} 
                                error={errors.message ? true : false} 
                                multiline={true} 
                                numberOfLines={8} 
                                onChangeText={(text) => {
                                    setRemainingChar(maxCharacters - text.length)
                                    onChange(text)}
                                } 
                            />
                        )}
                    />
                    <Text
                        style={{
                            marginTop: '5px',
                            color: '#000000',
                            fontFamily: 'JekoRegular',
                            fontSize: '12px'
                        }}
                    >{remainingChar} characters left.</Text>
                    {errors.message && <ErrorText><span aria-describedby="error-message" role="alert">A message is required</span></ErrorText> }
                </View>
                <View style={{ marginTop: 45,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <View style={{marginRight:24}}>
                        <CustomButton 
                            label="SEND" 
                            variant={dataLoaded && emailState.valueOf()!==EmailState.Sending.valueOf()?"default":"primary-disabled"} 
                            onPress={handleSubmit(onSubmit)}
                        />
                    </View>
                    <CustomButton 
                        label="CANCEL" 
                        variant="secondary"
                        onPress={()=>navigation.navigate("Home")}
                    />
                </View>
                </>
                }
            </View> 
            {emailState===EmailState.Composing && <UnsavedChangesWarning formState={formState}  onIgnoreChanges={reset}/>}
        </Layout>
    )
}

const FreeTextInput = styled(TextInput)`
  border-width: ${props => props.error ? "2px" : "1px"};
  font-family: 'JekoBold';
  font-size: 16px;
  border-color: ${props => props.error ? "#e00000" : "#002C73"};
  padding: 12px;
  color: #002C73;
  height: 143px;
  background-color: #E9F2F9;
  border-radius: 7px;
`;
const SuccessText = styled(Text)`
  font-family: 'JekoBold';
  font-size: 24px;
  color: #53a653;
  margin-bottom:12px;
`;
const MessageText = styled(Text)`
  font-family: 'JekoBold';
  font-size: 18px;
`;
const FailureText = styled(Text)`
  font-family: 'JekoBold';
  font-size: 24px;
  color: #e00000;
  margin-bottom:12px;
`;