import {
    FETCH_DOCUMENTS_SUCCESS,
    UPLOAD_DOCUMENTS_SUCCESS,
    UPLOAD_DOCUMENTS_PROGRESS,
    UPLOAD_DOCUMENTS_PROGRESS_RESET,
    FETCH_DOCUMENTS_ERROR,
    UPLOAD_DOCUMENTS_ERROR,
    DELETE_DOCUMENTS_SUCCESS,
    DELETE_DOCUMENTS_ERROR,
    DELETE_DOCUMENTS_STATUS_RESET,
    DocumentDeleteStatus,
    CaseDocuments,
    CaseError,
    DocumentProgress,
    DocumentsDispatchTypes,
    DOCUMENTS_ERROR_RESET,
    FETCH_DOCUMENTS_PENDING
  } from "./types"
   
  interface CaseDate{
    caseId:string,
    lastModifiedDate:Date
  }
  export interface DocumentsState {
    data: CaseDocuments[];
    error?: CaseError[];
    lastModifiedDates:CaseDate[];
    uploadProgress:DocumentProgress[];
    deleteStatus:DocumentDeleteStatus;
    fetchComplete:boolean;
    fetchErrors:CaseError[]
  }
  
  const defaultState: DocumentsState = {
    data: undefined,
    error: undefined,
    lastModifiedDates: undefined,
    uploadProgress:undefined,
    deleteStatus:undefined,
    fetchComplete:false,
    fetchErrors:undefined

  }
  
  export const DocumentsReducer = (state: DocumentsState = defaultState, action: DocumentsDispatchTypes) => {
  
    switch (action.type) {
      case FETCH_DOCUMENTS_SUCCESS:
        return {
          ...state,
          fetchComplete:true,
          fetchErrors: state.fetchErrors=== undefined?undefined: state.fetchErrors.filter(f=>f && f.caseId!==action.payload.caseId),
          data:state.data===undefined? [action.payload]:
                          [...state.data.filter(f=>f.caseId!==action.payload.caseId),action.payload]
        }
      case FETCH_DOCUMENTS_PENDING:
      return {
        ...state,
        fetchComplete:false
      }
      case FETCH_DOCUMENTS_ERROR:
          return {
                ...state,
                fetchComplete:true,
                fetchErrors:state.fetchErrors===undefined?[action.payload]:
                [...state.fetchErrors.filter(f=>f.caseId!==action.payload.caseId),action.payload],
                data:state.data===undefined? undefined: state.data.filter(f=> f && f.caseId!==action.payload.caseId)
        }
      case UPLOAD_DOCUMENTS_SUCCESS:
        const uploadAction={caseId:action.payload.caseId,lastModifiedDate:new Date()};
        return { 
          ...state, 
          lastModifiedDates: state.lastModifiedDates===undefined?[uploadAction]:
          [...state.lastModifiedDates.filter(f=>f.caseId!==action.payload.caseId),uploadAction]
  
        }
      case UPLOAD_DOCUMENTS_PROGRESS:
        return { 
          ...state, 
          uploadProgress:  state.uploadProgress===undefined?[action.payload]:
          [...state.uploadProgress.filter(f=>f.fileName!==action.payload.fileName),action.payload]
  
        }
      case UPLOAD_DOCUMENTS_PROGRESS_RESET:
        return { 
          ...state, 
          uploadProgress:undefined
  
        }
      case UPLOAD_DOCUMENTS_ERROR:
        return {
            ...state,
            error: state.error===undefined?[action.payload]:
            [...state.error.filter(f=>f.caseId!==action.payload.caseId),action.payload]
      }
      case DELETE_DOCUMENTS_SUCCESS:
      case DELETE_DOCUMENTS_ERROR:
        return {
            ...state,
            deleteStatus:action.payload
      }
      case DELETE_DOCUMENTS_STATUS_RESET:
        return { 
          ...state, 
          deleteStatus:undefined
  
        }
      case DOCUMENTS_ERROR_RESET:
        return { 
          ...state, 
          error:undefined
        }
      default:
        return state;
    }
  }