import React from 'react';
import {View,Text } from 'react-native';
import styled ,{ css } from 'styled-components/native';
import {FileRejection} from 'react-dropzone';
import useGoogleTagManager, { GTMEvent } from '../../utils/useGoogleTagManager';

const Wrapper = styled(View)`
margin: 24px 0 0;
padding: 16px 16px 16px 16px;
background-color: rgba(239, 76, 76, 0.1);
`

const Message=styled(Text)`
    font-family: SourceSansPro-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #DB0000;
    ${props => props.bold && css`
    font-family: SourceSansPro-Bold;
  `} 
  `

export interface UploadErrorProps {
   
    rejectedFiles:FileRejection[];
    allowedFileTypes: string;
    maxFileSize: string;
    maxUploadAllowed: number;
    remainingUploadAllowed:number
}


export default function UploadError(props:UploadErrorProps) {
    if(props.rejectedFiles && props.rejectedFiles.length>0){
        return(
            <Wrapper> {props.rejectedFiles.map((f)=>
                <>
                <Message bold>
                    {`"${f.file.name}" could not be uploaded`} 
                </Message>{f.errors.map(e=>{
                        if( e.code==='file-too-large')
                            return(
                                    <Message>Files need to be {props.maxFileSize} or less.</Message>
                                )
                        if(e.code==='file-invalid-type')
                            return(
                                <Message>Files need to be in the followig format: {props.allowedFileTypes}.</Message>
                                )
                        if(e.code==='too-many-files') {

                            if(props.remainingUploadAllowed===0){
                                const gtm = useGoogleTagManager()
                                const charLimit = (str) => str.substring(0, 100);
                                const fetchDocFailed = `You cannot upload anymore files for not exceeding ${props.maxUploadAllowed} total files.`   
                              
                                gtm.pushEvent(GTMEvent.DocumentSignFailure, {
                                    'event': 'UploadError',
                                    'errorType': 'Exceeded max uploads',
                                    'errorMessage' : charLimit(fetchDocFailed)
                                })

                                return(
                                    <Message>{fetchDocFailed}</Message>
                                )
                            }
                            else{

                            let fileLabel ='files'
                            if(props.remainingUploadAllowed===1){
                                fileLabel='file'
                            }
                            
                            return(
                                <Message>You can only upload {props.remainingUploadAllowed} {fileLabel} for not exceeding {props.maxUploadAllowed} total files.</Message>
                                )
                            }
                        }
                    })
                }
                </>          
                )
            }
            </Wrapper> 
        )
    }
    else {
        return null
    }
}