import React from 'react'
import { View } from 'react-native'
import useDeviceType from '../../utils/useDeviceType'

// TODO: force children to be of type GridItem
interface GridProps {
  direction: 'row' | 'column';
  children: any;
  // children: (props: GridItemProps) => JSX.Element;
}
export default function Grid(props: GridProps) {
  const { children, direction } = props
  return (
    <View style={{ width: '100%', display: 'flex', flexDirection: direction }}>
      {children}
    </View>
  )
}

interface GridItemProps {
  children?: JSX.Element;
  tablet: string | number;
  phone: string | number;
  desktop: string | number;
  style?: any;
}

export const GridItem = (props: GridItemProps) => {
  const { tablet, phone, desktop, children, style } = props
  const deviceType = useDeviceType()

  const responsiveWidth = () => {
    switch(deviceType) {
      case 'tablet': return tablet 
      case 'phone': return phone 
      case 'desktop': return desktop
      default: return 'auto'
    }
  }

  return (<View style={[{width: responsiveWidth()}, style]}>{children}</View>)
}