import React from 'react'
import { View } from 'react-native'
import { Button } from '..'
import Modal, { ModalBody, ModalFooter, ModalText } from '.'


export interface ConfirmationProps {
   
    message:string;
    onCancel: () => void;
    onOK: () => void;
    show: boolean;
}

export default function Confirmation(props: ConfirmationProps) {

    return (
        <>
           
                <Modal
                    visible={props.show}
                    onClose={props.onCancel}>
                    <View>
                    <ModalBody>
                        <ModalText>{props.message}</ModalText>
                    </ModalBody>
                    <ModalFooter>
                        <View style={{marginRight: 12}}>
                            <Button label="Cancel" variant="secondary"  onPress={props.onCancel}  />
                        </View>
                        <Button label="OK" onPress={props.onOK} />
                    </ModalFooter>
                    </View>
                </Modal>
            
          
        </>
    )
}