import React, { useEffect, useState } from 'react';
import { Text } from '../../components'
import { QuestionResponse, QuestionResponseTemplate } from '../../redux/memberstory/types';
import { TextInput, View } from "react-native";
import styled from 'styled-components/native';
import { Case } from '../../redux/cases/types';
import RadioButton from '../../components/radiobutton';
import { useForm } from 'react-hook-form';
import { FormGroup, FormText, LabelWrapper, QuestionLabel } from './layout';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import RadioADA from '../../components/RadioADA';
import { MaterialIcons } from '@expo/vector-icons';

const RadioGroup = styled(View)`
  padding: ${props => props.error ? "8px" : "0"};
  padding-bottom: 0;
  border-width: ${props => props.error ? "2px" : "0"};
  border-color: ${props => props.error ? "#e00000" : "#999999"};
`
const RadioButtonWrapper = styled(View)``;

const HintText = styled(Text)``;

const ErrorText = styled(Text)`
  margin-top: 4px;
  color: #e00000;
  font-family: SourceSansPro-Regular;
  font-size: 16px;
`

const NumericTextInput = styled(TextInput)`
  width: 100px;
  height: 40px;
  border-width: ${props => props.error ? "2px" : "1px"};
  border-color: ${props => props.error ? "#e00000" : "#002C73"};
  padding: 12px;
  color: #002C73;
`;

const FreeTextInput = styled(TextInput)`
  font-family: SourceSansPro-Regular;
  font-size: 16px;
  border-width: ${props => props.error ? "2px" : "1px"};
  border-color: ${props => props.error ? "#e00000" : "#002C73"};
  padding: 12px;
  color: #002C73;
`;

const NumberInputStyle = {
  fontFamily: 'SourceSansPro-Regular',
  color: '#002C73',
  fontSize: 16,
  width: 86,
  height: 40,
  padding: 12,
  background: '#F5F9FB',
  borderRadius: 7,
}

const TextAreaInputStyle = {
  fontFamily: 'SourceSansPro-Regular',
  color: '#002C73',
  fontSize: 16,
  background: '#F5F9FB',
  borderRadius: 7
}

const CurrencyWrapper = styled(View)`
  padding: ${props => props.error ? "8px" : "0"};
  padding-bottom: 0;
  border-width: ${props => props.error ? "2px" : "0"};
  border-color: ${props => props.error ? "#e00000" : props.disabled ? "#94989B" : "#002C73"};
`

const CurrencyWrapperInner = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 42px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  padding-left: 8px;
  padding-right: 5px;
`
export interface QuestionFormProps {
  case: Case;
  questions: QuestionResponseTemplate[];
  version: number;
  onUpdateComplete: () => void;
}
interface AnswerListChoice {
  answerId: number;
  answerText: string;
}
export interface QuestionGroup {
  groupNumber: number;
  questions: QuestionResponse[];
}

export interface CurrentStepProps {
  questionGroup: QuestionGroup;
  caseId?: string;
  questionVersion: number;
  showErrors: boolean;
  onSetNextDisabled: (any) => void;
  onSetCurrentPageAnswers: (any) => void;
  onErrorsShown: () => void;
}

export default function CurrentStep(props: CurrentStepProps) {
  const { register, setValue, formState, reset, trigger, clearErrors } = useForm()
  const [ currentPageAnswers, setCurrentPageAnswers] = useState({})
  const [form, setForm] = useState({})
  const [clearForm, setClearForm] = useState(false)
  const { errors } = formState

  useEffect(() => {
    // check if all the required questions have a response
    const allRequiredAnswered = props.questionGroup?.questions?.every(q => {
      return q.required && !disableDependentQuestion(q) ? currentPageAnswers?.responses?.some(r => r.questionId === q.questionId) : true 
    })
    props.onSetNextDisabled(!allRequiredAnswered)
  }, [currentPageAnswers])

  useEffect(() => {
    props.questionGroup?.questions?.forEach(question => {
      register(question.questionId.toString(), { required: question.required })
      registerDependency(question)
    })
  }, [register])

  useEffect(() => {
    if (clearForm) {
      reset()
      setClearForm(false)
    }
  }, [clearForm])

  useEffect(() => {
    if (props.showErrors) {
      clearErrors()
      trigger()
      props.onErrorsShown()
    }
  }, [props.showErrors])

  const updateQuesionAnswers = () => {
    // format the form into a structure ready for submission to the backend
    const story = {
      caseId: props.caseId,
      questionSetVersion: props.questionVersion,
      responses: Object.keys(form).map(key => +key).map(key => {
        const question = props.questionGroup?.questions.find(q => q.questionId === key)
    
        return {
          questionId: key,
          answerId: question && question.answerFormat.toLowerCase() === 'picklist' ? form[key] : undefined,
          answerText: question && question.answerFormat.toLowerCase() !== 'picklist' ? form[key]?.toString() : '',
        }
      }).filter(question => (question.answerText !== undefined && question.answerText ? question.answerText.trim() !== '' : false) || question.answerId !== undefined && question.answerId !== null)
    }

    props.onSetCurrentPageAnswers(story)
    setCurrentPageAnswers(story)
  }

  useEffect(() => {
    bindValues()
    reset()
  }, [props.questionGroup])

  useEffect(() => {
    updateQuesionAnswers()
  }, [form])
  
  const bindValues = () => {
    const bindedForm = {}

    props.questionGroup?.questions?.forEach(question => {
      if (question.answerFormat.toLowerCase() === "picklist") {
        setValue(question.response.questionId.toString(), question.response.answerId, { shouldDirty: true })
        bindedForm[question.response.questionId] = question.response.answerId
        bindedForm[`${question.response.questionId}-${question.response.answerId}`] = question.response.answerId
      }
      else {
        setValue(question.response.questionId.toString(), question.response.answerText, { shouldDirty: true })
        bindedForm[question.response.questionId] = question.response.answerText
        bindedForm[`${question.response.questionId}-${question.response.questionId}`] = question.response.answerText
      }
    })
    setForm({
      ...form,
      ...bindedForm
    })
  }

  const handleSetValue = (questionId: number, answer: any, answerKey: any) => {
    setValue(questionId.toString(), answer, { shouldDirty: true })
    setForm({
      ...form,
      [questionId]: answer,
      [`${questionId}-${answerKey}`]: answer
    })
  }

  const handleSetDisabled = (question: QuestionResponse, enabledAnswer: any, disabled?: boolean) => {
    const questionId = question.questionId.toString()
    let answer = enabledAnswer;

    if (disabled) {
      answer = ''
    }
    //if a question is required but has a dependency, make it required only if it is enabled
    registerDependency(question, disabled)

    //ui update only when there is a change.
    const key = `${question.questionId}-disabled`
    if (form[key] !== disabled) {
      setForm({
        ...form,
        [key]: disabled
      })

      setValue(questionId, answer, { shouldDirty: true })
    }
  }

  const registerDependency = (question: QuestionResponse, disabled?: boolean) => {
    const questionId = question.questionId.toString()
    if (question.dependsOn && question.required) {
      if (disabled === undefined) {
        const key = `${question.questionId}-disabled`
        disabled = form[key] as boolean
      }
      register(questionId, { required: !disabled })
      if (formState.isSubmitted)
        trigger(questionId)
    }
  }

  const disableDependentQuestion = (question: QuestionResponse) => {
    let disabled = false;

    if (question.dependsOn) {
      //calculate values when disabled/enabled
      let answers = question.possibleAnswers ? question.possibleAnswers as [] : undefined;
      let enabledAnswer = '';

      if (answers)
        answers.forEach((value: AnswerListChoice) => {
          if (form[question.questionId] === value.answerId) {
            enabledAnswer = form[`${question.questionId}-${value.answerId}`]
          }
        });

      const dependency = question.dependsOn

      disabled = !dependency.answerIds.includes(form[dependency.questionId])
      handleSetDisabled(question, enabledAnswer, disabled)
    }
    return disabled
  }

  const renderQuestionLabels = (question: QuestionResponse, required: Boolean) => {
    if (required) {
      return <LabelWrapper>
      <QuestionLabel>{question.questionText} <FormText>(Required)</FormText></QuestionLabel>
    </LabelWrapper>
    } else {
      return <LabelWrapper>
        <QuestionLabel>{question.questionText} <FormText>(Optional)</FormText></QuestionLabel>
      </LabelWrapper>
    }
  }

  const getErrorText = (error: any) => {
    if (error.type === 'required') {
      return 'This question is required'
    }

    return error.message
  }

  return (
    <>
      {props.questionGroup?.questions?.map((question, index) => {
        
        let marginDependsOn = (question?.dependsOn?.questionId === question?.questionId - 1) || index === 0 ? 15 : 40;

        if (question.answerFormat.toLowerCase() === 'picklist') {
          const disabled = disableDependentQuestion(question)
          let { possibleAnswers = [] } = question

          const otherIdx = possibleAnswers.findIndex(a => a.answerText === 'Other')

          if (otherIdx !== -1) {
            const otherAnswer = possibleAnswers[otherIdx]
            possibleAnswers.splice(otherIdx, 1)
            possibleAnswers.push(otherAnswer)
          }

          return <fieldset key={`picklist-${question.questionId}`} style={{ padding: 0, marginTop: 16, marginBottom: 16, borderStyle: "none" }} aria-required={question.required && !disabled ? 'true' : 'false'}>
            <legend>
              <Text accessibilityLabel={question.questionText} for={`q-${question.questionId}`} style={{marginTop: marginDependsOn, marginBottom: errors[question.questionId] ? 0 : 12}}>{renderQuestionLabels(question, question.required && !disabled)}</Text>
            </legend>
            <FormGroup aria-describedby="memberStoryForm" key={question.questionId}>
              {errors[question.questionId] && <ErrorText style={{ display: 'flex', alignItems: 'center' }}><MaterialIcons name="error" size={16} color="#e00000" /><span id="questionId" role="alert">{getErrorText(errors[question.questionId])}</span></ErrorText>}
              <RadioGroup
                nativeID={`q-${question.questionId}`}
                aria-describedby={errors[question.questionId] ? 'questionId' : ''}
                error={typeof errors[question.questionId] !== 'undefined'}
                aria-invalid={errors[question.questionId] ? 'true' : 'false'}
                accessibilityRole="radiogroup"
                disabled={disabled}>
                <RadioADA>
                  {(possibleAnswers as AnswerListChoice[]).map((value: AnswerListChoice, idx) => (
                    <RadioButtonWrapper key={idx} accessibilityRole="radio">
                      <View style={{ marginBottom: idx === possibleAnswers.length - 1 ? 0 : 12 }}>
                        <RadioButton onCheck={() =>
                          handleSetValue(question.questionId, value.answerId, value.answerId)
                        }
                          selected={form[question.questionId] === value.answerId}
                          label={value.answerText}
                          testID={`member-story-radio-${value.answerId}-${props.caseId}`} />
                      </View>
                    </RadioButtonWrapper>
                  ))}
                </RadioADA>
              </RadioGroup>
            </FormGroup>
          </fieldset>
        } else if (question.answerFormat.toLowerCase() === 'int') {
          const disabled = disableDependentQuestion(question)
          return <FormGroup key={question.questionId} style={{marginTop: marginDependsOn}}>
            <Text accessibilityLabel={question.questionText} for={`q-${question.questionId}`} style={{ marginBottom: errors[question.questionId] ? 0 : 12 }}>{renderQuestionLabels(question, question.required && !disabled)}</Text>
            {errors[question.questionId] && <ErrorText style={{ display: 'flex', alignItems: 'center' }}><MaterialIcons name="error" size={16} color="#e00000" /><span id="questionId" role="alert">{getErrorText(errors[question.questionId])}</span></ErrorText>}
            <NumericTextInput
              nativeID={`q-${question.questionId}`}
              style={NumberInputStyle}
              aria-invalid={errors[question.questionId] ? 'true' : 'false'}
              aria-required={question.required && !disabled ? 'true' : 'false'}
              error={typeof errors[question.questionId] !== 'undefined'}
              keyboardType = 'number-pad'
              onChangeText={(text: string) =>
                // require numeric input only
                handleSetValue(question.questionId, text.replace(/[^0-9]/g, ''), question.questionId)
              } 
              value={form[`${question.questionId}`]} 
              testID={`member-story-input-${question.questionId}-${props.caseId}`}
              disabled={disabled} />
          </FormGroup>
        } else if (question.answerFormat.toLowerCase() === 'string') {
          const disabled = disableDependentQuestion(question)
          return <FormGroup key={question.questionId} style={{marginTop: marginDependsOn}}>
            <Text accessibilityLabel={question.questionText} for={`q-${question.questionId}`} style={{ marginBottom: errors[question.questionId] ? 0 : 12 }}>{renderQuestionLabels(question, question.required && !disabled)}</Text>
            {errors[question.questionId] && <ErrorText style={{ display: 'flex', alignItems: 'center' }}><MaterialIcons name="error" size={16} color="#e00000" /><span id="questionId" role="alert">{getErrorText(errors[question.questionId])}</span></ErrorText>}
            <FreeTextInput
              nativeID={`q-${question.questionId}`}
              style={TextAreaInputStyle}
              aria-invalid={errors[question.questionId] ? 'true' : 'false'}
              aria-required={question.required && !disabled ? 'true' : 'false'}
              error={typeof errors[question.questionId] !== 'undefined'}
              multiline={true}
              numberOfLines={8}
              onChangeText={(text: string) =>
                handleSetValue(question.questionId, text, question.questionId)
              }
              value={form[`${question.questionId}`]}
              testID={`member-story-input-${question.questionId}-${props.caseId}`}
              disabled={disabled}
              aria-describedby={`q-${question.questionId}-max-chars`}
            />
            <HintText id={`q-${question.questionId}-max-chars`}>1000 maximum characters</HintText>
          </FormGroup>
        } else if (question.answerFormat.toLowerCase() === 'currency') {
          const disabled =disableDependentQuestion(question)

          const CurrencyInputStyle = {
            fontFamily: 'SourceSansPro-Regular',
            color: '#002C73',
            fontSize:16,
            width:140,
            height:16,
            padding:12,
            background:'#F5F9FB',
            borderTopRightRadius: 7,
            borderBottomRightRadius: 7,
            borderTop: disabled ? '1px solid #94989B' : '1px solid rgb(0, 44, 115)',
            borderBottom: disabled ? '1px solid #94989B' : '1px solid rgb(0, 44, 115)',
            borderRight: disabled ? '1px solid #94989B' : '1px solid rgb(0, 44, 115)'
          }

          return <FormGroup key={question.questionId} style={{marginTop: marginDependsOn}} disabled={disabled}>
            <Text accessibilityLabel={question.questionText} for={`q-${question.questionId}`} style={{ marginBottom: errors[question.questionId] ? 0 : 12 }}>{renderQuestionLabels(question, question.required && !disabled)}</Text>
            {errors[question.questionId] && <ErrorText style={{ display: 'flex', alignItems: 'center' }}><MaterialIcons name="error" size={16} color="#e00000" /><span id="questionId" role="alert">{getErrorText(errors[question.questionId])}</span></ErrorText>}
            <CurrencyWrapper 
              nativeID={`q-${question.questionId}`}
              disabled={disabled}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
            <CurrencyWrapperInner 
              disabled={disabled}
              style={{
                borderTop: errors[question.questionId] ? "1px solid #e00000" : disabled ? "1px solid #94989B" : "1px solid #002C73",
                borderBottom: errors[question.questionId] ? "1px solid #e00000" : disabled ? "1px solid #94989B" : "1px solid #002C73",
                borderLeft: errors[question.questionId] ? "1px solid #e00000" : disabled ? "1px solid #94989B" : "1px solid #002C73",
              }}
            >
                <MaterialIcons 
                  name="attach-money" 
                  role="img"
                  accessibilityLabel="In dollars"
                  size={20} 
                  color={disabled ? '#7A858C' : "rgb(0, 44, 115)"}
                />
            </CurrencyWrapperInner>
              <NumberFormat 
                disabled={disabled}
                style={CurrencyInputStyle} 
                displayType="input" 
                value={form[`${question.questionId}`]} 
                thousandSeparator={true} 
                isNumericString={true} 
                decimalScale={2}
                onValueChange={(text:NumberFormatValues) =>handleSetValue(question.questionId, text.value, question.questionId)}
              />
            </CurrencyWrapper>
          </FormGroup>
        }
      })}
    </>
  )
}