export interface Contacts {
    id: string;
    mobilePhone: string;
    workPhone: string;
    otherPhone: string;
    email: string;
    preferredPhone: string;
    preferredChannel: string;
    firstName:string;
    lastName:string;
}

export const FETCH_CONTACTS_PENDING = 'FETCH_CONTACTS_PENDING'


export interface FetchContactsPending {
    type: typeof FETCH_CONTACTS_PENDING;
    payload:  boolean;
}

export type ContactsDispatchTypes =
 | FetchContactsPending

 