import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/native';
import {  View, Text, TouchableOpacity } from 'react-native';
import useDeviceWidth from '../../utils/useDeviceWidth'
import { Select } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../redux/rootReducer';
import { updateCaseSelect } from '../../redux/caseselect/action';
import { Case } from '../../redux/cases/types';

const StyledTextViewInformation = styled.Text`
  color: #002C73;
  font-family: SourceSansPro-Bold;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.77px;
`



export interface CaseSelectProps{
  cases:Case[],
  casesPending:boolean,
  onCasesFiltered:(filteredCases:Case[])=>void,
  disabled?: boolean;
}

export default function CaseSelect(props:CaseSelectProps) {
  const {cases,casesPending,onCasesFiltered}=props;
  const responsiveStyle = useDeviceWidth()
  const [showDropDown, setShowDropDown] = useState(false);
  const statusList = [{statusLabel:"All Cases",statusKey:["All Cases"]},
  {statusLabel:"Open Cases",statusKey:["Case Opened","Under Review","Negotiation In Progress","Negotiation Complete, you'll hear from us soon"]},
  {statusLabel:"Closed Cases",statusKey:["Case Closed"]}];

  const dispatch= useDispatch() ;
  const  status:string[] = useSelector((state: ApplicationState) => state.caseSelect.status) 
  const [statusLabel, setStatusLabel] = useState(statusList.find(s=>status && s.statusKey.toString()===status.toString())?.statusLabel);
  const dropdownRef=useRef<TouchableOpacity>()
 
 useEffect(()=>{

     if(status){
        setStatusLabel(statusList.find(s=>status && s.statusKey.toString()===status.toString()).statusLabel)
     }
    else{
    dispatch(updateCaseSelect(statusList[0].statusKey))
    }
    
    if (!casesPending && cases.length > 0) {
      if(!status || status.toString()===statusList[0].statusKey.toString()){
          onCasesFiltered([...cases])  
      }
      else{
         onCasesFiltered(cases.filter(c=>status.includes(c.caseStatus)))  
      }
  }

 },[status,cases,casesPending])

 
  return (
    <View style={[{flexDirection: 'row', alignItems: 'center'}]}>
      <StyledTextViewInformation 
        style={responsiveStyle === 'width295_359' ? { fontSize: 12 } : null}
      >
        <label htmlFor="information-for">
          INFORMATION FOR
        </label>
      </StyledTextViewInformation>
      <div id="information-for">
        <View style={{marginHorizontal: 20, justifyContent: 'center',borderBottomWidth:2,borderBottomColor:"#E64B38", opacity: props.disabled ? .5 : 1}}>
          <TouchableOpacity onPress={() => setShowDropDown(!showDropDown)} ref={dropdownRef}>
            <Select
              staticLabel={
                    <Text  style={{color:'#002C73',fontSize:16,fontFamily:'JekoRegular',marginRight:10,fontWeight:"700"}}>
                      {statusLabel}
                    </Text>
              }
              value={status}
              setValue={(value:string[])=>dispatch(updateCaseSelect(value))}
              list={statusList}
              labelKey="statusLabel"
              valueKey="statusKey"
              rightIcon ={
                    showDropDown?
                    <View style={{alignSelf:'center'}}>
                    <svg width="10" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 2H0V0H14V2Z" fill="#002C73"/>
                    </svg>
                  </View>:
                    <View style={{alignSelf:'center'}}>
                      <svg width="10" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#002C73"/>
                      </svg>
                    </View>
                  }
              leftIcon={true}
              leftIconName="check"
              visible={!props.disabled && showDropDown}
              showDropDown={() => !props.disabled && setShowDropDown(true)}
              onDismiss={() => {!props.disabled && setShowDropDown(false);dropdownRef.current.focus()}}
            />
          </TouchableOpacity>
        </View>
      </div>
    </View>
  )
}

