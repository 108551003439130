import React from "react";
import { View } from "react-native";
import { Step } from "../../../redux/cases/types";
import Text from '../../TextComponent' 
import StatusTrackerItem, { StatusTrackerItemStatus } from "./StatusTrackerItem";

export interface StatusTrackerProps{
    progress: {
        steps: Step[];
        caseOpenedDate: string;
        caseClosedDate: string;
    };
}

export default function StatusTracker(props:StatusTrackerProps){
    const {progress}=props
    let steps:Step[]=undefined
    let caseOpenedDate: string = undefined
    let caseClosedDate: string = undefined

    const compareStep=(step1:Step, step2:Step)=> {
        if (step1.stepNumber<step2.stepNumber) {
          return -1;
        }
        if (step1.stepNumber>step2.stepNumber) {
          return 1;
        }
       
        return 0;
    }

    if(progress){
        caseOpenedDate = progress.caseOpenedDate
        caseClosedDate = progress.caseClosedDate
        steps=progress.steps
        steps.sort(compareStep)
    }

    const mapProgressStatusToItemStatus=(status:string)=>{
        let itemStatus:StatusTrackerItemStatus=undefined

        switch (status) {
            case "NotStarted":
                itemStatus=StatusTrackerItemStatus.NotStarted
                break;
            case "InProgress":
                itemStatus=StatusTrackerItemStatus.InProgress
                break;
            case "Complete":
                itemStatus=StatusTrackerItemStatus.Completed
                break;
            default:
                itemStatus=undefined
                break;
        }
        return itemStatus
    }
    const parseDate=(dateString:string)=>{
        if(dateString){
            const date=new Date(dateString)
            return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
        }
        else{
            return ""
        }
    }
    const getStatusItemDetail=(step:Step)=>{
        let detail:string=''

        switch (step.name) {
            case 'Case Opened':
                detail=caseOpenedDate?`Case opened ${parseDate(caseOpenedDate)}`:""
                break;
            case 'Under Review':
                detail="Your case is being reviewed to determine next steps so that Naviguard can best support you"
                break;
            case 'With Negotiation Team':
                const status = mapProgressStatusToItemStatus(step.status);
                if(status === StatusTrackerItemStatus.Completed) {
                    detail="You'll hear from us soon"
                } else {
                    detail="Typically takes 2-5 weeks"
                }
                break;
            case 'Negotiation Complete':
                detail="You'll hear from us soon"
                break;
            case 'Case Closed':
                detail=caseClosedDate?`Case closed on ${parseDate(caseClosedDate)}`:""
                break;
            default:
                detail=""
                break;
        }
        return detail
    }

    const getStepName = (step: Step): string => {
        if(step.name === 'With Negotiation Team' && step.status === 'Completed') return 'Negotiation Complete'

        return step.name;
    }
   
    return (
        <View>
             <Text accessibilityRole="heading" ariaLevel="3" bold={true} size={16} style={{marginBottom:14}}>Case Progress</Text>
             {steps?.map((step,index)=>
                <StatusTrackerItem 
                    key={step.stepNumber} 
                    status={mapProgressStatusToItemStatus(step.status)} 
                    nextStatus={index+1<steps.length? mapProgressStatusToItemStatus(steps[index+1].status):undefined} 
                    title={getStepName(step)} 
                    detail={getStatusItemDetail(step)}/>
             )}
        </View>

    )
}