import React, { useEffect } from 'react';
import { Text } from '../../components/index'
import { useIsMobile } from '../../utils/useDeviceWidth'
import { Button } from '../../components'
import Layout , 
{
  LayoutDivider,
  MainSection,
  InfoSection,
  NavigationSection, 
  ViewAdditionalInfo,
  TextRowGroup,
  ErrorText
} from './Components/Layout';
import { View } from 'react-native';
import YourNumbers from './Components/YourNumbers';
import QuestionTooltip from './Components/QuestionTooltip';
import { Numbers as NegotiationNumbers } from '../../redux/negotiation/numbers/types';
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from "react-hook-form";
import { ApplicationState } from '../../redux/rootReducer';
import { updateNumbers } from '../../redux/negotiation/numbers/actions';
import CurrencyFormat from './Components/CurrencyFormat';
import useGoogleTagManager, { usePageLoadEffect } from '../../utils/useGoogleTagManager';


export default function Numbers({route, navigation }) {
  const caseId:string=route.params && route.params.caseId?route.params.caseId:""
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const numbers:NegotiationNumbers= useSelector((state: ApplicationState) =>state.negotiationNumbers && state.negotiationNumbers.data && state.negotiationNumbers.data.find(f=>f.caseId===caseId)?  state.negotiationNumbers.data.find(f=>f.caseId===caseId): undefined)
  const { control, reset, handleSubmit, formState: { errors } } = useForm( {defaultValues: numbers});
  const gtm=useGoogleTagManager()
 
  useEffect(()=>{
    if(!numbers){
      goToStory()
    }
  },[caseId])

  useEffect(()=>{
    reset(numbers)
  },[route.params])

  usePageLoadEffect()
  
  const onSubmit=(data:NegotiationNumbers)=>{
    data.caseId=caseId
    data.deductible=numbers.deductible
    data.copay=numbers.copay
    data.coinsurance=numbers.coinsurance
     dispatch(updateNumbers(data))
     reset(data)
     gtm.pushNegotiationTrack("knowYourNumber","Continue",data.providerAmount)
    navigation.navigate("NegotiationOptions",route.params)
  }
   
    const goToStory=()=>{
      navigation.navigate("NegotiationStory",route.params)
    }
    const goBack=()=>{
      gtm.pushNegotiationTrack("knowYourNumber","Back")
      goToStory()
    }
    if(!numbers){
      return null
    }

    return (
   <Layout title="Know Your Numbers" isMobile={isMobile} StepTrackerActiveStepNumber={2}>
        <LayoutDivider  isMobile={isMobile}>
          <MainSection  isMobile={isMobile}>
            <Text bold={true} size={24}>Please verify this information from your insurance claim</Text>
            <Text size={16} style={{fontStyle:'italic'}}>Fill in the form below to calculate a fair offer for the service(s) received. This information can be found on your Explanation of Benefits (EOB).</Text>
           
              <TextRowGroup isMobile={isMobile} marginTop="20px">
                <Text size={16} bold={true}>Amount the provider billed to insurance</Text>
                <CurrencyFormat control={control} name="billed" isMobile={isMobile}/>
              </TextRowGroup>
              <TextRowGroup isMobile={isMobile}  marginTop="20px">
                <Text size={16} bold={true}>Plan paid amount
                <View accessible={true} accessibilityLabel={'Plan Paid Amount Tooltip'}>
                  <QuestionTooltip  placement={isMobile?"top":"right"} content="The amount your insurance company agreed to pay a provider for a specific service. For out-of-network situations, if the allowed amount is less than the charged amount, the patient may need to pay the balance." marginLeft={15}/>
                  </View>
                </Text>
                <CurrencyFormat control={control} name="insurancePaid" isMobile={isMobile}/>
            </TextRowGroup>
            <TextRowGroup isMobile={isMobile} marginTop="20px">
              
                <Text size={16} bold={true}>Cost share (deductible + co-insurance/co-pay)
                  <View accessible={true} accessibilityLabel={'Cost share Tooltip'}>
                    <QuestionTooltip   placement={isMobile?"top":"right"} content="Cost share is the amount of your deductible plus the amount of your co-pay/co-insurance." marginLeft={15}/>
                  </View>
                </Text>
              <CurrencyFormat control={control} name="" defaultValue={numbers.deductible+numbers.coinsurance+numbers.copay} isMobile={isMobile}/>
            </TextRowGroup>
            <TextRowGroup isMobile={isMobile}  marginTop="20px">
              <Text size={16} bold={true}>Insurance allowed amount
              <View accessible={true} accessibilityLabel={'Insurance allowed amount Tooltip'}>
                <QuestionTooltip placement={isMobile?"top":"right"} content="The maximum amount a health care plan will pay for a covered health plan service. This is also known as eligible expense, payment allowance, negoatiated rate, allowable charge." marginLeft={15}/>
                </View>
              </Text>
              <CurrencyFormat control={control} name="allowedAmount" isMobile={isMobile}/>
            </TextRowGroup>
            <TextRowGroup isMobile={isMobile}  marginTop="20px">
              <Text size={16} bold={true}>Non-covered amount</Text>
              <CurrencyFormat control={control} name="nonCoveredAmount" isMobile={isMobile}/>
            </TextRowGroup>

             <View style={{marginTop:60}}>
               <Text size={24} bold={true}>Please enter this information from the bill you received from your provider</Text>
               <TextRowGroup isMobile={isMobile}  marginTop="20px">
                  <Text size={16}><Text size={16} bold={true}>Amount provider is asking you to pay </Text>(Required)</Text>
                   <CurrencyFormat control={control} name="providerAmount" editable={true}  isMobile={isMobile}
                  required={true} error={errors.providerAmount?true:false} errorMessage="Provider amount is required"/>
            </TextRowGroup>
             </View>
          </MainSection>
          <InfoSection isMobile={isMobile}>
            <YourNumbers isMobile={isMobile} caseId={caseId} defaultNumbers={numbers} />
            <ViewAdditionalInfo isMobile={isMobile}>
              <Text size={20} bold={true}>This is the amount that we will be asking the provider to discount.</Text>
              <Text size={16} style={{marginTop:5}}>
              Take some time to think through the total amount you are willing to pay before you call the provider. It will be important to know the most that you can afford to make on this bill. Think through things like your financial situation and your other bills when making this decision.
              </Text>
            </ViewAdditionalInfo>
          </InfoSection>
        </LayoutDivider>
        <NavigationSection>
          <Button onPress={goBack} variant="secondary"  label='BACK'/> 
          <Button onPress={handleSubmit(onSubmit)}  label='CONTINUE' style={{marginLeft:10}}
          variant={Object.keys(errors).length > 0 ? "primary-disabled" : "default"} />
        </NavigationSection>
        {Object.keys(errors).length > 0 && 
            <ErrorText>Please correct the fields highlighted above and try again.</ErrorText>
          }
    </Layout>
   )
  }