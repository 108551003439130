import React from "react";
import { Control, Controller, FieldName, FieldValues, Validate } from "react-hook-form";
import { View } from "react-native";
import RadioADA from "../../../components/RadioADA";
import RadioButton from "../../../components/radiobutton";
import FormInputText from "./FormInputText";
import { ErrorText, ErrorView } from "./Layout";

export interface RadioButtonGroupProps{
    control:Control,
    name:string,
    labelValues:RadioButtonLabelValue[],
    required:boolean,
    error:boolean,
    errorMessage:string,
    validate?:Validate | Record<string, Validate>,
    onCheck?:(value:string)=>void,
    clearErrors?:(name?: FieldName<FieldValues> | FieldName<FieldValues>[])=> void;
    disabled?:boolean
}
export interface RadioButtonLabelValue{
    label:string|JSX.Element,
    value:string,
    input?:boolean,
    inputName?:string,
    inputError?:boolean,
    inputErrorMessage?:string
}

export default function RadioButtonGroup(props:RadioButtonGroupProps){
   const handleClearInputErrors=(excludeInputName?:string)=>{
    props.labelValues.forEach(labelValue => {
       if(labelValue.inputName && labelValue.inputName!==excludeInputName){
        props.clearErrors(labelValue.inputName)
       }
   }); 
    
   }
    return(
        <>
    <ErrorView hasErrors={props.error} aria-required={props.required ? 'true' : 'false'}>
        <Controller control={props.control} name={props.name}
        rules={{required:props.required,validate:props.validate}}
        render={( { onChange, value }) => (
            <RadioADA>{
                    props.labelValues.map(labelValue=>{
                         return(
                        <View style={{paddingTop:8,paddingBottom:8,width:'85%'}}>
                            <RadioButton  selected={value===labelValue.value} 
                                        onCheck={()=>{onChange(labelValue.value); 
                                                    handleClearInputErrors(labelValue.inputName);
                                                    if(props.onCheck){props.onCheck(labelValue.value)}}} 
                                         label={labelValue.label}
                                         disabled={props.disabled}
                                         ></RadioButton>
                            {labelValue.input &&
                            <View style={{marginTop:6,width:'100%',marginLeft:25}}>
                                <FormInputText  control={props.control} name={labelValue.inputName}
                                editable={value===labelValue.value}
                                placeholder="Please describe" 
                                required={value===labelValue.value}
                                 error={labelValue.inputError}
                                errorMessage={labelValue.inputErrorMessage}
                                />
                            </View>
                        }
                        </View>
                        )
                    })
                }
            </RadioADA>
        )} />
    </ErrorView>
     {props.error && <ErrorText>{props.errorMessage}</ErrorText>}
     </>
    )
}