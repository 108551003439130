import styled from 'styled-components/native'

export const Question = styled.View`
  margin-bottom: 28px;
`
export const Row = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const FreeTextInput = styled.TextInput`
  border-width: ${props => props.error ? "2px" : "1px"};
  font-family: SourceSansPro-Regular;
  font-size: 16px;
  border-color: ${props => props.error ? "#9F2140" : "#002C73"};
  border-radius: 7px;
  padding: 12px;
  color: #002C73;
  background-color: #E9F2F9;
`;

export const RadioButtonWrapper = styled.View`
  margin-right: 15px;
`
export const ButtonOptionWrapper = styled.View`
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 5px;
`
export const SelectWrapper = styled.View`
  border-width: ${props => props.error ? "2px" : "1px"};
  border-color: ${props => props.error ? "#9F2140" : "#002C73"};
  border-radius: 7px;
  background-color: #E9F2F9;
  padding: 12px;
  color: #4A4E54;
`