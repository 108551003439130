import React from 'react';
import { View } from 'react-native';
import Text from '../../components/TextComponent'
import { getUserAgent, isIosSafari } from '../../utils/agent';

const version = process.env.EXPO_APP_VERSION

export default function Diagnostics() {
  
  return (
    <View>
      <Text>Version: {version}</Text>
      <Text>User Agent: {getUserAgent()}</Text>
      <Text>Platform: {navigator.platform}</Text>
      <Text>iOS Safari: {isIosSafari() ? 'Yes' : 'No'}</Text>
    </View>
  )
}
