import React, { useState, useRef } from 'react'
import Card from 'react-native-elements/src/card/Card'

import {
    StyleSheet,
    View,
    Animated,
    TouchableOpacity,
    Easing,
} from 'react-native'
import { Title, Body } from './components'
import { Case } from '../../redux/cases/types'
import { SkeletonTitle } from './components/title'
import { margin, padding } from '../../utils/styles'
import BodyNegotiation from './components/BodyNegotiation'

const cardStyle = {
    borderRadius: 14,
    borderWidth: 0,
    shadowColor: "rgba(0, 44, 115, 0.1)",
    shadowOffset: {
        width: 0,
        height: 4,
    },
    shadowOpacity: 1,
    shadowRadius: 10,

    elevation: 3,

    ...margin(16, 0, 0, 0),
    ...padding(23, 23, 0),
}

export interface CardComponentProps {
    case: Case
    defaultOpen: boolean
    onTaskAction: (route: string, params?: Record<string, string>) => void
}

export default function CardComponent(props: CardComponentProps) {
    const [open, setOpen] = useState(props.defaultOpen)
    const animatedController = useRef(new Animated.Value(0)).current
    const [bodySectionHeight, setBodySectionHeight] = useState<any>()

    const bodyHeight = animatedController.interpolate({
        inputRange: [0, 1],
        outputRange: props.defaultOpen ? [0, bodySectionHeight].reverse() : [0, bodySectionHeight],
    })

    const toggleListItem = () => {
        if (open) {
            Animated.timing(animatedController, {
                duration: 300,
                toValue: props.defaultOpen? 1 : 0 ,
                easing: Easing.bezier(0.4, 0.0, 0.2, 1),
                useNativeDriver: true,
            }).start()
        } else {
            Animated.timing(animatedController, {
                duration: 300,
                toValue: props.defaultOpen? 0 : 1 ,
                easing: Easing.bezier(0.4, 0.0, 0.2, 1),
                useNativeDriver: true,
            }).start()
        }
        setOpen(!open)
    }
    const isNegotationCase=()=>{
        if(props.case && props.case.naviguardProduct==='Self Coach'){
            return true
        }
        else{
            return false
        }
    }
    return (
        <Card containerStyle={cardStyle}>
            <TouchableOpacity onPress={() => toggleListItem()} testID={`case-${props.case.caseNumber}`}>
                <Title case={props.case}  expanded={open} />
            </TouchableOpacity>
            <Animated.View style={{ overflow: 'hidden', height: bodyHeight }}>
                <Card.Divider />
                <View
                    style={styles.bodyContainer}
                    onLayout={(event) =>
                        setBodySectionHeight(event.nativeEvent.layout.height)
                    }>
                    {!isNegotationCase() && open && 
                    <Body case={props.case} onTaskAction={props.onTaskAction} /> }
                    {isNegotationCase() && open && <BodyNegotiation case={props.case}/> }
                </View>
            </Animated.View>
        </Card>
    )
}

export const SkeletonCaseCard = () => {
    return (
        <Card containerStyle={cardStyle}>
            <SkeletonTitle />
        </Card>
    )
}

const styles = StyleSheet.create({
    bodyContainer: {
        padding: '1rem',
        paddingLeft: 0,
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
})
