import React, { useEffect, useState } from "react";
import { View } from "react-native";
import Text from '../TextComponent'
import styled from 'styled-components/native';

const Banner = styled(View)`
  background-color: #002C73;
  color: #fff;
  font-weight: bold;
  padding: 5px;
  height: 30px;
`

export default function UpdateBanner() {
    const [update, setUpdate] = useState(false)

    useEffect(() => {
        // if service worker has detected an update to the asset bundle, display a banner message
        // informing the user to refresh. normally, cache control should handle updates, but this
        // is a fallback in case the user's browser doesn't honor caching headers.
        if (window.isUpdateAvailable) {
            window.isUpdateAvailable.then((available: boolean) => {
                setUpdate(available)
            })
        }
    }, [])

    return update
        ? <Banner>
            <Text>Our website has been updated! Refresh your browser to see the latest version.</Text>
        </Banner>
        : <></>
}
