import React, { useEffect } from 'react';
import useGoogleTagManager, { GMTNavigationLinkPlacement } from '../../utils/useGoogleTagManager';
import { authProvider } from '../../authProvider';

export default function SignOut() {
  const gtm = useGoogleTagManager();
  const loginLocation = `${location.protocol}//${location.host}`;

  useEffect(() => {
    gtm.pushNavigation(GMTNavigationLinkPlacement.Header, "Log Out");
    authProvider.clearSession();
    setTimeout(() => {
      window.location.href = loginLocation;
    }, 30000) //need at least 30 seconds for HSID to clean up their session
  }, [])

  return (
    <>
      <div 
        style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          marginTop: 20 
        }}>
        Signing Off...
      </div>
    </>
  )
}