import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

let appInsights:ApplicationInsights = null;
const createTelemetryService = () => {
  const reactPlugin = new ReactPlugin();
  const initialize = (subject:string) => {
    if(!process.env.EXPO_INSTRUMENTATION_KEY) {
      console.error("Telemetry Service Not Initialized: Instrumentation key is missing")
      return
    }
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: process.env.EXPO_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        maxBatchInterval: 0,
        disableFetchTracking: false,
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true,
      }
    })

    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer(function (envelope) {
      var telemetryItem = envelope.baseData;

      // To set custom properties:
      telemetryItem.properties = telemetryItem.properties || {};
      telemetryItem.properties["subject"] = subject;

    });
    
  };

  return { appInsights,reactPlugin, initialize };
};

export const ai = createTelemetryService();