import { AxiosResponse } from "axios"

export const FETCH_MEMBER_STORY = 'FETCH_MEMBER_STORY'
export const FETCH_MEMBER_STORIES = 'FETCH_MEMBER_STORIES'
export const FETCH_MEMBER_STORY_TEMPLATE = 'FETCH_MEMBER_STORY_TEMPLATE'
export const UPDATE_MEMBER_STORY = 'UPDATE_MEMBER_STORY'

export interface MemberStory {
    caseId: string;
    questionSetVersion: number;
    responses: QuestionResponse[];
}

export interface QuestionResponse {
    questionId: number;
    questionText: string;
    answerId?: number;
    answerText?: string;
    answerFormat:string;
    displayOrder:number;
    required: boolean;
    possibleAnswers: {answerId:number,answerText:string}[];
    dependsOn?: {questionId:number,answerIds:number[]};
    response?: { questionId: number, answerId: number, answerText: string };
}

export interface MemberStoryTemplate {
    caseId: string;
    questionSetVersion: number;
    template: QuestionResponseTemplate[];
}
export interface QuestionResponseTemplate {
    questionId: number;
    displayOrder:number;
    questionText: string;
    required:boolean;
    dependsOn?:{questionId:number,answerIds:number[]};
    answerType: string;
    answers: {answerId:number,answerText:string}[];
    givenAnswer?: { answerId:number, answerText:string };
}
export interface WizardMemberStory {
    authorized: boolean;
    questionSetVersion: number;
    startGroup: number;
    questionGroups: QuestionResponseTemplate[][];
    patientStoryQuestionsDto?: QuestionResponseTemplate[];
}

export interface FetchMemberStory {
    type: typeof FETCH_MEMBER_STORY;
    payload: Promise<AxiosResponse<MemberStory>>;
}
export interface FetchMemberStoryTemplate {
    type: typeof FETCH_MEMBER_STORY_TEMPLATE;
    payload: Promise<AxiosResponse<MemberStoryTemplate>>;
}
export interface FetchMemberStories {
    type: typeof FETCH_MEMBER_STORIES;
    payload: Promise<AxiosResponse<WizardMemberStory>>;
}
export interface UpdateMemberStory {
    type: typeof UPDATE_MEMBER_STORY;
    payload: Promise<AxiosResponse<any>>;
}

export type MemberStoryDispatchTypes =
 | FetchMemberStory
 | UpdateMemberStory
 | FetchMemberStories
