import React, { useState, useRef,  ReactNode, useEffect } from 'react'
import { createPortal } from "react-dom"
import { View, TouchableWithoutFeedback, TouchableOpacity, StyleSheet, ScrollView } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons';
import { Hoverable } from 'react-native-web-hover'
import styled from 'styled-components/native';
import Text from './TextComponent'
import { useIsMobile } from '../utils/useDeviceWidth'

interface IProps {
  options: any[];
  onValueChange: (selected: any) => void;
  customLabel?: (option: any) => ReactNode;
  labelKey: string;
  valueKey: string;
  placeHolder?: string;
  style?: any;
  value?: any
}
export default function CustomSelect(props: IProps) {
  const { options, labelKey, valueKey, placeHolder, onValueChange, customLabel } = props
  const isMobile = useIsMobile()
  const anchorRef = useRef(null)
  const [ selected, setSelected ] = useState(null)
  const [open, setIsOpen] = useState(false)
  const [anchorLayout, setAnchorLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (!selected || selected !== props.value) {
      setSelected(props.value)
    }
  }, [props.value])

  const onSelect = (option) => {
    setSelected(option)
    setIsOpen(false)
    onValueChange(option)
  }

  return (
    <TouchableWithoutFeedback onPress={() => setIsOpen(!open)}>
      <SelectWrapper style={props.style} ref={anchorRef} onLayout={(event) => setAnchorLayout(event.nativeEvent.layout)}>
        <View style={styles.select}>
          {!selected && <Text>{placeHolder ?? "Select one"}</Text>}
          {selected && !customLabel && <Text>{selected[labelKey]}</Text>}
          {selected && customLabel ? customLabel(selected) : null }
          <MaterialIcons name="keyboard-arrow-down" size={24} color="black" />
        </View>
        {
          open && 
            <Menu 
              anchorRef={anchorRef}
              anchorLayout={anchorLayout}
              style={[styles.menu, 
                { 
                  width: anchorLayout?.width,
                  left: isMobile ? 17 : 32
                }]}
            >
              <ScrollView>
                <View style={{flex: 1}}>
                {
                  options.map((option) => {
                    let isSelected = selected ? selected[valueKey] === option[valueKey] : false
                    return (
                      <Hoverable key={option[valueKey]}>
                        {({ hovered }) => (
                          <TouchableOpacity 
                            onPress={() => onSelect(option)}
                            style={[
                              styles.option, 
                              hovered || isSelected ? styles.hoveredOption : {},
                            ]}
                          >
                            { customLabel ? customLabel(option) : <Text>{option[labelKey]}</Text> }
                          </TouchableOpacity>
                        )}
                      </Hoverable>
                      
                    )
                  })
                }
                </View>
              </ScrollView>
            </Menu>
        }
      </SelectWrapper>
    </TouchableWithoutFeedback>
  )
}

export const SelectWrapper = styled.View`
  border-width: ${props => props.error ? "2px" : "1px"};
  border-color: ${props => props.error ? "#e00000" : "#999999"};
  border-radius: 2px;
  padding: 12px;
  cursor: pointer;
`

export const isSafari = () => {
  if(!window) return false 
  if(window && !window.navigator) return false 

  return window.navigator.userAgent.indexOf('Safari') != -1 && window.navigator.userAgent.indexOf('Chrome') <= -1
}

const Menu = ({ children, anchorRef, anchorLayout, style }) => {
  if(!anchorLayout || !anchorRef.current) return null
  
  let position = anchorRef.current.getBoundingClientRect()
  let coord = { top: (position.y + anchorLayout.height) - 5, left: position.x }
  return createPortal(<View accessible={false} style={[style, coord]}>{children}</View>, document.body)
}
  
const styles = StyleSheet.create({
  select: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  menu: {
    position: "absolute",
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderColor: "#999999",
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    borderTopWidth: 0,
    maxHeight: 200,
    zIndex: 1000000000
  },
  option: {
    height: 44,
    paddingLeft: 11,
    flexDirection: 'row',
    alignItems: 'center'
  },
  selectedOption: {
    backgroundColor: ""
  },
  hoveredOption: {
    backgroundColor: "#f6f6f6"
  }
})