import React from 'react'
import styled from 'styled-components/native';
import { View } from 'react-native';
import useDeviceWidth from '../utils/useDeviceWidth'

const Large = styled.Text`
  color: #002C73;
  font-family: SourceSansPro-Regular;
  font-size: 18px;
`
const Regular = styled.Text`
  color: #002C73;
  font-family: SourceSansPro-Regular;
  font-size: 16px;
`
const Caption = styled.Text`
  color: #002C73;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
`

export default function Paragraph(props) {
  const deviceWidth = useDeviceWidth()

  let responsiveStyle = props.style && props.style[deviceWidth] ? props.style[deviceWidth] : props.style
  
  return (
    <View style={{marginTop: 20}}>
      {(() => {
        switch (props.size) {
          case "large":
            return <Large style={responsiveStyle}>{props.children}</Large>
          case "regular":
            return <Regular style={responsiveStyle}>{props.children}</Regular>
          case "caption":
            return <Caption style={responsiveStyle}>{props.children}</Caption>
          default:
            return <Regular style={responsiveStyle}>{props.children}</Regular>
        }
      })()}
    </View>
  )
}