import { View } from "react-native";
import Text from '../../components/TextComponent'
import styled from 'styled-components/native';

export const FormGroup = styled(View)<{marginTop: number}>`
  display: flex;
  margin-top: ${props => props.marginTop}px;
  ${props => props.disabled ?'pointer-events:none; opacity: 0.5;' : ''}
`;

export const Intructions = styled(Text)`
  font-size: 16px;
  margin-top: 4px;
`;

export const LabelWrapper = styled(View)`
  display: flex;
  flex-direction: row;
`;

export const QuestionLabel = styled(Text)`
  color: ${props => props.disabled ? '#7A858C' : '#002C73'};
  font-size: 16px;
  margin-bottom: 12px;
  margin-right: 5px;
  font-family: SourceSansPro-Bold;
`;

export const FormText = styled(Text)`
  color: ${props => props.disabled ? '#7A858C' : '#002C73'};
  font-size: 16px;
  line-height: 24px;
`

export const ButtonWrapper = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 15px;
`;
