import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Linking } from "expo";

const QueryContext = createContext<{initialParams?: object}>({})

/**
 * Hook that navigates users between screens while preserving query parameters.
 * 
 * @param navigation React Native navigation
 */
export const useQueryNavigator = (navigation: any) => {
    const context = useContext(QueryContext)

    return {
        navigate: (route: string, params?: object) => {
            navigation.navigate(route, {
                ...params,
                ...context.initialParams
            })
        }
    }
}

export interface QueryProviderProps {
    children: ReactNode;
}

/**
 * Provider that persists the initial set of query parameters provided upon landing.
 * 
 * @param props
 */
export const QueryProvider = (props: QueryProviderProps) => {
    const [initialParams, setInitialParams] = useState(undefined)

    useEffect(() => {
        const parseUrl = async () => {
            try {
                const url = await Linking.parseInitialURLAsync()
                setInitialParams(url.queryParams)
            } catch (e) {
                console.error(e)
                setInitialParams({})
            }
        }

        parseUrl()
    }, [])

    return (
        <QueryContext.Provider value={{initialParams}}>
            {initialParams !== undefined ? props.children : <></>}
        </QueryContext.Provider>
    )
}
