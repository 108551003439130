import React from "react";
import {Case, Task} from "../../../redux/cases/types";
import Link from "../../link";
import Text from "../../TextComponent";
import {TaskAction} from "./body";
import Checkbox from "../../checkbox";
import {getTaskName} from "../../../utils/tasks";
import {View} from "react-native";
import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import CustomTooltip from '../../customTooltip'


interface TaskLabelProps {
    task: Task;
    case: Case;
    taskAction: TaskAction
    linkOnPress: Function
}


export default function TaskLineItem(props: TaskLabelProps) {
    const { task, taskAction  } = props;
    const { billingProviderName, hasUserStoryAnswers } = props.case;
    const disabled = !task.readyForUser && task.actionReqInd;
    
    if(disabled) {
        const taskName = getTaskName(task, billingProviderName, hasUserStoryAnswers);

        return (
            <CustomTooltip message="Your advisor will alert you when these tasks are ready for you to complete.">
                <Checkbox
                    accessibilityLabel="Not Complete"
                    checked={task.taskCompleteInd}
                    disabled={disabled}
                    labelComponent={<Text size={16} style={{color: "#73767A"}}>{taskName}</Text>}
                    wrapperStyle={{ marginBottom: 8 }}
                />
            </CustomTooltip>
        )
    }

    const onFollowLink = () => {
        if (task.readyForUser && taskAction && !task.taskCompleteInd) {
            props.linkOnPress()
        }
    }

    const taskName = getTaskName(task, billingProviderName, hasUserStoryAnswers);

    const hasAction = task.readyForUser && taskAction && !task.taskCompleteInd

    const renderLabel = () => {
        if (hasAction) {
            return (
                <View style={{maxWidth: '100%'}}>
                    <Link testID={`task-${task.taskId}`}
                    accessible={false}
                    onPress={onFollowLink}
                    style={{fontSize: 16, maxWidth: '100%'}}
                    rightIcon={task.taskName === 'ESign' && <MaterialIcons name="launch"/>}
                    > 
                        {taskName}
                    </Link>
                </View>
            )
        } else {
            return (
                <Text testID={`task-${task.taskId}`} size={16}>{taskName}</Text>
            )
        }
    }

    return (
        <div onKeyUp={(ev)=> {if(ev.key==='Enter') onFollowLink()}}>
            <Checkbox
                accessible={hasAction}
                accessibilityLabel={task.taskCompleteInd ? "Complete" : "Not Complete"}
                checked={task.taskCompleteInd}
                disabled={disabled}
                labelComponent={
                    renderLabel()
                }
                wrapperStyle={{ marginBottom: 8 }}
            />
        </div>
    )
}