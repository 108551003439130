import React, { useEffect, useState } from 'react';
import { Text } from '../../components/index'
import { useIsMobile } from '../../utils/useDeviceWidth'
import { Button } from '../../components'
import Layout ,{ViewAdditionalInfo,NavigationSection} from './Components/Layout';
import { View,Image,StyleSheet } from 'react-native';
import styled from 'styled-components/native'
import axiosInstance from '../../redux/axiosInstance';
import {  AxiosResponse } from 'axios';
import { Questionnaire, storyReasonDescriptions, storyServiceDescriptions, storyServiceNames } from './Components/types';
import { Story as NegotiationStory } from '../../redux/negotiation/story/types';
import { Numbers as NegotiationNumbers } from '../../redux/negotiation/numbers/types';
import { Options as NegotiationOptions } from '../../redux/negotiation/options/types';
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../redux/rootReducer';
import { Case } from '../../redux/cases/types';
import { updateStory } from '../../redux/negotiation/story/actions';
import { fetchCases } from '../../redux/cases/actions';
import { updateNumbers } from '../../redux/negotiation/numbers/actions';
import { updateOptions } from '../../redux/negotiation/options/actions';
import NegotiationSkeleton from './Components/NegotiationSkeleton';
import NegotiationError from './Components/NegotiationError';
import { usePageLoadEffect } from '../../utils/useGoogleTagManager';

enum InitializingState {
  Started = 1,
  Completed=2,
  Error = 3
}

export default function Intro({route, navigation }) {
  const isMobile = useIsMobile()
  const caseId:string= route.params && route.params["caseId"]?route.params["caseId"]:""
  let caseItem:Case  = useSelector((state: ApplicationState) => state.cases.data.find(c=>c.caseId===caseId))
  const casesPending = useSelector((state: ApplicationState) => state.cases.pending)
  const casesError = useSelector((state: ApplicationState) => state.cases.error)
  const dispatch= useDispatch()
  const [initializingStatus,setInitializingStatus] = useState(InitializingState.Completed)
  

  const goToStory=()=>{
    navigation.navigate("NegotiationStory",route.params)
  }


  useEffect(() => {
    initialize()
  }, [caseId,caseItem])

  usePageLoadEffect()
  
  const initialize=()=>{
    if(caseItem) {
      initializeData()
   }
   else{
     dispatch(fetchCases());
   }
  }
  const style = StyleSheet.create({
    textCenter:{
        textAlign:isMobile?'left':'center'
    }
  })
  const isNumber=(value:number)=>{
   return typeof value === 'number'
  }
  
  const  initializeData=async()=>{
    
    const axios = await axiosInstance()
    try {
      setInitializingStatus(InitializingState.Started)
      const questionnaireResponse:AxiosResponse<Questionnaire> = await axios.get(`/self-service-questionnaire?case-id=${caseId}`)
     
      if(questionnaireResponse.data){
        
        const questionnaire=questionnaireResponse.data
        let serviceKey=undefined
        let reasonKey=undefined

        Object.entries(storyServiceNames).forEach(([key, value]) => {
          if(value===questionnaire.type_of_Service__c){
            serviceKey=key
          }
        });
        Object.entries(storyReasonDescriptions).forEach(([key, value]) => {
          if(value===questionnaire.seen_by_Out_Of_Network__c){
            reasonKey=key
          }
        });
       //init story
        const story: NegotiationStory = {
          caseId: questionnaire.case__c?questionnaire.case__c:caseItem.caseId,
          patientName: questionnaire.patient_Name__c?questionnaire.patient_Name__c:caseItem.patientFullName,
          dateOfService: questionnaire.date_of_Service__c?questionnaire.date_of_Service__c:caseItem.firstDateOfService===caseItem.lastDateOfService?caseItem.firstDateOfService:caseItem.datesOfService,
          healthEvent:questionnaire.short_Description__c,
          service:serviceKey,
          serviceNames:storyServiceNames,
          serviceDescriptions:storyServiceDescriptions,
          otherServiceDescription:questionnaire.type_of_Service_Other__c,
          reason:reasonKey,
          reasonDescriptions:storyReasonDescriptions,
          otherReasonDescription:questionnaire.seen_by_Out_Of_Network_Other__c
        }
        
        dispatch(updateStory(story))
        //init numbers
        const numbers: NegotiationNumbers = {
          caseId:questionnaire.case__c?questionnaire.case__c:caseItem.caseId,
          billed: isNumber(questionnaire.provider_Billed_Amount__c)?questionnaire.provider_Billed_Amount__c:caseItem.billedAmt,
          insurancePaid:isNumber(questionnaire.plan_Paid_Amount__c)?questionnaire.plan_Paid_Amount__c:caseItem.planPaidAmt,
          deductible:caseItem.deductibleAmt,
          copay:caseItem.copayAmt,
          coinsurance:caseItem.coinsuranceAmt,
          allowedAmount:isNumber(questionnaire.insurance_Allowed_Amount__c)?questionnaire.insurance_Allowed_Amount__c:caseItem.allowedAmt,
          nonCoveredAmount:isNumber(questionnaire.non_Covered_Amount__c)?questionnaire.non_Covered_Amount__c:caseItem.notCoveredAmt,
          providerAmount:isNumber(questionnaire.patient_Asked_To_Pay_Amount__c)?questionnaire.patient_Asked_To_Pay_Amount__c:undefined
          
        }
        dispatch(updateNumbers(numbers))

        //init options
        const options: NegotiationOptions = {
          caseId:questionnaire.case__c?questionnaire.case__c:caseItem.caseId,
          plannedVisits:questionnaire.realize_out_of_network__c?questionnaire.realize_out_of_network__c:undefined,
          futureVisits:questionnaire.plan_to_See_Provider_More_Than_One_Time__c?questionnaire.plan_to_See_Provider_More_Than_One_Time__c:undefined,
          pastVisits:questionnaire.seen_Provider_More_Than_One_Time__c?questionnaire.seen_Provider_More_Than_One_Time__c:undefined,
          billIncreased:questionnaire.bill_Amount_Increased_Since_Last_Visit__c?questionnaire.bill_Amount_Increased_Since_Last_Visit__c:undefined
        }
        dispatch(updateOptions(options))
        setInitializingStatus(InitializingState.Completed)
      }
    } 
    catch (e) {
      setInitializingStatus(InitializingState.Error)
   }

  }
  return (
  <Layout title="Build a Negotiation Plan" isMobile={isMobile}>
    <View style={{ marginLeft: isMobile?'0':'15%',marginRight: isMobile?'0':'15%'}}>
    {(initializingStatus===InitializingState.Started || casesPending) && <NegotiationSkeleton/>}
    {(initializingStatus===InitializingState.Error || casesError) && <NegotiationError onTryAgain={initialize}/>}
    {initializingStatus===InitializingState.Completed && !casesPending &&
     <>
      <View style={{display:'flex', flexDirection:'column'}}>
        <Text size={20}  style={[style.textCenter]}>Have you received an unexpected bill, or one that was higher than expected? We'll
          help you understand pricing and prepare you to negotiate with your provider to try
          to lower your bill.
        </Text>
        <View style={{marginTop:40}}>
          <Text bold={true} size={24} style={[style.textCenter]}>
            Here are a few key steps we'll take to help you prepare
          </Text>
           <ViewKeySteps isMobile={isMobile}>
            <ViewKeyStep isMobile={isMobile}>
              <View style={{width:175,height:145,alignSelf:'center'}}>
                <Image 
                  accessible={true}
                  accessibilityLabel="Step 1: Know your story" 
                  style={{width:173,height:113}} 
                  source={require('../../assets/images/know-your-story.png')}
                />
              </View>
              <Text bold={true} size={20}>Step 1: Know Your Story</Text>
              <Text size={16}>Having the details of your story in order will make speaking to the insurance company, providers or billing companies much easier.</Text>
            </ViewKeyStep>
            <ViewKeyStep isMobile={isMobile}>
              <View style={{width:175,height:145,alignSelf:'center'}}>
                <Image 
                  accessible={true}
                  accessibilityLabel="Step 2: Know your numbers" 
                  style={{width:133,height:124}} 
                  source={require('../../assets/images/know-your-numbers.png')}
                />
              </View>
                <Text bold={true} size={20}>Step 2: Know Your Numbers</Text>
                <Text size={16}>Understanding your bill, knowing your insurance coverage, and doing competitive research
                will help you understand what you are being charged for and why.</Text>
            </ViewKeyStep>
            <ViewKeyStep isMobile={isMobile}>
              <View style={{width:175,height:145,alignSelf:'center'}}>
                <Image 
                  accessible={true}
                  accessibilityLabel="Step 3: Know your options" 
                  style={{width:171,height:144}} 
                  source={require('../../assets/images/know-your-options.png')}
                />
              </View>
                <Text bold={true} size={20}>Step 3: Know Your Options</Text>
                <Text size={16}>Decide what you want out of the negotiation process. Do you want a lower payment, or to establish a payment plan?
                Having a result in mind will make the process easier.</Text>
            </ViewKeyStep>
          </ViewKeySteps>
        </View>
        <ViewAdditionalInfo isMobile={isMobile}>
          <Text size={18} bold={true}>Before We Begin</Text>
          <Text size={16} style={{marginTop:5}}>
            Gather all documentation regarding your claim including your explanation of benefits (EOB) and your bill from your provider. You should also document your procedure code or CPT code, this is often on your bill or you can call your provider and ask. 
          </Text>
        </ViewAdditionalInfo>
      </View>
      <NavigationSection>
        <Button onPress={goToStory} label='NEXT' />
      </NavigationSection>
    </>
    }
    </View>
  </Layout>

 )
}

const ViewKeySteps = styled.View`
    flex-direction:${(props) => props.isMobile?'column':'row'}
    justify-content: space-between
    align-items: center
    margin-top:40px
`
const ViewKeyStep = styled.View`
    margin-bottom:40px
    width:${(props) => props.isMobile?'90%':'30%'}
    align-self:flex-start
`


