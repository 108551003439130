import { useRoute } from "@react-navigation/native";

const lookupNavigationHistoryActions:Record<string,(event:PopStateEvent)=>void> = {};
const lookupNavigationRoutes:Record<string,string> = {};

const handleNavigationHistory=(event:PopStateEvent)=>{
       const navigationAction = lookupNavigationHistoryActions[window.location.href];
       if(navigationAction)
            navigationAction(event)
};


window.addEventListener("popstate",handleNavigationHistory,true);


const useNavigationHistory=()=>{
  const currentRoute= useRoute()
  const addNavigationHistoryAction=(action:(event:PopStateEvent)=>void)=>{
    lookupNavigationHistoryActions[window.location.href]=action;
  }
  const removeNavigationHistoryAction=(location:string)=>{
    lookupNavigationHistoryActions[location]=undefined;
  }
  const isNavigationRouteValid=(location:string)=>{
     let route= lookupNavigationRoutes[location];
     const compareRoute=`${currentRoute.name}-${currentRoute.params && Object.keys(currentRoute.params).length>0?JSON.stringify(currentRoute.params):''}`;
     if(!route){
        route=compareRoute;
        lookupNavigationRoutes[location]=route;
     }
     return (route===compareRoute)
  }
  return {addNavigationHistoryAction,removeNavigationHistoryAction,isNavigationRouteValid}
}

export default useNavigationHistory