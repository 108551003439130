import React, { ReactNode } from 'react';
import { Modal as ReactModal, Text, TouchableOpacity, View } from "react-native";
import styled from 'styled-components/native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

const CenteredView = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  backgroundColor: rgba(0, 0, 0, 0.5);
`;

const Header = styled(View)`
  display: flex; 
  flex-direction: row; 
  justify-content: flex-end;
`;

const SuccessText = styled(Text)`
  color: #61A60D;
  font-size: 20px;
  margin-top: 15px;
  font-family: 'JekoBold';
`;

const ErrorText = styled(Text)`
  color: #9F2140;
  font-size: 20px;
  margin-top: 15px;
  font-family: 'JekoBold';
`;

export const ModalHeader = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`;

export const ModalBody = styled(View)`
  margin: 15px 0;
  padding: 10px;
  text-align: center;
`;

export const ModalFooter = styled(View)<{align?: 'left' | 'center' | 'right'}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.align === 'left' ? 'flex-start' : props.align === 'right' ? ' flex-end' : 'center'};
  margin: 15px 0 35px 0;
`;

export const ModalText = styled(Text)`
  font-size: 15px;
  color: #002C73;
  font-family: 'JekoRegular';
`;

export const Alert = styled(View)`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left-style: solid;
  border-left-color: #61A60D;
  border-left-width: 10px;
  background-color: #EFF5E5;
  margin: 10px 10px 0;
`;

export const AlertError = styled(View)`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left-style: solid;
  border-left-color: #9f2140;
  border-left-width: 10px;
  background-color: #f7e7e8;
  margin: 10px 10px 0;
`;

export const AlertText = styled(Text)`
  margin-left: 5px;
  font-size: 14px;
  color: rgb(0, 44, 115);
`;

export interface ModalProps {
    children: ReactNode;
    variant?: 'success' | 'error' | 'success-v2' | 'error-v2';
    onClose: () => void;
    visible: boolean;
    overlayStyle?: any;
    modalContentStyle?: any;
    minWidth?: string;
}

export default function Modal(props: ModalProps) {

  const ModalView = styled(View)`
    margin: 20px;
    background-color: white;
    border-radius: 14px;
    padding: 15px;
    shadow-color: '#000';
    shadow-offset-width: 0;
    shadow-offset-height: 2px;
    shadow-opacity: 0.25;
    shadow-radius: 3.84px;
    elevation: 5;
    margin: auto;
    ${props.minWidth ? props.minWidth : 'min-width: 35%;'}
  `;

    return (
        <ReactModal
            transparent={true}
            visible={props.visible}
            onRequestClose={props.onClose}>
            
            <CenteredView style={props.overlayStyle}>
              <div style={{minWidth: props.minWidth ?? '35%'}} data-role="modal">
                <ModalView style={props.modalContentStyle}>
                    <Header>
                        <TouchableOpacity onPress={props.onClose}>
                            <MaterialIcons 
                              accessible={true}
                              accessibilityRole="button"
                              accessibilityLabel="Close Modal"
                              name="close" 
                              size={24} 
                              color='#000' 
                            />
                        </TouchableOpacity>
                    </Header>
                    {props.variant === 'success' &&
                        <ModalHeader>
                            <MaterialIcons name="check-circle" size={40} color='#52A653' />
                            <SuccessText>Success!</SuccessText>
                        </ModalHeader>
                    }
                    {props.variant === 'success-v2' &&
                        <Alert>
                            <MaterialIcons name="check-circle" size={24} color='#61A60D' />
                            <AlertText>Success</AlertText>
                        </Alert>
                    }
                    {props.variant === 'error-v2' &&
                        <AlertError>
                            <MaterialIcons name="cancel" size={24} color='#9F2140' />
                            <AlertText>Error</AlertText>
                        </AlertError>
                    }
                    {props.variant === 'error' &&
                        <ModalHeader>
                            <MaterialIcons name="error" size={40} color='#E00001' />
                            <ErrorText>An error occured</ErrorText>
                        </ModalHeader>
                    }
                    {props.children}
                </ModalView>
              </div>
            </CenteredView>
        </ReactModal>
    )
}
