import React, { ReactNode } from 'react'
import { View, Image } from 'react-native'
import styled from 'styled-components/native'

const Text = styled.Text`
    color: #002C73;
    font-family: SourceSansPro-Regular;
    font-size: 16px;
    margin-left: 6px;
`

export interface CheckboxProps {
    checked: boolean
    labelComponent?: ReactNode
    label?: string
    textStyle?: object
    wrapperStyle?: object
    disabled?: boolean
    testID?: string;
    accessible?: boolean;
    accessibilityLabel?: string;
}

export default function Checkbox(props: CheckboxProps) {
    return (
        <View
            accessible={props.accessible}
            accessibilityRole={'checkbox'}
            accessibilityLabel={`checkbox`}
            aria-checked={props.checked}
            testID={props.testID}
            style={[
                { flexDirection: 'row', alignItems: 'flex-start' },
                props.wrapperStyle,
            ]}>
            <Image
                accessibilityLabel={props.accessibilityLabel}
                source={
                    props.checked
                        ? require('../assets/images/ic/ic-check/ic-check-box.png')
                        : require('../assets/images/ic/ic-check/ic-check-blank.png')
                }
                style={{
                    width: '16px',
                    height: '16px',
                    marginRight: 6,
                    marginTop: 2
                }}
            />
            {props.labelComponent ? (
                props.labelComponent
            ) : (
                <Text style={props.textStyle}>{props.label}</Text>
            )}
        </View>
    )
}
