import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/layout'
import Card from 'react-native-elements/src/card/Card'
import ProgressBar from './ProgressBar'
import CurrentStep from './CurrentStep'
import StepButtons from './StepButtons'
import { View } from 'react-native'
import { fetchMemberStories, updateMemberStory } from '../../redux/memberstory/actions';
import { AxiosError, AxiosResponse } from 'axios';
import useGoogleTagManager, { GTMEvent } from '../../utils/useGoogleTagManager';
import { WizardMemberStory } from '../../redux/memberstory/types';
import SkeletonQuestion from '../YourExperienceDetails/SkeletonQuestion'
import Modal, { ModalBody, ModalFooter, ModalText } from '../../components/modal';
import { Button } from '../../components';
import AdvisorPanel from '../../components/advisorpanel';
import { ApplicationState } from '../../redux/rootReducer';
import { fetchCases } from '../../redux/cases/actions';
import { useIsMobile, useResize } from '../../utils/useDeviceWidth';
import Skeleton from '../../components/Skeleton';
import useDeviceWidth from '../../utils/useDeviceWidth'
import Text from '../../components/TextComponent'
import { useQueryNavigator } from "../../context-api/query"
import { ButtonWrapper, FormGroup, FormText, Intructions, LabelWrapper, QuestionLabel } from './layout'
import { QuestionResponseTemplate } from '../../redux/memberstory/types';

const cardTitleStyle = {
  borderTopLeftRadius: 14,
  borderTopRightRadius: 14,
  display: 'flex',
  justifyContent: 'center',
  color: '#FFF',
  fontSize: 14,
  paddingLeft: 32,
  paddingTop: 10,
  marginBottom: 10,
  fontFamily: "SourceSansPro-Bold",
  letterSpacing: 0.77,
  height: 52
}

const cardStyle = {
  padding: 30,
  borderRadius: 14,
  borderWidth: 0,
  shadowColor: "rgba(0, 44, 115, 0.1)",
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 1,
  shadowRadius: 10,
  elevation: 3,
  margin: 0
}

export default function WizardViewer({ route, navigation }) {
  const navigator = useQueryNavigator(navigation)
  const [dataLoading, setDataLoading] = useState(false)
  const [error, setError] = useState(undefined)
  const [caseNumber, setCaseNumber] = useState(undefined)
  const [caseId, setCaseId] = useState(undefined)
  const [questionGroups, setQuestions] = useState([])
  const gtm = useGoogleTagManager()
  const paramCaseId = route.params?.caseId
  const [modal, setModal] = useState({
    visible: null,
    variant: null,
    message: null,
    onClose: null
  })
  let selectedCase = useSelector((state: ApplicationState) => state.cases.data.find(c => c.caseId === paramCaseId));
  const dispatch = useDispatch()

  useEffect(() => {
    if(!selectedCase) {
      dispatch(fetchCases());
    }
  }, [])

  const isMobile = useIsMobile()
  const responsiveStyle = useDeviceWidth()
  
  const groupMoonQuestions = (moonQuestions: QuestionResponseTemplate[]) => {
    const groupsOfQuestionsById = [
      [15, 14, 13],
      [3, 1, 9, 2],
      [5, 6, 7, 4],
      [10, 12, 11, 8]
    ]

    let groupedQuestions = []

    const questionDependencies = {
      6: {
        questionId: 5,
        answerIds: [13]
      },
      9: {
        questionId: 1,
        answerIds: [2, 3]
      }
    }

    for (let questionGroupIndex = 0; questionGroupIndex < groupsOfQuestionsById.length; questionGroupIndex++) {
      const groupNumber = questionGroupIndex + 1
      const questionGroup = groupsOfQuestionsById[questionGroupIndex]
      const questions = []
      for (let questionIndex = 0; questionIndex < questionGroup.length; questionIndex++) {
        const question = moonQuestions.find(q => q.questionId === questionGroup[questionIndex])
        questions[questionIndex] = {
          ...question,
          groupNumber,
          dependsOn: questionDependencies[question.questionId],
          required: true,
          displayOrder: questionIndex,
          response: {
            answerId: question.givenAnswer?.answerId,
            answerText: question.givenAnswer?.answerText,
            questionId: question.questionId
          }
        }
      }
      groupedQuestions.push({
        groupNumber,
        questions
      })
    }
    return groupedQuestions
  }

  useEffect(() => {
    setCaseId(route.params?.caseId)
  }, [route.params?.caseId])

  useEffect(() => {
    setCaseNumber(selectedCase?.caseNumber);

    if (selectedCase) {
      (async () => {
        setDataLoading(true)
        const request = await fetchMemberStories(selectedCase)

        request.payload
          .then((response: AxiosResponse<WizardMemberStory>) => {
            setQuestions(response.data.questionGroups || groupMoonQuestions(response.data.patientStoryQuestionsDto))
          })
          .catch((e: AxiosError) => {
            console.error(e)

            setQuestions([])
  
            if (e.response && e.response.headers['content-type']?.indexOf('text/plain') > -1) {
              setError(e.response.data)
            } else {
              const charLimit = str => str.substring(0, 100);
              const errorText = 'We were unable to display the results of your member story for this case. Please try again in a few minutes.'
  
              gtm.pushEvent(GTMEvent.MemberStoryError, {
                'event': 'fetchMemberStoriesData',
                'errorType': 'Unable to display member stories',
                'errorMessage': charLimit(errorText)
              })
  
              setError(errorText)
            }
          })
          .finally(() => setDataLoading(false))
      })()
    }
  }, [selectedCase])

  const formatAnswer = (answerText:string,answerType:string)=>{
    let formattedAnswerText=answerText;
    if(answerType && answerText){
      if (answerType.toLowerCase() === "currency"){
        let maximumFractionDigits=2
        //check if it has decimal values
        let answerFloat =parseFloat(answerText)
        if (isNaN(answerFloat)) { 
          answerFloat= 0; 
        }
        if((answerFloat % 1)===0)
            maximumFractionDigits=0

        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits:maximumFractionDigits
        });
        formattedAnswerText=formatter.format(answerFloat)
      }
    }
    return formattedAnswerText;
  }

  const closeModal = () => {
    setModal({
      visible: false,
      variant: undefined,
      message: undefined,
      onClose: undefined
    })
  }

  const handleNavigateHome = () => {
    navigator.navigate('Home')
  }

  let styles: any = {}
  if (responsiveStyle === 'largeScreen' || responsiveStyle === 'tabletDevice') {
    let ccflex = responsiveStyle === 'largeScreen' ? '100%' : '100%'
    styles = {
      caseCard: {
        display: 'flex',
        backgroundColor: "#FFF",
        borderRadius: 3,
        borderWidth: 0,
        shadowColor: "rgba(244, 165, 135, 0.2)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        padding: 32
      }
    }
  } else {
    styles = {
      caseCard: {
        display: 'flex',
        backgroundColor: "#FFF",
        borderRadius: 3,
        borderWidth: 0,
        shadowColor: "rgba(244, 165, 135, 0.2)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 12.22,
        padding: 32
      }
    }
  }

  return (
    <>
      <Layout
        navigation={navigation}
        breadcrumbs={[{
          label: 'Home',
          route: 'Home'
        }, {
          label: 'Your Experience',
          route: 'YourExperience'
        }, {
          label: `${selectedCase?`Case #${caseNumber}`:''}`,
        }]}
        introHeading={
          <View style={{flexDirection:'row',alignItems:'center'}}>
            <span>Case #</span>
            {caseNumber?<span>{caseNumber}</span>:
              <View style={{height:40,width:250}}>
                <Skeleton/>
              </View>
            }
          </View>
        }
        wizard
        wizardView
      >
        <Card containerStyle={cardStyle}>
          <View style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
          }}>
            <View style={{ width: '100%' }}>
              {
                (!questionGroups || dataLoading) && [...Array(10).keys() as any].map((i: number) => <SkeletonQuestion key={`skeleton-question-${i}`} />)
              }

              {questionGroups && !dataLoading &&
                <>
                  {questionGroups.map((res) => {
                      return res.questions.map(({ 
                        answerFormat, 
                        questionId, 
                        questionText,
                        possibleAnswers,
                        response: { 
                          answerText,
                          answerId
                      }}) => {
                        if(answerText || answerId){
                          return (
                            <FormGroup key={questionId} marginTop={24}>
                              <QuestionLabel>{questionText}</QuestionLabel>
                              {
                                answerText ? <FormText>{formatAnswer(answerText, answerFormat)}</FormText> : <FormText>{possibleAnswers.find(a => a.answerId === answerId).answerText}</FormText>
                              }
                            </FormGroup>
                          )
                        }
                      })
                    }
                  )}
                </>
              }

              <View
                style={{
                  marginTop: '30px',
                  marginBottom: '30px'
                }}
              >
                <Button
                  label={'Return to Homepage'}
                  onPress={() => handleNavigateHome()}
                  testID={`return-to-homepage-button-${paramCaseId}`}
                />
              </View>

              <View>
                <Text style={{ fontStyle: 'italic', fontSize: '16px', color: '#002C73' }}>If you have any updates about your experience, please contact your advisor.</Text>
              </View>

            </View>
          </View>
        </Card>
        
        <View>
          {selectedCase && <><AdvisorPanel cases={[selectedCase]} /></>}
        </View>
      </Layout>

      <Modal {...modal}>
        {modal.variant === 'error' || modal.variant === 'success' ? (
          <>
            <ModalBody>
              <ModalText>{modal.message}</ModalText>
            </ModalBody>
            <ModalFooter>
              <Button
                label={'Close'}
                onPress={modal.onClose}
                testID={`member-story-modal-button-${paramCaseId}`}
              />
            </ModalFooter>
          </>
        ) : null}
      </Modal>
    </>
  )
}