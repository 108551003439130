// builds css style rules that expand shorthand notation into explicit properties
const createStyle = (property: string, values: number[]) => {
    if (values.length === 1) {
        return {
            [property]: values[0]
        }
    } else if (values.length === 2) {
        return {
            [`${property}Top`]: values[0],
            [`${property}Right`]: values[1],
            [`${property}Bottom`]: values[0],
            [`${property}Left`]: values[1]
        }
    } else if (values.length === 3) {
        return {
            [`${property}Top`]: values[0],
            [`${property}Right`]: values[1],
            [`${property}Bottom`]: values[2],
            [`${property}Left`]: values[1]
        }
    } else if (values.length === 4) {
        return {
            [`${property}Top`]: values[0],
            [`${property}Right`]: values[1],
            [`${property}Bottom`]: values[2],
            [`${property}Left`]: values[3]
        }
    } else {
        return {}
    }
}

export const padding = (...values: number[]) => createStyle('padding', values)
export const margin = (...values: number[]) => createStyle('margin', values)
