import React from 'react';
import { 
  View, 
  Image
} from 'react-native';
import TextComponent from '../TextComponent'
import useDeviceWidth from '../../utils/useDeviceWidth'
import FooterItem from './footerItem'

export default function Footer() {
  const responsiveStyle = useDeviceWidth()

  const styles = {
    largeScreen: {
      footer: {
        backgroundColor: '#002C73',
        paddingTop: '30px',
        paddingRight: '84px',
        paddingBottom: '24px',
        paddingLeft: '84px',
      },
      footerNav: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '30px',
        marginBottom: '30px'
      },
      footerItem1: {},
      footerItem2: {
        marginLeft: '50px'
      },
      footerItem3: {
        marginLeft: '50px'
      },
      footerItem4: {
        marginLeft: '50px'
      },
      tradeMark1: {
        fontSize: '12px',
        lineHeight: '1.5',
        color: '#8CC6E7',
        marginBottom: '5px'
      },
      tradeMark2: {
        fontSize: '12px',
        lineHeight: '1.5',
        color: '#8CC6E7'
      },
      footerBar: {
        height: '15px',
        background: 'linear-gradient(90deg, #8CC6E7 0%, #071D49 50%, #E64B38 100%)'
      },
    },
    tabletDevice: {
      footer: {
        backgroundColor: '#002C73',
        paddingTop: '30px',
        paddingRight: '84px',
        paddingBottom: '24px',
        paddingLeft: '84px',
      },
      footerNav: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '30px',
        marginBottom: '30px'
      },
      footerItem1: {},
      footerItem2: {
        marginLeft: '50px'
      },
      footerItem3: {
        marginLeft: '50px'
      },
      footerItem4: {
        marginLeft: '50px'
      },
      tradeMark1: {
        fontSize: '12px',
        lineHeight: '1.5',
        color: '#8CC6E7',
        marginBottom: '5px'
      },
      tradeMark2: {
        fontSize: '12px',
        lineHeight: '1.5',
        color: '#8CC6E7'
      },
      footerBar: {
        height: '15px',
        background: 'linear-gradient(90deg, #8CC6E7 0%, #071D49 50%, #E64B38 100%)'
      },
    },
    width295_359: {
      footer: {
        backgroundColor: '#002C73',
        paddingTop: '30px',
        paddingBottom: '80px',
        paddingLeft: '14px',
        paddingRight: '14px',
        alignItems: 'center',
        flexDirection: 'column'
      },
      footerNav: {
        alignItems: 'center'
      },
      footerItem1: {
        marginTop: 30
      },
      footerItem2: {
        marginTop: '14px',
      },
      footerItem3: {
        marginTop: '14px',
      },
      footerItem4: {
        marginTop: '14px',
        marginBottom: '36px'
      },
      tradeMark1: {
        width: '75vw',
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: '1.5',
        color: '#8CC6E7',
        marginBottom: '18px'
      },
      tradeMark2: {
        width: '90vw',
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: '1.5',
        color: '#8CC6E7'
      },
      footerBar: {
        height: '15px',
        background: 'linear-gradient(90deg, #8CC6E7 0%, #071D49 50%, #E64B38 100%)'
      },
    },
    width375_811: {
      footer: {
        backgroundColor: '#002C73',
        paddingTop: '30px',
        paddingBottom: '80px',
        paddingLeft: '14px',
        paddingRight: '14px',
        alignItems: 'center',
        flexDirection: 'column'
      },
      footerNav: {
        alignItems: 'center'
      },
      footerItem1: {
        marginTop: 29
      },
      footerItem2: {
        marginTop: '14px',
      },
      footerItem3: {
        marginTop: '14px',
      },
      footerItem4: {
        marginTop: '14px',
        marginBottom: '36px'
      },
      tradeMark1: {
        width: '75vw',
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: '1.5',
        color: '#8CC6E7',
        marginBottom: '18px'
      },
      tradeMark2: {
        width: '90vw',
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: '1.5',
        color: '#8CC6E7'
      },
      footerBar: {
        height: '15px',
        background: 'linear-gradient(90deg, #8CC6E7 0%, #071D49 50%, #E64B38 100%)'
      },
    },
    wdth360_374: {
      footer: {
        backgroundColor: '#002C73',
        paddingTop: '30px',
        paddingBottom: '80px',
        paddingLeft: '14px',
        paddingRight: '14px',
        alignItems: 'center',
        flexDirection: 'column'
      },
      footerNav: {
        alignItems: 'center'
      },
      footerItem1: {
        marginTop: 29
      },
      footerItem2: {
        marginTop: '14px'
      },
      footerItem3: {
        marginTop: '14px',
      },
      footerItem4: {
        marginTop: '14px',
        marginBottom: '36px'
      },
      tradeMark1: {
        width: '75vw',
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: '1.5',
        color: '#8CC6E7',
        marginBottom: '18px'
      },
      tradeMark2: {
        width: '90vw',
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: '1.5',
        color: '#8CC6E7'
      },
      footerBar: {
        height: '15px',
        background: 'linear-gradient(90deg, #8CC6E7 0%, #071D49 50%, #E64B38 100%)'
      },
    },
    
  }

  const year = new Date().getFullYear()
  return (
    <>
      <View style={styles[responsiveStyle].footerBar} />
      <View style={styles[responsiveStyle].footer}>
        <Image
          accessible={false}
          accessibilityLabel="Naviguard" 
          source={require("../../assets/images/logos/naviguard_logo_footer.svg")} 
          style={{
            display: 'block',
            width: '200px',
            height: '36px'
          }}
        />
        <View style={styles[responsiveStyle].footerNav}>
          <FooterItem
            linkURL="https://www.naviguard.com/about/"
            footerStyle={styles[responsiveStyle].footerItem1}
            footerText="About Naviguard"
          />
          <FooterItem
            linkURL="https://www.naviguard.com/about/member-case-resolution-faq?CTA=ResolutionSupport&utm_source=NVG&utm_medium=web&utm_campaign=memberportal&utm_term=negotiationresolution&utm_content=faq/"
            footerStyle={styles[responsiveStyle].footerItem2}
            footerText="FAQ"
          />
          <FooterItem
            linkURL="https://www.naviguard.com/privacy-policy/"
            footerStyle={styles[responsiveStyle].footerItem3}
            footerText="Privacy"
          />
          <FooterItem
            linkURL="https://www.naviguard.com/terms-of-use/"
            footerStyle={styles[responsiveStyle].footerItem4}
            footerText="Terms"
          />
        </View>

        <TextComponent style={styles[responsiveStyle].tradeMark1}>Naviguard<sup style={{ fontSize: '6px' }}>TM</sup> is a member of the Nationwide Multistate Licensing System &amp; Registry. NMLS # 2051222</TextComponent>
        <TextComponent style={styles[responsiveStyle].tradeMark2}>© {year} Naviguard, Inc. Naviguard is a trademark of Naviguard, Inc.</TextComponent>
      </View>
    </>
  )
}