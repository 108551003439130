import React from 'react'
import {
    StyleSheet,
    View
} from 'react-native'
import Text from '../../TextComponent'
import { Case } from '../../../redux/cases/types'

export interface SectionNameProps {
    case: Case
    isMobile: boolean
    children?:JSX.Element|JSX.Element[]
}

export default function SectionName(props: SectionNameProps) {
   
    return(
        <View style={props.isMobile ? mobile.detailSection : desktop.detailSection}>
            <View style={props.isMobile ? mobile.nameSection : desktop.nameSection}>
                <Text
                    style={[
                        styles.columnText,
                        { textTransform: 'capitalize', marginBottom: 5 },
                    ]}>
                        <div id="maskpii-name">
                            Patient: {props.case.patientFullName?.toLowerCase()}
                        </div>
                </Text>
                {props.case.firstDateOfService && (
                    <Text
                        style={styles.columnText}>
                            <div id="maskpii-dos">
                                Service Date: {props.case.firstDateOfService}
                            </div>
                    </Text>
                )}
            </View>
            <View style={props.isMobile ? mobile.textSection : desktop.textSection}>
                <Text>
                    Please tell us about the experience you had with {props.case.billingProviderName}. The more details, the better. This will help avoid delays in reviewing and negotiating your bill.
                </Text>
            </View>
            {props.children}
        </View>
    )
}

const styles = StyleSheet.create({
    columnText: {
        color: '#002C73',
        fontFamily: 'SourceSansPro-Regular',
        fontSize: 16,
    }
})

const mobile = StyleSheet.create({
    detailSection: {
        flex: 1,
        width: '100%',
        flexDirection: 'column',
    },
    nameSection: {
        backgroundColor: '#F5F9FB',
        borderRadius: 7,
        padding: 10,
        marginBottom: 20,
        width: 'fit-content',
        flexBasis: '100%',
    },
    textSection: {
        width: '100%',
        marginBottom: 20,
    }
})

const desktop = StyleSheet.create({
    detailSection: {
        flex: 1,
        maxWidth: '60%',
        flexDirection: 'column',
        minWidth:'60%'
    },
    nameSection: {
        backgroundColor: '#F5F9FB',
        borderRadius: 7,
        padding: 10,
        marginBottom: 20,
        width: 'fit-content'
    },
    textSection: {
        width: '80%',
        marginBottom: 20,
    }
})
 
