import React, { useEffect, useState } from "react"
import Modal, { ModalBody,  ModalFooter,  ModalHeader} from ".."
import { Button } from "../.."
import styled from 'styled-components/native';
import { Text} from '../../../components'
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import SessionTicket from "./useSessionTicket";
import { authProvider } from "../../../authProvider";




export default function SessionTimeout (){
    const initialCounterDurationSeconds=1080 //18 minutes
    const finalCounterDurationSeconds=120 //2 minutes
    const [warningMessageVisibility,setWarningMessageVisibility]=useState(false)
    const {createSessionTicket,
        destroySessionTicket,
        useSessionTicketCreated,
        useSessionTicketDestroyed,
        useWarningStarted,
        updateCounter
    }=SessionTicket(initialCounterDurationSeconds ,finalCounterDurationSeconds)

    useEffect(()=>{
        window.addEventListener("focus",updateCounter)
        createSessionTicket()
    }
    ,[])

    useSessionTicketCreated(()=>{
        setWarningMessageVisibility(false)
        addActivityListeners()
        authProvider.refreshToken();
    })
    useSessionTicketDestroyed(()=>{
        setWarningMessageVisibility(false);
        removeActivityListeners();
        authProvider.logout()
    })

    useWarningStarted(()=>{
        removeActivityListeners()
        setWarningMessageVisibility(true); 
     })
      
    const addActivityListeners=()=>{
        window.addEventListener("click",createSessionTicket,true)
        window.addEventListener("touchstart",createSessionTicket,true)
    }
    const removeActivityListeners=()=>{
        window.removeEventListener("click",createSessionTicket,true)
        window.removeEventListener("touchstart",createSessionTicket,true)
    }

   
    

    return(      
        <Modal visible={warningMessageVisibility} onClose={createSessionTicket}>
            <ModalHeader>
                <MaterialIcons name="error" size={40} color='#E00001' />
                <Text accessibilityRole="heading" ariaLevel="2" bold={true} size={24} style={{marginTop:21}}>Your session is expiring soon</Text>
            </ModalHeader>
            <PaddedModalBody>
                <Text size={16} >You'll be logged out shortly.  Do you want to stay logged in?</Text>
            </PaddedModalBody>
            <MarginedModalFooter align="right">
                <Button label="LOG OFF" variant="secondary"  onPress={destroySessionTicket} style={{marginRight: 12}} />
                <Button label="CONTINUE SESSION" onPress={createSessionTicket} />
            </MarginedModalFooter>
        </Modal>
    )
}
const PaddedModalBody = styled(ModalBody)`
  padding: 0 40px 0 40px;
`;
const MarginedModalFooter = styled(ModalFooter)`
  margin: 43px 20px 20px 20px;
`;


