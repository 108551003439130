export const FETCHED_MESSAGE_COUNT = 'FETCHED_MESSAGE_COUNT'

export interface MessagesState {
    messageCount: number;
}

export interface FetchCount {
    type: typeof FETCHED_MESSAGE_COUNT;
    payload:  boolean;
}
