import { useAppInsightsContext} from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useRoute } from "@react-navigation/native";
import {Case} from '../redux/cases/types'

export enum AIEvent {
    // event fired when a bill upload task is clicked from a case card
    UploadClick = 'uploadClick',
  
    // event fired when a file has successfully been uploaded
    Upload = 'upload',
  
     // event fired when a member click to view a file
     DocumentView = 'documentView',
  
      // event fired when  a member click to initiate download of a file
      DocumentDownload = 'documentDownload',
  
      // event fired when  a member click to delete of an uploaded file
      DocumentDelete = 'documentDelete',

       // event fired when a member click to view the list of files
       DocumentList = 'documentList',

       //event fired when loading a screen
       LoadScreen ='loadScreen'
  
  }
  export type AIDocumentEventTypes =
 | AIEvent.UploadClick
 | AIEvent.Upload
 | AIEvent.DocumentView
 | AIEvent.DocumentDownload
 | AIEvent.DocumentDelete
 | AIEvent.DocumentList
 
 export interface Document{
    id?:string
    fileName?: string;
    fileSize?:number;
}
 
 export default  function useAppInsights(){
    const appInsights = useAppInsightsContext();
    const route =useRoute()

    return {
        trackEvent:appInsights.trackEvent,
        trackException:appInsights.trackException,
        trackDocumentEvent:(eventType:AIDocumentEventTypes, caseItem:Case,document?:Document)=>{
            appInsights.trackEvent({name:eventType.toString()},{document,case:caseItem})
        },
        trackLoadScreenEvent:(cases:Case[])=>{
           const caseIdentifiers =cases && cases.length>0? cases.map(c=> {return {caseId:c.caseId,caseNumber:c.caseNumber}}):null
            appInsights.trackEvent({name:`${AIEvent.LoadScreen.toString()} ${route.name}`},{cases:caseIdentifiers})
        },
        trackDocumentException:(eventType:AIDocumentEventTypes,exception:Error,message:string,caseItem:Case,document:Document)=>{
                appInsights.trackException({exception,severityLevel:SeverityLevel.Error},
                               {eventName:eventType.toString(),message,document,case:caseItem})
        }
    }
}