import React, {  useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom"
import { 
  StyleSheet,
  ScrollView, 
  View, 
  TouchableOpacity,
  BackHandler,
  Dimensions,
  Platform
} from "react-native"
import { Hoverable } from 'react-native-web-hover'
import Ionicons from '@expo/vector-icons/Ionicons';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import ListItem from 'react-native-elements/src/list/ListItem'
import Text from './TextComponent'

const Menu = ({ children }) => {
  return createPortal(<View accessibilityRole="combobox" style={{ position: "absolute", top: 0, left: 0, height: '100%', width: '100%' }}>{children}</View>, document.body)
}

const isBrowser = () => Platform.OS === 'web' && 'document' in global;
const SCREEN_INDENT = 5;

export default function Dropdown(props) {
  const anchorRef = useRef(null)
  const { visible, onDismiss, showDropDown, value, setValue, list, dropDownContainerMaxHeight} = props;
  const [, setDisplayValue] = useState<any>();
  const [ menuLayout, setMenuLayout ] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  })
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });
  const dropdown=useRef<HTMLDivElement>()
  let selectItemIndex=-1;

  useEffect(() => {
    let mounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    if(mounted && !signal.aborted) {
      const _label = list.find((_) => _.value === value)?.label;
      if (_label) {
          setDisplayValue(_label);
      }
    }

    return () => {
      mounted = false;
      abortController.abort();
    };
  }, [list, value]);


  const handleDismiss = () => {
    if(visible) {
      onDismiss()
    }

    return true
  }

  const handleKeypress = (e: KeyboardEvent) => {
    if (e.key === 'Escape' || e.key==='Tab') {
      onDismiss();
    }
  }

  const attachListeners = () => {
    BackHandler.addEventListener('hardwareBackPress', handleDismiss);
    Dimensions.addEventListener('change', handleDismiss);

    isBrowser() && document.addEventListener('keyup', handleKeypress);
    isBrowser() && document.addEventListener('keydown', handleArrowKey);
  };

  const removeListeners = () => {
    BackHandler.removeEventListener('hardwareBackPress', handleDismiss);
    Dimensions.removeEventListener('change', handleDismiss);

    isBrowser() && document.removeEventListener('keyup', handleKeypress);
    isBrowser() && document.removeEventListener('keydown', handleArrowKey);
  };

  useEffect(() => {
      if(visible) {
        attachListeners()
      }
    return () => {
      removeListeners()
    };
  }, [visible])

 
  const listItemFocus=(index:number)=>{
    if(dropdown.current){
      const focusables= dropdown.current.querySelectorAll("[data-focusable=true]")
      if(focusables.length>0){
        (focusables[index] as HTMLElement).focus()
      }
    }
  }

  const handleArrowKey=(e: KeyboardEvent)=>{
    //avoid screen scrolling
    e.preventDefault()

    switch(e.key){
      case 'ArrowUp':
        selectItemIndex=selectItemIndex>0? selectItemIndex-1:0
        listItemFocus(selectItemIndex)
        break;
      case 'ArrowDown':
        const itemCount =list.length
        selectItemIndex=Math.max(0,selectItemIndex<itemCount-1? selectItemIndex +1:itemCount-1)
        listItemFocus(selectItemIndex)
        break;
      default:
        break;
    }
  }



  let coord = { top:0, left:0 }
  if(anchorRef.current) {
    const pos = anchorRef.current.getBoundingClientRect()
    coord = getPosition({ left: pos.x,top: pos.y, menuLayout, inputLayout })
  }
 
  return (
    <View ref={anchorRef} accessible={false} onLayout={(event) =>setInputLayout(event.nativeEvent.layout) }>
      <TouchableOpacity accessible={false} onPress={showDropDown}>
        <View accessible={false}>
          <View accessible={false} style={styles.dropdown}>
            {
              props.staticLabel ? props.staticLabel
              : <Text style={{fontSize: 16, marginRight: 5, textTransform: 'capitalize'}}>{props.value?.toLowerCase()}</Text>
            }
            {
              props.rightIcon ? props.rightIcon
              : <Ionicons accessible={false} name="md-arrow-dropdown" size={24} color="#002C73" />
            }
          </View>
        </View>
        {
        visible ?
          <Menu>
            <TouchableOpacity accessibilityRole="button" accessible={false} style={{height: '100%'}} onPress={onDismiss}>
              <View style={StyleSheet.absoluteFill} />
            </TouchableOpacity>
            <View
              onLayout={(event) => setMenuLayout(event.nativeEvent.layout)}
              style={{ 
                position: "absolute", 
                top: coord.top + inputLayout?.height + 10, 
                left: coord.left
              }}
            >
              <View style={styles.menuStyle}>
                <ScrollView 
                  style={{ 
                    maxHeight: dropDownContainerMaxHeight || 200, 
                    minWidth: inputLayout?.width * 2,
                    width: inputLayout?.width * 2.3,//1.5,
                    borderRadius: 7
                }}>

                  <View style={styles.listStyle}>
                    <div ref={dropdown} >
                  {list.map((_item, _index) => ( 
                    <Hoverable key={`hoverable-${_index}`} testID={`select-${props.testID}-${_index}`}>
                      {({ hovered }) => (
                        <ListItem
                          Component={TouchableOpacity}
                          accessible={true}
                          accessibilityLabel={_item.label}
                          testID="select-option"
                          key={_index} 
                          onPress={() => {
                            let itemValue = _item[props.valueKey] || _item.value
                            setValue(itemValue);
                            if(props.onSelect) { props.onSelect(_item) }
                            if (onDismiss) { onDismiss() }
                          }}
                          containerStyle={{ 
                            width: '100%', 
                            backgroundColor: hovered ? 
                              '#E9F2F9' 
                            : ( value === _item.value ? props.selectedItemBackground : 'none'),
                            //is the item selected? if so, apply proper style
                            borderLeft: hovered ? '0' : (value === _item.value ||JSON.stringify(value)=== JSON.stringify(_item[props.valueKey])  ? '8px solid #0074C8' : '0'),
                          }}
                        >
                          <ListItem.Content style={[styles.listItemContent, {marginLeft: hovered ? '16px' : (value === _item.value ? '8px' : '16px')}]}>
                          {
                            props.leftIcon &&
                              <MaterialIcons name={props.leftIconName} size={16} color={(_item[props.valueKey] || _item.value) === value ? "green" : "#FFF"} />
                          }
                            <ListItem.Title style={styles.listItemTextStyle}>
                              {_item[props.labelKey]?.toLowerCase() || _item.label?.toLowerCase()}
                            </ListItem.Title>
                          </ListItem.Content>
                        </ListItem>
                      )}
                    </Hoverable>
                  ))}
                    </div>
                  </View>
                </ScrollView>
              </View>
            </View>
          </Menu>
        : null
      }
      </TouchableOpacity>
      
    </View>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
  },
  shadowMenuContainer: {
    opacity: 0,
    paddingVertical: 8,
    elevation: 8,
  },
  dropdown: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    borderBottomWidth: 0,
    borderBottomColor: "#002C73"
  },
  menuStyle: {
    borderRadius: 7,
    borderWidth: 0,
    shadowColor: "rgba(42, 46, 114, 0.2)",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 1,
    shadowRadius: 8,

    elevation: 3,
  },
  listStyle: {
    backgroundColor: "#FFF",
  },
  listItem: {
    width: '100%',
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  listItemContent: {
    padding: 0, 
    marginLeft: 16,
    flexDirection: 'row', 
    alignItems: 'center'
  },
  listItemTextStyle: {
    color: "#002C73",
    fontFamily: "JekoRegular",
    fontSize: '14px',
    width: '100%',
    fontWeight: 700,
    textTransform: 'capitalize'
  }
});

const getPosition = ({ left, top, menuLayout, inputLayout }) => {
  const additionalVerticalValue = Platform.select({ default: 0 });

  const windowLayout = Dimensions.get('window');

  left = left - 20
  // Check if menu fits horizontally and if not align it to right.
  const maxLeft= windowLayout.width - menuLayout.width - SCREEN_INDENT;
  if (left <= maxLeft) {
    // Check if menu position has enough space from left side
    if (left < SCREEN_INDENT) {
      left = SCREEN_INDENT;
    }
  } else {
    left = maxLeft;
  }

  // If the menu is larger than available vertical space,
  // calculate the height of scrollable view
  let scrollableMenuHeight = 0;

  // Check if the menu should be scrollable
  if (
    // Check if the menu overflows from bottom side
    top >=
      windowLayout.height -
        menuLayout.height -
        SCREEN_INDENT -
        additionalVerticalValue &&
    // And bottom side of the screen has more space than top side
    top <= windowLayout.height - top
  ) {
    // Scrollable menu should be below the anchor (expands downwards)
    scrollableMenuHeight =
      windowLayout.height - top - SCREEN_INDENT - additionalVerticalValue;
  } else if (
    // Check if the menu overflows from bottom side
    top >=
      windowLayout.height -
        menuLayout.height -
        SCREEN_INDENT -
        additionalVerticalValue &&
    // And top side of the screen has more space than bottom side
    top >= windowLayout.height - top &&
    // And menu overflows from top side
    top <=
      menuLayout.height -
        inputLayout.height +
        SCREEN_INDENT -
        additionalVerticalValue
  ) {
    // Scrollable menu should be above the anchor (expands upwards)
    scrollableMenuHeight =
      top + inputLayout.height - SCREEN_INDENT + additionalVerticalValue;
  }

  // Scrollable menu max height
  scrollableMenuHeight =
    scrollableMenuHeight > windowLayout.height - 2 * SCREEN_INDENT
      ? windowLayout.height - 2 * SCREEN_INDENT
      : scrollableMenuHeight;

  // Menu is typically positioned below the element that generates it
  // So first check if it fits below the anchor (expands downwards)
  if (
    // Check if menu fits vertically
    top <=
      windowLayout.height -
        menuLayout.height -
        SCREEN_INDENT -
        additionalVerticalValue ||
    // Or if the menu overflows from bottom side
    (top >=
      windowLayout.height -
        menuLayout.height -
        SCREEN_INDENT -
        additionalVerticalValue &&
      // And bottom side of the screen has more space than top side
      top <= windowLayout.height - top)
  ) {

    // Check if menu position has enough space from top side
    if (top < SCREEN_INDENT) {
      top = SCREEN_INDENT;
    }
  } else {
    
    top += inputLayout.height - (scrollableMenuHeight || menuLayout.height);

    const bottom =
      top +
      (scrollableMenuHeight || menuLayout.height) +
      additionalVerticalValue;

    // Check if menu position has enough space from bottom side
    if (bottom > windowLayout.height - SCREEN_INDENT) {
      top =
        scrollableMenuHeight === windowLayout.height - 2 * SCREEN_INDENT
          ? -SCREEN_INDENT * 2
          : windowLayout.height -
            menuLayout.height -
            SCREEN_INDENT -
            additionalVerticalValue;
    }
  }

  return { top, left }
}