

export const UPDATE_STORY = 'UPDATE_STORY'

export interface Story {
    caseId: string;
    patientName: string;
    dateOfService: string;
    healthEvent:string;
    service:'mentalHealth'|'altMedicine'|'lab'|'officeVisit'|'radiology'|'procedure'|'other';
    serviceNames:StoryService;
    serviceDescriptions:StoryService;
    otherServiceDescription:string;
    reason:'scheduled'|'primaryDoctor'|'inNetwork'|'emergency'|'pastCoverage'|'noAltReason'|'referral'|'choice'|'other';
    reasonDescriptions:StoryReason;
    otherReasonDescription:string;
}
export interface StoryService{
    mentalHealth:string
    altMedicine:string
    lab:string
    officeVisit:string
    radiology:string
    procedure:string
    other:string
}
export interface StoryReason{
    scheduled:string
    primaryDoctor:string
    inNetwork:string
    emergency:string
    pastCoverage:string
    noAltReason:string
    referral:string
    choice:string
    other:string
   
}
export interface UpdateStory {
    type: typeof UPDATE_STORY;
    payload:  Story;
}


