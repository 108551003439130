import React, { useState } from 'react'
import {
    StyleSheet,
    View
} from 'react-native'
import Text from '../../TextComponent'
import { useIsMobile } from '../../../utils/useDeviceWidth'
import {
    Case
} from '../../../redux/cases/types'
import { useNavigation } from '@react-navigation/native'
import { Button } from '../..'
import SectionName from './SectionName'
import BodyCard from './BodyCard'
import useDocument from '../../../screens/Negotiation/Components/useDocument'
import Tooltip from 'react-native-walkthrough-tooltip'
import useGoogleTagManager, { GTMEvent } from '../../../utils/useGoogleTagManager'

export interface BodyProps {
    case: Case
}
const toolTipStyle={
    padding: 12,
    borderRadius: 2,
    backgroundColor:'#002C73',
    fontFamily:'SourceSansPro',
    width: 116,
    height: 44
   
  }
  const toolTipTextStyle={
    fontSize: 14,
    color: '#ffffff'
  }
  

export default function BodyNegotiation(props: BodyProps) {
    const navigation = useNavigation();
    const isMobile = useIsMobile()
    const plan =useDocument(props.case.caseId)
    const [printing, setPrinting] =useState(false)
    const gtm = useGoogleTagManager()
    const editNavigationText='Edit Your Plan'
    const buildNavigationText='Build a Negotiation Plan'
    const viewPlanText='View Your Plan'

    const goToNegotiation=(eventText:string)=>{
        gtm.pushEvent(GTMEvent.InteractionClick, {
            caseNumber: props.case.caseNumber,
            clickText:eventText
        })
        navigation.navigate("NegotiationIntro",{caseId:props.case.caseId})
    }
    const ViewPlan=async()=>{
        gtm.pushEvent(GTMEvent.InteractionClick, {
            caseNumber: props.case.caseNumber,
            clickText:viewPlanText
        })

        setPrinting(true)
       await plan.print()
       setPrinting(false)
    }
    const isCompleted=()=>{
      let completed =false
      const task=  props.case.tasks.find(t=>t.taskName==='Questionnaire')
      if(task && task.taskCompleteInd){
        completed= true
      }
      return completed
    }
    return (
        <BodyCard case={props.case} isMobile={isMobile}>
            <SectionName case={props.case} isMobile={isMobile}/>
            <View  style={isMobile ? mobile.selfCoachingSection : desktop.selfCoachingSection}>
               <View style={{marginBottom:14}}>
                    <Text style={styles.negotiationText}>Based on your case details, we recommend you self-negotiate with your provider to lower your bill.</Text>
                </View>
                {isCompleted() &&
                <View style={{flexDirection:'row',flexWrap:'wrap'}}>
                    <View style={{marginRight:15,marginBottom:5}}>
                        <Tooltip contentStyle={toolTipStyle} disableShadow={true} isVisible={printing} content={<Text style={toolTipTextStyle}>Downloading...</Text>} placement="top" backgroundColor='rgba(0,0,0,0)'  showChildInTooltip={false} onClose={()=>setPrinting(false)}>
                            <Button onPress={ViewPlan}  label={viewPlanText}/>
                        </Tooltip>
                    </View>
                    
                    {
                     props.case.caseStatus==='Case Opened' &&
                     <Button onPress={()=>goToNegotiation(editNavigationText)}  label={editNavigationText}></Button>
                    }
                </View>
                }
                {!isCompleted()  && props.case.caseStatus==='Case Opened' && 
                <Button onPress={()=>goToNegotiation(buildNavigationText)}  label={buildNavigationText}></Button>
                }
            </View>
        </BodyCard>
    )
}

const styles = StyleSheet.create({
    subTitle: {
        color: '#002C73',
        fontWeight: '700',
    },
    columnTitle: {
        fontWeight: '700',
        color: '#002C73',
        marginBottom: 10,
        fontSize: 16,
    },
    link: {
        color: '#D04443',
        fontSize: 14,
        fontWeight: '700',
        textDecorationLine: 'underline',
    },
    negotiationText:{
        fontStyle:'italic',
        fontSize:16
    }

})

const mobile = StyleSheet.create({
    selfCoachingSection:{
        maxWidth:'100%',
        marginBottom: 40
    }
})

const desktop = StyleSheet.create({
    selfCoachingSection:{
        maxWidth:'40%',
        marginBottom: 40
    }
})
 
