import styled from 'styled-components/native'
import { View } from 'react-native'

export const ErrorText = styled.Text`
    padding-left:${(props) => (props.paddingLeft? props.paddingLeft : '0px')};
    margin-top: 6px;
    color: #e00000;
    font-family: SourceSansPro-Regular;
    font-size: 12px;
    font-style: italic;
`


export const ErrorView = styled.View`
padding: ${(props) => (props.hasErrors ? '5px' : '0px')};
border-color: ${(props) => (props.hasErrors ? '#E00000' : '')};
border-width: ${(props) => (props.hasErrors ? '2px' : '0px')};
margin-top: 22px
`
export const ErrorWrapper = styled(View)`
margin: 24px 0 0;
padding: 16px 16px 16px 16px;
background-color: rgba(239, 76, 76, 0.1);
`
export const ErrorMessage=styled.Text`
    font-family: SourceSansPro-Bold;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #e00000;
    
  `