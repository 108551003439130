import React, { useEffect } from 'react';
import { Text } from '../../components/index'
import { useIsMobile } from '../../utils/useDeviceWidth'
import { Button } from '../../components'
import Layout,{
    ViewAdditionalInfo,
    LayoutDivider,
    MainSection,
    InfoSection,
    NavigationSection,
    TextSection,
    TextRowGroup,
    ErrorText
} from './Components/Layout';
import { View} from 'react-native'
import styled from 'styled-components/native';
import { useForm } from "react-hook-form";
import { updateStory } from '../../redux/negotiation/story/actions';
import { Story as NegotiationStory } from '../../redux/negotiation/story/types';
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../redux/rootReducer';
import FormInputText from './Components/FormInputText';
import RadioButtonGroup from './Components/RadioButtonGroup';
import { storyReasonDescriptions, storyServiceDescriptions, storyServiceNames } from './Components/types';
import {usePageLoadEffect } from '../../utils/useGoogleTagManager';
import useGoogleTagManager from '../../utils/useGoogleTagManager';

export default function Story({route, navigation }) {
    const caseId:string=route.params && route.params.caseId?route.params.caseId:""
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const story:NegotiationStory= useSelector((state: ApplicationState) =>state.negotiationStory && state.negotiationStory.data? state.negotiationStory.data.find(f=>f.caseId===caseId):undefined)
    const { control,reset, handleSubmit, clearErrors, formState: { errors } } = useForm( {defaultValues: story});
    const gtm=useGoogleTagManager()
    
    useEffect(()=>{
        if(!story){
            goToIntro()
        }
    },[caseId])
    
    useEffect(()=>{
      reset(story)
    },[route.params])

    usePageLoadEffect()
    
    const onSubmit=(data:NegotiationStory)=>{
        data.caseId=caseId
        data.serviceNames=storyServiceNames
        data.serviceDescriptions=storyServiceDescriptions
        data.reasonDescriptions=storyReasonDescriptions
       dispatch(updateStory(data))
       reset(data)
       gtm.pushNegotiationTrack("knowYourStory","Continue")
      navigation.navigate("NegotiationNumbers",route.params)
    }
    const goToIntro=()=>{
        navigation.navigate("NegotiationIntro",route.params)
      }
    const goBack=()=>{
        gtm.pushNegotiationTrack("knowYourStory","Back")
        goToIntro()
    }
    if(!story){
      return null
    }

    return (
    <Layout title="Tell Us Your Story" isMobile={isMobile} StepTrackerActiveStepNumber={1}>
         <LayoutDivider  isMobile={isMobile}>
             <MainSection  isMobile={isMobile}>
                <TextSection isMobile={isMobile}>
                    <TextRowGroup isMobile={isMobile} width="30%">
                        <Text size={16} bold={true}>Patient name</Text>
                        <FormInputText control={control} name="patientName" editable={false}/>
                    </TextRowGroup>
                    <TextRowGroup isMobile={isMobile}  width="30%" marginLeft={isMobile?"0px":"20px"} marginTop={isMobile?"10px":"0px"}>
                        <Text size={16} bold={true}>Date of service</Text>
                        <FormInputText control={control} name="dateOfService"   editable={false} />
                        <Text size={12} style={{fontStyle:'italic',color:'#333333'}}>MM/DD/YYYY</Text>
                    </TextRowGroup>
                    <TextSingleGroup isMobile={isMobile}>
                        <Text size={16}><Text size={16} bold={true}>Provide a one sentence description of your health event</Text> (Required)</Text>
                        <FormInputText control={control} name="healthEvent" multiline={isMobile}
                            required={true} error={errors.healthEvent?true:false} errorMessage={"Health event description is required"} 
                        />
                        <Text size={12} style={{fontStyle:'italic',color:'#333333'}}>Examples: (I broke my arm. I had a colonoscopy.)</Text>
                    </TextSingleGroup>
                </TextSection>
                <View style={{marginTop:32}} >
                    <Text size={26} bold={true}>What type of service did you receive?</Text>
                    <Text size={16}>(Required)</Text>
                    <RadioButtonGroup control={control} name="service"
                    labelValues={Object.keys(story.serviceNames).map(serviceName=>{
                      return  {
                        label:<>{story.serviceNames[serviceName]} <Text size={16} style={{fontStyle:'italic'}}>{story.serviceDescriptions[serviceName]}</Text></>,
                        value:serviceName,
                        input:serviceName==='other',
                        inputName:serviceName==='other'?"otherServiceDescription":"",
                        inputError:serviceName==='other'? errors.otherServiceDescription?true:false:false,
                        inputErrorMessage:serviceName==='other'?"Other service description is required":"",
                        }})}
                    required={true} 
                    error={errors.service?true:false} 
                    errorMessage="A response is required"
                    clearErrors={clearErrors}/>
                   
                </View>
                 <View style={{marginTop:32}} >
                    <Text size={24} bold={true}>Why were you seen by an out-of-network provider?</Text>
                    <Text size={16}>(Required)</Text>
                    <RadioButtonGroup control={control} name="reason" 
                    labelValues={Object.keys(story.reasonDescriptions).map(reasonName=>{
                      return  {
                        label:`${story.reasonDescriptions[reasonName]}.`,
                        value:reasonName,
                        input:reasonName==='other',
                        inputName:reasonName==='other'?"otherReasonDescription":"",
                        inputError:reasonName==='other'? errors.otherReasonDescription?true:false:false,
                        inputErrorMessage:reasonName==='other'?"Other reason is required":"",
                        }})}
                    required={true} 
                    error={errors.reason?true:false} 
                    errorMessage="A response is required"
                    clearErrors={clearErrors}/>
                  </View>
                 
             </MainSection>
             <InfoSection isMobile={isMobile}>
                <ViewAdditionalInfo  isMobile={isMobile}>
                    <Text size={20} bold={true}>Is your story easy to tell?</Text>
                    <Text size={16} style={{marginTop:5}}>
                        Is your story concise? Could you tell it in a few minutes? Peple are more likely to help when you
                        get to the point quickly. Also, you may have to tell this story several times. Make it easier on yourself
                        and be brief.
                    </Text>
                </ViewAdditionalInfo>
            </InfoSection>
        </LayoutDivider>
         <NavigationSection>
                <Button onPress={goBack} variant="secondary"  label='BACK'/> 
                <Button onPress={handleSubmit(onSubmit)}  
                    label='CONTINUE' style={{marginLeft:10}}
                    variant={Object.keys(errors).length > 0 ? "primary-disabled" : "default"}
                />
        </NavigationSection>
        {Object.keys(errors).length > 0 && 
                <ErrorText>Please correct the fields highlighted above and try again.</ErrorText>
            }
     </Layout>
  
   )
  }

const TextSingleGroup = styled(View)`
  width:100%;
  margin-top:${props => props.isMobile ? '10px' : '52px'};
`

