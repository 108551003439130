import React from 'react'
import Text from '../../TextComponent' 
import {
    StyleSheet,
    View,
    Image
} from 'react-native'

export enum StatusTrackerItemStatus {
    NotStarted = 0,
    InProgress=1,
    Completed=2

}
export interface StatusTrackerItemProps{
    status:  StatusTrackerItemStatus,
    title: string,
    detail: string,
    nextStatus?:StatusTrackerItemStatus
}

export default function StatusTrackerItem(props:StatusTrackerItemProps){
   
    let styStatus=undefined
    let accessibilityLabel = null

    switch (props.status) {
        case StatusTrackerItemStatus.NotStarted:
            styStatus =styleStatus.notStarted
            accessibilityLabel = 'Not Started'
            break;
        case StatusTrackerItemStatus.InProgress:
            styStatus =styleStatus.inProgress
            accessibilityLabel = 'Current Step'
            break;
        case StatusTrackerItemStatus.Completed:
            styStatus =styleStatus.complete
            accessibilityLabel = 'Completed Step'
            break;
        default:
            styStatus =undefined
            break;
    }

    let styStatusConnect=undefined

    switch (props.nextStatus) {
        case StatusTrackerItemStatus.NotStarted:
            styStatusConnect =styleStatusConnect.notStarted
            break;
        case StatusTrackerItemStatus.InProgress:
        case StatusTrackerItemStatus.Completed:
            styStatusConnect =styleStatusConnect.inProgressOrComplete
            break;
        default:
            styStatusConnect =undefined
            break;
    }
    return(
        <View style={{display:'flex', flexDirection:'row'}}>
            <View>
                {props.status===StatusTrackerItemStatus.Completed &&
                    <Image style={styStatus} source={require('../../../assets/images/check-icon.svg')} accessibilityLabel={accessibilityLabel}/>
                }
                {props.status!==StatusTrackerItemStatus.Completed &&
                    <View style={{height:18,width:18,padding:1}} accessibilityLabel={accessibilityLabel}>
                        <View style={[styleStatus.common,styStatus]}/>
                    </View>
                }
                <View style={ [styleStatusConnect.common,styStatusConnect]}/>
            </View>
            <View style={{marginLeft:13}}>
                <Text bold={true} size={16}>{props.title}</Text>
                <Text style={{maxWidth: 275}} size={14}>{props.detail}</Text>
            </View>
        </View>
    )
}

const styleStatus = StyleSheet.create({
    common:{
        width: 16,
        height: 16,
        borderRadius: 8,
        borderStyle:'solid',
        borderWidth:3,
    },
    notStarted: {
        borderColor: '#94949E'
    },
    inProgress: {
        borderColor:'#61A60D'
    },
    complete: { 
        width: 18,
        height: 18
    }
        
})


const styleStatusConnect = StyleSheet.create({
    common:{
        marginTop:-1,
        marginBottom:-1,
        alignSelf:'center',
        height: 45,
        minWidth:3 
    },
    notStarted: {
        backgroundColor: '#94949E'
    },
    inProgressOrComplete: {
        backgroundColor:'#61A60D'
    }
})
