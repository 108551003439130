import React, { useEffect, useState } from 'react';
import { Text } from '../../components/index'
import { useIsMobile } from '../../utils/useDeviceWidth'
import { Button } from '../../components'
import Layout, 
{ 
  InfoSection,
   LayoutDivider, 
   MainSection, 
   NavigationSection,
    ViewAdditionalInfo,
    InfoDivider, 
    ErrorText,
    FormGroup} from './Components/Layout';
import { View } from 'react-native';
import { useForm } from 'react-hook-form';
import { Options as NegotiationOptions } from '../../redux/negotiation/options/types';
import { Numbers as NegotiationNumbers } from '../../redux/negotiation/numbers/types';
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../redux/rootReducer';
import RadioButtonGroup from './Components/RadioButtonGroup';
import { updateOptions } from '../../redux/negotiation/options/actions';
import FormatCurrency from '../../utils/FormatCurrency';
import axiosInstance from '../../redux/axiosInstance';
import { AxiosResponse } from 'axios';
import { Story as NegotiationStory } from '../../redux/negotiation/story/types';
import { Questionnaire } from './Components/types';
import { useOverlay } from '../../context-api/overlay/overlayState'
import useGoogleTagManager, { GTMEvent, usePageLoadEffect } from '../../utils/useGoogleTagManager';
import { fetchCases } from '../../redux/cases/actions';

export default function Options({route, navigation }) {
  const caseId:string=route.params && route.params.caseId?route.params.caseId:""
  const options:NegotiationOptions= useSelector((state: ApplicationState) =>state.negotiationOptions && state.negotiationOptions.data && state.negotiationOptions.data.find(f=>f.caseId===caseId)?  state.negotiationOptions.data.find(f=>f.caseId===caseId): undefined)
  const numbers:NegotiationNumbers= useSelector((state: ApplicationState) =>state.negotiationNumbers && state.negotiationNumbers.data && state.negotiationNumbers.data.find(f=>f.caseId===caseId)?  state.negotiationNumbers.data.find(f=>f.caseId===caseId): undefined)
  const story:NegotiationStory= useSelector((state: ApplicationState) =>state.negotiationStory && state.negotiationStory.data? state.negotiationStory.data.find(f=>f.caseId===caseId):undefined)
   const isMobile = useIsMobile()
   const dispatch = useDispatch()
   const { control,getValues,clearErrors, reset, handleSubmit, formState: { errors } } = useForm();
  const [increasedBillDisabled,setIncreasedBillDisabled]=useState(options && options.pastVisits!=='Yes')
  const [saveSuccess,setSaveSuccess]=useState(true)
  const { overlay } = useOverlay()
  const gtm=useGoogleTagManager()
  const successFailedText = 'Oops! something when wrong. Please try again.';
  
  useEffect(()=>{
    if(!options){
      goToNumbers()
    }
  },[caseId])

   useEffect(()=>{
      reset(options)
      if(options){
        if(options.pastVisits!=='Yes'){
          setIncreasedBillDisabled(true)
        }
      }
  },[route.params])

  usePageLoadEffect()

  const onSubmit=async (data:NegotiationOptions)=>{
    data.caseId=caseId
    
    if(await saveData(data)){
      setSaveSuccess(true)
      dispatch(updateOptions(data))
      reset(data)
      gtm.pushNegotiationTrack("knowYourOptions","Continue")
      navigation.navigate("NegotiationPlan",route.params)
    }
    else{
      const charLimit = (str) => str.substring(0, 100);
      
      gtm.pushEvent(GTMEvent.NegotiationOptionsFailed, {
        'event': 'saveData - Negotiation/Options',
        'errorType': 'Save Data Failed',
        'errorMessage' : charLimit(successFailedText)
      })

      setSaveSuccess(false)
    }
  }
  const  saveData=async(updatedOptions:NegotiationOptions)=>{
    
    overlay({
      show: true,
      type: 'spinner',
      message: '',
      autoHide: false
    })

    let success=false

    const axios = await axiosInstance()
    try {
      if(updatedOptions.pastVisits!=='Yes'){
        updatedOptions.billIncreased=null
      }
      const questionnaire:Questionnaire={
        case__c:caseId,
        patient_Name__c: story.patientName,
        bill_Amount_Increased_Since_Last_Visit__c:updatedOptions.billIncreased,
        cost_Share_Amount__c: numbers.coinsurance + numbers.copay + numbers.deductible,
        date_of_Service__c: story.dateOfService,
        insurance_Allowed_Amount__c: numbers.allowedAmount,
        non_Covered_Amount__c: numbers.nonCoveredAmount,
        patient_Asked_To_Pay_Amount__c: numbers.providerAmount,
        percent_of_Non_Covered_Amount__c: 90,
        plan_Paid_Amount__c: numbers.insurancePaid,
        plan_to_See_Provider_More_Than_One_Time__c: updatedOptions.futureVisits,
        provider_Billed_Amount__c: numbers.billed,
        realize_out_of_network__c: updatedOptions.plannedVisits,
        seen_by_Out_Of_Network__c: story.reasonDescriptions[story.reason],
        seen_by_Out_Of_Network_Other__c: story.otherReasonDescription,
        seen_Provider_More_Than_One_Time__c: updatedOptions.pastVisits,
        short_Description__c: story.healthEvent,
        type_of_Service__c: story.serviceNames[story.service],
        type_of_Service_Other__c: story.otherServiceDescription
      }
      
      const response:AxiosResponse<any> = await axios.post('/self-service-questionnaire',questionnaire)
      success=response.status===201
        if(success){
          //update the cases to see task complete
            dispatch(fetchCases())
        }
    } 
    catch (e) {
      //To do:handle axios failure
      success=false
   }
   overlay({
    show: false,
    type: 'spinner',
    message: ''
    })
   return success
  }
    
    const goToNumbers=()=>{
      navigation.navigate("NegotiationNumbers",route.params)
    }
    const goBack=()=>{
      gtm.pushNegotiationTrack("knowYourOptions","Back")
      goToNumbers()
    }
    const increasedBillValidation = (value:string) => {
      const pastVisits= getValues("pastVisits");
      const valid =pastVisits!=='Yes' || (value?true:false)
      return valid
  
  }
  const handlePastVisitsCheck=(value:string)=>{
    if(value==='Yes'){
      setIncreasedBillDisabled(false)
    }
    else{
      setIncreasedBillDisabled(true)
      clearErrors("billIncreased")
    }
  }
  if(!options){
    return null
  }
    return (
   <Layout title="Know Your Options" isMobile={isMobile} StepTrackerActiveStepNumber={3}>
     <LayoutDivider  isMobile={isMobile}>
        <MainSection  isMobile={isMobile}>
          <View>
            <Text size={24} bold={true}>Did you realize you were going out-of-network?</Text>
            <Text size={16}>(Required)</Text>
            <RadioButtonGroup control={control} name="plannedVisits" labelValues={[{label:"Yes",value:"Yes"},{label:"No",value:"No"},{label:"I don't know",value:"I don't know"}]}
             required={true} error={errors.plannedVisits?true:false} errorMessage="A response is required"
            />
          </View>
          <View style={{marginTop:40}}>
            <Text size={24} bold={true}>Do you plan to see this provider more than one time?</Text>
            <Text size={16}>(Required)</Text>
            <RadioButtonGroup control={control} name="futureVisits" labelValues={[{label:"Yes",value:"Yes"},{label:"No",value:"No"},{label:"I don't know",value:"I don't know"}]}
            required={true} error={errors.futureVisits?true:false} errorMessage="A response is required"/>
          </View>
          <View style={{marginTop:40}}>
            <Text size={24} bold={true}>Have you seen this provider more than one time?</Text>
            <Text size={16}>(Required)</Text>
            <RadioButtonGroup control={control} name="pastVisits" labelValues={[{label:"Yes",value:"Yes"},{label:"No",value:"No"},{label:"I don't know",value:"I don't know"}]}
            required={true} error={errors.pastVisits?true:false} errorMessage="A response is required" 
            onCheck={handlePastVisitsCheck}/>
          </View>
         
          <FormGroup marginTop={40} disabled={increasedBillDisabled}>
            <Text size={24} bold={true}>If you have seen this provider more than one time, has your bill amount increased since your last visit?</Text>
            <Text size={16}>(Required)</Text>
            <RadioButtonGroup control={control} name="billIncreased" labelValues={[{label:"Yes",value:"Yes"},{label:"No",value:"No"},{label:"I don't know",value:"I don't know"}]}
            required={false} error={errors.billIncreased?true:false} errorMessage="A response is required"
            validate={increasedBillValidation}
             disabled={increasedBillDisabled} />
          </FormGroup>
        </MainSection>
        <InfoSection isMobile={isMobile}>
          <ViewAdditionalInfo isMobile={isMobile}>
              <Text size={24} bold={true}>Your Leverage</Text>
          </ViewAdditionalInfo>
          <InfoDivider  isMobile={isMobile}/>
          <ViewAdditionalInfo isMobile={isMobile}>
                <Text size={16}>
                Knowing these simple facts will help you reduce your bill. Right now you are negotiating for what you owe:
                </Text>
          </ViewAdditionalInfo>
          <ViewAdditionalInfo isMobile={isMobile} backgroundColor="#002C73">
              <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                  <Text size={24} bold={true} style={{color:'#FFFFFF'}}>Non-Covered Amount:</Text>
                  <Text size={32} bold={true} style={{color:'#FFFFFF'}}>{FormatCurrency(numbers.nonCoveredAmount)}</Text>
                </View>
                <Text size={16} style={{marginTop:15 ,color:'#FFFFFF'}}>
                This is the amount that you will be asking the provider to discount.
                </Text>
          </ViewAdditionalInfo>
        </InfoSection>
      </LayoutDivider>
      <NavigationSection>
        <Button onPress={goBack} variant="secondary"  label='BACK'/> 
        <Button onPress={handleSubmit(onSubmit)}  label='CONTINUE' style={{marginLeft:10}}
         variant={Object.keys(errors).length > 0 ? "primary-disabled" : "default"} />
      </NavigationSection>
      {Object.keys(errors).length > 0 && 
                <ErrorText>Please correct the fields highlighted above and try again.</ErrorText>
            }
      {!saveSuccess &&
       <ErrorText>{successFailedText}</ErrorText>
      }
    </Layout>
   )
  }
  