import AsyncStorage from '@react-native-async-storage/async-storage'

function getKey(caseId: string) {
    return`${caseId}/signing_complete`;
}

export async function getCaseSigning(caseId: string) {
    const key = getKey(caseId);

    try {
        const value = await AsyncStorage.getItem(key);
        if (!value) return false;
        const dvalue = JSON.parse(value);
        if (new Date(dvalue) < new Date()) {
            try {
                await AsyncStorage.removeItem(key);
            } catch (e) {
                // TODO: app insights?
                console.error(e);
            }
                
            return false;
        }
        
        return true;
    } catch (e) {
        console.error(e)
        return false;
    }
}

export function setCaseSigning(caseId: string, expireInMinutes: number = 240) {
   return AsyncStorage.setItem(getKey(caseId), getExpireDate(expireInMinutes));
}

/**
 *
 * @param expireInMinutes
 * @returns {Date}
 */
function getExpireDate(expireInMinutes) {
    const now = new Date();
    let expireTime = new Date(now);
    expireTime.setMinutes(now.getMinutes() + expireInMinutes);
    return JSON.stringify(expireTime);
}