import useAuthentication from './useAuthentication'
import TagManager from 'react-gtm-module'
import { Case } from '../redux/cases/types'
import { ApplicationState } from '../redux/rootReducer'
import { useFocusEffect } from '@react-navigation/native'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import useAppInsights from './useAppInsights'

export enum UserType {
  Subscriber,
  Dependent
}

export enum GTMEvent {
  // event fired when a page or component has finished loading
  PageLoad = 'pageLoad',

  // event fired when a member story task is clicked from a case card
  StoryClick = 'storyClick',

  // event fired when a user submits their member story
  SubmitStory = 'submitStory',

  // event fired when a hipaa release form task is clicked from a case card
  InitiateRelease = 'initiateRelease',

  // event fired when a hipaa release form task is completed on redirect from docusign
  CompleteRelease = 'completeRelease',

  // event fired when a bill upload task is clicked from a case card
  UploadClick = 'uploadClick',

  // event fired when a file has successfully been uploaded
  Upload = 'upload',

  // event fired when an advisor email is clicked from a case card
  EmailAdvisor = 'emailAdvisor',

   // event fired when a member click to view a file
   DocumentView = 'documentView',

    // event fired when  a member click to initiate download of a file
    DocumentDownload = 'documentDownload',

    // event fired when  a member click to delete of an uploaded file
    DocumentDelete = 'documentDelete',

    // event fired when  a member click of a navigation link (links in the header, footer, or breadcrumbs)
    NavigationClick = 'navigationClick',

    // event fired when  a member click to save account settings
    SaveSettings = 'saveSettings',

    // event fired when a feedback survey is submitted
    SurveySubmit = 'surveySubmit',

    // event fired when a user click some navigation button
    InteractionClick='interactionClick',

    // event fired when a user click the negotiation buttons
    NegotiationTrack='negotiationTrack',

    //event fired when a user click on the provider links
    FindProvider='findprovider',

    ContactsNotFound='contactsNotFound',

    RefreshDataFailed='refreshDataFailed',

    NegotiationOptionsFailed='negotiationOptionsFailed',

    DocumentFetchFailure='documentFetchFailure',

    DocumentSignFailure='documentSignFailure',

    UploadMaxDocError='uploadMaxDocError',

    EsignError='esignError',

    CasesErrorBoundary='casesErrorBoundary',

    MemberStoryError='memberStoryError',

    InvitationExpireError='invitationExpireError'
}
export enum GMTNavigationLinkPlacement {
  Header = 'Header',
  Footer = 'Footer',
  Breadcrumb = 'Breadcrumb'
}
export type GTMDocumentEventTypes =
 | GTMEvent.UploadClick
 | GTMEvent.Upload
 | GTMEvent.DocumentView
 | GTMEvent.DocumentDownload
 | GTMEvent.DocumentDelete
 
export interface GoogleTagManager {
  pushEvent: (event: GTMEvent, attributes?: any) => void;
  pushDocument: (event: GTMDocumentEventTypes, caseNumber:string,patientID:string,userIsPatient:boolean) => void;
  pushNegotiationTrack: (screenName:string,actionName:string,bidvalue?:number) => void;
  pushNavigation: (linkPlacement:GMTNavigationLinkPlacement,linkTitle:string) => void;
  pushPageLoadWithCase: (caseData: Case, extra?: any) => void;
  pushPageLoadWithCases: (cases: Case[], extra?: any) => void;
  pushPageLoad: (advisorName?: string, userType?: UserType, clientName?: string, insurance?: string, extra?: any) => void;
  pushFindProvider: (cases:Case[],linkName:string,destinationURL:string) => void;
}

/**
 * Hook that will push a pageLoad event to Google Tag Manager.
 * 
 * This hook assumes the screen loads case information for the user.
 */
export function usePageLoadEffect() {
  const gtm = useGoogleTagManager()
  const casesPending = useSelector((state: ApplicationState) => state.cases.pending)
  const cases:Case[] = useSelector((state: ApplicationState) => state.cases.data)
  const appInsights =useAppInsights()

  useFocusEffect(
    useCallback(() => {
      // set a brief timeout to account for the fact that a react navigation focus event fires
      // just before the url in the browser updates. without this in place, gtm events may have
      // a discrepancy between the url and page title they report.
      // a better fix would be to capture window.location.pathname changes and only push an event
      // once the path matches the one for the currently focused screen.
       const timer =setTimeout(() => {
        if (!casesPending) {
          gtm.pushPageLoadWithCases(cases)
          appInsights.trackLoadScreenEvent(cases)
        }
        return () => {
          clearTimeout(timer);
         };
      }, 5000)      
    }, [cases, casesPending])
  )
}

/**
 * Hook that provides a interface for pushing data to Google Tag Manager.
 * 
 * You should use this hook immediately once your component has mounted with all required data (cases, member stories, etc.).
 */
export default function useGoogleTagManager(): GoogleTagManager {
  const auth = useAuthentication()

  return {
    pushEvent: function (event: GTMEvent, attributes?: any) {
      TagManager.dataLayer({
        dataLayer: {
          event,
          ...attributes
        },
      })
    },
    pushFindProvider: function(cases: Case[],linkName:string,destinationURL:string){
      if(cases && cases.length>0){
          const mostRecentCase = cases.reduce((a, b) => {
          return   Number(a.caseNumber) >  Number(b.caseNumber) ? a : b;
          })
          const dataLayer = {
            highestCaseNumber:mostRecentCase.caseNumber,
            linkName,
            destinationURL
          }
          this.pushEvent(GTMEvent.FindProvider.toString(),dataLayer)
      }
     },
    pushDocument: function (event: GTMDocumentEventTypes, caseNumber:string,patientID:string,userIsPatient:boolean){
      const dataLayer = {
        caseNumber,
        patientID,
        userIsPatient:userIsPatient?"Yes":"No"
      }
      this.pushEvent(event.toString(),dataLayer)
    },
    pushNegotiationTrack: function (screenName:string,actionName:string,bidvalue?:number){
      const dataLayer = {
        form_interaction: `negotiationPlan_${screenName}_${actionName}`,
        bidvalue
      }
      
      this.pushEvent(GTMEvent.NegotiationTrack.toString(),dataLayer)
    },
    pushNavigation: function (linkPlacement:GMTNavigationLinkPlacement,linkTitle:string){
      const dataLayer = {
        navLinkName:`${linkPlacement.toString()}: ${linkTitle}`
      }
      this.pushEvent(GTMEvent.NavigationClick.toString(),dataLayer)
    },
    pushPageLoadWithCases: function (cases: Case[], extra?: any) {
      if (cases.length > 0) {
        const notClosedCases =cases.filter(c=>c.caseStatus!=='Case Closed')
        
        this.pushPageLoadWithCase(cases[0], {
          caseCount: cases.length,
          scOpenCount: notClosedCases? notClosedCases.filter(c=>c.naviguardProduct==='Self Coach').length:0,
          fsOpenCount: notClosedCases? notClosedCases.filter(c=>c.naviguardProduct==='Full Support').length:0,
          ...extra
        })
      } else {
        this.pushPageLoad(null, null, null, null, {
          caseCount: 0,
          scOpenCount:0,
          fsOpenCount:0,
          ...extra
        })
      }
    },
    pushPageLoadWithCase: function (caseData: Case, extra?: any) {
      const userType = caseData?.userType === 'Subscriber'
        ? UserType.Subscriber
        : caseData?.userType === 'Dependent'
          ? UserType.Dependent
          : null

      this.pushPageLoad(
        caseData.advisor?.firstName,
        userType,
        caseData.employerGroupName,
        'UHG',
        extra)
    },
    pushPageLoad: function (advisorName?: string, userType?: UserType, clientName?: string, insurance?: string, extra?: any) {
      const userSubscriber = userType === UserType.Subscriber
        ? 'Subscriber'
        : userType === UserType.Dependent
          ? 'Dependent'
          : null

      const dataLayer = {
        event: GTMEvent.PageLoad.toString(),
        advisorName,
        userSubscriber,
        clientName,
        insurance,
        ...(auth.account ? {
          userID: auth.account.sub
        } : {}),
        ...extra
      }

      // send it off and wish it farewell
      TagManager.dataLayer({
        dataLayer,
      })
    }
  }
}
