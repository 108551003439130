import React, { useState, useRef, useEffect } from 'react'
import { createPortal } from "react-dom"
import { Hoverable } from 'react-native-web-hover'
import { StyleSheet, View, Animated, TouchableWithoutFeedback } from 'react-native'
import Text from '../TextComponent'

interface ICustomTooltip {
  message: string;
  children: any;
}
export default function CustomTooltip(props: ICustomTooltip) {
  const anchorRef = useRef(null)
  const [show, setShow] = useState(false)
  const [anchorLayout, setAnchorLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const handleClick = () => {
    setShow(!show)
  }

  return (
    <Hoverable>
      {({ hovered }) => (
        <>
          <TouchableWithoutFeedback onPress={handleClick}>
            <View ref={anchorRef} onLayout={(event) => setAnchorLayout(event.nativeEvent.layout)}>
              {props.children}
            </View>
          </TouchableWithoutFeedback>
          <Tooltip 
            anchorRef={anchorRef} 
            anchorLayout={anchorLayout}
            hovered={hovered || show}
          >
            <Text size={14} style={{color: "#FFF"}}>{props.message}</Text>
          </Tooltip>
        </>
      )}
    </Hoverable>
  )
}

const Tooltip = ({ children, anchorRef, anchorLayout, hovered }) => {
  const animation = useRef(new Animated.Value(0)).current 

  useEffect(() => {
    if(hovered) {
      Animated.timing(animation, {
        toValue: 1,
        duration: 100,
        useNativeDriver: true
      }).start();
    } else {
      Animated.timing(animation, {
        toValue: 0,
        duration: 100,
        useNativeDriver: true
      }).start();
    }
  }, [hovered])

  const [tooltipLayout, setTooltipLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  if(!anchorLayout || !anchorRef.current) return null

  let position = anchorRef.current.getBoundingClientRect()
  /**
   * top -8 (half arrow height) and -actual tooltip height
   * to satisfy requirement: Tooltip should appear above the task that is unavailable
   */
  let top = (position.top - 8) - tooltipLayout?.height
  /* 
    left (+22) because checkbox width = 16 + margin right = 6
    to satisfy requirement: Tooltip should align left with task text
  */
  let coord = { top: top, left: position.left + 22 }
  let layout = { width: (anchorLayout.width * 0.9) - 16 }

  return createPortal(
    <Animated.View 
      accessible={false} 
      style={[styles.tooltip, layout, coord, { opacity: animation, display: hovered ? 'flex' : 'none' }]}
      onLayout={(event) => setTooltipLayout(event.nativeEvent.layout)}
    >
      {children}
      <View style={styles.arrow} />
    </Animated.View>, document.body)
}

const styles = StyleSheet.create({
  tooltip: {
    position: "absolute",
    backgroundColor: "#002C73",
    borderRadius: 2,
    padding: 12
  },
  arrow: {
    position: "absolute",
    backgroundColor: '#002C73', 
    minHeight: 16, 
    maxHeight: 16,
    maxWidth: 16,
    minWidth: 16, 
    bottom: -8,
    transform: [{ rotate: '45deg' }]
  }
})