import {DisplayBanner,DISPLAY_BANNER ,SESSION_PROVIDER_BANNER} from  "./types"


export interface ProviderBannerState {
    visible: boolean;
    displayedCount:number;
}
  
const defaultState: ProviderBannerState = {
    visible: false,
    displayedCount:0
}


export const ProviderBannerReducer = (state: ProviderBannerState = defaultState, action:DisplayBanner) => {

    const sessionStateString = sessionStorage.getItem(SESSION_PROVIDER_BANNER);
    let sessionState=Object.assign({}, state);
    
    if(sessionStateString){ 
        sessionState= JSON.parse(sessionStateString)
    }

    if (action.type=== DISPLAY_BANNER){
        
        sessionState.visible=action.payload
        sessionState.displayedCount=action.payload && !state.visible?state.displayedCount+1:state.displayedCount
        sessionStorage.setItem(SESSION_PROVIDER_BANNER, JSON.stringify(sessionState));
    }

    return sessionState;
    
}
