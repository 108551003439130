
import { UpdateCaseSelect, UPDATE_CASE_SELECT} from "./types";
  
  export interface CaseSelectState {
    status:string[];
  }
  
  const defaultState: CaseSelectState = {
    status:undefined
  }
  


export const CaseSelectReducer = (state: CaseSelectState = defaultState, action: UpdateCaseSelect) => {
  
  if(action.type=== UPDATE_CASE_SELECT){
    return (
      {
        ...state,
        status:action.payload,
      }
    )
  }
  else{
        return state;
  }
}