import React from 'react';
import Card from 'react-native-elements/src/card/Card'

export default function CardComponent(props) {
  const cardStyle = {
    borderRadius: 14,
    borderWidth: 0,
    shadowColor: "rgba(0, 44, 115, 0.1)",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 3,
    margin: 0
  }
  return (
    <Card containerStyle={[cardStyle, props.style]}>{props.children}</Card>
  )
}