import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/native';
import { Animated, Easing } from 'react-native'
import { ModalText } from '.'

const StyledSpinner = styled(Animated.View)`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border-width: 10px;
    border-color: #dce3f2;
    margin-bottom: 24px;
    border-top-color: #2b2d76; 
`;

interface ISpinner {
    message: string
}
export default function Spinner(props: ISpinner) {
    const animatedController = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.loop(
            Animated.sequence([
                Animated.timing(animatedController, {
                    toValue: 0,
                    easing: Easing.inOut(Easing.quad),
                    useNativeDriver: true,
                }),
                Animated.timing(animatedController, {
                    toValue: 1,
                    easing: Easing.inOut(Easing.quad),
                    useNativeDriver: true,
                }),
            ])
        ).start()
    }, [])

    const transform = animatedController.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', `360deg`],
    });

    return (
        <>
            <StyledSpinner style={{ transform: [{ rotate: transform }] }} />
            <ModalText>{props.message}</ModalText>
        </>
    )
}