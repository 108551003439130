import React, { useState } from 'react'
import { View} from 'react-native';
import CardTitle from '../card/cardtitle'
import CardComponent from '../card'
import { Case, CaseAdvisor } from '../../redux/cases/types';
import { useNavigation } from '@react-navigation/native';
import PhoneCard from './phonecard';
import AdvisorContent from './AdvisorContent';
import AdvisorNoneContent from './AdvisorNoneContent';
import ProviderCard from './ProviderCard';
import { useWindowDimensions } from 'react-native';

export interface AdvisorCardProps {
  advisor: CaseAdvisor;
  email: string;
  cases:Case[],
}

export default function AdvisorCard(props: AdvisorCardProps) {
  const navigation = useNavigation();
  const [showPhone,setShowPhone]=useState(false)
  const { width } = useWindowDimensions()

  const handleEmailPress=()=>{
    navigation.navigate("Contact",{caseId: undefined,contactAdvisorLocation:'Advisor Card'})
  }
  const handlePhonePress=()=>{
   setShowPhone(true)
  }
  return (<>
    <CardComponent style={{padding: 0}}>
    <CardTitle accessibilityRole="heading" ariaLevel="2">HAVE A QUESTION ABOUT YOUR CASE?</CardTitle>
      {props.advisor && <AdvisorContent advisor={props.advisor} onPhonePress={handlePhonePress} onEmailPress={handleEmailPress} />}
      {!props.advisor && <AdvisorNoneContent  onEmailPress={handleEmailPress} />}
    </CardComponent>
    {showPhone &&<View style={{marginTop:16}}><PhoneCard advisor={props.advisor}/></View>}
    <View style={{marginTop:16}}>
      <ProviderCard cases={props.cases}/>
    </View>
    </>
  )
}

 
