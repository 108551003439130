import { useEffect, useState } from "react";


let  counterHandle:NodeJS.Timeout=undefined
    
export default function useCounter(){
    const  [counterStarted,setCounterStarted]=useState(false);
    let  [countdownSeconds,setCountdownSeconds]=useState(0);
    let counterDisplayingCallback:(countdownSeconds:number)=>void=undefined
   
    const startCounter=(durationSeconds:number)=>{
        if(durationSeconds>0){
            if (counterDisplayingCallback) 
                counterDisplayingCallback(durationSeconds)
            setCountdownSeconds(durationSeconds-1)
            setCounterStarted(true)
        }
        else {
            stopCounter();
        }
    }
    const stopCounter=()=>{

        setCounterStarted(false)

        if(counterHandle) {
            clearTimeout(counterHandle)
        }
        
    }
   

    useEffect(()=>{
        if(counterStarted){
            counterHandle=setTimeout(() => {
                if(countdownSeconds===0){
                    stopCounter();
                }
                else {
                    if (counterDisplayingCallback) 
                        counterDisplayingCallback(countdownSeconds)

                    setCountdownSeconds(countdownSeconds-1)
                }
                
            }, 1000);
        }
    },[counterStarted,countdownSeconds])

   
    const useCounterDisplaying=(action:(countdownSeconds:number)=>void)=>counterDisplayingCallback=action

    return {startCounter,stopCounter,useCounterDisplaying}
}
