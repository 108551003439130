import React from "react";
import { View, TouchableOpacity } from 'react-native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import Text from '../TextComponent'
import { CaseAdvisor } from '../../redux/cases/types';
import { Button } from "..";

export interface AdvisorContentProps {
  advisor: CaseAdvisor;
  onEmailPress:()=>void;
  onPhonePress:()=>void;
}

export default function AdvisorContent(props: AdvisorContentProps) {
    const iconStyle={color:"#0074C8"}
    const accountIconSize=69
    const accountBackgroundMarginSize=14;
    const accountBackgroundSize=accountIconSize - 2*accountBackgroundMarginSize;
    const onFaqPress=()=>{
      const providerUrl='https://www.naviguard.com/about/member-case-resolution-faq?CTA=ResolutionSupport&utm_source=NVG&utm_medium=web&utm_campaign=memberportal&utm_term=negotiationresolution&utm_content=faq'
      window.open(providerUrl, '_blank')
    }
    return (
        <View style={{flex: 1, alignItems: "center", padding: 15,paddingBottom:40,backgroundColor:"#FFFFFF",borderRadius:14,top:-14}}>
          <View>
            <Text accessibilityRole="heading" ariaLevel="3" bold={true} style={{fontSize: 20, marginBottom: 8}}>Contact a Naviguard Advisor</Text>
          </View>
            <View style={{marginTop:16,display:'flex',flexDirection:'row'}}>
                <TouchableOpacity onPress={props.onEmailPress} accessible={false} style={{paddingRight:38}}>
                    <MaterialIcons
                        onKeyUp={(ev) => {
                          if(ev.key==='Enter') {
                            props.onEmailPress()
                          }
                        }}
                        style={iconStyle}
                        accessibilityRole="button"
                        accessible={true}
                        accessibilityLabel="Email advisor"
                        name="email"
                        size={24} />
                </TouchableOpacity>
                <TouchableOpacity onPress={props.onPhonePress} accessible={false} style={{borderLeftWidth:1,borderLeftColor: 'rgba(42, 46, 114, 0.2)',paddingLeft:37}}>
                    <MaterialIcons
                        onKeyUp={(ev) => {
                          if(ev.key==='Enter') {
                            props.onPhonePress()
                          }
                        }}
                        style={iconStyle}
                        accessibilityRole="button"
                        accessible={true}
                        accessibilityLabel="Call advisor"
                        name="phone"
                        size={24}
                        //hoverMode={hovered}
                        onPress={props.onPhonePress}  />
                </TouchableOpacity>
            </View>
            <View style={{marginTop:16,display:'flex',flexDirection:'row'}}>
            <Text accessibilityRole="heading" ariaLevel="3" bold={true} style={{fontSize: 20, marginBottom: 8}}>View frequently asked questions</Text>
          </View>
          <View style={{marginTop:16,alignItems:"center"}}>
                <Button variant="primary-cta" onPress={onFaqPress} accessibilityLabel="FAQ opens in a new window"
                label={
                  <>
                    <Text>FAQ</Text>
                    <MaterialIcons name="launch" style={{marginLeft:6, fontSize:15,verticalAlign:'middle'}} />
                  </>
                }
                ></Button>
            </View>
      </View> 
    )
}


