import { combineReducers } from "redux";
import { CasesReducer, CasesState } from './cases/reducer';
import { DocumentsReducer, DocumentsState } from './documents/reducer';
import { ContactsReducer, ContactsState } from './accountSettings/reducer';
import { CaseSelectReducer, CaseSelectState } from './caseselect/reducer';
import { NegotiationStoryReducer, NegotiationStoryState } from './negotiation/story/reducer';
import { NegotiationNumbersReducer, NegotiationNumbersState } from './negotiation/numbers/reducer';
import { NegotiationOptionsReducer, NegotiationOptionsState } from "./negotiation/options/reducer";
import { ProviderBannerReducer, ProviderBannerState } from "./providerBanner/reducer";
import { MessagesReducer } from './messages/reducer'
import { MessagesState } from './messages/types'

export interface ApplicationState {
  cases: CasesState;
  documents: DocumentsState;
  contacts:ContactsState;
  caseSelect:CaseSelectState;
  negotiationStory:NegotiationStoryState;
  negotiationNumbers:NegotiationNumbersState;
  negotiationOptions:NegotiationOptionsState;
  providerBanner:ProviderBannerState;
  messages: MessagesState;
}

export const RootReducer = combineReducers({
    cases: CasesReducer,
    documents:DocumentsReducer,
    contacts:ContactsReducer,
    caseSelect:CaseSelectReducer,
    negotiationStory:NegotiationStoryReducer,
    negotiationNumbers:NegotiationNumbersReducer,
    negotiationOptions:NegotiationOptionsReducer,
    providerBanner:ProviderBannerReducer,
    messages: MessagesReducer,
  });
