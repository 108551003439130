import React from 'react'
import styled, { css } from 'styled-components/native'

interface IQuestionContainer {
  children: any;
  error: boolean;
  errorMessage: string;
  noBorderOnError?: boolean;
  styleOnError?: any;
  id?: string;
}
export default function QuestionContainer(props: IQuestionContainer) {
  return (
    <>
      <Container 
        style={props.error ? props.styleOnError : {}} 
        error={props.error} 
        aria-invalid={props.error ? true : false }
        aria-describedBy={props.id}
        noBorderOnError={props.noBorderOnError}
      >
        {props.children}
      </Container>
      {props.error && <ErrorText><span id={props.id} role="alert">{props.errorMessage}</span></ErrorText>}
    </>
  )
}

const ErrorText = styled.Text`
  margin-top: 6px;
  color: #9F2140;
  font-family: SourceSansPro-Regular;
  font-size: 12px;
  font-style: italic;
`

const Container = styled.View`
  border-width: ${props => props.error && !props.noBorderOnError ? 2 : 0};
  border-color: #9F2140;
  ${props => props.error && !props.noBorderOnError ? css`padding: 8px` : 0}
`