import React from 'react'
import { Text } from '../../index'
import  { useResize } from '../../../utils/useDeviceWidth'

export default function GreetingBody(props) {
  const [ width, isMobile, isTablet ] = useResize()
  const largeScreen = {
    paddingTop: 30,
    paddingBottom: 30,
    height: 56,
    width: 624,
    fontFamily: "SourceSansPro-Regular"
  }
  const smallScreen = {
    paddingTop: 12,
    paddingBottom: 14,
    width: "auto",
    fontFamily: "SourceSansPro-Regular"
  }

  const getDesktopStyle = () => {
    if(!isMobile && width <= 1110) {
      return [largeScreen, { maxWidth: '70%' }]
    }

    return largeScreen
  }

  const getMobileStyle = () => {
    if(isTablet) {
      return [ smallScreen, { maxWidth: '50%' } ]
    }
    return smallScreen
  }
  
  return (<Text size="xs" style={!isMobile ? getDesktopStyle() : getMobileStyle()}>{props.text}</Text>)
}
