import React from 'react'
import { View } from 'react-native';
import { ImageBG, GreetingBody } from './components'
import { Text } from '../index'
import AppContainer from '../appcontainer'
import useDeviceWidth, { useIsMobile } from '../../utils/useDeviceWidth'
import {styles as imageBGStyle} from './components/imagebg'

/* 
  adding these props to add to and/or override default layout styling
  - introSectionWrapper
  - contentWrapper
  - mainSectionWrapper
  - mainSectionStyle
*/
interface ILayout {
  imageBG?: boolean;
  introHeading?: any;
  introBody?: string;
  introSectionWrapper?: any;
  contentWrapper?: any;
  mainSectionWrapper?: any; // custom style for View container (in AppContainer component) of the main content children
  mainSectionStyle?: any;
  children?: any;
  navigation?: any;
  breadcrumbs?: any;
  testID?: string;
  noAdvisorCard?: boolean;
  informationSelect?:any;
  wizard?: boolean;
  wizardView?: boolean;
  singleColumn?: boolean;
}

export default function Layout(props: ILayout) {
  const responsiveStyle = useDeviceWidth()
  const isMobile = useIsMobile()
  let styles: any = {}
  let imageBGTopOffset = (responsiveStyle!=='largeScreen' && responsiveStyle!=='tabletDevice')?140:70

  if(responsiveStyle === 'largeScreen' || responsiveStyle === 'tabletDevice') {
    let ccflex = responsiveStyle === 'largeScreen' ? '72%' : '62%'
    let acflex = responsiveStyle === 'largeScreen' ? '25%' : '35%'

    if (props.singleColumn) {
      ccflex = '100%'
      acflex = '0%'
    }

    let acflexview = responsiveStyle === 'largeScreen' ? '29%' : '100%'
    styles = {
      container: {
        width: "100%",
        flex: props.wizard ? '2' : '1',
        flexDirection: props.wizardView && responsiveStyle === 'tabletDevice' ? 'column' : 'row',
      },
      caseCard: {
        flexBasis: (props.wizard && responsiveStyle === 'tabletDevice') || (props.wizardView && responsiveStyle === 'largeScreen') ? '72%' : props.wizard && !props.wizardView ? '100%' : ccflex
      },
      advisorCard: {
        flexBasis: props.wizardView ? acflexview : acflex, 
        marginLeft: 'auto',
        paddingLeft: props.wizard && responsiveStyle === 'largeScreen' ? 20 : 0,
        marginTop: props.wizardView && responsiveStyle !== 'largeScreen' ? 17 : 0
      },
      messages: {
        padding: 28, 
        flexDirection: 'row'
      }
    }
  } else {
    styles = {
      container: {
        flexDirection: 'column',
      },
      caseCard: {
        flexBasis: "100%"
      },
      advisorCard: {
        flexBasis: '100%', 
        marginTop: 17
      },
      messages: {
        padding: 28, 
        flexDirection: props.wizard && !props.wizardView ? 'row' : 'column'
      }
    }
  }
  
  return (
    <AppContainer 
      navigation={props.navigation} 
      breadcrumbs={props.breadcrumbs}
      mainSectionWrapper={props.mainSectionWrapper}
      testID={props.testID}
      style={{
        display: props.wizard ? 'flex' : 'block',
        flexDirection: 'row'
      }}
    >
      <View style={props.imageBG? [imageBGStyle[responsiveStyle]]:null}>
        { props.imageBG ? <ImageBG/> : null }
        <View style={[{ paddingTop: props.imageBG ? imageBGTopOffset : 0, paddingBottom: props.introHeading ? 30 : 0 }, props.introSectionWrapper]}>
          {
            props.introHeading && 
            <View>
              <Text 
                size="xl"
                style={{
                  fontFamily: 'JekoBold'                
                }}
                accessibilityRole="heading"
                ariaLevel="1"
              >{props.introHeading}</Text>
              {props.introBody ? <GreetingBody text={props.introBody} /> : null}
            </View>
          }
          {
             props.informationSelect &&
             <View style={{marginTop:40}}>
             {
              props.informationSelect
             }
             </View>
          }
        </View>
      </View>
      <View style={[{ marginTop: !isMobile ? props.imageBG?20-imageBGTopOffset: 20 : 0 }, props.contentWrapper]}>
        {props.wizardView ? (
          <View style={styles.container}>
            {
              React.Children.map(props.children, (child, i) => {
                if(i === 0 || props.noAdvisorCard) {
                  return <View style={{ width: responsiveStyle === 'largeScreen' ? '70%' : '100%' }}>{child}</View>
                } else {
                  return <View style={[styles.advisorCard, { width: responsiveStyle === 'largeScreen' ? '29%' : '100%' }]}>{child}</View>
                }
              })
            }
          </View>
        ) : (
          <>
            <View style={styles.container}>
              {
                React.Children.map(props.children, (child, i) => {
                  if(i === 0 || props.noAdvisorCard) {
                    return <View style={[styles.caseCard, props.mainSectionStyle]}>{child}</View>
                  } else {
                    return <View style={styles.advisorCard}>{child}</View>
                  }
                }) 
              }
            </View>
          </>
        )}
      </View>
    </AppContainer>
  )
}