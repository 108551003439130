import { useRoute } from "@react-navigation/native";
import { useEffect } from "react";
import { FormState } from "react-hook-form";

const lookupFormChanged:Record<string,boolean> = {};

const useForm=(formState:FormState<any>)=>{
  const currentRoute = useRoute();
  //formState.isDirty doesn't reset when user undo. so use dirtyField directly.
  const {dirtyFields} = formState;

  useEffect(() => {
    setFormChanged(Object.keys(dirtyFields).length>0);
  },[Object.keys(dirtyFields).length])

  const getKey=()=>JSON.stringify(currentRoute);
  const setFormChanged=(changed:boolean)=>{
    const key=getKey();
    lookupFormChanged[key]=changed;
  }
  const hasFormChanged=()=>{
    const key=getKey();
    return lookupFormChanged[key]?lookupFormChanged[key]:false;
  }
  
  return{hasFormChanged}
}

export default useForm