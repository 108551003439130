import useCounter from "./useCounter";

let checkSessionHandle:NodeJS.Timeout =undefined
let sessionEndTime:number=undefined
let counterStarted=false

export default function useSessionTicket(defaultInitialCounterDurationSeconds:number,defaultFinalCounterDurationSeconds:number){
    const {startCounter, stopCounter, useCounterDisplaying}=useCounter()
    const sessionEndTimeKeyName ="sessionEndTime"
    let sessionTicketDestroyedCallback:()=>void=undefined;
    let sessionTicketCreatedCallback:()=>void=undefined;
    let warningStartedCallback:()=>void=undefined

    const createSessionTicket=()=>{

        clearSessionTicket();

        const expirationDate =new Date()
        const durationSeconds=defaultInitialCounterDurationSeconds + defaultFinalCounterDurationSeconds
       
        expirationDate.setSeconds(expirationDate.getSeconds() + durationSeconds);
        sessionEndTime=expirationDate.getTime();
        sessionStorage.setItem(sessionEndTimeKeyName,sessionEndTime.toString())
        
        if(sessionTicketCreatedCallback)
            sessionTicketCreatedCallback()

        checkSessionHandle=setInterval(()=>{
           
            //new session created
            const storageSessionEndTime= getStorageSessionEndTime()
            if(sessionEndTime<storageSessionEndTime){
                sessionEndTime=storageSessionEndTime
                stopWarningCounter()
                if(sessionTicketCreatedCallback)
                    sessionTicketCreatedCallback()
               
            }
             
            //session expired
            const currentTime= new Date().getTime()
            if(currentTime>=sessionEndTime){
                destroySessionTicket()
            }
            else{//start warning counter
              
                const remainingSeconds= getRemainingSeconds()
                if (!counterStarted &&  remainingSeconds<=defaultFinalCounterDurationSeconds){
                   
                    startWarningCounter(remainingSeconds)
                }

            }
        },1000)
    }
    const getRemainingSeconds=()=>{
        const currentTime= new Date().getTime()
      return  Math.floor(Math.max(0,sessionEndTime-currentTime)/1000)
    }
    const stopWarningCounter=()=>{
        stopCounter();
        counterStarted=false;
    }
    const startWarningCounter=(countdownSeconds:number)=>{
        stopWarningCounter()

        if(warningStartedCallback) 
            warningStartedCallback();

        startCounter(countdownSeconds);
        counterStarted=true;
    }
    const getStorageSessionEndTime=()=>{
        let storageSessionEndTime:number=0;
        const expirationTimeString=sessionStorage.getItem(sessionEndTimeKeyName)
        
        if(expirationTimeString)
         storageSessionEndTime=Number(expirationTimeString)
          
        return storageSessionEndTime
    }
    const clearSessionTicket=()=>{
        stopWarningCounter()
        if(checkSessionHandle){
            clearInterval(checkSessionHandle)
        }
        sessionStorage.removeItem(sessionEndTimeKeyName)
    }
    const destroySessionTicket=()=>{
        clearSessionTicket()
        if(sessionTicketDestroyedCallback)
            sessionTicketDestroyedCallback()
    }
    const useSessionTicketCreated=(action:()=>void)=>{
        sessionTicketCreatedCallback=action;
    }
    const useSessionTicketDestroyed=(action:()=>void)=>{
        sessionTicketDestroyedCallback=action;
    }
    const useWarningStarted=(action:()=>void)=>{
        warningStartedCallback=action
    }
    const updateCounter=()=>{
        
        const remainingSeconds= getRemainingSeconds()

        if(remainingSeconds>0){
            if (remainingSeconds<=defaultFinalCounterDurationSeconds)
                startWarningCounter(remainingSeconds) 
        }
       
    }
   
    return {createSessionTicket,
        destroySessionTicket,
        useSessionTicketCreated,
        useSessionTicketDestroyed,
        useWarningStarted,
        useWarningDisplaying:useCounterDisplaying,
        updateCounter}
}
