import React, { useEffect, useRef, useState } from 'react';
import { Linking, TouchableOpacity } from 'react-native';
import { Hoverable } from 'react-native-web-hover'
import styled from 'styled-components/native';

const StyledBold = styled.Text`
  font-family: SourceSansPro-Bold;
  cursor: pointer;
  text-decoration-style: solid;
  text-decoration-color: #d04343;
`

export interface LinkProps{
  children: JSX.Element|string;
  leftIcon?: JSX.Element;
  rightIcon?:JSX.Element;
  url?:string;
  onPress?:any;
  onFocus?:any;
  style?:object;
  testID?:string;
  accessibilityLabel?:string;
  accessible?:boolean;
  disabled?:boolean;
}

export default function Link(props:LinkProps) {
  const linkRef= useRef<HTMLElement>()
  const [focusStyle,setFocusStyle]=useState({padding:0})
  
  const hasFocus=(focus:boolean)=>{
    if (focus){ 
      setFocusStyle({padding:3})
    }
    else{ 
      setFocusStyle({padding:0})
    }
    props.onFocus && props.onFocus(focus)
  }
  const handleOnPress=()=>
  {
    if(props.url)
      Linking.openURL(`${props.url}`);
    else if(props.onPress)
      props.onPress();
  }
  useEffect(()=>{
    //cannot use the attritube accessibililty='link' on TouchableOpacity because it doesn't get focus
    linkRef.current.setAttribute("role","link")
  },[linkRef.current])
   
  return (
    <Hoverable>
      {({ hovered }) => (
        <TouchableOpacity ref={linkRef} style={[focusStyle,{width: "fit-content"}]} testID={props.testID} onPress={handleOnPress}
        onFocus={()=>hasFocus(true)}
        onBlur={()=>hasFocus(false)} 
        accessibilityLabel={props.accessibilityLabel}
        accessible={props.accessible} 
        disabled={props.disabled}
         >
          <StyledBold 
            style={[props.style, 
              { 
                color: hovered ? '#9F2140' : '#0074C8'
              }]}
          >
            {
              props.leftIcon &&
              <span style={{ marginRight: 5 }}>{props.leftIcon}</span>
            }
            <span style={{ textDecoration: hovered ? 'unset' : 'underline'}}>{props.children}</span>
             {
              props.rightIcon &&
              <span style={{ marginLeft: 5 }}>{props.rightIcon}</span>
            }
          </StyledBold>
          

        </TouchableOpacity>
      )}
    </Hoverable>
  )
}