import useDeviceWidth from '../../utils/useDeviceWidth'

export interface IBaseStyle {
    backgroundColor: string;
    flexBasis?: string;
    minWidth?:string;
    borderRadius: number;
    borderWidth: number;
    shadowColor:string;
    shadowOffset: {
        width: number;
        height: number;
    };
    shadowOpacity: number;
    shadowRadius: number;
    padding: number;
}

export interface  ICaseCard extends IBaseStyle {}

export interface IBottomSection extends IBaseStyle {
    marginLeft?: string;
    marginTop?: string;
}

export const baseStyle = {
    backgroundColor: '#FFF',
    borderRadius: 3,
    borderWidth: 0,
    shadowColor: 'rgba(0, 44, 115, 0.1)',
    shadowOffset: {
        width: 0,
        height: 1,
    },
    shadowOpacity: 1,
    shadowRadius: 14,
    padding: 32,
}

export default function useScreenFormat() {
    const responsiveStyle = useDeviceWidth()
    let styles: { caseCard: ICaseCard, bottomSection: IBottomSection }
    
    if (responsiveStyle === 'largeScreen' ||responsiveStyle === 'tabletDevice') {
        let nonMobileWidth = responsiveStyle === 'largeScreen' ? '70%' : '60%'
        styles = {
            caseCard: {
                ... baseStyle,
                minWidth: nonMobileWidth
            
            },
            bottomSection: {
                ... baseStyle,
                marginLeft: '44px'
            }
        }
    } 
    else {
        styles = {
            caseCard: {
                ... baseStyle,
                flexBasis: '100%',
            },
            bottomSection: {
                ... baseStyle,
                marginTop: '20px'
            }
        }
    }
    return styles
}


