export const FETCH_CASES_PENDING = 'FETCH_CASES_PENDING'
export const FETCH_CASES_SUCCESS = 'FETCH_CASES_SUCCESS'
export const FETCH_CASES_ERROR = 'FETCH_CASES_ERROR'
export const UPDATE_CASE_TASK = 'UPDATE_CASE_TASK'

export interface Case {
    advisor: CaseAdvisor;
    caseId: string;
    caseSetId: string;
    caseNumber: string;
    caseStatus: string;
    caseType: string;
    userType: string;
    patientId: string;
    patientInd: boolean;
    firstDateOfService: string;
    hasUserStoryAnswers: boolean;
    lastDateOfService: string;
    employerGroupName: string;
    billingProviderName: string;
    patientFirstName: string;
    patientFullName: string;
    emailTag: string;
    naviguardProduct:string;
    datesOfService:string;
    potBalanceBillAmt:number;
    deductibleAmt:number;
    copayAmt:number;
    coinsuranceAmt:number;
    allowedAmt:number;
    notCoveredAmt:number;
    costShareAmt:number;
    planPaidAmt:number;
    billedAmt:number;
    tasks: Task[];
    progress: {
        caseOpenedDate: Date;
        steps: Step[];
    };
}

export interface Step{
    stepNumber: number,
    name:string,
    status: string,
    date: string
}
export interface CaseAdvisor {
    firstName: string;
    lastName: string;
    phone?: string;
    extension?: string;
}

export interface Task {
    taskId: string;
    taskName: string;
    displayOrder: number;
    taskCompleteInd: boolean;
    actionReqInd: boolean;
    readyForUser: boolean;
}

export interface UpdateTask {
    caseId: string;
    taskId: string;
    completed: boolean;
}

export interface FetchCasesPending {
    type: typeof FETCH_CASES_PENDING;
    payload: boolean;
}

export interface FetchCasesSuccess {
    type: typeof FETCH_CASES_SUCCESS;
    payload: Case[];
}

export interface FetchCasesError {
    type: typeof FETCH_CASES_ERROR;
    payload?: string;
}

export interface UpdateCaseTask {
    type: typeof UPDATE_CASE_TASK;
    payload: UpdateTask;
}

export type CasesDispatchTypes =
 | FetchCasesPending
 | FetchCasesSuccess
 | FetchCasesError
 | UpdateCaseTask