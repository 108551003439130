import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/layout'
import Card from 'react-native-elements/src/card/Card'
import ProgressBar from './ProgressBar'
import CurrentStep from './CurrentStep'
import StepButtons from './StepButtons'
import { View } from 'react-native'
import { fetchMemberStories, updateMemberStory } from '../../redux/memberstory/actions';
import { AxiosError, AxiosResponse } from 'axios';
import useGoogleTagManager, { GTMEvent } from '../../utils/useGoogleTagManager';
import { WizardMemberStory } from '../../redux/memberstory/types';
import SkeletonQuestion from '../YourExperienceDetails/SkeletonQuestion'
import Modal, { ModalBody, ModalFooter, ModalText } from '../../components/modal';
import { Button } from '../../components';
import AdvisorPanel from '../../components/advisorpanel';
import { ApplicationState } from '../../redux/rootReducer';
import { fetchCases } from '../../redux/cases/actions';
import { useIsMobile, useResize } from '../../utils/useDeviceWidth';
import Skeleton from '../../components/Skeleton';
import useDeviceWidth from '../../utils/useDeviceWidth'
import Text from '../../components/TextComponent'
import { useQueryNavigator } from "../../context-api/query";
import { QuestionResponseTemplate } from '../../redux/memberstory/types';

const cardTitleStyle = {
  borderTopLeftRadius: 14,
  borderTopRightRadius: 14,
  display: 'flex',
  justifyContent: 'center',
  color: '#FFF',
  fontSize: 14,
  paddingLeft: 32,
  paddingTop: 10,
  marginBottom: 10,
  fontFamily: "SourceSansPro-Bold",
  letterSpacing: 0.77,
  height: 52
}

const cardStyle = {
  padding: 30,
  borderRadius: 14,
  borderWidth: 0,
  shadowColor: "rgba(0, 44, 115, 0.1)",
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 1,
  shadowRadius: 10,
  elevation: 3,
  margin: 0
}

export default function Wizard({ route, navigation }) {
  const navigator = useQueryNavigator(navigation)
  const [dataLoading, setDataLoading] = useState(false)
  const [error, setError] = useState(undefined)
  const [questionVersion, setQuestionVersion] = useState(undefined)
  const [caseNumber, setCaseNumber] = useState(undefined)
  const [caseId, setCaseId] = useState(undefined)
  const [questions, setQuestions] = useState([])
  const [currentStep, setCurrentStep] = useState(1)
  const gtm = useGoogleTagManager()
  const paramCaseId = route.params?.caseId
  const [currentPageAnswers, setCurrentPageAnswers] = useState(null)
  const [showErrors, setShowErrors] = useState(false)
  const [nextDisabled, setNextDisabled] = useState(false)
  const [backDisabled, setBackDisabled] = useState(false)
  const [modal, setModal] = useState({
    visible: null,
    variant: null,
    message: null,
    onClose: null
  })
  let selectedCase = useSelector((state: ApplicationState) => state.cases.data.find(c => c.caseId === paramCaseId));
  const dispatch = useDispatch()

  const groupMoonQuestions = (moonQuestions: QuestionResponseTemplate[]) => {
    const groupsOfQuestionsById = [
      [15, 14, 13],
      [3, 1, 9, 2],
      [5, 6, 7, 4],
      [10, 12, 11, 8]
    ]

    let groupedQuestions = []

    const questionDependencies = {
      6: {
        questionId: 5,
        answerIds: [13]
      },
      9: {
        questionId: 1,
        answerIds: [2, 3]
      }
    }

    for (let questionGroupIndex = 0; questionGroupIndex < groupsOfQuestionsById.length; questionGroupIndex++) {
      const groupNumber = questionGroupIndex + 1
      const questionGroup = groupsOfQuestionsById[questionGroupIndex]
      const questions = []
      for (let questionIndex = 0; questionIndex < questionGroup.length; questionIndex++) {
        const question = moonQuestions.find(q => q.questionId === questionGroup[questionIndex])
        questions[questionIndex] = {
          ...question,
          groupNumber,
          dependsOn: questionDependencies[question.questionId],
          required: true,
          displayOrder: questionIndex,
          response: {
            answerId: question.givenAnswer?.answerId,
            answerText: question.givenAnswer?.answerText,
            questionId: question.questionId
          }
        }
      }
      groupedQuestions.push({
        groupNumber,
        questions
      })
    }
    return groupedQuestions
  }

  useEffect(() => {
    if(!selectedCase) {
      dispatch(fetchCases());
    }
  }, [])

  const isMobile = useIsMobile()
  const responsiveStyle = useDeviceWidth()
  
  useEffect(() => {
    setCaseId(route.params?.caseId)
  }, [route.params?.caseId])

  useEffect(() => {
    setCaseNumber(selectedCase?.caseNumber);
    if (selectedCase && currentStep !== null) {
      (async () => {
        setDataLoading(true)
        const request = await fetchMemberStories(selectedCase)
  
        request.payload
          .then((response: AxiosResponse<WizardMemberStory>) => {
            if (response.data.questionGroups) {
              setQuestions(response.data.questionGroups)
            } else if (response.data.patientStoryQuestionsDto) {
              setQuestions(groupMoonQuestions(response.data.patientStoryQuestionsDto))
            }
            setCurrentStep(response.data.startGroup || 1)
            setQuestionVersion(response.data.questionSetVersion)
          })
          .catch((e: AxiosError) => {
            console.error(e)
  
            setQuestions([])
            setCurrentStep(1)
  
            if (e.response && e.response.headers['content-type']?.indexOf('text/plain') > -1) {
              setError(e.response.data)
            } else {
              const charLimit = str => str.substring(0, 100);
              const errorText = 'We were unable to display the results of your member story for this case. Please try again in a few minutes.'
  
              gtm.pushEvent(GTMEvent.MemberStoryError, {
                'event': 'fetchMemberStoriesData',
                'errorType': 'Unable to display member stories',
                'errorMessage': charLimit(errorText)
              })
  
              setError(errorText)
            }
          })
          .finally(() => setDataLoading(false))
      })()
    }
  }, [selectedCase])

  useEffect(() => {
    setBackDisabled(currentStep === 1 || dataLoading)
  }, [currentStep, dataLoading])

  const handleErrorsShown = () => {
    setShowErrors(false)
  }

  const onClickNext = async () => {
    if (nextDisabled || dataLoading) {
      setShowErrors(true)
      return
    } else {
      setShowErrors(false)
    }
    if (currentStep === 4) {
      currentPageAnswers.complete = true
    } else {
      currentPageAnswers.complete = false
    }
    await saveQuestionAnswers(currentPageAnswers)

    if (currentStep < 4) {
      // call save API
      setCurrentStep(currentStep + 1)
    } else if (currentStep === 4) {
      // call save API, go to summary page
      setCurrentStep(null)

      // wizard end
      gtm.pushEvent(GTMEvent.SubmitStory, {
        caseNumber: selectedCase.caseNumber,
        patientID: selectedCase.patientId,
        userIsPatient: selectedCase.patientInd ? 'Yes' : 'No'
      })
    }
  }

  const onClickPrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1)
    } else {
      handleNavigateHome()
    }
  }

  const saveQuestionAnswers = async (story) => {
    setDataLoading(true)
    const request = await updateMemberStory(story, selectedCase)
    return request.payload.then(() => {

    }).catch((e: AxiosError) => {
      console.error(e)
      setModal({
        visible: true,
        variant: 'error-v2',
        message: 'An unknown error has occured. Please try again in a few minutes.',
        onClose: closeModal
      })
    }).finally(() => setDataLoading(false))
  }

  const handleSetNextDisabled = (value) => {
    setNextDisabled(value)
  }

  const handleSetCurrentPageAnswers = (story) => {
    // Patch local copy of questions
    let tempQuestions = questions
    for (let index = 0; index < story.responses.length; index++) {
      const { questionId, answerId, answerText } = story.responses[index]
      const questionIdx = tempQuestions[currentStep - 1].questions.findIndex(q => q.questionId === questionId)
      tempQuestions[currentStep - 1].questions[questionIdx].response.answerId = answerId
      tempQuestions[currentStep - 1].questions[questionIdx].response.answerText = answerText
    }
    setQuestions(tempQuestions)
    setCurrentPageAnswers(story)
  }

  const closeModal = () => {
    setModal({
      visible: false,
      variant: undefined,
      message: undefined,
      onClose: undefined
    })
  }

  const handleNavigateHome = () => {
    dispatch(fetchCases());
    navigator.navigate('Home')
  }

  let styles: any = {}
  if (responsiveStyle === 'largeScreen' || responsiveStyle === 'tabletDevice') {
    let ccflex = responsiveStyle === 'largeScreen' ? '100%' : '100%'
    styles = {
      caseCard: {
        display: 'flex',
        backgroundColor: "#FFF",
        borderRadius: 3,
        borderWidth: 0,
        shadowColor: "rgba(244, 165, 135, 0.2)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 12.22,
        padding: 32
      }
    }
  } else {
    styles = {
      caseCard: {
        display: 'flex',
        backgroundColor: "#FFF",
        borderRadius: 3,
        borderWidth: 0,
        shadowColor: "rgba(244, 165, 135, 0.2)",
        shadowOffset: {
          width: 0,
          height: 1,
        },
        shadowOpacity: 1,
        shadowRadius: 12.22,
        padding: 32
      }
    }
  }

  return (
    <>
      <Layout
        navigation={navigation}
        breadcrumbs={[{
          label: 'Home',
          route: 'Home'
        }, {
          label: 'Your Experience',
          route: 'YourExperience'
        }, {
          label: `${selectedCase?`Case #${caseNumber}`:''}`,
        }]}
        introHeading={
          <View style={{flexDirection:'row',alignItems:'center'}}>
            <span>Case #</span>
            {caseNumber?<span>{caseNumber}</span>:
              <View style={{height:40,width:250}}>
                <Skeleton/>
              </View>
            }
          </View>
        }
        wizard
      >
        <Card containerStyle={cardStyle}>
          <Card.Title style={[cardTitleStyle, { paddingLeft: isMobile ? 0 : 0 }]}>
            <ProgressBar percentComplete={currentStep !== null ? currentStep / 5 : 1} />
          </Card.Title>
          <Card.Divider style={{ marginBottom: '50px' }} />
          <View style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between'
          }}>
            <View style={{ paddingBottom: 32, width: responsiveStyle === 'largeScreen' ? '64%' : isMobile ? '100%' : '60.666%', paddingRight: isMobile ? 0 : '6%' }}>
              {
                (!questions || dataLoading) && [...Array(10).keys() as any].map((i: number) => <SkeletonQuestion key={`skeleton-question-${i}`} />)
              }

              {
                currentStep === null && 
                (
                  <>
                    <View
                      style={{ marginBottom: 30 }}
                    >
                      <Text style={{ fontSize: 32 }}><Text bold={true}>Thank you for your time and effort.</Text> We understand that revisiting this experience may not have been easy, but we appreciate the work it takes to tell us your story.</Text>
                    </View>
                    <Button
                      label={'Return to Homepage'}
                      onPress={() => handleNavigateHome()}
                      testID={`return-to-homepage-button-${paramCaseId}`}
                    />
                  </>
                )
              }

              <View
                style={{ marginBottom: 30 }}
              >
                {currentStep === 1 && !dataLoading && (
                  <Text style={{fontSize: 32}}><Text bold={true}>We will be asking you to answer a few questions regarding your experience with <Text bold={true} style={{ textTransform: 'capitalize' }}>{ selectedCase && selectedCase.billingProviderName.toLowerCase() }</Text>.</Text> The answers you provide will help us in our attempt to lower your costs.</Text>
                )}

                {currentStep === 2 && !dataLoading && (
                  <Text style={{fontSize: 32}}><Text bold={true}>Thank you for working through the first set of questions! We know that the first one can take extra time.</Text> Now let's dive into some more details about your bill.</Text>
                )}

                {currentStep === 3 && !dataLoading && (
                  <Text style={{fontSize: 32}}><Text bold={true}>You're doing great!</Text> We understand that bills need to be paid, so these next questions address that part of your experience.</Text>
                )}

                {currentStep === 4 && !dataLoading && (
                  <Text style={{fontSize: 32}}><Text bold={true}>You made it through the details. Great work!</Text> Now we would like to understand how you found <Text style={{ textTransform: 'capitalize' }}>{ selectedCase && selectedCase.billingProviderName.toLowerCase() }</Text> and how you were informed throughout the experience.</Text>
                )}
              </View>

              {currentStep !== null && questions && !dataLoading &&
                <>
                  <CurrentStep
                    questionGroup={questions.length > 0 ? questions[currentStep - 1] : {}}
                    caseId={caseId}
                    questionVersion={questionVersion}
                    showErrors={showErrors}
                    onSetNextDisabled={handleSetNextDisabled}
                    onSetCurrentPageAnswers={handleSetCurrentPageAnswers}
                    onErrorsShown={handleErrorsShown}
                  />
                  <StepButtons
                    nextDisabled={false}
                    backDisabled={false}
                    currentStep={currentStep}
                    onClickNext={onClickNext}
                    onClickPrevious={onClickPrevious}
                  />
                </>
              }

            </View>
            {responsiveStyle === 'largeScreen' && (
              <View style={{ paddingBottom: 32, width: responsiveStyle === 'largeScreen' ? '30%' : isMobile ? '100%' : '33.333%' }}>
                {selectedCase && <><AdvisorPanel cases={[selectedCase]} /></>}
              </View>
            )}
          </View>
        </Card>
        
        {responsiveStyle !== 'largeScreen' && (
          <View style={{ paddingBottom: 32, width: isMobile ? '100%' : '33.333%' }}>
            {selectedCase && <><AdvisorPanel cases={[selectedCase]} /></>}
          </View>
        )}
      </Layout>

      <Modal {...modal}>
        {modal.variant === 'error' || modal.variant === 'success' ? (
          <>
            <ModalBody>
              <ModalText>{modal.message}</ModalText>
            </ModalBody>
            <ModalFooter>
              <Button
                label={'Close'}
                onPress={modal.onClose}
                testID={`member-story-modal-button-${paramCaseId}`}
              />
            </ModalFooter>
          </>
        ) : null}
      </Modal>
    </>
  )
}