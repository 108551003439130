import React, { useState, useEffect, useRef } from 'react'
import { View, StyleSheet, ScrollView,Image } from 'react-native'
import { Overlay } from 'react-native-elements';
import { useForm } from 'react-hook-form';
import Button from '../button'
import Text from '../TextComponent'
import { useResize } from '../../utils/useDeviceWidth'
import { useOverlay } from '../../context-api/overlay/overlayState'
import { questions } from './constants'
import FeedbackHeader from './components/FeedbackHeader'
import FeedbackTypeQuestion from './components/feedbackType'
import CommentsQuestion from './components/CommentsQuestion'
import VisitPurpose from './components/visitPurpose'
import DidCompleteTask from './components/didCompleteTask'
import Recommend from './components/recommend'
import useGoogleTagManager, { GTMEvent } from '../../utils/useGoogleTagManager';


export default function Feedback() {
  const gtm = useGoogleTagManager()
  const { overlay } = useOverlay()
  const modalRef = useRef(null)
  const [ isVisible, setIsVisible ] = useState(false)
  const { register, setValue, handleSubmit, formState, reset } = useForm()
  const { errors,  isSubmitting } = formState
  const [ , isMobile, isTablet, height ] = useResize()
  let topPos = height as number

  useEffect(() => {
    Object.keys(questions).forEach(key => {
      let question = questions[key]
      register(question.questionId, question.rules)
    });
  }, [register])

  const successMessage = () => {
    return (
      <View style={styles.successMessage}>
        <Text style={{marginBottom: 10}} bold={true} size="l">Thank You!</Text>
        <Text style={{lineHeight: 24}} size={16}>We take all feedback seriously and use it to</Text>
        <Text style={{lineHeight: 24}} size={16}>help improve the experience for everyone.</Text>
      </View>
    )
  }

  const closeForm = () => {
    setIsVisible(false)
    reset()
  }

  const handleFormSubmit = async (data: any) => {
    // push a gtm event to indicate the user completed the survey
    gtm.pushEvent(GTMEvent.SurveySubmit, {
      surveyFeedbackType: data.feedbackType,
      surveyComments: data.comments,
      surveyPurpose: data.visitPurpose,
      surveyAbleToComplete: data.completedTask,
      surveyScore: data.willRecommend
    })

    closeForm()
    overlay({
      show: true,
      type: 'message',
      message: '',
      customMessage: true,
      customMessageRender: successMessage,
      messageType: 'success',
      buttonLabel: 'Close',
    })
  }

  const handleSetValue = (questionId: string, answer: any) => {
    setValue(questionId, answer)
  }

  return (
    <View>
      <Button style={
        {borderRadius: 7,
        borderBottomLeftRadius: 0, 
        borderBottomRightRadius: 0, 
        backgroundColor: '#0074C8',
        borderWidth:0
        }}
        textStyle={{paddingLeft:12,paddingRight:12,paddingTop:10,paddingBottom:10}}
        onPress={() => setIsVisible(true)} 
        label={
          <>
          <Image style={{width:20,height:20,top:5,marginRight:7}} source={require('../../assets/images/feedback-icon.svg')}/> 
            <Text style={{fontFamily:"SourceSansPro-Bold",fontSize:18,color:"#FFF"}}>Feedback</Text>
            <View style={{marginLeft:12}}>
              <svg width="10" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#FFF"/>
              </svg>
            </View>
          </>
          }
        
        />
      <Overlay 
        isVisible={isVisible} 
        backdropStyle={{backgroundColor:'rgba(0, 0, 0, 0.5)'}}
        overlayStyle={[
          {  
            borderRadius:14, 
            margin: 16, 
            maxHeight: topPos -32, 
            padding: 0
          }, 
          !isMobile ? {minWidth: 600} : {} 
        ]}
        onBackdropPress={closeForm}
      >
        <ScrollView>
          <View ref={modalRef} style={isMobile? styles.contentMobile : styles.contentDesktop}>
            <div data-role="modal">
              <FeedbackHeader handleClose={closeForm} />
              <FeedbackTypeQuestion errors={errors} handleSetValue={handleSetValue} />
              <CommentsQuestion errors={errors} setValue={setValue} />
              <VisitPurpose 
                modalRef={modalRef} 
                errors={errors} 
                handleSetValue={handleSetValue} 
                isMobile={isMobile as boolean}
                isTablet={isTablet as boolean}
              />
              <DidCompleteTask errors={errors} handleSetValue={handleSetValue} />
              <Recommend errors={errors} handleSetValue={handleSetValue} />

              <Button 
                disabled={isSubmitting}
                onPress={handleSubmit(handleFormSubmit)} 
                label="SUBMIT" 
              />
            </div>
          </View>
        </ScrollView>
      </Overlay>
    </View>
    
  )
}

const styles = StyleSheet.create({
  contentDesktop: {
    flex: 1,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 28,
    paddingBottom: 24
  },
  contentMobile: {
    flex: 1,
    paddingLeft: 17,
    paddingRight: 17,
    paddingTop: 28,
    paddingBottom: 16
  },
  buttonStyle: {
    marginRight: 8,
    marginTop: 8
  },
  successMessage: {
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
    marginBottom: 36
  }
})