import React, { useEffect, useState } from 'react'
import Confirmation from './Confirmation';
import { Route,  useNavigation,useRoute } from '@react-navigation/native';
import { FormState } from 'react-hook-form';
import useForm from './useForm';
import useNavigationHistory from './useNavigationHistory';


let leavingRoute:Route<string,object>=undefined;
let leavingConfirmed=false;
let  historyNavigated=false;

export interface UnsavedChangesWarningProps {
   
    formState:FormState<any>;
    onIgnoreChanges:()=>void;
}
/**
 * Warn the user if there are any changes done to the form. 
 * @param props:UnsavedChangesWarningProps 
 */
const UnsavedChangesWarning=(props:UnsavedChangesWarningProps)=>{
  const[showLeavingConfirmation,setShowLeavingConfirmation]=useState(false)
  const navigation = useNavigation();
  const currentRoute = useRoute();
  const {hasFormChanged} =useForm(props.formState);
 
  const confirmLeaving=(e)=>{
    if(!leavingConfirmed && hasFormChanged()){
        if(e.data){
          leavingRoute=e.data.action.payload;
          e.preventDefault();
        }
        else{
          const {index, routes} = navigation.dangerouslyGetState();
          leavingRoute = routes[index];
          navigation.navigate(currentRoute)
        }
        
       setShowLeavingConfirmation(true)
    }
    leavingConfirmed=false
  }
  useEffect(()=>{
    const unsubscribe = navigation.addListener('beforeRemove', confirmLeaving)
    return unsubscribe
  },[navigation])

  useEffect(()=>{
    const unsubscribe =navigation.addListener('blur',confirmLeaving)
    return unsubscribe
  },[navigation])


 
  /////handle back button
  const  {addNavigationHistoryAction,removeNavigationHistoryAction,isNavigationRouteValid}=useNavigationHistory()
  useEffect(()=>{
      let location ='';
      useScreenLoaded(()=>{  
        location =window.location.href;
        //cases where the current route and location do not match, ignore the additional push state
      if(isNavigationRouteValid(location)){ 
          historyPushMainState();
          addNavigationHistoryAction(onHistoryBackConfirmLeaving)
        }
      })
      return ()=> removeNavigationHistoryAction(location)
    },[])
  
    useEffect(()=>{
      const unsubscribe = navigation.addListener('beforeRemove', (e:any)=>{
        if(history.state.name==='main' && !hasFormChanged() && isNavigationRouteValid(window.location.href)){
          e.preventDefault();
          leavingConfirmed=true
          leavingRoute=e.data.action.payload;
          history.back();
        }
      })
      return unsubscribe
  },[navigation])
  

  const useScreenLoaded=(action:()=>void)=>{
    const timeOut = setTimeout(()=>{
      action()
      clearTimeout(timeOut);
    },1000)
    /* need to delay for Safari to load it later when window is minized with hamburger menu
     rather than early to make this work, where Chrome doesn't a time (0)*/
  }

  
  const historyPushMainState=()=>{
    history.replaceState({...history.state,name:'back'},'','')
    history.pushState({...history.state,name:'main'},'','')
  }
  const onHistoryBackConfirmLeaving=(event:PopStateEvent)=>{ 
    if(event.state.name==='back'){
      if(!leavingConfirmed && hasFormChanged()){
        historyNavigated=true; 
        historyPushMainState();
        setShowLeavingConfirmation(true)
      }
      else if(leavingConfirmed && leavingRoute){
        navigation.navigate(leavingRoute);
        leavingRoute=undefined;
      }
      else{
        history.back();
      }
      leavingConfirmed=false
    }
  }

  ///////////handle dialog box confirmation
  const handleLeavingConfirmationOk=()=>{
    setShowLeavingConfirmation(false)
    leavingConfirmed=true;
    props.onIgnoreChanges()

    if(historyNavigated)
      history.back();
    else
      navigation.navigate(leavingRoute)

    historyNavigated=false
    leavingRoute=undefined;
    
  }
  const handleLeavingConfirmationCancel=()=>{
    setShowLeavingConfirmation(false)
    leavingConfirmed=false;
    leavingRoute=undefined;
    historyNavigated=false
    
  }
  return(
      <Confirmation show={showLeavingConfirmation} message="You have made some changes. Are you sure to discard them and leave the page?" onCancel={handleLeavingConfirmationCancel} onOK={handleLeavingConfirmationOk}></Confirmation> 
  )
}

export default UnsavedChangesWarning