const initialState = {
    show: false,
    message: '',
    type: 'spinner',
    autoHide: true,
    hideTime: 3000
};
export default (state = initialState, action: { type: string; payload: any; }) => {
    if (action.type==='UPDATE_OVERLAY'){
            return { ...state, ...action.payload } 
    }    
    else{
            throw new Error('Unsupported action type');
    }
};