import { createDrawerNavigator } from '@react-navigation/drawer';
import * as React from 'react';
import { Platform} from 'react-native';
import NotFoundScreen from '../screens/NotFoundScreen';
import BottomTabNavigator from './BottomTabNavigator';
import HomeNavigator from '../screens/Home';
import Documents from '../screens/Documents'
import YourExperience from '../screens/YourExperience'
import YourExperienceDetails from '../screens/YourExperienceDetails'
import MessageCenter from '../screens/MessageCenter'
import AccountSettings from '../screens/AccountSettings'
import Verification from '../screens/Verification';
import Landing from '../screens/Landing';
import Diagnostics from '../screens/Diagnostics';
import Contact from '../screens/Contact';
import Wizard from '../screens/Wizard'
import WizardViewer from '../screens/Wizard/WizardViewer'
import useAuthentication from '../utils/useAuthentication';
import { ai } from '../telemetryService';
import NegotiationIntro from '../screens/Negotiation';
import NegotiationStory from '../screens/Negotiation/Story';
import NegotiationNumbers from '../screens/Negotiation/Numbers';
import NegotiationOptions from '../screens/Negotiation/Options';
import NegotiationPlan from '../screens/Negotiation/Plan';
import SignOut from '../screens/SignOut';
import { SESSION_PROVIDER_BANNER } from '../redux/providerBanner/types';

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Drawer = createDrawerNavigator();
const isWeb = Platform.OS === 'web'
export default function RootNavigator(props) {

  //allow the navigation to load prior to prompt for login if unauthorized so that the 
  //app will redirect to the user inputted url/initial url.
  React.useEffect(() => {
      if(props.login && props.unauthenticated) {
        //clear the provider banner session
        sessionStorage.removeItem(SESSION_PROVIDER_BANNER)
        props.login()
      }
    }, [])

    const { account } = useAuthentication()
    React.useEffect(() => {
        if(account  && account.sub) {
          const subject = account.sub
          ai.initialize(subject);
        }
        else{
          console.error("Telemetry Service Not Initialized: user subject is missing")
        }
    }, [])
    //remove some hidden elements for better tabbing experience
    React.useEffect(()=>{
      const focusables = document.querySelectorAll("[data-focusable=true]");
      focusables.forEach(f => {
        const focusable= (f as HTMLElement)
        if(focusable["ariaSelected"] === 'false' && (focusable.getAttribute("role")==='link' || focusable.getAttribute("role") === 'button')) {
         focusable.tabIndex=-1
         focusable["ariaHidden"] = "true"
        }
      })
    },[])
    //load nothing prior to authentication so not to waste api calls on unauthorized access
    const authenticatedComponent = (component:({route,navigation})=>JSX.Element)=>{
      return props.unauthenticated?()=>null:component
    }
    return (
    <Drawer.Navigator screenOptions={{ headerShown: false }}>
      {
        isWeb ? 
          <>
           <Drawer.Screen 
              name="Landing"
              component={Landing} //authenticatedComponent not needed here since Landing simply redirects to Home 
            />
            <Drawer.Screen 
              name="SignOut"
              component={authenticatedComponent(SignOut)}
            />
            <Drawer.Screen 
              name="Home"
              component={authenticatedComponent(HomeNavigator)}
            /> 
            <Drawer.Screen 
              name="Documents"
              component={authenticatedComponent(Documents)} 
            />
            <Drawer.Screen 
              name="MessageCenter"
              component={authenticatedComponent(MessageCenter)} 
            />
            <Drawer.Screen 
              name="Verification"
              component={authenticatedComponent(Verification)} 
            />
            <Drawer.Screen 
              name="YourExperience"
              component={authenticatedComponent(YourExperience)} 
            />
            <Drawer.Screen 
              name="YourExperienceDetails"
              component={authenticatedComponent(YourExperienceDetails)} 
            />
            <Drawer.Screen 
              name="AccountSettings"
              component={authenticatedComponent(AccountSettings)} 
            />
            <Drawer.Screen 
              name="Diagnostics"
              component={authenticatedComponent(Diagnostics)} 
            />
            <Drawer.Screen 
              name="Contact"
              component={authenticatedComponent(Contact)} 
            />
            <Drawer.Screen 
              name="NegotiationIntro"
              component={authenticatedComponent(NegotiationIntro)} 
            />
            <Drawer.Screen 
              name="NegotiationStory"
              component={authenticatedComponent(NegotiationStory)} 
            />
             <Drawer.Screen 
              name="NegotiationNumbers"
              component={authenticatedComponent(NegotiationNumbers)} 
            />
             <Drawer.Screen 
              name="NegotiationOptions"
              component={authenticatedComponent(NegotiationOptions)} 
            />
             <Drawer.Screen 
              name="NegotiationPlan"
              component={authenticatedComponent(NegotiationPlan)} 
            />
            <Drawer.Screen 
              name="Wizard"
              component={authenticatedComponent(Wizard)} 
            />
            <Drawer.Screen 
              name="WizardViewer"
              component={authenticatedComponent(WizardViewer)} 
            />
          </>
        :
          <Drawer.Screen 
            name="Root"
            component={BottomTabNavigator} 
          />
      }
      <Drawer.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
    </Drawer.Navigator>
  );
}