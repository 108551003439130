import React, { useEffect, useState } from 'react';
import { View, Image } from 'react-native';
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../redux/rootReducer';
import Layout from '../../components/layout'
import { SkeletonCaseCard } from '../../components/casecard'
import { Text } from '../../components'
import { useIsMobile } from '../../utils/useDeviceWidth'
import CasesErrorBoundary from '../../components/boundaries/CasesErrorBoundary';
import useDeviceWidth from '../../utils/useDeviceWidth'
import { fetchCases } from '../../redux/cases/actions';
import CaseItem from './CaseItem'
import { usePageLoadEffect } from '../../utils/useGoogleTagManager';
import CaseSelect from '../../components/caseselect';
import { Case } from '../../redux/cases/types';

export default function Documents({ navigation }) {
  const responsiveStyle = useDeviceWidth()
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const casesPending = useSelector((state: ApplicationState) => state.cases.pending)
  const casesError = useSelector((state: ApplicationState) => state.cases.error)
  const cases:Case[] = useSelector((state: ApplicationState) => state.cases.data)
  const [filteredCases,setFilteredCases]=useState(cases)

  useEffect(() => {
    if(cases.length <= 0) {
      doFetchCases()
    }
  }, [])

  useEffect(() => {
    setExcludeSelfCoachingCases(cases)
  }, [cases])

  usePageLoadEffect()


  const doFetchCases = () => {
    dispatch(fetchCases());
  }

  const baseStyle = {
    paddingTop: '0px',
    flexFlow: 'column wrap'
  }

  let styles: any = {
    explainerText: {
      backgroundColor: '#ffffff',
      borderRadius: '0 0 14px 14px',
      borderWidth: 0,
      shadowColor: "rgba(0, 44, 115, 0.1)",
      shadowOffset: {
          width: 0,
          height: 1,
      },
      shadowOpacity: 1,
      shadowRadius: 12.22,
      paddingTop: 28,
      paddingLeft: 28,
      paddingRight: 28,
      paddingBottom: 28,
    }
  }

  if(responsiveStyle === 'largeScreen' || responsiveStyle === 'tabletDevice') {
    let ccflex = responsiveStyle === 'largeScreen' ? '70%' : '60%';
    let ccflexSmall = responsiveStyle === 'largeScreen' ? '30%' : '40%';
    
    styles = {
      ...styles,
      caseCard: { flexBasis: ccflex },
      explainerSection: {
        ...baseStyle,
        width: '280px',
        flexBasis: ccflexSmall,
        flexFlow: 'column nowrap'
      },
      imageContainer: {
        width: '280px'
      }
    }
  } else {
    styles = {
      ...styles,
      caseCard: { flexBasis: "100%" },
      explainerSection: {
        ...baseStyle,
        flexBasis: '100%',
        marginTop: '20px'
      },
      imageContainer: {
        width: '100%'
      }
    }
  }

  const handleRetryCases = () => {
    doFetchCases()
  }
  
  const setExcludeSelfCoachingCases=(selectCases:Case[])=>{
    if(selectCases && selectCases.length>0){
      setFilteredCases(selectCases.filter(c=>c.naviguardProduct!=='Self Coach'))
    }
  }
  return (
    <Layout 
      testID="documents-root"
      introHeading="Documents" 
      mainSectionStyle={styles.caseCard}
      informationSelect={
        <CaseSelect cases={cases} casesPending={casesPending} onCasesFiltered={setExcludeSelfCoachingCases}/>
      } 
    >
      <CasesErrorBoundary onRetry={handleRetryCases}>
        {
          casesPending && (
            [0, 1, 2].map((item) => <SkeletonCaseCard key={`skeleton-${item}`} />)
          )
        }
        {
          filteredCases.map((caseItem) => {
            return <CaseItem key={caseItem.caseId} caseItem={caseItem} />
          })
        }
        {
          !casesPending && !casesError && filteredCases.length === 0 && (
            <View style={{marginTop: 30}}>
              <Text size="m" style={{transform: 'unset'}}>There are no cases currently available for your review.</Text>
            </View>
          )
        }
      </CasesErrorBoundary>
      <View style={styles.explainerSection}>
        <View style={{
          backgroundColor: '#002C73',
          borderTopLeftRadius: '14px',
          borderTopRightRadius: '14px',
          padding: 12,
          position: 'relative',
          bottom: '-14px'
        }}>
          <View style={
              !isMobile ? {height: 144, width: '100%'}
              : {height: 144, width: '100%'}
            }>
            <Image 
              style={{ width: '100%', height: '100%'}} 
              resizeMode="contain" 
              source={require('../../assets/images/eob-vs-bill.svg')} 
              accessible={false}
              accessibilityLabel="" 
            /> 
          </View>
        </View>
        
        <View>
        </View>
        <View style={[styles.explainerText, { borderRadius: '14px' }]}>
          <Text accessibilityRole="heading" ariaLevel="3" bold={true} style={{fontSize: 20}}>Explanation of benefits (EOB) vs. a bill?</Text>
          <Text style={{ marginTop: 10, fontSize: 16 }}>
            An Explanation of Benefits is a report from your insurance company. The EOB outlines what your plan will pay and what you will owe when you receive a bill from your provider.
          </Text>
          <Text style={{ marginTop: 10, fontSize: 16 }}>
            A bill should come from your provider's office and will typically show the amount charged, the amount your insurance plan paid, and what you owe. <span style={{fontFamily: "SourceSansPro-Bold"}}>Naviguard <Text>&trade;</Text> needs a provider bill in order to negotiate a case on your behalf.</span>
          </Text>
        </View>
      </View>
    </Layout>
  )
}