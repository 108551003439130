import React from 'react'
import { Hoverable } from 'react-native-web-hover'
import {  TouchableOpacity, Platform } from "react-native"
import { Text } from '../index'
import useGoogleTagManager, { GMTNavigationLinkPlacement } from '../../utils/useGoogleTagManager';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

interface FooterItemProps {
  linkURL: string;
  footerStyle: any;
  footerText: string;
}

export default function FooterItem(props: FooterItemProps) {
  const { linkURL, footerStyle, footerText } = props
  const gtm=useGoogleTagManager()

  const onClick = () => {
    gtm.pushNavigation(GMTNavigationLinkPlacement.Footer,footerText)
    if(Platform.OS === 'web') {
      window.open(linkURL, '_blank')
    } 
  }

  return (
    <Hoverable 
      accessible={true} 
      accessibilityRole='link' 
      >
      {({ hovered }) => (
        <TouchableOpacity
          accessibilityLabel={`${footerText} opens in a new window`}
          accessible={true}
          onPress={onClick}
          style={[footerStyle, {
            color: '#ffffff',
            borderBottom: hovered ? '1px solid #ffffff' : '1px solid transparent'
          }]}
        >
          <Text 
            bold={true}
            style={{
              fontSize: 14,
              fontFamily: 'JekoBold',
              color: '#ffffff',
              letterSpacing: '1.5px',
              textTransform: 'capitalize'
            }}
          >
            {footerText} <MaterialIcons style={{ position: 'relative', top: '4px' }} name="open-in-new" size={20} color="#ffffff" />
          </Text>
        </TouchableOpacity>
      )}
    </Hoverable>
  )
}