import { Dispatch } from "redux";
import { UpdateCaseSelect, UPDATE_CASE_SELECT} from "./types";


export const updateCaseSelect =  (status:string[]) => (dispatch: Dispatch<UpdateCaseSelect>)=> {
    dispatch({
        type:UPDATE_CASE_SELECT,
        payload:status
      })
  
}