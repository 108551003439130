import React, { useState, useRef,useEffect } from 'react'
import { 
  View, 
  Animated,
  TouchableOpacity,
  Easing,
} from 'react-native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import Ionicons from '@expo/vector-icons/Ionicons';
import useDeviceWidth from '../../utils/useDeviceWidth'
import { Text, Link, Button } from '../../components'
import Upload from './UploadOverlay'
import {Case} from '../../redux/cases/types'
import { styles } from '../../components/casecard/components/styles'
import {fetchDocuments,deleteDocument, downloadDocument, resetDeleteDocumentStatus,DownloadResult} from '../../redux/documents/actions'
import {CaseError, Document, DocumentDeleteStatus} from '../../redux/documents/types'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../redux/rootReducer';
import  mime  from 'mime-types'
import Confirmation from '../../components/modal/Confirmation'
import Message from '../../components/modal/Message';
import useGoogleTagManager, { GTMEvent } from '../../utils/useGoogleTagManager';
import Tooltip from 'react-native-walkthrough-tooltip';
import { DocumentsState } from '../../redux/documents/reducer';
import Skeleton from '../../components/Skeleton'
import styled  from 'styled-components/native';
import useAppInsights, { AIEvent } from '../../utils/useAppInsights'


const toolTipStyle={
  padding: 12,
  borderRadius: 2,
  backgroundColor:'#002C73',
  fontFamily:'SourceSansPro',
  width: 116,
  height: 44
 
}

const toolTipTextStyle={
  fontSize: 14,
  color: '#ffffff'
}
const cardStyle = {
  borderRadius: 14,
  borderWidth: 0,
  shadowColor: "rgba(0, 44, 115, 0.1)",
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 1,
  shadowRadius: 12.22,

  elevation: 3,
  padding: 20,
  backgroundColor: '#FFF',
  marginBottom: 20
}
interface Props{
  caseItem:Case
}
export default function CaseItem( {caseItem}:Props) {
 const gtm = useGoogleTagManager();
 const appInsights = useAppInsights();
  const responsiveStyle = useDeviceWidth()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState("");
  const [open, setOpen] = useState(false);
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState<any>();

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  });

  const toggleListItem = () => {
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: true
      }).start();
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: true
      }).start();
    }
    setOpen(!open);
  };


useEffect(()=>{
    dispatch(fetchDocuments(caseItem))
    }
,[])
 
 
const dispatch = useDispatch()
const documentsState:DocumentsState= useSelector((state:ApplicationState)=>state.documents)
let documents:Document[] = [];

if(documentsState.data) 
{
      const cases=documentsState.data.filter(f=>f && f.caseId===caseItem.caseId);

      if(cases.length>0)
        documents= cases[0].documents;
      else
       documents=[]
}

  const [downloadTooltipVisibleLookup,setDownloadTooltipVisibleLookup] =useState(new Map())
  const [linkTooltipVisibleLookup,setLinkTooltipVisibleLookup] =useState(new Map())
  const [deleteTooltipVisibleLookup,setDeleteTooltipVisibleLookup] =useState(new Map())
 const [messageVisible,setMessageVisible] =useState(false)
  const [messageText,setMessageText] =useState("")

  const  setDeleteTooltipVisible=(documentId:string,visible:boolean)=>{
    const map = new Map(deleteTooltipVisibleLookup);
    map.set(documentId,visible);
    setDeleteTooltipVisibleLookup(map)
   
  }
  const  setDownloadTooltipVisible=(documentId:string,visible:boolean)=>{
    const map = new Map(downloadTooltipVisibleLookup);
    map.set(documentId,visible);
    setDownloadTooltipVisibleLookup(map)
   
  }
  const  setLinkTooltipVisible=(documentId:string,visible:boolean)=>{
    const map = new Map(linkTooltipVisibleLookup);
    map.set(documentId,visible);
    setLinkTooltipVisibleLookup(map)
   
  }

  const createDocumentURL=async (documentId:string,documentName:string,forLink:boolean)=>{
    if(forLink){
      setLinkTooltipVisible(documentId,true)
    }
    else{
      setDownloadTooltipVisible(documentId,true)
    }

    const result:DownloadResult = await downloadDocument(documentId,caseItem.caseId);
    let url ="";

   
   if(result.errorMessage)
  {
    appInsights.trackDocumentException(forLink? AIEvent.DocumentView:AIEvent.DocumentDownload,result.exception,result.errorMessage,caseItem,{id:documentId,fileName:documentName});
    
    setMessageText(result.errorMessage)
    setMessageVisible(true)
  }
  else{
    const mimeType = mime.lookup(documentName)
    const blob = new Blob([result.buffer], { type: mimeType })
    url=URL.createObjectURL(blob)
  }
 
  if(forLink){
    setLinkTooltipVisible(documentId,false)
  }
  else{
    setDownloadTooltipVisible(documentId,false)
  }

  return url;
}
  
  const handleOnDocumentLinkPress= async(documentId:string,documentName:string)=>
  {
    gtm.pushDocument(GTMEvent.DocumentView,caseItem.caseNumber,caseItem.patientId,caseItem.patientInd)
    appInsights.trackDocumentEvent(AIEvent.DocumentView,caseItem,{id:documentId,fileName:documentName})
    const url= await createDocumentURL(documentId,documentName,true);

   if (url)
   {
     window.open(url);
     URL.revokeObjectURL(url);
   }
   
  }
  const handleOnFileDownloadPress= async(documentId:string,documentName:string)=>
  {
    gtm.pushDocument(GTMEvent.DocumentDownload,caseItem.caseNumber,caseItem.patientId,caseItem.patientInd)
    appInsights.trackDocumentEvent(AIEvent.DocumentDownload,caseItem,{id:documentId,fileName:documentName})
    const url= await createDocumentURL(documentId,documentName,false);

    if(url)
    {
    const link=document.createElement('a');
    link.href=url;
    link.download=documentName;
    link.click();
    URL.revokeObjectURL(url);
    }
    
  }
  const handleOnDeleteIconPress=(documentId:string)=>
  {
    setDeleteDocumentId(documentId);
    setShowDeleteConfirmation(true)
  
  }
  const handleDeleteConfirmationCancel=()=>
  {
    setDeleteDocumentId("");
    setShowDeleteConfirmation(false)
  }
  
   const handleDeleteConfirmationOk=()=>
  {
    gtm.pushDocument(GTMEvent.DocumentDelete,caseItem.caseNumber,caseItem.patientId,caseItem.patientInd)
    appInsights.trackDocumentEvent(AIEvent.DocumentDelete,caseItem,{id:deleteDocumentId})
    dispatch(deleteDocument(deleteDocumentId, caseItem))
    setShowDeleteConfirmation(false)
   setDeleteTooltipVisible(deleteDocumentId,true)
  }

   const [uploadVisible,setUploadVisible] =useState(false)
   const handleUploadClose=()=>{
    setUploadVisible(false)
   }
  
   const handleUploadOpen=()=>{
    gtm.pushDocument(GTMEvent.UploadClick,caseItem.caseNumber,caseItem.patientId,caseItem.patientInd)
    appInsights.trackDocumentEvent(AIEvent.UploadClick,caseItem)
    setUploadVisible(true)
   }
  
   const deleteSelector:DocumentDeleteStatus=documentsState.deleteStatus
  useEffect(()=>{
      if(deleteSelector!==undefined){
         setDeleteTooltipVisible(deleteDocumentId,false)
        if(!deleteSelector.success && caseItem.caseId==deleteSelector.caseId){
          appInsights.trackDocumentException(AIEvent.DocumentDelete,deleteSelector.exception,deleteSelector.message,caseItem,{id:deleteDocumentId})
          setMessageText(deleteSelector.message)
          setMessageVisible(true);
        }
        dispatch(resetDeleteDocumentStatus())
      }
    }
   
  ,[deleteSelector])

  let documentFetchError:CaseError=undefined
  if(documentsState.fetchErrors) 
  {
    const errors=documentsState.fetchErrors.filter(f=>f.caseId===caseItem.caseId);

    if(errors.length>0){
      documentFetchError= errors[0];
    }
  }

  const fetchDocFailedText = 'Oh no! We’re having trouble displaying your documents.';

  const GoogleTagHandler = () => {
    const charLimit = (str) => str.substring(0, 100);

    gtm.pushEvent(GTMEvent.DocumentFetchFailure, {
      'event': 'fetchDoc - Documents/CaseItem',
      'errorType': 'Failed to fetch documents',
      'errorMessage' : charLimit(fetchDocFailedText)
    })
  }


  useEffect(() => {
    if(documentFetchError){
      GoogleTagHandler();
      appInsights.trackDocumentException(AIEvent.DocumentList,documentFetchError.exception,documentFetchError.error,caseItem,{})
    }
  },[documentFetchError])
  const oneMB=1048576
  const oneKB =1024
  
  const formatFileSize=(fileSize:number)=>{
    let formattedSize=""
    
    if(fileSize){
      if(fileSize>=oneMB){
        formattedSize= `(${Math.round(fileSize/oneMB)}MB)`;
      }
      else if(fileSize>=oneKB){
        formattedSize =`(${Math.round(fileSize/oneKB)}KB)`
      }
      else{
        formattedSize =`(${fileSize/oneKB}B)`
      }
    }
    return formattedSize
  }
 
  const handleRetryPress=()=>{
    dispatch(fetchDocuments(caseItem))
  }
  return (
    <View style={cardStyle} key={caseItem.caseId}>
      <TouchableOpacity 
        testID={`document-${caseItem.caseNumber}`}
        accessibilityLabel={caseItem.caseNumber}
        accessibilityState={{expanded: open}}
        onPress={() => toggleListItem()}>
        <View accessible={false} style={[styles.containerCommon, styles[responsiveStyle].container]}>
          {
            responsiveStyle === 'largeScreen' ?
              <View style={{width: '70%', flexDirection: 'row', alignItems: 'center'}}>
                <View style={{ marginRight: 24 }}>
                  <Text accessibilityRole="heading" ariaLevel="2" bold={true} style={{fontSize: 24}}>{`Case # ${caseItem.caseNumber}`}</Text>
                </View>
                <View>
                  <Text accessibilityRole="heading" ariaLevel="2" style={{fontSize: 16, textTransform: 'capitalize'}}>
                    {caseItem.billingProviderName?.toLowerCase()}
                  </Text>
                </View>
              </View>
            :
              <>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                  <Text bold={true} style={{fontSize: 24}}>{`Case # ${caseItem.caseNumber}`}</Text>
                  <Animated.View>
                    {open ? (
                      <MaterialIcons  name="remove" size={24} color="#0074C8" />
                    ) : (
                      <MaterialIcons  name="add" size={24} color="#0074C8" />
                    )}
                  </Animated.View>
                </View>
                <View style={styles[responsiveStyle].provider}>
                  <Text style={{fontSize: 16, textTransform: 'capitalize'}}>
                    {caseItem.billingProviderName?.toLowerCase()}
                  </Text>
                </View>
              </>
          }
          {
            responsiveStyle === 'largeScreen' &&
              <View style={styles[responsiveStyle].statusPill}>
                <Animated.View>
                {open ? (
                    <MaterialIcons  name="remove" size={24} color="#0074C8" />
                  ) : (
                    <MaterialIcons  name="add" size={24} color="#0074C8" />
                  )}
                </Animated.View>
              </View>
          }
        </View>
      </TouchableOpacity>
      <Animated.View style={{ overflow: "hidden", height: bodyHeight }}>
        { open && 
        <View
          style={{position: 'absolute', bottom: 0, width: '100%', paddingTop: 27}}
          onLayout={event =>
            setBodySectionHeight(event.nativeEvent.layout.height)
          }
        >
        { documentsState.fetchComplete  &&
          <View>
            { !documentFetchError &&
              <View style={{marginBottom: 40}}>
                  { caseItem.caseStatus !== 'Case Closed' &&  <Button testID={`upload-document-button-${caseItem.caseNumber}`} onPress={handleUploadOpen} label="Upload" /> }
                {
                documents.length===0 &&
                <Text style={{fontFamily: 'JekoRegular', color: '#002C73', fontStyle: 'italic', marginTop: 37}}>No Documents for this case</Text>
                }
              </View>
             }
              {documentFetchError &&
              <>
              <ErrorWrapper>
                <ErrorMessage>
                {fetchDocFailedText}
              </ErrorMessage>
            </ErrorWrapper>
            <View style={{marginTop:46}}>
              <Button label="RETRY" onPress={handleRetryPress} />
            </View>
            </>
              }
           
         { 
   
            documents.map((doc,i)=>{
              return (
                <DocumentRow 
                  key={doc.id}
                  
                >
                  <View style={{maxWidth:'80%'}}>
                  <Tooltip contentStyle={toolTipStyle} disableShadow={true} isVisible={linkTooltipVisibleLookup.get(doc.id)} content={<Text style={toolTipTextStyle}>Downloading...</Text>} placement="right" backgroundColor='rgba(0,0,0,0)'  showChildInTooltip={false} onClose={()=>setLinkTooltipVisible(doc.id,false)}>
                  <Link onPress={async()=>await handleOnDocumentLinkPress(doc.id,doc.fileName)} accessibilityLabel={`Download attachment ${doc.fileName} opens in a new window`}>{`${doc.fileName} ${formatFileSize(doc.fileSize)}`}</Link>
                  </Tooltip>
                  </View>
                  <View style={{flexDirection: 'row'}}>
                  <Tooltip contentStyle={[toolTipStyle,{width:90}]} disableShadow={true} isVisible={deleteTooltipVisibleLookup.get(doc.id)} content={<Text style={toolTipTextStyle}>Deleting...</Text>} placement="left" backgroundColor='rgba(0,0,0,0)'  showChildInTooltip={false} onClose={()=>setDeleteTooltipVisible(doc.id,false)}>
                  { doc.canDelete && <TouchableOpacity onPress={()=>handleOnDeleteIconPress(doc.id)} accessible={false}>
                      <Ionicons 
                        accessible={true}
                        accessibilityRole="button"
                        accessibilityLabel="Delete file"
                        name="md-trash" 
                        size={16} 
                        color="#0074C8" 
                        style={{marginRight: 8}} 
                      />
                    </TouchableOpacity>
                  }
                  </Tooltip>
                  <Tooltip contentStyle={toolTipStyle} disableShadow={true} isVisible={downloadTooltipVisibleLookup.get(doc.id)} content={<Text style={toolTipTextStyle}>Downloading...</Text>} placement="left" backgroundColor='rgba(0,0,0,0)'  showChildInTooltip={false} onClose={()=>setDownloadTooltipVisible(doc.id,false)}>
                    <TouchableOpacity 
                      accessibilityRole="button"
                      accessibilityLabel="Download File" 
                      onPress={async ()=>await handleOnFileDownloadPress(doc.id,doc.fileName)}>
                      <MaterialIcons 
                        accessible={false}
                        accessibilityRole="button"
                        accessibilityLabel="Download File"
                        name="file-download" 
                        size={16} 
                        color="#0074C8" 
                      />
                      </TouchableOpacity>
                  </Tooltip>
                  </View>
                </DocumentRow>
              )
            })
          }
          </View>
        }
        {
          !documentsState.fetchComplete &&
         <SkeletonDocumentList/>
        }
        </View>
        }
      </Animated.View>   
     
      <Upload  caseItem={caseItem} onClose={handleUploadClose} visible={uploadVisible}/>
      <Confirmation show={showDeleteConfirmation} message="Are you sure you want to delete this file?" onCancel={handleDeleteConfirmationCancel} onOK={handleDeleteConfirmationOk}></Confirmation> 
     <Message message={messageText} show={messageVisible} onClose={()=> setMessageVisible(false)} onOK={()=> setMessageVisible(false)} variant="error"></Message>
    </View>
  )
}
const ErrorWrapper = styled(View)`
margin: 24px 0 0;
padding: 16px 16px 16px 16px;
background-color: rgba(239, 76, 76, 0.1);
`
const ErrorMessage=styled(Text)`
    font-family: SourceSansPro-Bold;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #e00000;
    
  `
const DocumentRow=styled(View)`
flex-direction: row; 
justify-content: space-between;
padding-top: 16px;
padding-bottom: 16px;
border-top-color: rgba(42, 46, 114, 0.3);
border-top-width: ${(props) => props.key===0?'1px':'0px'}; 
border-bottom-color: rgba(42, 46, 114, 0.3);
border-bottom-width: 1px;
`
 const SkeletonDocumentList = () => {
  
  return (
   <>
   {
    [0,1,2].map((i)=>
     <DocumentRow  key={i} >
        <View  style= {{height: 20, marginLeft: 20, width: '50%'}}>
           <Skeleton />
        </View>
        <View  style= {{height: 20, width: '10%'}}>
           <Skeleton  style={{borderRadius: 14}}/>
        </View>
      </DocumentRow>
    )
    }
   </>
  )
}


