import React from 'react';
import { 
  StyleSheet,
  Dimensions,
  Text, 
  View, 
  ImageBackground
} from 'react-native';

var height = Dimensions.get('window').height;
export default function Home() {
  return (
    <View style={styles.container}>
      <View style={styles.bubbles}>
        <ImageBackground 
          source={require('../../assets/images/Bubbles.png')} 
          style={styles.buttonSignIn} 
          resizeMode="center"
        >
          <View style={styles.heroWrapper}>
            <Text style={styles.heroText}>Your fierce defender from out-of-network bills.</Text>
          </View>
        </ImageBackground>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#002C73",
    height: height
  },
  table: {
    backgroundColor: "#FFF",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  bubbles: {
    flex: 1
  },
  buttonSignIn: {
    height: '100%',
    width: '100%'
  },
  heroWrapper: {
    flex: 1,
    alignItems: 'center',
    marginTop: '20%'
  },
  heroText: {
    color: "#FFF",
    
    fontSize: 70,
    fontWeight: "700",
    lineHeight: 68
  }
})