import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View } from 'react-native'
import Layout from '../../components/layout'
import { Text, Link, Button as CustomButton, Button } from '../../components'
import useGoogleTagManager, {
    GTMEvent,
    usePageLoadEffect,
} from '../../utils/useGoogleTagManager'
import { ApplicationState } from '../../redux/rootReducer'
import { fetchCases } from '../../redux/cases/actions'
import { Picker } from '@react-native-picker/picker'
import { fetchContacts, updateContacts } from '../../redux/accountSettings/actions'
import { Contacts } from '../../redux/accountSettings/types'
import { useForm, Controller } from 'react-hook-form'
import { passwordChange } from '../../utils/useAuthentication'

import Message from '../../components/modal/Message';
import PhoneFormat from '../../components/form/PhoneFormat'
import UnsavedChangesWarning from '../../components/modal/UnsavedChangesWarning'
import { ContactsState } from '../../redux/accountSettings/reducer'
import TextFormat from '../../components/form/TextFormat'
import PickerFormat from '../../components/form/PickerFormat'
import { ErrorText, ErrorView, ErrorWrapper, ErrorMessage } from '../../components/form/ErrorFormat'
import useScreenFormat from '../../components/form/useScreenFormat'
import UnsavedChangesWarningManual from '../../components/modal/UnsavedChangesWarningManual'
  
export default function AccountSettings() {
    const gtm = useGoogleTagManager()
    const dispatch = useDispatch()
    const cases = useSelector((state: ApplicationState) => state.cases.data)
    const canEdit = false
    const [phoneEditMode, setPhoneEditMode] = useState(false)
    const [emailEditMode, setEmailEditMode] = useState(false)
    const [defaultContacts, setDefaultContacts] = useState({} as Contacts)
    const [showCompletedMessage, setShowCompletedMessage] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const { control, handleSubmit, errors, reset, setValue, getValues, clearErrors, formState } = useForm({
        defaultValues: defaultContacts,
    })
    const[refreshDataFailed,setRefreshDataFailed]=useState(false)
    const[contactsNotFound,setContactsNotFound]=useState(false)
    const styles= useScreenFormat();
    const [showUnsavedChangesWarningManual,setShowUnsavedChangesWarningManual]=useState(false)

    useEffect(() => {
        if (cases.length === 0) {
            dispatch(fetchCases())
        }
    }, [])

    usePageLoadEffect()

    const contactsState: ContactsState = useSelector((state: ApplicationState) => state.contacts)

    useEffect(() => refreshData(), [])

    const refreshData = () => {
        loadContacts();
        setPhoneEditMode(false);
        setEmailEditMode(false);
    }
    const loadContacts = () => {
        dispatch(fetchContacts()).then((c) => {
            const contacts = {
                ...c,
                mobilePhone: c.mobilePhone === null ? '' : keepNumbersOnly(c.mobilePhone),
                workPhone: c.workPhone === null ? '' : keepNumbersOnly(c.workPhone),
                otherPhone: c.otherPhone === null ? '' : keepNumbersOnly(c.otherPhone),
                preferredPhone: c.preferredPhone === '--None--' ? '' : c.preferredPhone,
                preferredChannel: c.preferredChannel === '--None--' ? '' : c.preferredChannel,

            }
            setDefaultContacts(contacts);
            reset(contacts)
        })
            .catch((error) => {
                if (error.response && error.response.status === 404){
                    setContactsNotFound(true)
                } else {
                    setRefreshDataFailed(true)
                }
            })
    }

    //format validation

    const preferredPhoneValidation = (value: string) => {
        const contacts = getValues();

        switch (value) {
            case "Mobile":
                if (!contacts.mobilePhone) return false
                break;
            case "Work Phone":
                if (!contacts.workPhone) return false
                break;
            case "Other Phone":
                if (!contacts.otherPhone) return false
                break;

        }

        return true;
    }

    const clearPreferredPhoneValidation = (preferredPhone: string, value: string) => {
        if (errors.preferredPhone && errors.preferredPhone.type === 'validate' && value) {
            const contacts = getValues();
            if (contacts.preferredPhone === preferredPhone)
                clearErrors('preferredPhone');
        }

    }
    const keepNumbersOnly = (phone) => {
        let result = "";

        if (phone) {
            result= phone.replace(/\D/g, "");
        }

        return result
    }
    const addDashesToPhone = (phone) => {
        let result = "";

        if (phone) {
            const numbers = keepNumbersOnly(phone);
            result = numbers.slice(0, 3) + "-" + numbers.slice(3, 6) + "-" + numbers.slice(6);
        }

        return result;
    }
    const hasErrors = Object.keys(errors).length > 0
    const [isUpdating, setIsUpdating] = useState(false)

    const onSubmit = (contacts: Contacts) => {

        setIsUpdating(true)
        const newContacts = {
            ...contacts,
            id: defaultContacts.id,
            //service portal phone format
            mobilePhone: addDashesToPhone(contacts.mobilePhone),
            workPhone: addDashesToPhone(contacts.workPhone),
            otherPhone: addDashesToPhone(contacts.otherPhone),
        };
        updateContacts(newContacts)
            .then(() => {
                gtm.pushEvent(GTMEvent.SaveSettings)
                loadContacts();
                setPhoneEditMode(false);
                setEmailEditMode(false);
                setShowCompletedMessage(true);
            })
            .catch(error => {
                let message = error.message;

                if (error.response && error.response.data && error.response.data.error)
                    message = error.response.data.error;

                setErrorMessage(message);
                setShowErrorMessage(true)
            })
            .finally(() => setIsUpdating(false))
    }

    const handleChangePassword = () => {
        if(hasData()){
            setShowUnsavedChangesWarningManual(true)
        }
        else{
            passwordChange()
        }
    }

    useEffect(() => {
        if (phoneEditMode) {
            const phoneMobile = document.getElementById("phone-mobile-input-wrapper").querySelector("input")
            phoneMobile?.focus()
        }
    }, [phoneEditMode])

    useEffect(() => {
        if (emailEditMode) {
            const email = document.getElementById("email-input-wrapper").querySelector("input")
            email?.focus()
        }
    }, [emailEditMode])

    const handleRetryPress = () => {
        setRefreshDataFailed(false);
        refreshData();
    }
    const hasData=()=>{
        return !refreshDataFailed && !contactsNotFound 
    }
   
    const charLimit = (str) => str.substring(0, 100);
    const noContactFoundErrorText = 'There are no contact preferences currently available for your review.';
    const refreshDataFailedText = 'Oh no! We’re having trouble displaying your contact information.'

    const GoogleTagManagerErrorHandler = () => {
        if(contactsNotFound) {
            gtm.pushEvent(GTMEvent.ContactsNotFound, {
                'event': 'fetchContacts - /accountSettings',
                'errorType': 'Contacts not found',
                'errorMessage' : charLimit(noContactFoundErrorText)
            })
        }

        if(refreshDataFailed) {
            gtm.pushEvent(GTMEvent.RefreshDataFailed, {
                'event': 'fetchContacts - /accountSettings',
                'errorType': 'Data refresh failed',
                'errorMessage' : charLimit(refreshDataFailedText)
            })
        }
    }

    useEffect(() => {
        GoogleTagManagerErrorHandler();
    }, [contactsNotFound, refreshDataFailed])

    return (
        <Layout
            introHeading="Account Settings"
            mainSectionStyle={{flexBasis: '50%'}}
            noAdvisorCard={true} >
            <View style={styles.caseCard}>
                <View>
                    <Text accessibilityRole="heading" ariaLevel="2" size="l" style={{ fontFamily: 'JekoBold' }}>Contact Preferences</Text>
                    <Text size={16} style={{ marginTop: 15 }}>
                        Your contact information will be used to provide important communication updates regarding your case.
                    </Text>
                </View>
                {contactsNotFound &&
                    <View style={{ marginTop: 72 }}>
                        <Text size="m" >{noContactFoundErrorText}</Text>
                    </View>
                }
                {refreshDataFailed &&
                    <>
                        <ErrorWrapper>
                            <ErrorMessage>
                                {refreshDataFailedText}
                            </ErrorMessage>
                        </ErrorWrapper>
                        <View style={{ marginTop: 46 }}>
                            <Button label="RETRY" onPress={handleRetryPress} />
                        </View>
                    </>
                }
                {
                    !refreshDataFailed && !contactsNotFound &&
                    <>
                        <View accessibilityRole="group" aria-labelledby="phone" style={{ marginTop: 22 }}>
                            <Text accessibilityRole="heading" ariaLevel="3" size="s">Phone</Text>
                            <ErrorView hasErrors={false}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                    <Text  size={16} bold={true} style={{ width: 60 }} for="phone-mobile">
                                        Mobile
                                    </Text>

                                    <Controller control={control} name="mobilePhone" defaultValue="" 
                                        rules={{ minLength: 10 }}
                                        render={({ onChange, onBlur, value }) => (
                                            <span id="phone-mobile-input-wrapper">
                                                <PhoneFormat 
                                                    id="phone-mobile"
                                                    aria-invalid={errors.mobilePhone ? 'true' : 'false' }
                                                    aria-describedBy={errors.mobilePhone ? "phoneError" : ''}
                                                    editMode={phoneEditMode} 
                                                    dataLoaded={!contactsState.fetchPending}
                                                    errorState={errors.mobilePhone ? true : false}
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onValueChange={(values) => { onChange(values.value); clearPreferredPhoneValidation("Mobile", values.value) }}
                                                />
                                            </span>
                                        )}
                                    />
                                </View>
                                    {
                                        errors.mobilePhone &&
                                        errors.mobilePhone.type === 'minLength' &&
                                        <ErrorText paddingLeft="60px"><span id="phoneError" role="alert">Mobile phone requires 10 numbers</span></ErrorText>
                                    }
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        marginTop: 5,
                                        alignItems: 'center',
                                    }}>
                                    <Text size={16} bold={true} style={{ width: 60 }} for="phone-work">
                                        Work
                                    </Text>
                                    <Controller 
                                        control={control} 
                                        name="workPhone" 
                                        defaultValue=""
                                        rules={{ minLength: 10 }}
                                        render={({ onChange, onBlur, value }) => (
                                            <span id="phone-work-input-wrapper">
                                                <PhoneFormat
                                                    id="phone-work"
                                                    aria-invalid={errors.workPhone ? 'true' : 'false' }
                                                    aria-describedBy={errors.workPhone ? "workError" : ''}
                                                    editMode={phoneEditMode} 
                                                    dataLoaded={!contactsState.fetchPending}
                                                    errorState={errors.workPhone ? true : false}
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onValueChange={(values) => { onChange(values.value); clearPreferredPhoneValidation("Work Phone", values.value) }}
                                                />
                                            </span>
                                        )}
                                    />
                                </View>
                                {
                                    errors.workPhone &&
                                    errors.workPhone.type === 'minLength' &&
                                    <ErrorText paddingLeft="60px"><span id="workError" role="alert">Work phone requires 10 numbers</span></ErrorText>
                                }
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        marginTop: 5,
                                        alignItems: 'center',
                                    }}>
                                    <Text size={16} bold={true} style={{ width: 60 }} for="phone-other">
                                        Other
                                    </Text>

                                    <Controller control={control} name="otherPhone" defaultValue=""
                                        rules={{ minLength: 10 }}
                                        render={({ onChange, onBlur, value }) => (
                                            <span id="phone-other-input-wrapper">
                                                <PhoneFormat 
                                                    id="phone-other"
                                                    aria-invalid={errors.otherPhone ? 'true' : 'false' }
                                                    editMode={phoneEditMode} 
                                                    dataLoaded={!contactsState.fetchPending}
                                                    errorState={errors.otherPhone ? true : false}
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onValueChange={(values) => { onChange(values.value); clearPreferredPhoneValidation("Other Phone", values.value) }}
                                                />
                                            </span>
                                        )}
                                    />
                                </View>
                                {
                                    errors.otherPhone &&
                                    errors.otherPhone.type === 'minLength' &&
                                    <ErrorText paddingLeft="60px"><span aria-describedby="phone-other" role="alert">Other phone requires 10 numbers</span></ErrorText>
                                }
                            </ErrorView>
                            <View style={{ marginTop: 12 }}>
                                {phoneEditMode && (
                                    <Link
                                        onPress={() => {
                                            setPhoneEditMode(false)
                                            setValue("mobilePhone", defaultContacts.mobilePhone, { shouldValidate: true, shouldDirty: true })
                                            setValue("workPhone", defaultContacts.workPhone, { shouldValidate: true, shouldDirty: true })
                                            setValue("otherPhone", defaultContacts.otherPhone, { shouldValidate: true, shouldDirty: true })
                                        }}>
                                        Cancel
                                    </Link>
                                )}
                                {canEdit && !phoneEditMode && (
                                    <Link onPress={() => setPhoneEditMode(true)}>
                                        Edit
                                    </Link>
                                )}
                            </View>

                            <View style={{ marginTop: 22 }}>
                                <Text size={16} bold={true} style={{ marginBottom: 4 }} for="preferred-phone">Preferred Phone Number</Text>

                                <Controller
                                    id="preferred-phone"
                                    control={control}
                                    render={({ onChange, value }) => {
                                        if (canEdit) {
                                            return <PickerFormat
                                                required={true}
                                                aria-invalid={errors.preferredPhone ? 'true' : 'false' }
                                                onValueChange={(itemValue) => {
                                                    onChange(itemValue)
                                                }}
                                                dataLoaded={!contactsState.fetchPending}
                                                selectedValue={value}
                                                errorState={
                                                    errors.preferredPhone ? true : false
                                                }>
                                                <Picker.Item label="Select one" value="" />
                                                <Picker.Item label="Mobile" value="Mobile" />
                                                <Picker.Item label="Work" value="Work Phone" />
                                                <Picker.Item label="Other" value="Other Phone" />
                                            </PickerFormat>
                                        } else {
                                            return value || <Text size={16}>None</Text>

                                        }
                                    }}
                                    name="preferredPhone"
                                    defaultValue=""
                                    rules={{ required: true, validate: preferredPhoneValidation }}
                                />
                                {errors.preferredPhone &&
                                    <ErrorText>
                                        <span aria-describedby="preferred-phone" role="alert">
                                        {
                                            errors.preferredPhone.type === 'validate' &&
                                            <>
                                                The selected preferred phone requires a value
                                </>
                                        }
                                        {
                                            errors.preferredPhone.type === 'required' &&
                                            <>
                                                Preferred phone is required
                                </>
                                        }
                                        </span>
                                    </ErrorText>
                                }
                            </View>
                        </View>
                        <View style={{ marginTop: 22 }}>
                            <Text accessibilityRole="heading" ariaLevel="3" size="s" for="email">Email</Text>
                            <View style={{ marginTop: 12 }}>
                                <Controller control={control} name="email" defaultValue="" id="email" rules={{
                                    required: true,
                                    pattern: RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
                                }}

                                    render={({ onChange, onBlur, value }) => (
                                        <span id="email-input-wrapper">
                                            <TextFormat 
                                                aria-invalid={errors.email ? 'true' : 'false' }
                                                editMode={emailEditMode} 
                                                onBlur={onBlur}
                                                onChangeText={(text) => onChange(text)}
                                                value={value}
                                                errorState={errors.email ? true : false}
                                                dataLoaded={!contactsState.fetchPending}
                                                aria-required="true"
                                            />
                                        </span>
                                    )}
                                />
                                {errors.email &&
                                    <ErrorText>
                                        <span aria-describedby="email-input-wrapper" role="alert">
                                            {errors.email.type === 'required' &&
                                                <>
                                                    Email is required
                                                </>
                                            }
                                            {errors.email.type === 'pattern' &&
                                                <>
                                                    Email format is invalid
                                                </>
                                            }
                                        </span>
                                    </ErrorText>
                                }
                                <View style={{ marginTop: 12 }}>
                                    {emailEditMode && (
                                        <Link
                                            onPress={() => {
                                                setEmailEditMode(false)
                                                setValue("email", defaultContacts.email, { shouldValidate: true, shouldDirty: true })

                                            }}>
                                            Cancel
                                        </Link>
                                    )}
                                    {canEdit && !emailEditMode && (
                                        <Link onPress={() => setEmailEditMode(true)}>
                                            Edit
                                        </Link>
                                    )}
                                </View>
                            </View>

                            <View style={{ marginTop: 22 }}>
                                <Text size={16} bold={true} style={{ marginBottom: 4 }} for="preferred-contact-method">Preferred Contact Method</Text>

                                <Controller
                                    id="preferred-contact-method"
                                    control={control}
                                    render={({ onChange, value }) => {
                                        if (canEdit) {
                                            return <PickerFormat
                                                required={true}
                                                aria-invalid={errors.preferredChannel ? 'true' : 'false' }
                                                onValueChange={(itemValue) => {
                                                    onChange(itemValue)
                                                }}
                                                dataLoaded={!contactsState.fetchPending}
                                                selectedValue={value}
                                                errorState={
                                                    errors.preferredChannel ? true : false
                                                }>
                                                <Picker.Item label="Select one" value="" />
                                                <Picker.Item label="Phone" value="Phone" />
                                                <Picker.Item label="Email" value="Email" />
                                            </PickerFormat>
                                        } else {
                                            return value || <Text size={16}>None</Text>
                                        }
                        
                                    }}
                                    name="preferredChannel"
                                    defaultValue=""
                                    rules={{ required: true }}
                                />
                                {errors.preferredChannel && (
                                    <ErrorText>
                                        <span aria-describedby="preferred-contact-method" role="alert">
                                            Preferred contact method is required
                                        </span>
                                    </ErrorText>
                                )}
                            </View>
                        </View>
                        <View style={{ marginTop: 22 }}>
                            {canEdit && <CustomButton
                                label="Save"
                                variant={hasErrors || isUpdating || contactsState.fetchPending ? "primary-disabled" : "default"}
                                onPress={handleSubmit(onSubmit)}
                            />}
                            {hasErrors && (
                                <ErrorText>
                                    <span id="accountSettingsForm" role="alert">
                                        Please correct the fields highlighted above and try again.
                                    </span>
                                </ErrorText>
                            )}
                        </View>
                    </>
                }
            </View>
            <View style={styles.bottomSection}>
                <View>
                    <Text accessibilityRole="heading" ariaLevel="2"  size="l" style={{ fontFamily: 'JekoBold' }}>Security & Login</Text>
                    <Text size={16} style={{ marginTop: 15 }}>Manage your account access.</Text>
                    <View style={{ marginTop: 15, marginBottom: 15 }}>
                        <Text accessibilityRole="heading" ariaLevel="3" size="s">Password</Text>
                    </View>
                    <Link onPress={handleChangePassword}>Change your password</Link>
                </View>
            </View>

            {hasData() && <UnsavedChangesWarning formState={formState} onIgnoreChanges={()=>{reset();refreshData()}} />}
            {hasData() && <UnsavedChangesWarningManual formState={formState} onCancel={()=>setShowUnsavedChangesWarningManual(false)} onIgnoreChanges={passwordChange} showIfAnyChanges={showUnsavedChangesWarningManual} />}
            <Message show={showCompletedMessage} variant="success" onClose={() => setShowCompletedMessage(false)} onOK={() => setShowCompletedMessage(false)} message="You have successfully updated your contact preferences."></Message>
            <Message show={showErrorMessage} variant="error" onClose={() => setShowErrorMessage(false)} onOK={() => setShowErrorMessage(false)} message={errorMessage}></Message>
           
        </Layout>
    )
}
