import React from "react";
import { View ,TouchableOpacity} from "react-native";
import Text from '../TextComponent'
import Link from "../link";
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { useIsMobile } from '../../utils/useDeviceWidth'
import { Hoverable } from "react-native-web-hover";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../redux/rootReducer";
import { DisplayBanner } from '../../redux/providerBanner/actions';
import useGoogleTagManager from "../../utils/useGoogleTagManager";


export default  function ProviderBanner(){
    const isMobile = useIsMobile()
    const bannerState = useSelector((state: ApplicationState) => state.providerBanner)
    const dispatch = useDispatch()
    const gtm = useGoogleTagManager()
    const cases =useSelector((state:ApplicationState)=>state.cases.data)
    const handleProviderLinkPress=()=>{
        const providerUrl='https://www.uhc.com/find-a-doctor?cid=NaviguardMPR'
        gtm.pushFindProvider(cases,'INN Lookup banner click',providerUrl)
        window.open(providerUrl, '_blank')
    }
    const handleClose=()=>{
        dispatch(DisplayBanner(false))
    }
    //only display once session
    if(!bannerState.visible || bannerState.displayedCount>1){
      return null
    }
    return(
// <View  style={{padding:0,minHeight:65,backgroundColor:'#fcefe7'}}>
            <View style={{ paddingTop:20, padding: 15, justifyContent: "center",minHeight:65, backgroundColor:'#E9F2F9',zIndex:1}}>
                <View style={{marginLeft:isMobile?0:60,marginRight:isMobile?0:40,flexDirection:'row',justifyContent:'space-between'}}>
                        <View style={{flexDirection:'row',maxWidth:'80%'}}>
                            <MaterialIcons name="info"  style={{color:'#002C73',fontSize:24, marginTop:-2}} />&nbsp;
                            <Text style={{fontSize: 16,flexWrap:'wrap'}} bold={true}>
                                Finding an in-network provider may lower costs in the future.&nbsp;
                                <Link onPress={handleProviderLinkPress}
                                rightIcon={ <MaterialIcons name="launch"  style={{fontSize:16,verticalAlign:'bottom'}} />} >
                                    Find a provider on uhc.com 
                                </Link>
                            </Text>
                        </View> 
                        <Hoverable> 
                         {({ hovered }) => ( 
                            <TouchableOpacity onPress={handleClose}> 
                                <MaterialIcons name="close" 
                                accessible={true}
                                accessibilityRole="button"
                                accessibilityLabel="Close provider link banner" 
                                style={{color: hovered?'#9F2140': '#0074C8',fontSize:24, marginTop:-2}} />
                            </TouchableOpacity> 
                            )}
                        </Hoverable> 
                 </View>
                 
            </View>
            
       // </View>
    )
}