export const UPDATE_OPTIONS = 'UPDATE_OPTIONS'

export interface Options {
    caseId: string;
    plannedVisits: 'Yes'|'No'|"I don't know"|string;
    futureVisits: 'Yes'|'No'|"I don't know"|string;
    pastVisits:'Yes'|'No'|"I don't know"|string;
    billIncreased:'Yes'|'No'|"I don't know"|string;
}

export interface UpdateOptions {
    type: typeof UPDATE_OPTIONS;
    payload:  Options;
}


