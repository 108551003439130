import React, { useState, useEffect, useRef } from 'react';
import { 
  View, 
  TouchableOpacity,
  Platform,
  TextInput,
  StyleSheet
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux'
import { Hoverable } from 'react-native-web-hover'
import { MaterialIcons, SimpleLineIcons, Feather } from '@expo/vector-icons'
import Layout from '../../components/layout'
import CardTitle from '../../components/card/cardtitle'
import CardComponent from '../../components/card'
import Button from '../../components/button'
import Link from '../../components/link'
import { Select, CustomSelect } from '../../components'
import styled from 'styled-components/native'
import axiosInstance from '../../redux/axiosInstance'
import { Case } from '../../redux/cases/types'
import { ApplicationState } from '../../redux/rootReducer'
import { fetchCases } from '../../redux/cases/actions'
import { format } from "date-fns"
import { useIsMobile } from '../../utils/useDeviceWidth'
import useGoogleTagManager, { GMTNavigationLinkPlacement, GTMEvent } from '../../utils/useGoogleTagManager';
import { fetchMessageCount } from '../../redux/messages/actions'
import RenderHtml from 'react-native-render-html'
import Upload from './UploadOverlay'
import Tooltip from 'react-native-walkthrough-tooltip'
import { AxiosResponse } from 'axios'

const Wrapper = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BoldText = styled.Text`
  font-size: 14px;
  font-weight: 600;
  fontFamily: 'SourceSansPro-Regular';
  color: rgb(0, 44, 115);
`
const RegularText = styled.Text`
  fontFamily: 'SourceSansPro-Regular';
  color: rgb(0, 44, 115);
`

const emailTypeList = [
  {
    emailTypeLabel: 'Inbox',
    emailTypeKey: ['inbox']
  },
  {
    emailTypeLabel: 'Sent',
    emailTypeKey: ['sent']
  },
  {
    emailTypeLabel: 'Archive',
    emailTypeKey: ['archive']
  }
]

export default function MessageCenter() {
  const hideVersion1Features = true
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const gtm = useGoogleTagManager()

  const [linkTooltipVisibleLookup, setLinkTooltipVisibleLookup] = useState(new Map())
  const [emailAttachments,setEmailAttachments] = useState([])
  const [uploadVisible,setUploadVisible] = useState(false)
  const [emailBodyWidth, setEmailBodyWidth] = useState(null)
  const [mobileViewList, setMobileViewList] = useState(true)
  const [selectedEmail, setSelectedEmail] = useState(null)
  const [showCompose, setShowCompose] = useState(false)
  const [showEmailSentSuccess, setShowEmailSentSuccess] = useState(false)
  const [allEmails, setAllEmails] = useState([])
  const [allReplies, setAllReplies] = useState([])
  const [emailBody, setEmailBody] = useState(null)
  const [selectedCaseSetId, setSelectedCaseSetId] = useState(null)
  const [showDropDown, setShowDropDown] = useState(false)
  const [emailSending, setEmailSending] = useState(false)
  const [statusLabel, setStatusLabel] = useState(emailTypeList[0].emailTypeLabel)
  const cases: Case[] = useSelector((state: ApplicationState) => state.cases.data.filter(c => c.caseSetId)).sort((a, b) => {
    return +new Date(b.progress.caseOpenedDate) - +new Date(a.progress.caseOpenedDate)
  })
  const dropdownRef = useRef<TouchableOpacity>()
  const privacyPolicyURL = 'https://www.naviguard.com/privacy-policy/'
  const nonDiscriminationURL = 'https://www.uhc.com/legal/nondiscrimination-and-language-assistance-notices'
  const [showEmailSentFailure, setShowEmailSentfailure] = useState(false)

  const setLinkTooltipVisible=(documentId: string, visible: boolean) => {
    const map = new Map(linkTooltipVisibleLookup)
    map.set(documentId, visible)
    setLinkTooltipVisibleLookup(map)
  }

  const handleUploadClose=()=>{
   setUploadVisible(false)
  }
 
  const handleUploadOpen=()=>{
    selectedEmail && gtm.pushDocument(GTMEvent.UploadClick, selectedEmail.messageId, selectedEmail.threadId, selectedEmail.caseSetId)
   setUploadVisible(true)
  }

  useEffect(() => {
    if (cases.length === 0) {
      dispatch(fetchCases())
    }
  }, [])

  useEffect(() => {
    if (!showCompose) {
      setSelectedCaseSetId(null)
    }
  }, [showCompose])

  useEffect(() => {
    fetchMessages()
  }, [])
  
  const advisorName = () => {
    const caseSetId = selectedCaseSetId || selectedEmail?.caseSetId
    const selectedCase = cases.find(c => c.caseSetId === caseSetId)
    if (selectedCase) {
      const name = selectedCase.advisor ? selectedCase.advisor?.firstName : null
      return name
    } else {
      return null
    }
  }

  const markMessageRead = async (email) => {
    const { messageId } = email
    const axios = await axiosInstance()
    await axios.put(`/messages?messageId=${messageId}`)
    dispatch(fetchMessageCount())
  }

  const fetchMessages = async (showFirstEmail = true) => {
    setShowCompose(false)
    setAllEmails([])
    const axios = await axiosInstance()
    const { data } = await axios.get(`/messages`, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    })
    setAllEmails(data.messages)
    if (showFirstEmail) {
      handleClickViewEmail(data.messages[0], false)
    }
  }

  const fetchReplies = async (email) => {
    const { threadId, messageId } = email
    const axios = await axiosInstance()
    const { data } = await axios.get(`/messages/thread/${threadId}`, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    })
    const replies = data.messages.filter(e => e.messageId !== messageId)
    setAllReplies(replies)
  }

  const handleClickComposeEmail = () => {
    setShowEmailSentSuccess(false)
    setShowEmailSentfailure(false)
    setSelectedEmail(null)
    setShowCompose(true)
    setMobileViewList(false)
    if (cases.length) {
      const mostRecentCases = cases.sort((a, b) => {
        return +new Date(b.progress.caseOpenedDate) - +new Date(a.progress.caseOpenedDate)
      })
      setSelectedCaseSetId(mostRecentCases[0].caseSetId)
    }
  }

  const handleClickViewEmail = async (email, showEmail = true) => {
    setShowEmailSentSuccess(false)
    setShowEmailSentfailure(false)
    const { isRead } = email
    if (!isRead) {
      await markMessageRead(email)
      email.isRead = true
    }
    setSelectedEmail(null)
    setAllReplies([])
    await fetchReplies(email)
    setShowCompose(false)
    setSelectedEmail(email)
    if (showEmail) {
      setMobileViewList(false)
    }
  }

  const handleClickCreateEmail = (email) => {
    setShowEmailSentSuccess(false)
    setShowEmailSentfailure(false)
    setSelectedEmail(email)
    setShowCompose(true)
  }

  const handleSetEmailBody = (text) => {
    setEmailBody(text)
  }

  const handleRemoveAttachment = (attachment) => {
    const updatedEmailAttachments = emailAttachments.filter(a => a !== attachment)
    setEmailAttachments(updatedEmailAttachments)
  }

  const handleDownloadAttachment = async (attachment) => {
    setLinkTooltipVisible(attachment.attachmentId, true)
    const axios = await axiosInstance()
    const response = await axios.get(`/messages/attachment?attachmentId=${attachment.attachmentId}&mimeType=${encodeURIComponent(attachment.mimeType)}`, {responseType: 'arraybuffer'})
    const { data } = response
  
    if (data) {
      const blobUrl = URL.createObjectURL(new Blob([data], { type: attachment.mimeType }))
      const link = document.createElement('a')
      link.href = blobUrl
      link.download = attachment.name
      link.click()
      URL.revokeObjectURL(blobUrl)
    }
    setLinkTooltipVisible(attachment.attachmentId, false)
  }

  const handleDiscardEmail = () => {
    const discard = confirm('Discard Draft Message?')
    if (discard) {
      setSelectedCaseSetId(null)
      setEmailBody(null)
      setShowCompose(false)
      setMobileViewList(true)
      setEmailAttachments([])
    }
  }

  const handleClickSendEmail = async () => {
    setEmailSending(true)
    const emailForm = new FormData()
    emailForm.append('subject', 'Question for my advisor')
    emailForm.append('body', emailBody)
    emailForm.append('caseSetId', selectedCaseSetId)
    for (let index = 0; index < emailAttachments.length; index++) {
      const attachment = emailAttachments[index]
      emailForm.append('attachments', attachment)
    }

    const axios = await axiosInstance()
    const response:AxiosResponse<any> = await axios.post('/messages', emailForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    })
    if(response.status != 200){
      setShowEmailSentSuccess(false)
      setShowEmailSentfailure(true)
    }
    else
    {
      setShowEmailSentSuccess(true)
      setShowEmailSentfailure(false)
    }
    setEmailSending(false)
    setShowCompose(false)
    setEmailBody(null)
    fetchMessages(true) //comment out if you want to see the email selected
    //setSelectedEmail(response.data) //uncomment if you want to see the email selected
    setSelectedEmail(null) //comment out if you want to see the email selected
    setEmailAttachments([])
  }

  const handleClickSendEmailReply = async (email) => {
    setEmailSending(true)
    const {
      threadId,
      sender,
      recipients,
      subject,
      caseSetId
    } = email

    const {
      mailboxId
    } = recipients.recipient[0]

    const emailForm = new FormData()
    emailForm.append('threadId', threadId)
    emailForm.append('subject', subject)
    emailForm.append('body', emailBody)
    emailForm.append('senderMailboxId', mailboxId)
    emailForm.append('recipientMailboxId', sender.mailboxId)
    emailForm.append('caseSetId', caseSetId)
    for (let index = 0; index < emailAttachments.length; index++) {
      const attachment = emailAttachments[index]
      emailForm.append('attachments', attachment)
    }

    const axios = await axiosInstance()
    const response:AxiosResponse<any> = await axios.post('/messages/reply', emailForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    })
    if(response.status != 200){
      setShowEmailSentSuccess(false)
      setShowEmailSentfailure(true)
    }
    else
    {
      setShowEmailSentSuccess(true)
      setShowEmailSentfailure(false)
    }
    setEmailSending(false)
    await fetchReplies(email)
    setShowCompose(false)
    setEmailBody(null)
    setSelectedEmail(email)
    setEmailAttachments([])
  }

  const handlePrivacyPolicyClick = (url, text) => {
    gtm.pushNavigation(GMTNavigationLinkPlacement.Footer, text)
    if(Platform.OS === 'web') {
      window.open(url, '_blank')
    } 
  }

  const emailSelector = 
  <View style={{width: 122 }}>
    <TouchableOpacity 
      onPress={() => setShowDropDown(!showDropDown)}
      ref={dropdownRef}
      style={[styles.dropDown, {
        paddingTop: 8,
        paddingRight: 22,
        paddingBottom: 8,
        paddingLeft: 22,
        backgroundColor: '#E9F2F9',
        borderRadius: 20
      }]}
      accessible={true}
      accessibilityRole="button"
      accessibilityLabel="Email folder list"
      >
      <Select
        label={'Menu'}
        mode={'outlined'}
        staticLabel={
          <RegularText  style={{color:'#002C73',fontSize:16,fontFamily:'JekoRegular',marginRight:10,fontWeight:"700"}}>
            {statusLabel}
          </RegularText>
        }
        value={status}
        setValue={(value: string[]) => setStatusLabel(emailTypeList.find(e => e.emailTypeKey === value).emailTypeLabel)}
        list={emailTypeList}
        labelKey="emailTypeLabel"
        valueKey="emailTypeKey"
        selectedItemBackground="#ffffff"
        rightIcon={<MaterialIcons name="expand-more" size={20} color="#002C73" />}
        leftIcon={true}
        leftIconName="check"
        visible={showDropDown}
        showDropDown={() => setShowDropDown(true)}
        onDismiss={() => {setShowDropDown(false);dropdownRef.current.focus()}}
      />
    </TouchableOpacity>
  </View>

  const composeButton = <Button 
    onPress={handleClickComposeEmail}
    label="Compose" 
    hoveredStyle={{ color:'#9F2140' }} 
    style={{ width: '100%' }}
  />

  const emailListActionsButtonBar = <View style={{ display: 'flex', flexDirection: 'row' }}>
    {
      !hideVersion1Features && 
      <TouchableOpacity onPress={handleClickComposeEmail} style={{ marginRight: 8 }}>
        <Feather
          name="filter"
          size={24}
          color="#002C73"
          accessible={true}
          accessibilityRole="button"
          accessibilityLabel="Filter emails" />
      </TouchableOpacity>
    }
    {
      !hideVersion1Features && 
      <TouchableOpacity onPress={handleClickComposeEmail} style={{ marginRight: 8 }}>
        <Feather
          name="search"
          size={24}
          color="#002C73"
          accessible={true}
          accessibilityRole="button"
          accessibilityLabel="Search emails" />
      </TouchableOpacity>
    }
    {
      !hideVersion1Features && 
      <TouchableOpacity onPress={fetchMessages} style={{ marginRight: 4 }}>
        <SimpleLineIcons
          name="reload"
          size={24}
          color="#002C73"
          accessible={true}
          accessibilityRole="button"
          accessibilityLabel="Reload emails" />
      </TouchableOpacity>
    }
    <Button 
      onPress={fetchMessages}
      label={<SimpleLineIcons name="reload" size={16} />}
      hoveredStyle={{ color:'#9F2140' }} 
      textStyle={{ paddingLeft: 14, paddingRight: 12, paddingBottom: 2 }}
    />
    </View>


  const emailListItem = (email) => {
    const { subject, isRead, dateCreated, messageId, caseSetId } = email
    const caseItem = cases.find(c => c.caseSetId === caseSetId)
    const { billingProviderName, caseNumber } = caseItem || {}
    const nowDate = new Date()
    const emailDate = new Date(dateCreated)
    const lessThanOneDayAgo = nowDate.getDate() === emailDate.getDate() && nowDate.getMonth() === emailDate.getMonth() && nowDate.getFullYear() === emailDate.getFullYear()

    return <TouchableOpacity 
      key={messageId}
      onPress={() => handleClickViewEmail(email)}
      accessible={true}
      accessibilityRole="button"
      accessibilityLabel="Open email"
      style={{ backgroundColor: selectedEmail === email ? 'rgb(233, 242, 249)' : '' }}
      >
      <Hoverable >
        {({ hovered }) => (
          <View  style={[ styles.emailListItem, { cursor: 'pointer', backgroundColor: hovered ? 'rgb(233, 242, 249)' : '' } ]}>
            <View style={{ width: 20 }}>
              <View style={{ backgroundColor: '#0074C8', width: 12, height: isRead ? 0 : 12, borderRadius: 12, marginLeft: 3, marginTop: 3 }} />
            </View>
            <View style={{ width: 'calc(100% - 50px)', display: 'flex', flexDirection: 'column' }}>
              <RegularText style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <BoldText>{subject}</BoldText>
                {
                  lessThanOneDayAgo ? 
                    <RegularText style={{ marginRight: 8 }}>{ format(new Date(dateCreated), 'h:mma') }</RegularText> :
                    <RegularText style={{ marginRight: 8 }}>{ format(new Date(dateCreated), 'MMM do') }</RegularText>
                }
              </RegularText>
              {
                billingProviderName && caseNumber && 
                <RegularText style={{ marginTop: 3, marginBottom: 3 }}>Provider/Case: {billingProviderName}/{caseNumber}</RegularText>
              }
            </View>
            <View style={{ width: 30, paddingRight: 8, alignItems: 'center' }}>
              <TouchableOpacity style={{ textAlign: 'center' }} onPress={() => handleClickCreateEmail(email)}>
                <MaterialIcons
                  name="reply"
                  size={20}
                  color="rgb(0, 44, 115)"
                  accessible={true}
                  accessibilityRole="button"
                  accessibilityLabel="Reply to email" />
                <RegularText style={{ fontFamily: 'JekoBold', fontSize: 11 }}>Reply</RegularText>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </Hoverable>
    </TouchableOpacity>
  }

  const attachmentItem = (attachment, id, showRemove = false, showDownload = false) => {
    return (
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} key={id}>
        <Link 
          style={{fontSize: 15}}
          onPress={() => handleDownloadAttachment(attachment)} 
          disabled={linkTooltipVisibleLookup.get(attachment.attachmentId)}>
            { attachment.name }
        </Link>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {
            showRemove && 
            <TouchableOpacity onPress={() => handleRemoveAttachment(attachment)}>
              <MaterialIcons
                name="delete"
                size={20}
                color="rgb(0, 44, 115)"
                accessible={true}
                accessibilityRole="button"
                accessibilityLabel="Delete attachment" />
            </TouchableOpacity>
          }
          {
            showDownload && 
            <Tooltip contentStyle={toolTipStyle} disableShadow={true} isVisible={linkTooltipVisibleLookup.get(attachment.attachmentId)} content={<RegularText style={toolTipTextStyle}>Downloading...</RegularText>} placement="left" backgroundColor='rgba(0,0,0,0)' showChildInTooltip={false} onClose={()=>setLinkTooltipVisible(attachment.attachmentId,false)}>
              <TouchableOpacity disabled={linkTooltipVisibleLookup.get(attachment.attachmentId)} onPress={() => handleDownloadAttachment(attachment)}>
                <MaterialIcons
                  name="file-download"
                  size={20}
                  color="rgb(0, 44, 115)"
                  accessible={true}
                  accessibilityRole="button"
                  accessibilityLabel="Download attachment" />
              </TouchableOpacity>
            </Tooltip>
          }
        </View>
      </View>
    )
  }

  const emailBodyTemplate = (email) => {
    const { body, dateCreated, attachments = {}, caseSetId, messageId } = email
    const caseItem = cases.find(c => c.caseSetId === caseSetId)
    const { billingProviderName, caseNumber } = caseItem || {}
    const attachmentsList = [ ...attachments]
    const renderersProps = {
      a: {
        onPress(_, url) {
          window.open(url, '_blank')
        }
      }
    }

      return <View key={messageId}>
      <View style={[styles.emailViewPanel, { borderRadius: 7 }]}>
        <View style={styles.emailViewPanelSubject}>
          <View style={{ maxWidth: '60%' }}>
            {
              caseNumber && 
                <RegularText style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  Provider/Case: {billingProviderName}/{caseNumber}
                </RegularText>
            }
            <RegularText>
            { format(new Date(dateCreated), 'MM/d/yyyy h:mma') }
            </RegularText>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <TouchableOpacity style={{ textAlign: 'center' }} onPress={() => handleClickCreateEmail(email)}>
              <MaterialIcons
                name="reply"
                size={20}
                color="rgb(0, 44, 115)"
                accessible={true}
                accessibilityRole="button"
                accessibilityLabel="Reply to email" />
              <RegularText style={{ fontFamily: 'JekoBold', fontSize: 11 }}>Reply</RegularText>
            </TouchableOpacity>
            {
              !hideVersion1Features && 
              <TouchableOpacity style={{ marginLeft: 10, textAlign: 'center' }} onPress={handleClickComposeEmail}>
                <MaterialIcons
                  name="archive"
                  size={20}
                  color="rgb(0, 44, 115)"
                  accessible={true}
                  accessibilityRole="button"
                  accessibilityLabel="Archive email"/>
                <RegularText style={{ fontFamily: 'JekoBold', fontSize: 11 }}>Archive</RegularText>
              </TouchableOpacity>
            }
          </View>
        </View>
        <div className="glassboxmask" id="maskpii-div">
        <View style={{ padding: 6, overflow: 'auto' }} onLayout={(event) => {
          const { width } = event.nativeEvent.layout;
          setEmailBodyWidth(width - 25)
        }}>
          {
            body.includes('</') &&
              <RenderHtml
                contentWidth={emailBodyWidth}
                source={{ html: body }}
                renderersProps={renderersProps}
              />
          }
          {
            !body.includes('</') &&
            <RegularText>{ body }</RegularText>
          }
          </View>
          </div>
      </View>
      {
        attachmentsList.length > 0 && 
        <View style={[styles.emailViewPanelAttachments, { marginTop: 4, marginBottom: 8}]}>
          {
            attachmentsList.map(a => attachmentItem(a, a.attachmentId, false, true))
          }
        </View>
      }
    </View>
  }
  const disabledSend = !emailBody || emailSending
  const disabled = emailSending
  const emailDisplayPanel = (email, showReplyInput) => {
    const { subject = "", messageId = "" } = email || {}
    const newEmail = showReplyInput && !subject
    let subjectDisplay = ''
    if (showReplyInput) {
      if (subject) {
        subjectDisplay = `RE: ${subject}`
      } else {
        subjectDisplay = 'Question for my advisor'
      }
    } else {
      subjectDisplay = subject
    }
    return <View>
      { 
        showEmailSentSuccess &&
        <View style={{ alignItems: 'left' }}>
          <View
            style={{ 
              display: 'flex', 
              flexDirection: 'row', 
              width: isMobile ? '100%' : 'auto', 
              justifyContent: 'start',
              borderLeftWidth: 8,
              borderStyle: 'solid',
              borderColor: '#61A60D',
              backgroundColor: '#EFF5E5',
              borderRadius: 8,
              paddingTop: 8,
              paddingRight: 16,
              paddingBottom: 8,
              paddingLeft: 8,
              marginBottom: 20 
            }}
          >
            <MaterialIcons 
              name="check-circle" 
              size={22} 
              color="#61A60D" 
            />
            <Wrapper>
            <RegularText
              style={{
                fontFamily: 'JekoBold',
                fontSize: 16,
                color: '#002C73',
                marginLeft: 5
              }}
            >
              Your message was sent! 
          </RegularText>
          <RegularText>Your advisor will reach out soon.</RegularText>
          </Wrapper>
        </View>
        
      </View>
      }
      { 
        emailSending &&
        <View style={{ alignItems: 'left' }}>
          <View 
          style={{ 
            display: 'flex', 
            flexDirection: 'row', 
            width: isMobile ? '100%' : 'auto', 
            justifyContent: 'start',
            borderLeftWidth: 8,
            borderStyle: 'solid',
            borderColor: '#002C73',
            backgroundColor: '#E9F2F9',
            borderRadius: 8,
            paddingTop: 8,
            paddingRight: 16,
            paddingBottom: 8,
            paddingLeft: 8,
            marginBottom: 20 
          }}
          >
            <MaterialIcons 
              name="info" 
              size={22} 
              color="#002C73" 
            />
            <RegularText
              style={{
                fontFamily: 'JekoBold',
                fontSize: 16,
                color: '#002C73',
                marginLeft: 5
              }}
            >
              Sending your message...
          </RegularText>
        </View>
      </View>
      }
      { 
        !showEmailSentSuccess && showEmailSentFailure &&
        <View style={{ alignItems: 'left' }}>
          <View
            style={{ 
              display: 'flex', 
              flexDirection: 'row', 
              width: isMobile ? '100%' : 'auto', 
              justifyContent: 'start',
              borderLeftWidth: 8,
              borderStyle: 'solid',
              borderColor: '#FF3333',
              backgroundColor: '#fce4e4',
              borderRadius: 8,
              paddingTop: 8,
              paddingRight: 16,
              paddingBottom: 8,
              paddingLeft: 8,
              marginBottom: 20 
            }}
          >
            <MaterialIcons 
              name="info" 
              size={22} 
              color="red" 
            />
            <Wrapper>
            <RegularText
              style={{
                fontFamily: 'JekoBold',
                fontSize: 16,
                color: '#002C73',
                marginLeft: 5
              }}
            >
              Your message failed to send! Please try again. 
          </RegularText>
          <RegularText>If you continue to experience this error you can contact your advisor at (866) 622-8015.</RegularText>
          </Wrapper>
        </View>
        
      </View>
      }
      <View style={[styles.emailViewPanel, { borderWidth: email || showReplyInput ? 1 : 0 }]}>
        {
          (email || newEmail) && 
          <View style={styles.emailViewPanelSubject}>
              <BoldText style={{ fontFamily: 'JekoBold', fontSize: 24 }}>
                {subjectDisplay}
              </BoldText>
            <View style={{ display: 'flex', flexDirection: 'row', width: isMobile ? '100%' : 'auto', justifyContent: 'end' }}>
              {
                showReplyInput && 
                <TouchableOpacity style={{ textAlign: 'center' }} disabled={!emailBody || emailSending} onPress={() => subject ? handleClickSendEmailReply(email) : handleClickSendEmail()}>
                  <MaterialIcons
                    name="send"
                    size={20}
                    color={disabledSend ? '#7A858C' : "rgb(0, 44, 115)"}
                    disabled={!emailBody || emailSending}                    
                    accessible={true}
                    accessibilityRole="button"
                    accessibilityLabel="Send new email" />
                    <RegularText style={{ fontFamily: 'JekoBold', fontSize: 11, color: disabledSend ? '#7A858C' : "rgb(0, 44, 115)" }}>Send</RegularText>
                </TouchableOpacity>
              }
              {
                showReplyInput && 
                <TouchableOpacity style={{ marginLeft: 10, textAlign: 'center' }} disabled={emailSending} onPress={handleUploadOpen}>
                  <MaterialIcons
                    name="attachment"
                    size={20}
                    color={disabled ? '#7A858C' : "rgb(0, 44, 115)"}
                    disabled={emailSending}
                    accessible={true}
                    accessibilityRole="button"
                    accessibilityLabel="Attach file" />
                  <RegularText style={{ fontFamily: 'JekoBold', fontSize: 11, color: disabled ? '#7A858C' : "rgb(0, 44, 115)" }}>Attach File</RegularText>
                </TouchableOpacity>
              }
              {
                showReplyInput && 
                <TouchableOpacity style={{ marginLeft: 10, textAlign: 'center' }} disabled={emailSending} onPress={handleDiscardEmail}> 
                  <Feather 
                    name="trash" 
                    size={18}
                    color={disabled ? '#7A858C' : "rgb(0, 44, 115)"}
                    disabled={emailSending}
                    accessible={true}
                    accessibilityRole="button"
                    accessibilityLabel="Close new email" />
                  <RegularText style={{ fontFamily: 'JekoBold', fontSize: 11, color: disabled ? '#7A858C' : "rgb(0, 44, 115)" }}>Discard</RegularText>
                </TouchableOpacity> 
              }
              {
                !hideVersion1Features && email && 
                <TouchableOpacity style={{ marginLeft: 10 }} onPress={() => handleClickCreateEmail(email)}>
                  <MaterialIcons 
                    name="reply" 
                    size={20}
                    color="rgb(0, 44, 115)" 
                    accessible={true}
                    accessibilityRole="button"
                    accessibilityLabel="Reply to email" />
                </TouchableOpacity>
              }
              {
                !hideVersion1Features && email &&
                <TouchableOpacity style={{ marginLeft: 10 }} onPress={handleClickComposeEmail}>
                  <MaterialIcons
                    name="archive"
                    size={20}
                    color="rgb(0, 44, 115)" 
                    accessible={true}
                    accessibilityRole="button"
                    accessibilityLabel="Archive email"/>
                </TouchableOpacity>
              }
            </View>
          </View>
        }
        <View style={{ padding: 12 }}>
          {
            newEmail &&
            <>
              <BoldText style={{ fontFamily: 'JekoBold', fontSize: 14 }}>Provider and Case</BoldText>
              <CustomSelect 
                options={cases} 
                valueKey="caseSetId" 
                labelKey="caseNumber" 
                placeHolder=""
                onValueChange={(opt) => setSelectedCaseSetId(opt.caseSetId)}
                value={cases.find(c => c.caseSetId === selectedCaseSetId)}
                customLabel={
                  (opt) => <RegularText>{opt.billingProviderName} {opt.caseNumber}</RegularText>
                }
                style={{ padding: 4 }}>

              </CustomSelect>
            </>
          }
          {
            showReplyInput &&
            <View style={{ marginBottom: 8 }}>
              <BoldText style={{ fontFamily: 'JekoBold', fontSize: 14, marginTop: 6 }}>Message</BoldText>
              <View >
                <FreeTextInput
                  multiline={true}
                  numberOfLines={8}
                  onChangeText={(text: string) =>
                    handleSetEmailBody(text)
                  }
                  testID={`reply-input-${messageId}`}
                />
              </View>
              {
                emailAttachments.length > 0 && 
                <View style={[styles.emailViewPanelAttachments, { marginTop: 4, marginBottom: 8 }]}>
                  {
                    emailAttachments.map(a => attachmentItem(a, a.lastModifiedDate, true, false))
                  }
                </View>
              }
   
            </View>
          }
          {
            selectedEmail && [selectedEmail, ...allReplies].map(e => emailBodyTemplate(e))
          }
        </View>
      </View>
    </View>
  }

  return (
    <Layout 
      testID="documents-root"
      mainSectionWrapper={{ paddingTop: 0, paddingRight: isMobile ? 12 : 65, paddingLeft: isMobile ? 12 : 65 }}
      mainSectionStyle={styles.layout}
      >
      <CardComponent style={{ marginTop: 0, padding: 0 }}>
        <CardTitle>Message Center</CardTitle>
        <View style={{ padding: 16, backgroundColor: '#FFFFFF', borderRadius: 14, top: -14 }}>
          <View style={{ marginBottom: 14 }}>
            { !hideVersion1Features && emailSelector }
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ width: isMobile ? '100%' : '40%', display: isMobile ? (mobileViewList ? 'flex' : 'none') : 'flex' }}>
              { composeButton }
              <View style={ styles.buttonBar }>
                { emailListActionsButtonBar }
              </View>
              <View style={{ height: 'calc(100vh - 300px)', overflow: 'auto' }}>
                { allEmails.map(email => emailListItem(email)) }
              </View>
              <View style={{ backgroundColor: '#E9F2F9', padding: 12 }}>
                <RegularText>These emails are sent by:</RegularText>
                <BoldText>
                  Naviguard
                </BoldText>
                <BoldText>
                  9700 Health Care Lane
                </BoldText>
                <BoldText style={{ marginBottom: 8 }}>
                  Minnetonka, MN 55343
                </BoldText>
                <Hoverable accessible={true} accessibilityRole='link' style={{ marginBottom: 8 }}>
                  {({ hovered }) => (
                    <TouchableOpacity
                      accessible={true}
                      accessibilityLabel="View Privacy Policy"
                      onPress={() => handlePrivacyPolicyClick(privacyPolicyURL, 'Privacy Policy')}
                      style={{
                        width: 'fit-content',
                        borderBottom: hovered ? '1px solid rgb(0, 44, 115)' : '1px solid transparent'
                      }}
                    >
                      <BoldText style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <>
                          Privacy Policy
                        </>
                        <MaterialIcons name="open-in-new" style={{ marginLeft: 4 }} size={14} />
                      </BoldText>
                    </TouchableOpacity>
                  )}
                </Hoverable>
                <RegularText style={{ marginBottom: 8 }}>
                  These emails are being sent to you as a member of a UnitedHealthcare plan or administered plan. Participation in Naviguard is limited to persons eligible for such benefit under their UnitedHealthcare provided or administered health insurance plan. Naviguard does not provide legal services or legal advice.
                </RegularText>
                <Hoverable accessible={true} accessibilityRole='link' style={{ marginBottom: 8 }}>
                  {({ hovered }) => (
                    <TouchableOpacity
                      accessible={true}
                      accessibilityLabel="View Privacy Policy"
                      onPress={() => handlePrivacyPolicyClick(nonDiscriminationURL, 'Language Assistance and Non-discrimination Notice')}
                      style={{
                        width: 'fit-content',
                        borderBottom: hovered ? '1px solid rgb(0, 44, 115)' : '1px solid transparent'
                      }}
                    >
                      <BoldText>
                        <View  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <>
                            Language Assistance and Non-discrimination Notice
                          </>
                          <MaterialIcons name="open-in-new" style={{ marginLeft: 4 }} size={14} />
                        </View>
                      </BoldText>
                    </TouchableOpacity>
                  )}
                </Hoverable>
                <Hoverable accessible={true} accessibilityRole='link' style={{ marginBottom: 8 }}>
                  {({ hovered }) => (
                    <TouchableOpacity
                      accessible={true}
                      accessibilityLabel="View Privacy Policy"
                      onPress={() => handlePrivacyPolicyClick(nonDiscriminationURL, 'Asistencia de Idiomas / Aviso de no Discriminación')}
                      style={{
                        width: 'fit-content',
                        borderBottom: hovered ? '1px solid rgb(0, 44, 115)' : '1px solid transparent'
                      }}
                    >
                      <BoldText>
                        <View  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <>
                            Asistencia de Idiomas / Aviso de no Discriminación
                          </>
                          <MaterialIcons name="open-in-new" style={{ marginLeft: 4 }} size={14} />
                        </View>
                      </BoldText>
                    </TouchableOpacity>
                  )}
                </Hoverable>
                <Hoverable accessible={true} accessibilityRole='link' style={{ marginBottom: 8 }}>
                  {({ hovered }) => (
                    <TouchableOpacity
                      accessible={true}
                      accessibilityLabel="View Privacy Policy"
                      onPress={() => handlePrivacyPolicyClick(nonDiscriminationURL, '語言協助 / 不歧視通知')}
                      style={{
                        width: 'fit-content',
                        borderBottom: hovered ? '1px solid rgb(0, 44, 115)' : '1px solid transparent'
                      }}
                    >
                      <BoldText>
                        <View  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <>
                            語言協助 / 不歧視通知
                          </>
                          <MaterialIcons name="open-in-new" style={{ marginLeft: 4 }} size={14} />
                        </View>
                      </BoldText>
                    </TouchableOpacity>
                  )}
                </Hoverable>
              </View>
            </View>
            <View style={{ width: isMobile ? '100%' : '60%', display: isMobile ? (!mobileViewList ? 'flex' : 'none') : 'flex', paddingLeft: isMobile ? 0 : 12 }}>
              {
                (selectedEmail || advisorName()) && 
                <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: 18 }}>
                  <View>
                    {
                      isMobile && 
                      <Button 
                        onPress={() => setMobileViewList(true)}
                        label={<MaterialIcons name="keyboard-arrow-left" size={20} />}
                        hoveredStyle={{ color:'#9F2140' }} 
                        textStyle={{ paddingLeft: 14, paddingRight: 12, paddingBottom: 2 }}
                      />
                    }
                  </View>
                  {
                    advisorName() && 
                    <View>
                      <CardComponent style={{ padding: 0, marginTop: 0, height: 68, width: 168, backgroundColor: "#E9F2F9" }}>
                        <CardTitle smallHeader>Your Advisor</CardTitle>
                        <View style={{ padding: 5, backgroundColor: '#E9F2F9', borderRadius: 14, top: -24, height: 50 }}>
                          <RegularText style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 38 }}>
                            { advisorName() }
                          </RegularText>
                        </View>
                      </CardComponent>
                    </View>
                  }
                </View>
              }
              {
                emailDisplayPanel(selectedEmail, showCompose)
              }
            </View>
          </View>

        </View>
      </CardComponent>
      <Upload attachFiles={(files) => setEmailAttachments(files)} onClose={handleUploadClose} visible={uploadVisible}/>
    </Layout>
  )
}

const toolTipTextStyle={
  fontSize: 14,
  color: '#ffffff'
}

const toolTipStyle={
  padding: 12,
  borderRadius: 2,
  backgroundColor:'#002C73',
  fontFamily:'SourceSansPro',
  width: 116,
  height: 44
}

const FreeTextInput = styled(TextInput)`
  font-family: SourceSansPro-Regular;
  font-size: 16px;
  border-width: 1px;
  border-color: #002C73;
  padding: 12px;
  color: #002C73;
  background: #F5F9FB;
  border-radius: 7;
`;

const styles = StyleSheet.create({
  emailViewPanelAttachments: {
    padding: 10,
    borderColor: '#C0C5C7',
    borderWidth: 1,
    borderRadius: 7,
  },
  emailViewPanelSubject: {
    padding: 10,
    borderBottomColor: '#C0C5C7',
    borderBottomWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap-reverse'
  },
  emailViewPanel: {
    borderColor: '#C0C5C7', 
    borderWidth: 1, 
    borderRadius: 14,
    marginBottom: 14
  },
  buttonBar: {
    marginTop: 16, 
    marginBottom: 3, 
    paddingTop: 5,
    paddingBottom: 5,
    //borderBottomWidth: 1, 
    borderBottomColor: '#C0C5C7', 
    borderTopWidth: 1, 
    borderTopColor: '#C0C5C7',
    alignItems: 'flex-end'
  },
  emailListItem: {
    display: 'flex', 
    flexDirection: 'row', 
    width: '100%',
    paddingTop: 6,
    paddingBottom: 6,
    borderTopWidth: 1, 
    borderTopColor: '#C0C5C7'
  },
  dropDown: {
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  layout: {
    flexBasis: '100%',
    padding: 0
  }
})