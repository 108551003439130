import React, { useState, useEffect } from 'react'
import { Question, FreeTextInput } from '../elements'
import QuestionContainer from '../questionContainer'
import { getErrorText, questions } from '../constants'
import Text from '../../TextComponent'
import { View } from 'react-native'

interface ICommentsQuestion {
  errors: any;
  setValue: (qId: string, answer: string) => void;
}

const max = questions['comments'].rules.maxLength.value
export default function CommentsQuestion(props: ICommentsQuestion) {
  const { errors, setValue } = props
  const [ isError, setIsError ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState("")
  const [ charactersLeft, setCharactersLeft ] = useState(max)

  useEffect(() => {
    if(typeof errors['comments'] !== 'undefined') {
      setIsError(true)
    } 
  }, [errors])

  const onChangeText = (text) => {
    let remaining = max - text.length 
    let _isError = text.length > max
    if(_isError) {
      setErrorMessage("You have reached your maximum limit of characters allowed.")
    } else {
      setErrorMessage("")
    }
    setIsError(_isError)
    setCharactersLeft(remaining)
    setValue('comments', text)
  }

  return (
    <Question>
      <View
        accessible
        accessibilityLabel="comments"
      >
      <View style={{flexDirection:'row'}}>
        <label id="comments" style={{ fontSize: '16px', fontFamily: 'SourceSansPro-Bold', color: '#002C73', marginBottom: 6}}>Comments</label>
        <Text size={16}> (Required)</Text>
      </View>

      <QuestionContainer
        error={isError}
        errorMessage={errorMessage || getErrorText(errors['comments'])}
        noBorderOnError={true}
        id="error-comment"
      >
        <FreeTextInput
          aria-labelledby="comments"
          aria-required="true"
          id="comments"
          error={isError}
          multiline={true}
          numberOfLines={3}
          onChangeText={(text: string) => onChangeText(text)} />
        <Text style={{fontStyle: 'italic', marginTop: 9,color:"#000000"}}>{`${charactersLeft} characters left.`}</Text>
      </QuestionContainer>
      </View>
    </Question>
  )
}