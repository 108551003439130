import { Linking } from "react-native"
import { Case } from "../redux/cases/types"

export const advisorEmail = process.env.EXPO_ADVISOR_EMAIL

export const openAdvisorEmail = (selectedCase?: Case) => {
    const subject = encodeURIComponent('Question for my Naviguard Advisor')

    const body = selectedCase
        ? encodeURIComponent(`\n\nDo not modify anything below this line:\n${selectedCase.emailTag}`)
        : ''

    Linking.openURL(`mailto:${advisorEmail}?subject=${subject}&body=${body}`)
}
