import React from 'react'
import { View } from 'react-native';
import Skeleton from '../Skeleton';
import styled from 'styled-components/native'

export interface TextFormatProps {
  nativeID?: string;
  editMode:boolean;
  value:string;
  onBlur?:()=>void;
  onChangeText?:(...event:any[])=>void;
  errorState:boolean;
  dataLoaded:boolean;
  width?:string;
  editModeHeight?:string;
  readOnlyModeHeight?:string;
  id?:string;
  ariaDescribedby?: string;
  required?:boolean;
}

export default  function TextFormat(textFormatProps:TextFormatProps){
const {editMode,value,onBlur,onChangeText,errorState,dataLoaded,width,editModeHeight,readOnlyModeHeight,required, id, ariaDescribedby} = textFormatProps;
let inputWidth=width
let editableInputHeight=editModeHeight
let readOnlyInputHeight=readOnlyModeHeight

if(!inputWidth)
  inputWidth="350px"

if(!editableInputHeight)
  editableInputHeight="35px"

if(!readOnlyInputHeight)
  readOnlyInputHeight="20px"

return(
  <>
   { !dataLoaded &&
    <View  style= {{height: readOnlyInputHeight, width:inputWidth}}>
     <Skeleton />
    </View>
   }
   { dataLoaded &&
      <EditableText
        nativeID={textFormatProps.nativeID}
        accessible={editMode}
        aria-labelledby={id}
        aria-describedby={ariaDescribedby}
        aria-invalid={errorState ? 'true' : 'false'}
        aria-required={required ? 'true' : 'false'}
        editable={editMode} onBlur={onBlur}
        onChangeText={(text) => onChangeText(text)}
        value={value}
        hasErrors={errorState}
        width={inputWidth}
        editModeHeight={editableInputHeight}
        readOnlyModeHeight={readOnlyInputHeight}
        data-focusable={editMode ? "true" : "false"}
      />
   }
   </>
)
}

const EditableText = styled.TextInput`
    height: ${(props) => (props.editable ? props.editModeHeight :props.readOnlyModeHeight)};
    width: ${(props)=>props.width};
    border-color: ${(props) => (props.hasErrors ? '#E00000' : '#002C73')};
    border-width: ${(props) =>
        props.hasErrors ? '2px' : props.editable ? '1px' : '0px'};
    padding-left: ${(props) => (props.editable ? '21px' : '0px')};
    font-family: 'JekoBold';
    border-radius: 7px;
    background-color:  ${(props) => (props.editable ? '#E9F2F9' : 'transparent')};
    color: #002C73;
    font-size: 16px;
`
